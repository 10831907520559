import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { getAPI } from '../Base/API';
import { StyledCard, ActiveInactiveStatusBadge } from '../Base/Common/CommonUIComponents';
import { CustomTable } from '../Base/Common/CustomTable';
import { FormattedMessage, injectIntl } from "react-intl";
import Authorization from '../Base/Authorization';

export class VoucherIssueAutomation extends Component {
    static displayName = VoucherIssueAutomation .name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            pmsConnection: [],
            currentPage: 0,
            PageSize: 10,
        }
    }


    componentDidMount() {
        this.getPmsConnection();

    }

    getPmsConnection() {
        this.setState({ blockCfg: true });


        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ pmsConnection: data.response, block: false });
            }
        }, '/api/Voucher/AutoVoucherReservation/v1/AutoVoucherReservationConfiguration');
    }

    render() {

        const columns = [
            {
                dataField: 'description',
                text: this.props.intl.formatMessage({ id: "VoucherIssueAutomation.Name" }),
                sort: true
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "VoucherIssueAutomation.status" }),
                sort: true,
                formatter: (cell, row) => <ActiveInactiveStatusBadge status={cell} />
            },
            {
                dataField: 'validFrom',
                text: this.props.intl.formatMessage({ id: "VoucherIssueAutomation.from" }),
                sort: true
            },
            {
                dataField: 'validTo',
                text: this.props.intl.formatMessage({ id: "VoucherIssueAutomation.to" }),
                sort: true
            },
            {
                dataField: 'Hotels',
                text: this.props.intl.formatMessage({ id: "VoucherIssueAutomation.hotels" }),
                sort: true
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push('/VoucherIssueAutomationDetails/' + this.state.pmsConnection[rowIndex].id)
            }
        };

        return (
            <StyledCard icon={'fas fa-robot'} title={'VoucherIssueAutomation.title'} help={<FormattedMessage id="VoucherIssueAutomation.Help" />} id="voucherIssueAutomation" error={this.state.error} block={this.state.block}>
                <Row>
                    <Col className="text-right">
                        <Authorization
                            perform="voucherIssueAutomation:add"
                            yes={() => (
                                <Button className="btn-sm btn-host mb-1" >
                                    <i className="fas fa-plus" onClick={() => this.props.history.push('/VoucherIssueAutomationDetails/')} />
                                </Button>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>

                </Row>
                <CustomTable
                    keyField='hotelId'
                    data={this.state.pmsConnection ? this.state.pmsConnection : []}
                    columns={columns}
                    shadow={false}
                    showTotal={true}
                    selectRow={selectRow}
                />
            </StyledCard>


        );
    }
}

export default injectIntl(VoucherIssueAutomation);