import React, { Component } from "react";
import { FormGroup, Label, Col, Input, Row, Button, Form } from "reactstrap";
import countryList from "react-select-country-list";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomSelect from "../Base/Common/CustomSelect";
import { StyledCard } from "../Base/Common/CommonUIComponents";
import {getCurrency} from "../Base/Common/ReferenceDataFunctions";
import { getAPI, postAPI, putAPI } from "../Base/API";
import { handleNotification } from "../Base/Common/Notification";
import Authorization from '../Base/Authorization';

export class Hotel extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.setComboLabel = this.setComboLabel.bind(this);
        this.setCombo = this.setCombo.bind(this);
        this.getHotelGroup = this.getHotelGroup.bind(this);
        this.saveHotel = this.saveHotel.bind(this);
        this.state = {
            block: false,
            hotelGroup: [],
            hotel: {},
            isEditMode: this.props.match.params.id != null,
            licenses: []
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.state.isEditMode) {
            this.getHotel();
        }
        this.getHotelGroup();
    }

    getHotel() {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                this.setState({ hotel: data.response[0] });
            }
            this.setState({ block: false, error: errorMessage });
        }, `/api/Voucher/Hotel/v1/Hotel/${this.props.match.params.id}`);
    }

    getHotelGroup() {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                data.response.forEach(hotelGroup => {
                    hotelGroup.value = hotelGroup.hotelGroupId;
                    hotelGroup.label = hotelGroup.name;
                });
                this.setState({ hotelGroup: data.response });
            }
            this.setState({ block: false, error: errorMessage });
        }, `/api/Voucher/Hotel/v1/HotelGroup`);
    }

    saveHotel(event) {
        event.preventDefault();

        const { hotel, block } = this.state;
        const regex = /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i;
        const hotelId = document.getElementById('hotelId');
        if (hotel.hotelId && !regex.test(hotel.hotelId)) {
            hotelId.setCustomValidity(this.props.intl.formatMessage({ id: "hotel.hotelIdInvalidFormat" }));
        }
        else {
            hotelId.setCustomValidity("");
        }        

        if (!this.form.current.reportValidity()) {
            return;
        }

        if (block) {
            return;
        }

        const request = {
            request: hotel
        };

        if (this.state.isEditMode) {
            putAPI( result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({
                        message: error.message,
                        stack: error.stack,
                        messageType: "danger"
                    });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id={`hotel.SaveMessageSuccess`} />, <FormattedMessage id="Hotel.Success" />);
                }
                this.setState({ block: false });
            },
            `/api/Voucher/Hotel/v1/Hotel`, JSON.stringify(request));
        }
        else {
            postAPI( result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({
                        message: error.message,
                        stack: error.stack,
                        messageType: "danger"
                    });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id={`hotel.SaveMessageSuccess`} />, <FormattedMessage id="Hotel.Success" />);
                }
                this.setState({ block: false });
            },
            `/api/Voucher/Hotel/v1/Hotel`, JSON.stringify(request) );
        }
    }

    handleInput({ target: { value, name } }) {
        const { hotel } = this.state;
        hotel[name] = value;
        this.setState({ hotel });
    }

    setComboLabel(combo, evt) {
        if (evt) {
            const { hotel } = this.state;
            hotel[evt.name] = combo && combo.label;
            this.setState({ hotel });
        }
    }

    setCombo(combo, evt) {
        if (evt) {
            const { hotel } = this.state;
            hotel[evt.name] = combo && combo.value;
            this.setState({ hotel });
        }
    }

    render() {
        const help = <div>TODO!</div>;

        const { hotel } = this.state;

        const countries = countryList().getData();

        return (
            <form ref={this.form}>
                <Authorization
                    perform="hotel:save"
                    yes={() => (
                        <div>
                            <Row className="mb-2">
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" onClick={this.saveHotel}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col>
                                    <StyledCard block={this.state.block} icon="far fa-address-card" help={help}>
                                        <FormGroup row>
                                            <FormattedMessage id={`hotel.hotelId`}>
                                                {
                                                    placeholder =>
                                                        (
                                                            <>
                                                                <Label for="hotelId" sm={2}>
                                                                    {placeholder}
                                                                </Label>
                                                                <Col sm={4}>
                                                                    <Input type="text" name="hotelId" id="hotelId" required={true} placeholder={placeholder} value={hotel.hotelId} onChange={e => this.handleInput(e)} disabled={this.state.isEditMode} />
                                                                </Col>
                                                            </>
                                                        )
                                                }
                                            </FormattedMessage>
                                            <FormattedMessage id={`hotel.hotelGroupId`}>
                                                {
                                                    placeholder =>
                                                        (
                                                            <>
                                                                <Label for="hotelGroupId" sm={2}>
                                                                    {placeholder}
                                                                </Label>
                                                                <Col sm={4}>
                                                                    <CustomSelect options={this.state.hotelGroup} name="hotelGroupId" id="hotelGroupId" required={true} isClearable isMulti={false} value={this.state.hotelGroup.find(hotelGroup => hotelGroup.value === this.state.hotel.hotelGroupId)} isSearchable placeholder={placeholder} onChange={this.setCombo} isDisabled={this.state.isEditMode} />
                                                                </Col>
                                                            </>
                                                        )
                                                }
                                            </FormattedMessage>
                                        </FormGroup>
                                    </StyledCard>
                                </Col>
                            </Row>
                        </div>
                    )}
                    no={() => <div/>}
                />

                <Row className="mb-2">
                    <Col>
                        <StyledCard block={this.state.block} error={this.state.error} icon="far fa-address-card" help={help}>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.name1`}>
                                    {placeholder => (
                                        <>
                                            <Label for="name1" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                                <Input type="text" required name="name1" id="name1" placeholder={placeholder} value={hotel.name1} onChange={e => this.handleInput(e)} />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                                <FormattedMessage id={`hotel.name2`}>
                                    {placeholder => (
                                        <>
                                            <Label for="Name2" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                                <Input type="text" required name="name2" id="name2" placeholder={placeholder} value={hotel.name2} onChange={e => this.handleInput(e)} />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                            </FormGroup>
                        </StyledCard>
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Col>
                        <StyledCard block={this.state.block} icon="fas fa-at" help={help}>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.fiscalNumber`}>
                                    {placeholder => (
                                        <>
                                            <Label for="fiscalNumber" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                                <Input
                                                    type="text" required name="fiscalNumber" id="fiscalNumber" placeholder={placeholder} bsSize="sm" value={hotel.fiscalNumber} onChange={e => this.handleInput(e)} />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>

                                <FormattedMessage id={`hotel.contact`}>
                                    {placeholder => (
                                        <>
                                            <Label for="contact" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                                <Input
                                                    type="text" required name="contact" id="contact" placeholder={placeholder} bsSize="sm" value={hotel.contact} onChange={e => this.handleInput(e)} />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                            </FormGroup>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.phone`}>
                                    {placeholder => (
                                        <>
                                            <Label for="phone" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                                <Input type="text" required name="phone" id="phone" placeholder={placeholder} bsSize="sm" value={hotel.phone} onChange={e => this.handleInput(e) } />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>

                                <FormattedMessage id={`hotel.email`}>
                                    {placeholder => (
                                        <>
                                            <Label for="email" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                                <Input
                                                    type="text" required name="email" id="email" placeholder={placeholder} bsSize="sm" value={hotel.email} onChange={e => this.handleInput(e)} />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>                                
                            </FormGroup>

                            <FormGroup row>
                                <FormattedMessage id={`hotel.currencyCode`}>
                                    {
                                        placeholder =>
                                            (
                                                <>
                                                    <Label for="defaultCurrency" sm={2}>
                                                        {placeholder}
                                                    </Label>
                                                    <Col sm={4}>
                                                        <CustomSelect icon={"fas fa-coins"} name="defaultCurrency" id="defaultCurrency" options={getCurrency()} required={true} isClearable isMulti={false} value={getCurrency().find(currencyCode => currencyCode.label === this.state.hotel.defaultCurrency)} isSearchable placeholder={placeholder} onChange={this.setComboLabel} />
                                                    </Col>
                                                </>
                                            )
                                    }
                                </FormattedMessage>
                                <FormattedMessage id={`hotel.website`}>
                                    {
                                        placeholder =>
                                            (
                                                <>
                                                    <Label for="webSite" sm={2}>
                                                        {placeholder}
                                                    </Label>
                                                    <Col sm={4}>
                                                        <Input type="text" required name="webSite" id="webSite" placeholder={placeholder} bsSize="sm" value={hotel.webSite} onChange={e => this.handleInput(e)} />
                                                    </Col>
                                                </>
                                            )
                                    }
                                </FormattedMessage>
                            </FormGroup>
                        </StyledCard>
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Col>
                        <StyledCard block={this.state.block} icon="far fa-map" help={help}>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.address1`}>
                                    {
                                        placeholder =>
                                            (
                                                <>
                                                    <Label for="street1" sm={2}>
                                                        {placeholder}
                                                    </Label>
                                                    <Col sm={10}>
                                                        <Input type="text" required name="address1" id="Street1" placeholder={placeholder} value={hotel.address1} onChange={e => this.handleInput(e)} />
                                                    </Col>
                                                </>
                                            )
                                    }
                                </FormattedMessage>
                            </FormGroup>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.address2`}>
                                    {
                                        placeholder =>
                                            (
                                                <>
                                                    <Label for="street2" sm={2}>
                                                        {placeholder}
                                                    </Label>
                                                    <Col sm={10}>
                                                        <Input type="text" required name="address2" id="Street2" placeholder={placeholder} value={hotel.address2} onChange={e => this.handleInput(e)} />
                                                    </Col>
                                                </>
                                            )
                                    }
                                </FormattedMessage>
                            </FormGroup>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.city`}>
                                    {
                                        placeholder =>
                                            (
                                                <>
                                                    <Label for="City" sm={2}>
                                                        {placeholder}
                                                    </Label>
                                                    <Col sm={4}>
                                                        <Input type="text" required name="city" id="city" placeholder={placeholder} value={hotel.city} onChange={e => this.handleInput(e)} />
                                                    </Col>
                                                </>
                                            )
                                    }
                                </FormattedMessage>
                            </FormGroup>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.zipCode`}>
                                    {
                                        placeholder =>
                                            (
                                                <>
                                                    <Label for="ZipCode" sm={2}>
                                                        {placeholder}
                                                    </Label>
                                                    <Col sm={4}>
                                                        <Input type="text" required name="zipCode" id="ZipCode" placeholder={placeholder} value={hotel.zipCode} onChange={e => this.handleInput(e)} />
                                                    </Col>
                                                </>
                                            )
                                    }
                                </FormattedMessage>
                                <FormattedMessage id={`hotel.country`}>
                                    {
                                        placeholder =>
                                            (
                                                <>
                                                    <Label for="countryCode" sm={2}>
                                                        {placeholder}
                                                    </Label>
                                                    <Col sm={4}>
                                                        <CustomSelect icon={"fas fa-globe"} options={countries} name="countryCode" id="countryCode" required={true} isClearable isMulti={false} value={countries.find(currencyCode => currencyCode.value === this.state.hotel.countryCode)} isSearchable placeholder={placeholder} onChange={this.setCombo} />
                                                    </Col>
                                                </>
                                            )
                                    }
                                </FormattedMessage>
                            </FormGroup>
                        </StyledCard>
                    </Col>
                </Row>
            </form>
        );
    }
}
export default injectIntl(Hotel);
