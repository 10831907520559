
import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Input, Button, Badge } from 'reactstrap';
import { getAPI } from '../Base/API';
import { StyledCard, NotificationCard } from '../Base/Common/CommonUIComponents';
import { CustomTable } from '../Base/Common/CustomTable';
import { FormattedMessage, injectIntl } from "react-intl";

import { SingleDatePicker } from 'react-dates';
import moment from "moment";

export class ReservationVoucherDetail extends Component {
    static displayName = ReservationVoucherDetail.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            reservation: {},
            currentPage: 0,
            PageSize: 10,
        }
    }


    componentDidMount() {
        if (this.props.match.params.id)
            this.getReservationsVouherDetails(this.props.match.params.id)
        

    }

    getReservationsVouherDetails(id) {
        this.setState({ blockCfg: true });


        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ reservation: data.response[0], block: false });
            }
        }, '/api/Voucher/VoucherReservation/v1/VoucherReservation/'+id);
    }
    voucherDetail(id) { this.props.history.push('/VoucherOperationDetails/' + id) }
    configDetail(id) { this.props.history.push('/VoucherIssueAutomationDetails/' + id) }
    
    setComboStatus = (evt, inputValue) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value);
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    }

    render() {

       

        const help = <div> </div>
        return (
            <>
            <StyledCard icon={'fas fa-suitcase'} title={'ReservationVoucherDetail.Reservation'} help={help} error={this.state.error} block={this.state.block}>
                <Row className="mb-2">
                    <Col className="col-2"><FormattedMessage id="ReservationVoucherDetail.reservationId" /></Col>
                        <Col className="col-4"> {this.state.reservation.pmsReservation && this.state.reservation.pmsReservation.reservationNumber}</Col>
                    <Col className="col-2"><FormattedMessage id="ReservationVoucherDetail.pmsReservationDetailId" /></Col>
                    <Col className="col-4"> {this.state.reservation.pmsReservationDetailId}</Col>
                </Row>
                {this.state.reservation.pmsReservationDetail ?
                    <>
                <Row className="mb-2">
                    <Col className="col-2"><FormattedMessage id="ReservationVoucherDetail.adults" /></Col>
                    <Col className="col-4"> {this.state.reservation.pmsReservationDetail.adults}</Col>
                    <Col className="col-2"><FormattedMessage id="ReservationVoucherDetail.children" /></Col>
                    <Col className="col-4"> {this.state.reservation.pmsReservationDetail.children}</Col>
                </Row>
                <Row className="mb-2">
                    <Col className="col-2"><FormattedMessage id="ReservationVoucherDetail.reservationCreateDate" /></Col>
                    <Col className="col-4"> {this.state.reservation.pmsReservationDetail.reservationCreateDate}</Col>
                    <Col className="col-2"><FormattedMessage id="ReservationVoucherDetail.status" /></Col>
                    <Col className="col-4"> {this.state.reservation.pmsReservationDetail.reservationStatus}</Col>
                </Row>
                <Row className="mb-2">
                    <Col className="col-2"><FormattedMessage id="ReservationVoucherDetail.name" /></Col>
                                <Col className="col-4">{this.state.reservation.pmsReservationDetail.guestCountryCode} {this.state.reservation.pmsReservationDetail.guestFirstName} {this.state.reservation.pmsReservationDetail.guestLastName}</Col>
                    <Col className="col-2"><FormattedMessage id="ReservationVoucherDetail.email" /></Col>
                                <Col className="col-4"> {this.state.reservation.pmsReservationDetail.guestEmail}</Col>
                            </Row>

                            <Row className="mb-2">
                                <Col className="col-2"><FormattedMessage id="ReservationVoucherDetail.checkIn" /></Col>
                                <Col className="col-4"> {this.state.reservation.pmsReservationDetail.checkIn}</Col>
                                <Col className="col-2"><FormattedMessage id="ReservationVoucherDetail.checkOut" /></Col>
                                <Col className="col-4"> {this.state.reservation.pmsReservationDetail.checkOut}</Col>
                            </Row>

                            <Row className="mb-2">
                                <Col className="col-2"><FormattedMessage id="ReservationVoucherDetail.packageCode" /></Col>
                                <Col className="col-4"> {this.state.reservation.pmsReservationDetail.packageCode}</Col>
                                <Col className="col-2"><FormattedMessage id="ReservationVoucherDetail.roomCategoryCode" /></Col>
                                <Col className="col-4"> {this.state.reservation.pmsReservationDetail.roomCategoryCode}</Col>
                            </Row>
                   </> : ''}
                </StyledCard>
                <div className="mb-2" />
                <StyledCard icon={'fas fa-robot mr-1'} title={'ReservationVoucherDetail.ConfigDetails'} help={help} error={this.state.error} block={this.state.block}>
                {this.state.reservation.autoVoucherReservationConfig ?
                    <>
                        <Row className="mb-2">
                            <Col className="col-2"><FormattedMessage id="ReservationVoucherDetail.code" /></Col>
                                <Col className="col-4"><a onClick={this.configDetail.bind(this, this.state.reservation.autoVoucherReservationConfig.id)} style={{ cursor: "pointer", color: "blue" }}> {this.state.reservation.autoVoucherReservationConfig.code}</a></Col>
                            <Col className="col-2"><FormattedMessage id="ReservationVoucherDetail.description" /></Col>
                            <Col className="col-4"> {this.state.reservation.autoVoucherReservationConfig.description}</Col>
                        </Row>
                    </> : ''}
                </StyledCard>
                <div className="mb-2" />
               

                <StyledCard icon={'fas fa-ticket-alt'} title={'OnlineSalesDetail.EmitedVouchers'} help={help} block={this.state.block}>
                    {this.state.reservation.voucher ?
                        <>
                    <Row className="mb-2 ">
                        <Col className="col-3"><FormattedMessage id="OnlineSalesDetail.VoucherId" /></Col>
                        <Col><FormattedMessage id="OnlineSalesDetail.Status" /></Col>
                        <Col><FormattedMessage id="OnlineSalesDetail.ValidUntil" /></Col>
                        <Col><FormattedMessage id="OnlineSalesDetail.SequenceNumber" /></Col>
                        <Col><FormattedMessage id="OnlineSalesDetail.Code" /></Col>
                       
                    </Row>
                        {/*this.state.reservation.vouchers && this.state.reservation.vouchers.map((el, k) =>
                        <Row key={k} className=" ">
                            <Col className="col-3"><a onClick={this.voucherDetail.bind(this, el.id)} style={{ cursor: "pointer", color: "blue" }}>{el.voucherId} </a></Col>
                            <Col><Badge className="p-2 btn-host"> {el.status}</Badge></Col>
                            <Col>{el.validUntil}</Col>
                            <Col>{el.ordinal}</Col>
                            <Col>{el.voucherTypeCode}</Col>
                        </Row>

                        )*/}

                            <Row  className=" ">
                                <Col className="col-3"><a onClick={this.voucherDetail.bind(this, this.state.reservation.voucher.id)} style={{ cursor: "pointer", color: "blue" }}>{this.state.reservation.voucher.voucherId} </a></Col>
                                <Col><Badge className="p-2 btn-host"> {this.state.reservation.voucher.status}</Badge></Col>
                                <Col>{this.state.reservation.voucher.validUntil}</Col>
                                <Col>{this.state.reservation.voucher.ordinal}</Col>
                                <Col>{this.state.reservation.voucher.voucherTypeCode}</Col>
                            </Row>

                    </> : ''}
                </StyledCard>

                <div className="mb-2" />
                {/*<NotificationCard icon={'fas fa-at'} block={this.state.block} list={[]} />*/}
                
            </> 
        );
    }
}

export default injectIntl(ReservationVoucherDetail);