import QRCode from 'qrcode.react';
import Barcode from 'react-barcode';
import React, { Component } from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Table, UncontrolledTooltip, Card, CardBody } from 'reactstrap';
import { getAPI, putAPI, postAPI } from '../Base/API';
import { getMultiLangEmpty, StyledCard } from '../Base/Common/CommonUIComponents';
import CustomSelect from '../Base/Common/CustomSelect';
import { handleNotification } from "../Base/Common/Notification";
import { countries } from "../Base/Common/CommonUIComponents";
import { getCodeGenerationTypes, getStatus, getRightVoucherId } from '../Base/Common/ReferenceDataFunctions';
import { AddRedeem } from './AddRedeem';
import { PrintableVoucher } from './PrintableVoucher';
import { FormattedMessage, injectIntl } from 'react-intl';
import Authorization, { CheckAuthorization } from '../Base/Authorization';
import moment from 'moment';
import { EditClientModal } from './EditClientModal';
import RefoundModal from './RefoundModal';
import { DateRangePicker } from 'react-dates';
import NotificationCard from './NotificationCard';


class VoucherOperationDetails extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
        this.toggleModalPrint = this.toggleModalPrint.bind(this);
        this.setLanguage = this.setLanguage.bind(this);
        this.toggleEditClient = this.toggleEditClient.bind(this);
        this.updateClientData = this.updateClientData.bind(this);
        this.toggleRefoundModal = this.toggleRefoundModal.bind(this);
        this.state = {
            block: true,
            modal: false,
            voucherRedeem: {},
            voucher: {},
            language: 'PT',
            editClientModal: false,
            refoundModal: false,
            redeem: null
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        if (this.props.match.params.id)
            this.getVouherDetails(this.props.match.params.id)
    }
    toggleModal(redeem) {
        this.setState(prevState => ({
            modal: !prevState.modal,
            redeem
        }));
    }
    toggleModalPrint() {
        this.setState(prevState => ({
            modalPrint: !prevState.modalPrint
        }));
    }

    getVouherDetails(id) {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    if (data.response[0]) {
                        this.setState({ voucher: data.response[0] });
                    }
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/Voucher/Voucher/v1/voucher/${id}?languageCode=${this.state.language}`);
    }

    saveVoucher(fromRefund) {
        this.setState({ block: true });

        const req = {
            request: {
                id: this.state.voucher.id,
                status: fromRefund === true ? 'sold' : this.state.voucher.status,
                validFrom: this.state.voucher.validFrom,
                validUntil: this.state.voucher.validUntil,
                notes: this.state.voucher.notes,
                issueCode: this.state.voucher.issueCode
            }
        };

        putAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="VoucherOperationDetails.VoucherUpdatedSuccessfully" />, <FormattedMessage id="VoucherOperationDetails.Success" />);
                this.setState({ block: false }, () => { if (fromRefund === true) this.getVouherDetails(this.props.match.params.id) });
            }
        }, '/api/Voucher/Voucher/v1/voucher', req);
    }

    updateFilterCombo(val, evt) {

        if (evt) {
            let name = evt.name;
            let value = val.value;

            this.setState(prevState => ({
                ...prevState,
                voucher: {
                    ...prevState.voucher,
                    [name]: value

                },
            }));

        }
    }

    viewVoucher() {
        this.setState({ modalPrint: true })
    }

    sendNotification() {
        this.setState({ block: true });
        const lang = this.state.language ? `?lang=${getMultiLangEmpty().filter(lang => lang.language === this.state.language).map((lang) => lang.languageCode)}` : ``
        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            handleNotification(data, <FormattedMessage id="VoucherOperationDetails.NotificationSentSuccessfully" />, <FormattedMessage id="VoucherOperationDetails.Success" />);
            this.setState({ block: false }, () => this.getVouherDetails(this.props.match.params.id));
        }, ' /api/Voucher/v1/Notification/sendVoucherNotification/' + this.state.voucher.id + lang);

    }

    onlineSaleDetail() { this.props.history.push('/OnlineSalesDetail/' + this.state.voucher.onlinePaymentId) }
    voucherTypeDetail() { this.props.history.push('/CardConfigWizard/' + this.state.voucher.voucherType.id) }

    setLanguage(country) {
        let { language } = this.state;
        if (language !== country) {
            language = country;
            this.setState({ language }, () => {
                if (this.props.match.params.id) {
                    this.getVouherDetails(this.props.match.params.id);
                }
            });
        }
    }

    editClient() {
        this.setState({ editClientModal: true });
    }

    toggleEditClient() {
        this.setState(prevState => ({ editClientModal: !prevState.editClientModal }));
    }

    updateClientData(profile) {
        const { voucher } = this.state;
        voucher.name = `${profile.firstName} ${profile.lastName}`;
        voucher.email = profile.email;
        voucher.profileId = profile.id;
        this.setState({ voucher });
    }

    toggleRefoundModal(redeem) {
        let maxQuantity = null;

        if (redeem) {
            const usage = this.state.voucher.redeems.filter(x => x.rebateId === redeem.rebateId).reduce((acc, cur) => acc + cur.quantity, 0);
            maxQuantity = usage < redeem.quantity ? usage : redeem.quantity;
        }

        this.setState(prevState => ({ refoundModal: !prevState.refoundModal, redeem: redeem, maxQuantity }));
    }

    disableServiceRefund = (redeem) => {
        let disable = true;
        const rebate = this.state.voucher.voucherType.voucherRebates.find(el => el.id === redeem.rebateId);

        if (redeem.quantity > 0 && rebate) {
            const usage = this.state.voucher.redeems.filter(x => x.rebateId === rebate.id).reduce((acc, cur) => acc + cur.quantity, 0)

            if (usage > 0) {
                disable = false
            }
        }

        return disable;
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState(prevState => ({
            ...prevState,
            voucher: {
                ...prevState.voucher,
                [name]: value

            },
        }));
    }

    handleDateRangeChange = (startDate, endDate) => {
        this.setState(prevState => ({
            ...prevState,
            voucher: {
                ...prevState.voucher,
                validFrom: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
                validUntil: endDate ? moment(endDate).format("YYYY-MM-DD") : null

            },
        }));
    }


    render() {
        let refund = this.state.voucher.refunds && this.state.voucher.refunds.reduce((acc, cur) => acc + cur.amount, 0).toFixed(2);
        let redeems = this.state.voucher.redeems && this.state.voucher.redeems.reduce((acc, cur) => acc + cur.amount, 0).toFixed(2);
        

        return (
            <>
                <StyledCard title='VoucherOperationDetails.VoucherDetails' block={this.state.block} error={this.state.error}>

                    <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-xl" >
                        <ModalBody className="modal-xl p-0">
                            <AddRedeem
                                voucher={this.state.voucher}
                                refresh={() => this.getVouherDetails(this.props.match.params.id)}
                                redeem={this.state.redeem}
                            />
                        </ModalBody>
                    </Modal>
                    {
                        this.state.editClientModal ?
                            <EditClientModal
                                isOpen={this.state.editClientModal}
                                toggle={this.toggleEditClient}
                                profileId={this.state.voucher.profileId}
                                voucherId={this.state.voucher.id}
                                updateClientData={this.updateClientData}
                                history={this.props.history}
                            />
                            :
                            <div />
                    }

                    {
                        document.getElementById('qrcode') ?
                            <Modal isOpen={this.state.modalPrint} toggle={this.toggleModalPrint} fade={false} className="modal-xl" >
                                <ModalHeader toggle={this.toggleModalPrint} className="p-2">

                                </ModalHeader>
                                <ModalBody className="modal-xl p-0">
                                    {/*<PrintableVoucher language={this.state.language} voucher={this.state.voucher} qrcode={document.getElementById('qrcode').toDataURL()} voucherId={this.props.match.params.id} />*/}
                                    <PrintableVoucher language={this.state.language} voucher={this.state.voucher} voucherId={this.props.match.params.id} />
                                </ModalBody>
                            </Modal>
                            :
                            ''
                    }
                    {
                        this.state.refoundModal ?
                            <RefoundModal
                                isOpen={this.state.refoundModal}
                                toggle={this.toggleRefoundModal}
                                redeem={this.state.redeem}
                                voucher={this.state.voucher}
                                refresh={() => this.getVouherDetails(this.props.match.params.id)}
                                maxQuantity={this.state.maxQuantity}
                            />
                            :
                            <div />
                    }
                    <Row className="mb-2">
                        <Col>
                            {
                                countries.map((country, key) =>
                                    <span key={key}>
                                        <div
                                            className={`flag-icon flag-icon-${country.toLowerCase()} mb-2 px-2 mr-1`}
                                            style={{ filter: this.state.language !== country ? 'brightness(50%)' : '' }}
                                            onClick={() => this.setLanguage(country)}
                                        />
                                    </span>
                                )
                            }
                        </Col>
                        <Col className="text-right d-flex justify-content-end">

                            <Button className="btn-sm btn-host mr-1" id="viewReceiptTooltip" disabled={this.state.voucher.pmsInvoiceURL == null || this.state.voucher.pmsInvoiceURL == undefined} onClick={() => window.open(this.state.voucher.pmsInvoiceURL, "_blank")} ><i className="fas fa-receipt" ></i> </Button>


                            <Authorization
                                perform="voucherDetails:sendNotif"
                                yes={() => (
                                    <div>
                                        <Button className="btn-host btn-sm mr-1" id="emailTooltip"
                                            disabled={(this.state.voucher.isGift === true && !this.state.voucher.recipient) || (this.state.voucher.isGift === false && !this.state.voucher.email)}
                                            onClick={this.sendNotification.bind(this)}
                                        >
                                            <i className="far fa-envelope"></i>
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target="emailTooltip">
                                            <FormattedMessage id="VoucherOperationDetails.SendEmail" />
                                        </UncontrolledTooltip>
                                    </div>
                                )}
                                no={() => <div></div>}
                            />
                            <Button className="btn-host btn-sm mr-1" id="printTooltip" onClick={this.viewVoucher.bind(this)}>
                                <i className="fas fa-print " />
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="printTooltip">
                                <FormattedMessage id="VoucherOperationDetails.PrintVoucher" />
                            </UncontrolledTooltip>
                            <Authorization
                                perform="voucherDetails:save"
                                yes={() => (
                                    <div>
                                        <Button className="btn-host btn-sm" id="saveChangesTooltip" onClick={this.saveVoucher.bind(this)}> <i className="fas fa-save " /></Button>
                                        <UncontrolledTooltip placement="bottom" target="saveChangesTooltip">
                                            <FormattedMessage id="VoucherOperationDetails.SaveChanges" />
                                        </UncontrolledTooltip>
                                    </div>
                                )}
                                no={() => <div></div>}
                            />
                        </Col>
                    </Row>
                    <hr className="my-1" />
                    {
                        this.state.voucher.voucherId ?
                                <Row>
                                    <Col>
                                        <h4>
                                            {this.state.voucher.voucherType && this.state.voucher.voucherType.code}
                                        </h4>
                                    </Col>
                                    <Col className="text-right">
                                    {
                                        global.codeGenerationType === getCodeGenerationTypes(this.props.intl)[0].value ?
                                            <span id="qrcode">
                                                <Barcode value={this.state.voucher.voucherId.substring(0, 8)} />
                                            </span>
                                            :
                                            <QRCode value={this.state.voucher.voucherId} size={80} id="qrcode" />
                                    }
                                    
                                    </Col>
                                </Row>
                            : ''
                    }
                    <Row className="mb-3">
                        <Col className="col-2"><FormattedMessage id="VoucherOperationDetails.SoldAt" /></Col>
                        <Col className="col-4">{this.state.voucher.hotelName}</Col>
                    </Row>

                    <Row className="mb-4">
                        {this.state.voucher.onlinePaymentId ?
                            <>
                                <Col className="col-2"><FormattedMessage id="VoucherOperationDetails.OnlineSale" /></Col>
                                <Col className="col-4"><a style={{ cursor: "pointer", color: "blue" }} onClick={() => this.onlineSaleDetail()}>{this.state.voucher.onlinePaymentId}</a></Col>
                            </>
                            : ''}
                        {this.state.voucher.voucherType ?
                            <>
                                <Col className="col-2"><FormattedMessage id="VoucherOperationDetails.CardType" /></Col>
                                <Col className="col-4"><a style={{ cursor: "pointer", color: "blue" }} onClick={() => this.voucherTypeDetail()}>{this.state.voucher.voucherType.code}</a></Col>
                            </>
                            : ''}
                    </Row>

                    <Row className="mb-2">
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherOperationDetails.CardId" /></Label>
                        </Col>
                        <Col className="col-4">
                            <FormattedMessage id="VoucherOperationDetails.CardId" >
                                {
                                    placeholder => <Input type="text" placeholder={placeholder} readOnly value={getRightVoucherId(this.state.voucher.voucherId, this.state.voucher.voucherIdFirst8)} />
                                }
                            </FormattedMessage>
                        </Col>
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherOperationDetails.Number" /></Label>
                        </Col>
                        <Col className="col-4">
                            <FormattedMessage id="VoucherOperationDetails.Number" >
                                {
                                    placeholder => <Input type="text" placeholder={placeholder} readOnly value={this.state.voucher.ordinal} />
                                }
                            </FormattedMessage>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherOperationDetails.SalePrice" /></Label>
                        </Col>
                        <Col className="col-4">
                            <FormattedMessage id="VoucherOperationDetails.PayedAmount" >
                                {
                                    placeholder => <Input type="number" placeholder={placeholder} readOnly value={this.state.voucher.salePrice} />
                                }
                            </FormattedMessage>
                        </Col>
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherOperationDetails.VoucherAmount" /></Label>
                        </Col>
                        <Col className="col-4">
                            <FormattedMessage id="VoucherOperationDetails.VoucherAmount" >
                                {
                                    placeholder => <Input type="number" placeholder={placeholder} readOnly value={this.state.voucher.voucherValue} />
                                }
                            </FormattedMessage>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherOperationDetails.Vality" /></Label>
                        </Col>
                        <Col className="col-4">
                            <DateRangePicker
                                startDateId="vouchersValidFrom"
                                startDate={this.state.voucher && this.state.voucher.validFrom ? moment(this.state.voucher.validFrom) : null}
                                isOutsideRange={day => day < moment()}
                                endDate={this.state.voucher && this.state.voucher.validUntil ? moment(this.state.voucher.validUntil) : null}
                                endDateId="vouchersValidUntil"
                                onDatesChange={({ startDate, endDate }) => this.handleDateRangeChange(startDate, endDate)}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                small={true}
                                required
                                numberOfMonths={2}
                                showDefaultInputIcon={true}
                                disabled={!CheckAuthorization("voucherDetails:editValidUntil")}
                            />
                        </Col>
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherOperationDetails.Status" /></Label>
                        </Col>
                        <Col className="col-4">
                            <CustomSelect options={getStatus()} value={getStatus().find(el => el.value === this.state.voucher.status)} name={'status'} onChange={this.updateFilterCombo.bind(this)} placeholder={<FormattedMessage id="VoucherOperationDetails.Status" />} />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherOperationDetails.CreatedAt" /></Label>
                        </Col>
                        <Col className="col-4">
                            <FormattedMessage id="VoucherOperationDetails.CreatedAt">
                                {
                                    placeholder => <Input type="text" placeholder={placeholder} readOnly value={moment(this.state.voucher.createdAt).format("YYYY-MM-DD")} />
                                }
                            </FormattedMessage>
                        </Col>
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherOperationDetails.InvoiceReference" /></Label>
                        </Col>
                        <Col className="col-4">
                            <FormattedMessage id="VoucherOperationDetails.InvoiceReference" >
                                {
                                    placeholder => <Input type="text" placeholder={placeholder} readOnly value={this.state.voucher.invoiceReference} />
                                }
                            </FormattedMessage>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherOperationDetails.IssueCode" /></Label>
                        </Col>
                        <Col className="col-4">
                            <FormattedMessage id="VoucherOperationDetails.IssueCode" >
                                {placeholder => 
                                    <Input
                                        type="text"
                                        name="issueCode"
                                        placeholder={placeholder}
                                        value={this.state.voucher.issueCode}
                                        onChange={this.handleChange}
                                        maxLength={50}
                                    />
                                }
                            </FormattedMessage>
                        </Col>
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherOperationDetails.Notes" /></Label>
                        </Col>
                        <Col className="col-4">
                            <FormattedMessage id="VoucherOperationDetails.Notes" >
                                {
                                    placeholder => <Input type="textarea" placeholder={placeholder} value={this.state.voucher.notes || ''} name="notes" onChange={this.handleChange} />
                                }
                            </FormattedMessage>
                        </Col>
                    </Row>

                    {this.state.voucher.isGift || (this.state.voucher.from && this.state.voucher.to) ?
                        <div>
                            <Row className="mt-4">
                                <Col>
                                    <h4><FormattedMessage id="VoucherOperationDetails.GiftInfo" /></h4>
                                </Col>
                            </Row>
                            <hr className="my-1 mb-3" />
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <Label><FormattedMessage id="VoucherManagement.From" /></Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="VoucherManagement.From" >
                                        {
                                            placeholder => <Input type="text" placeholder={placeholder} readOnly value={this.state.voucher.from} />
                                        }
                                    </FormattedMessage>
                                </Col>
                                <Col className="col-2">
                                    <Label><FormattedMessage id="VoucherManagement.To" /></Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="VoucherManagement.To" >
                                        {
                                            placeholder => <Input type="text" placeholder={placeholder} readOnly value={this.state.voucher.to} />
                                        }
                                    </FormattedMessage>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <Label><FormattedMessage id="VoucherManagement.Sender" /></Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="VoucherManagement.Sender" >
                                        {
                                            placeholder => <Input type="text" placeholder={placeholder} readOnly value={this.state.voucher.sender} />
                                        }
                                    </FormattedMessage>
                                </Col>
                                <Col className="col-2">
                                    <Label><FormattedMessage id="VoucherManagement.Recipient" /></Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="VoucherManagement.Recipient" >
                                        {
                                            placeholder => <Input type="text" placeholder={placeholder} readOnly value={this.state.voucher.recipient} />
                                        }
                                    </FormattedMessage>
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col className="col-2">
                                    <Label><FormattedMessage id="VoucherOperationDetails.Message" /></Label>
                                </Col>
                                <Col className="col-10">
                                    <FormattedMessage id="VoucherOperationDetails.Message" >
                                        {
                                            placeholder => <Input type="textarea" placeholder={placeholder} readOnly value={this.state.voucher.message} />
                                        }
                                    </FormattedMessage>
                                </Col>
                            </Row>
                        </div>
                        : ''}

                    <Row className="mt-2">
                        <Col>
                            <h5><FormattedMessage id="VoucherOperationDetails.Client" /></h5>
                        </Col>
                        <Authorization
                            perform="voucherDetails:client:edit"
                            yes={() => (
                                <Col className="text-right">
                                    <Button className="btn-host btn-sm" onClick={this.editClient.bind(this)}>
                                        <i className="fa fa-edit" />
                                    </Button>
                                </Col>
                            )}
                            no={() => <div></div>}
                        />
                    </Row>
                    <hr className="my-1" />
                    <Row>
                        <Col className="col-2"><Label><FormattedMessage id="VoucherOperationDetails.Name" /></Label></Col>
                        <Col>
                            <FormattedMessage id="VoucherOperationDetails.Name" >
                                {
                                    placeholder => <Input type="text" placeholder={placeholder} readOnly value={this.state.voucher.name} />
                                }
                            </FormattedMessage>
                        </Col>
                        <Col className="col-2"><Label>Email</Label></Col>
                        <Col>
                            <Input type="text" placeholder="Email" readOnly value={this.state.voucher.email || ''} />
                        </Col>
                    </Row>

                </StyledCard>
                <div className="mb-2" />
                <StyledCard icon={'fas fa-wallet'} title={'VoucherOperationDetails.UsageSummary'} block={this.state.block}>


                    {this.state.voucher.voucherType && this.state.voucher.voucherType.isPaymentMode === true ?
                        <Row className="mt-2">
                            <Col>
                                <FormattedMessage id="VoucherOperationDetails.CardAmount" /> {this.state.voucher.voucherValue}
                            </Col>
                            <Col>
                                <FormattedMessage id="VoucherOperationDetails.Refunded" />  {refund ? refund : 0}</Col>
                            <Col>
                                <FormattedMessage id="VoucherOperationDetails.Used" /> {redeems ? redeems : 0}
                            </Col>
                            <Col>
                                <b><FormattedMessage id="VoucherOperationDetails.Left" /> {(this.state.voucher.voucherValue - refund - redeems).toFixed(2)}</b>
                            </Col>
                        </Row>
                        : <Row>
                            <Col>
                                <Row>
                                    {this.state.voucher.voucherType && this.state.voucher.voucherType.voucherRebates.map((el, k) =>
                                        <Col key={k} className="col-3">
                                            <Card>
                                                <CardBody>
                                                    <div><b>{el.description}</b></div>
                                                    <Row>
                                                        <Col><FormattedMessage id="VoucherOperationDetails.Total" />  {el.quantity}</Col>
                                                        <Col><FormattedMessage id="VoucherOperationDetails.Used" />  {this.state.voucher.redeems != undefined && this.state.voucher.redeems.filter(x => x.rebateId === el.id).reduce((acc, cur) => acc + cur.quantity, 0)}</Col>
                                                        <Col><FormattedMessage id="VoucherOperationDetails.Left" />  <b>{el.quantity - (this.state.voucher.redeems != undefined && this.state.voucher.redeems.filter(x => x.rebateId === el.id).reduce((acc, cur) => acc + cur.quantity, 0))}</b></Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>

                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        </Row>}
                </StyledCard>
                <div className="mb-2" />
                <StyledCard icon={'fas fa-bars'} title={'VoucherOperationDetails.Redeems'} block={this.state.block}>
                    <Row className="mt-2">
                        <Col className="text-right">
                            <Authorization
                                perform="redeems:add"
                                yes={() => (
                                    <div>
                                        <Button className="btn-host btn-sm" id="redeemTooltip" onClick={this.toggleModal}> <i className="fas fa-plus " /></Button>
                                        <UncontrolledTooltip placement="bottom" target="redeemTooltip">
                                            <FormattedMessage id="VoucherOperationDetails.AddManualRedeem" />
                                        </UncontrolledTooltip>
                                    </div>
                                )}
                                no={() => <div></div>}
                            />
                        </Col>
                    </Row>
                    {
                        this.state.voucher.voucherType && this.state.voucher.voucherType.isPaymentMode === true ?
                            <Row className="mt-2">
                                <Col>
                                    <Table size="sm">
                                        <thead>
                                            <tr>
                                                <th><FormattedMessage id="VoucherOperationDetails.Hotel" /></th>
                                                <th><FormattedMessage id="VoucherOperationDetails.Invoice" /></th>
                                                <th><FormattedMessage id="VoucherOperationDetails.RedeemReference" /></th>
                                                <th><FormattedMessage id="VoucherOperationDetails.Amount" /></th>
                                                <th><FormattedMessage id="VoucherOperationDetails.Date" /></th>
                                                <th><FormattedMessage id="VoucherOperationDetails.Notes" /></th>
                                                <th className="text-center"><FormattedMessage id="VoucherOperationDetails.Revoke" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.voucher.redeems && this.state.voucher.redeems.map((el, k) => {
                                                const isPositiveAmount = el.amount > 0;

                                                return (
                                                    <tr key={k}  className="pointer" onClick={() => { if (isPositiveAmount) this.toggleModal(el) }}>
                                                        <td>{el.hotelName}</td>
                                                        <td>{el.invoice}</td>
                                                        <td>{el.targetReference}</td>
                                                        <td className={el.amount < 0 ? 'text-danger font-weight-bold' : ''}> {el.amount}</td>
                                                        <td>{el.businessDate.substring(0, 10)}</td>
                                                        <td>{el.notes}</td>
                                                        <td className="text-center" onClick={(e) => e.stopPropagation()}>
                                                            {isPositiveAmount > 0 ?
                                                                <Button className="btn-host btn-sm" onClick={_ => this.toggleRefoundModal(el)}>
                                                                    <i className="fas fa-undo" />
                                                                </Button>
                                                                : ''}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            :
                            <Row className="mt-2">
                                <Col>
                                    <Table size="sm">
                                        <thead>
                                            <tr>
                                                <th><FormattedMessage id="VoucherOperationDetails.RebateCode" /></th>
                                                <th><FormattedMessage id="VoucherOperationDetails.Hotel" /></th>
                                                <th><FormattedMessage id="VoucherOperationDetails.Invoice" /></th>
                                                <th><FormattedMessage id="VoucherOperationDetails.RedeemReference" /></th>
                                                <th><FormattedMessage id="VoucherOperationDetails.Quantity" /></th>
                                                <th><FormattedMessage id="VoucherOperationDetails.Date" /></th>
                                                <th><FormattedMessage id="VoucherOperationDetails.Notes" /></th>
                                                <th className="text-center"><FormattedMessage id="VoucherOperationDetails.Refund" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.voucher.redeems && this.state.voucher.redeems.map((el, k) => {
                                                const isPositiveQuantity = el.quantity > 0;

                                                return (
                                                    <tr key={k} className="pointer" onClick={() => { if (isPositiveQuantity) this.toggleModal(el) }}>
                                                        <td>{el.rebateCode}</td>
                                                        <td>{el.hotelName}</td>
                                                        <td>{el.invoice}</td>
                                                        <td>{el.targetReference}</td>
                                                        <td className={el.quantity < 0 ? 'text-danger font-weight-bold' : ''}>{el.quantity}</td>
                                                        <td>{el.businessDate.substring(0, 10)}</td>
                                                        <td>{el.notes}</td>
                                                        <td className="text-center">
                                                                {isPositiveQuantity ?
                                                                <Button className="btn-host btn-sm" onClick={_ => this.toggleRefoundModal(el)} disabled={this.disableServiceRefund(el)}>
                                                                    <i className="fas fa-undo" />
                                                                </Button>
                                                                : ''}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                    }
                </StyledCard>
                <div className="mb-2" />
                {
                    this.state.voucher.refunds ?
                        <StyledCard icon={'fas fa-undo'} title={'VoucherOperationDetails.Refunds'} block={this.state.block}>

                            <>
                                <Row className="mt-2">
                                    <Col>
                                        <Table size="sm">
                                            <thead>
                                                <tr>
                                                    <th><FormattedMessage id="VoucherOperationDetails.Date" /></th>
                                                    <th><FormattedMessage id="VoucherOperationDetails.Amount" /></th>
                                                    <th><FormattedMessage id="VoucherOperationDetails.Reason" /></th>
                                                    <th><FormattedMessage id="VoucherOperationDetails.Notes" /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.voucher.refunds && this.state.voucher.refunds.map((el, k) =>
                                                    <tr key={k}>
                                                        <td>{el.createdAt}</td>
                                                        <td>{el.amount}</td>
                                                        <td>{el.reason}</td>
                                                        <td>{el.notes}</td>

                                                    </tr>
                                                )}


                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>

                            </>

                        </StyledCard>
                        : ''}
                <div className="mt-2 mb-4" >
                    <NotificationCard icon={'fas fa-at'} block={this.state.block} list={this.state.voucher.notifications} />
                </div>

            </>


        );
    }
}
export default injectIntl(VoucherOperationDetails);