import React, { Component } from 'react';
import { Row, Col, Button, Label, Input, CustomInput } from 'reactstrap';
import { getAPI, postAPI } from '../Base/API';
import { StyledCard, BlankCard, SelectHotel, SelectVoucherType } from '../Base/Common/CommonUIComponents';
import { getAutoEmissionScheduleType, getAutoEmissionType, getAutoValidityType } from '../Base/Common/ReferenceDataFunctions';
import CustomSelect from '../Base/Common/CustomSelect';
import moment from "moment";
import { handleNotification } from "../Base/Common/Notification";
import { DateRangePicker } from 'react-dates';
import { FormattedMessage, injectIntl } from "react-intl";
import Authorization from '../Base/Authorization';

export class VoucherIssueAutomationDetails extends Component {
    static displayName = VoucherIssueAutomationDetails.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            automationConfig: {
                "name": undefined,
                "active": false,
                "validFrom": undefined,
                "validTo": undefined,
                "voucherTypeId": undefined,
                "validityType": undefined,
                "emissionType": undefined,
                "emissionScheduleType": undefined,
                "daysForEmission": undefined,
                "autoVoucherChannels": [],
                "autoVoucherHotelValidity": [],
                "autoVoucherRateCode": []
            },
            currentPage: 0,
            PageSize: 10,
            channelsList: [],
            ratesList: []
        }
    }


    componentDidMount() {
        if (this.props.match.params.id)
            this.getAutoConfig(this.props.match.params.id);

    }

    getAutoConfig(id) {
        this.setState({ blockCfg: true });
        
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.response[0].autoVoucherHotelValidity && data.response[0].autoVoucherHotelValidity[0].validHotelId) {
                    this.getChannels(data.response[0].autoVoucherHotelValidity[0].validHotelId);
                    this.getRates(data.response[0].autoVoucherHotelValidity[0].validHotelId);
                }
                this.setState({ automationConfig: data.response[0], block: false });

            }
        }, '/api/Voucher/AutoVoucherReservation/v1/AutoVoucherReservationConfiguration/'+id);
    }

    updateForm(f, evt) {

        let name = evt.target.name;
        let value = evt.currentTarget.type == 'checkbox' ? evt.target.checked : evt.target.value;
        
        this.setState(prevState => ({
            ...prevState,
            [f]: {
                ...prevState[f],
                [name]: value

            },
        }));
    }

    updateComboForm(prop, name, combo, evt) {
        this.setState(prevState => ({
            ...prevState,
            [prop]: {
                ...prevState[prop],
                [name]: combo ? combo.value : null

            },
        }));
    }
    
    getChannels = (hotelId) => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const list = [];

                if (data.response && data.response.length > 0) {
                    data.response.forEach(channel => {
                        const obj = {
                            value: channel.id,
                            label: channel.description
                        };
                        if (!list.find(elem => elem.label === obj.label)) {
                            list.push(obj);
                        }
                    })
                }
                this.setState({ channelsList: list });
            } 
            this.setState({ error: errorMessage, block: false });
        }, `/api/Voucher/v1/Config/onlineChannels/${hotelId}`);
    }

    getRates = (hotelId) => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const list = [];

                if (data.response && data.response.length > 0) {
                    data.response.forEach(rate => {
                        const obj = {
                            value: rate.id,
                            label: rate.rateCodeName
                        };
                        if (!list.find(elem => elem.label === obj.label)) {
                            list.push(obj);
                        }
                    })
                }
                    
                this.setState({ ratesList: list });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/Voucher/v1/Config/rateCodes/${hotelId}`);
    }

    saveConfig(e) {
        e.preventDefault();
        
        if (!this.form.current.reportValidity()) {
            return;
        }
        
        let req = { "request": { ...this.state.automationConfig } }

        if (this.state.automationConfig.validFrom instanceof moment)
            this.state.automationConfig.validFrom = this.state.automationConfig.validFrom.format('YYYY-MM-DD');
        if (this.state.automationConfig.validTo instanceof moment)
            this.state.automationConfig.validTo = this.state.automationConfig.validTo.format('YYYY-MM-DD');


        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ block: false });
                handleNotification(data, <FormattedMessage id="VoucherIssueAutomationDetails.saved" />, <FormattedMessage id="EditVoucher.Success" />);
            }
        }, '/api/Voucher/AutoVoucherReservation/v1/AutoVoucherReservationConfiguration', req);

        return false;
    }

    addHotel(evt) {
        if (evt) {
            this.getChannels(evt.value);
            this.getRates(evt.value);
        }
        else {
            this.setState({ ratesList: [], channelsList: [] })
        }

        this.setState(prevState => ({
            ...prevState,
            automationConfig: {
                ...prevState.automationConfig,
                autoVoucherHotelValidity: [{ validHotelId: evt ? evt.value : null }]
            },
            selectedChannel: null,
            selectedRate: null
        }));
    }

    addRate() {
        if (!this.state.selectedRate) {
            return;
        }

        var rateCode = [];
        if (this.state.automationConfig && this.state.automationConfig.autoVoucherRateCode) {
            rateCode = [...this.state.automationConfig.autoVoucherRateCode];
        }
        rateCode.push({ rateCode: this.state.selectedRate })

        this.setState(prevState => ({
            ...prevState,
            automationConfig: {
                ...prevState.automationConfig,
                autoVoucherRateCode: rateCode
            },
        }));
    }

    addChannel() {
        if (!this.state.selectedChannel) {
            return;
        }
        
        var channels = [];
        if (this.state.automationConfig && this.state.automationConfig.autoVoucherChannels) {
            channels = [...this.state.automationConfig.autoVoucherChannels];
        }
        channels.push({ channelCode: this.state.selectedChannel })

        this.setState(prevState => ({
            ...prevState,
            automationConfig: { ...prevState.automationConfig, autoVoucherChannels: channels },
        }));
    }

    deleteChannelOrRate = (selectedElement, prop) => {
        var list = [...this.state.automationConfig[prop]];
        console.log(list)

        list = list.filter(c => c.id !== selectedElement.id)
        console.log(list)

        this.setState(prevState => ({
            ...prevState,
            automationConfig: { ...prevState.automationConfig, [prop]: list },
        }));
    }

    render() {
        const help = <div> </div>
        return (
            <>
                <form ref={this.form}>
                    {/*<div>
                        <Breadcrumb>
                            <BreadcrumbItem><a href="/VoucherIssueAutomation"><FormattedMessage id="navbar.AutoVoucher" /></a> </BreadcrumbItem>
                        </Breadcrumb>
                       
                    </div>
                    */}
                    <StyledCard icon={'fas fa-robot'} title={'VoucherIssueAutomation.title'} help={help} error={this.state.error} block={this.state.block}>
                        <Row>
                            <Col>
                                <h6><FormattedMessage id="VoucherIssueAutomationDetails.BasicInfo" /></h6>
                            </Col>
                            <Col className="text-right">
                                <Authorization
                                    perform="voucherIssueAutomationDetails:save"
                                    yes={() => (
                                        <Button className="btn-sm btn-host mb-1" onClick={(e) => this.saveConfig(e)} >
                                            <i className="fas fa-save" />
                                        </Button>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherIssueAutomationDetails.Code" /></Label>
                        </Col>
                        <Col className="col-4">
                            <FormattedMessage id="VoucherIssueAutomationDetails.Code" >
                                {
                                    placeholder => <Input type="text" placeholder={placeholder} required minLength="1" maxLength="50" name="code" value={this.state.automationConfig.code ? this.state.automationConfig.code : ''} onChange={this.updateForm.bind(this, 'automationConfig')} />
                                }
                            </FormattedMessage>
                        </Col>
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherIssueAutomationDetails.Name" /></Label>
                        </Col>
                        <Col className="col-4">
                            <FormattedMessage id="VoucherIssueAutomationDetails.Name" >
                                {
                                    placeholder => <Input type="text" placeholder={placeholder} required minLength="1" maxLength="50" name="description" value={this.state.automationConfig.description ? this.state.automationConfig.description : ''} onChange={this.updateForm.bind(this, 'automationConfig')} />
                                }
                            </FormattedMessage>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherIssueAutomationDetails.ValidDates" /></Label>
                        </Col>
                        <Col className="col-4">       
                            <DateRangePicker
                                startDate={this.state.automationConfig.validFrom ? moment(this.state.automationConfig.validFrom) : null}
                                startDateId="sellStartid"
                                isOutsideRange={day => day <= moment()}
                                endDate={this.state.automationConfig.validTo ? moment(this.state.automationConfig.validTo) : null}
                                endDateId="sellEndid"
                                onDatesChange={({ startDate, endDate }) => this.setState(prevState => ({
                                    ...prevState,
                                    automationConfig: {
                                        ...prevState.automationConfig,
                                        validFrom: startDate,
                                        validTo: endDate

                                    },
                                }))}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                small={true}
                                required
                                numberOfMonths={2}
                                showDefaultInputIcon={true}
                            />
                        </Col>
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherIssueAutomationDetails.IssueDates" /></Label>
                        </Col>
                        <Col className="col-2">
                            <CustomSelect required options={getAutoValidityType()} value={getAutoValidityType().find(el => el.value === this.state.automationConfig.validityType)} onChange={this.updateComboForm.bind(this, 'automationConfig', 'validityType')} placeholder={<FormattedMessage id="VoucherIssueAutomationDetails.IssueDates" />} />
                        </Col>
                                <Col className="col-1 d-flex align-items-end">
                            <Label><FormattedMessage id="VoucherIssueAutomationDetails.Status" /></Label>
                        </Col>
                        <Col className="col-1 d-flex align-items-center">

                            <FormattedMessage id="VoucherIssueAutomationDetails.Status" >
                                {
                                    placeholder => <CustomInput type="switch" placeholder={placeholder} name="active" id="activeAutomationDetais" checked={this.state.automationConfig.active} onChange={this.updateForm.bind(this, 'automationConfig')} />
                                }
                            </FormattedMessage>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherIssueAutomationDetails.EmissionType" /></Label>
                        </Col>
                        <Col className="col-4">
                                    <CustomSelect required options={getAutoEmissionType()} value={getAutoEmissionType().find(el => el.value === this.state.automationConfig.emissionType)} onChange={this.updateComboForm.bind(this, 'automationConfig', 'emissionType')} placeholder={<FormattedMessage id="VoucherIssueAutomationDetails.EmissionType" />} />
                        </Col>
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherIssueAutomationDetails.VoucherTypeName" /></Label>
                        </Col>
                        <Col className="col-4">
                            <SelectVoucherType onChangeFunc={this.updateComboForm.bind(this, 'automationConfig', 'voucherTypeId')} value={this.state.automationConfig.voucherTypeId} placeholder={<FormattedMessage id="VoucherIssueAutomationDetails.VoucherTypeName" />}/>                                
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherIssueAutomationDetails.EmissionScheduleType" /></Label>
                        </Col>
                        <Col className="col-4">
                                    <CustomSelect required options={getAutoEmissionScheduleType()} value={getAutoEmissionScheduleType().find(el => el.value === this.state.automationConfig.emissionScheduleType)} onChange={this.updateComboForm.bind(this, 'automationConfig', 'emissionScheduleType')} placeholder={<FormattedMessage id="VoucherIssueAutomationDetails.EmissionScheduleType" />} />
                        </Col>
                        <Col className="col-2">
                            <Label><FormattedMessage id="VoucherIssueAutomationDetails.days" /></Label>
                        </Col>
                        <Col className="col-4">


                            <FormattedMessage id="VoucherIssueAutomationDetails.days" >
                                {
                                            placeholder => <Input type="number" min="1" max="100" placeholder={placeholder} required name="daysForEmission" value={this.state.automationConfig.daysForEmission ? this.state.automationConfig.daysForEmission : ''} onChange={this.updateForm.bind(this, 'automationConfig')} />
                                }
                            </FormattedMessage>
                        </Col>
                    </Row>
               
                    </StyledCard>
                    <div className="my-2" />
                        <Row className="mb-1">
                            <Col className="col-4">
                                <BlankCard block={this.state.block}>
                                    <h6><FormattedMessage id="VoucherIssueAutomationDetails.Hotels" /></h6>

                                    <Row className="mb-1">
                                        <Col>
                                            <SelectHotel
                                                onChangeFunc={(e) => this.addHotel(e)}
                                                value={this.state.automationConfig.autoVoucherHotelValidity && this.state.automationConfig.autoVoucherHotelValidity.length > 0 ? this.state.automationConfig.autoVoucherHotelValidity[0].validHotelId : ''}
                                            />
                                        </Col>   
                                    </Row>                            
                                </BlankCard>

                            </Col>
                            <Col className="col-4">
                                <BlankCard block={this.state.block}>
                                    <h6><FormattedMessage id="VoucherIssueAutomationDetails.Channels" /></h6>

                                <Row className="mb-1">

                                    <Col>
                                        <CustomSelect
                                            options={this.state.channelsList}
                                            value={this.state.selectedChannel ? this.state.channelsList.find(el => el.value === this.state.selectedChannel) : null}
                                            onChange={(e) => this.setState({ selectedChannel: e ? e.label : null})}
                                            placeholder={<FormattedMessage id="VoucherIssueAutomationDetails.Channel" />}
                                        />
                                    </Col>
                                    <Authorization
                                        perform="voucherIssueAutomationDetails:addChannel"
                                        yes={() => (
                                            <Col className="col-2 text-right">
                                                <Button className="btn-sm btn-host mb-1" onClick={this.addChannel.bind(this)}>
                                                    <i className="fas fa-plus" />
                                                </Button>
                                            </Col>
                                        )}
                                        no={() => <div></div>}
                                    />

                                </Row>
                                    {this.state.automationConfig && this.state.automationConfig.autoVoucherChannels && this.state.automationConfig.autoVoucherChannels.map((el, k) =>
                                        <Row className="mb-2" key={k}>
                                            <Col>{el.channelCode}</Col>
                                            <Authorization
                                                perform="voucherIssueAutomationDetails:removeChannel"
                                                yes={() => (
                                                    <Col className="col-2 text-right">
                                                        <div className="ml-3">
                                                            <i className="far fa-trash-alt" onClick={() => this.deleteChannelOrRate(el, 'autoVoucherChannels')} style={{ color: 'red', cursor: 'pointer' }} />
                                                        </div>
                                                    </Col>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        </Row>
                                    )}
                            </BlankCard>


                            </Col>
                            <Col className="col-4">

                                <BlankCard block={this.state.block}>
                                <h6>Rates</h6>

                                <Row className="mb-1">

                                    <Col>
                                        <CustomSelect
                                            options={this.state.ratesList}
                                            value={this.state.selectedRate ? this.state.ratesList.find(el => el.value === this.state.selectedRate) : null}
                                            onChange={(e) => this.setState({ selectedRate: e ? e.label : null })}
                                            placeholder={"Rate"}
                                        />
                                    </Col>
                                    <Authorization
                                        perform="voucherIssueAutomationDetails:addRate"
                                        yes={() => (
                                            <Col className="col-2 text-right">
                                                <Button className="btn-sm btn-host mb-1" onClick={this.addRate.bind(this)}>
                                                    <i className="fas fa-plus" />
                                                </Button>
                                            </Col>
                                        )}
                                        no={() => <div></div>}
                                    />

                                </Row>
                                {this.state.automationConfig && this.state.automationConfig.autoVoucherRateCode && this.state.automationConfig.autoVoucherRateCode.map((el, k) =>
                                    <Row className="mb-2" key={k}>
                                        <Col>{el.rateCode}</Col>
                                        <Authorization
                                            perform="voucherIssueAutomationDetails:removeRate"
                                            yes={() => (
                                                <Col className="col-2 text-right">
                                                    <div className="ml-3">
                                                        <i className="far fa-trash-alt" onClick={() => this.deleteChannelOrRate(el, 'autoVoucherRateCode')} style={{ color: 'red', cursor: 'pointer' }} />
                                                    </div>
                                                </Col>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </Row>
                                )}

                            </BlankCard>


                        </Col>
                    </Row>

                </form>
               </>
           


        );
    }
}

export default injectIntl(VoucherIssueAutomationDetails);