import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from '../../Base/Common/Notification';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from '../../Base/API';


class ResetCache extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            cacheKey: null
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    resetCache = (e, cacheKey) => {
        if (e) e.preventDefault();
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage, block: false }));
                }
            }
            else {
                handleNotification(data, <FormattedMessage id="ResetCache.CacheResetSuccessfully" />, <FormattedMessage id="generic.success" />);
                this.setState({ block: false });
            }
        }, `/api/Voucher/Profile/v1/resetCache?cacheKey=${cacheKey}`);
    }

    render() {
        const { block, blockScheduleTask, error } = this.state;

        const cacheOption = [
            { value: 'Users', label: 'Users Cache' },
            { value: `Hotels`, label: 'Hotels Cache' }
        ]

        return (
            <StyledCard block={block || blockScheduleTask} error={error} icon={'fas fa-sync'} title={'navbar.ResetCache'}>
                {cacheOption.map((cache, key) =>
                    <Row className="align-items-center py-2 mx-3" key={key}>
                        <Col>
                            <span className="m-0"> {cache.label} </span>
                        </Col>
                        <Col className="col-1 text-right">
                            <Button className="btn btn-host btn-sm" onClick={(e) => this.resetCache(e, cache.value)} >
                                <i className="fas fa-sync" />
                            </Button>
                        </Col>
                    </Row>
                )}
            </StyledCard>
        );
    }
}
export default injectIntl(ResetCache)