import React, { Component } from "react";
import DragAndDrop from "../Base/Common/DragAndDrop";

export class ImageDragAndDrop extends Component {

    onSelectImage(evt) {
        if (evt && evt.target) {
            this.props.handleDrop(evt.target.files);
        }
    }

    render() {
        const { handleDrop, children } = this.props;
        return (
            <DragAndDrop handleDrop={handleDrop}>
                <div>
                    <input id='multi' type="file" accept="image/*" onChange={this.onSelectImage.bind(this)} />
                </div>
                {children}
            </DragAndDrop>    
        );
    };
}