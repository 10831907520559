import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Row, Col, FormGroup, Label, Input, Button, Form } from 'reactstrap';
import { handleNotification } from "../Base/Common/Notification";
import CustomSelect from '../Base/Common/CustomSelect';
import { ErrorAlert } from "../Base/ErrorAlert";
import { postAPI, getAPI } from "../Base/API";
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';

export class PaymentModal extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            license: this.props.license ? this.props.license : { modules: [] },
            priceList: []
        };
    }

    componentDidMount() {
        this.getPriceList();
    }

    getPriceList = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(({ block: false, error: errorMessage }));
                return;
            }
            if (data) {
                var list = [];

                data.response && data.response.forEach(el => {
                    list.push({ value: el.id, label: el.name, amount: el.amount, numberOfVouchers: el.numberOfVouchers });
                })

                this.setState(({ error: [], priceList: list }));
            }
            this.setState(({ block: false }));
        }, '/api/Voucher/v1/Config/cardPrice');
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null,
            amount: combo ? combo.amount : null,
            numberOfVouchers: combo ? combo.numberOfVouchers : null
        })
    }
    
    saveCashDeposit = (e) => {
        e.preventDefault();

        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(({ block: false, error: errorMessage }));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="PaymentModal.CashDepositSaved" />, <FormattedMessage id="generic.success" />);

                    this.props.updateTable(data.response[0]);
                    this.setState(({ block: false }));
                    this.props.toggle();
                }
                this.setState(({ block: false }));
            }
            this.setState(({ block: false }));
        }, `/api/Voucher/v1/Config/clientCashDeposit/${this.state.cardPriceId}`);
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-xl">
                <ModalHeader toggle={this.props.toggle}>
                    <FormattedMessage id="PaymentModal.SelectPrice" />
                </ModalHeader>

                <ModalBody className="p-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={this.saveCashDeposit} innerRef={this.form}>
                            <Card className="shadow border-0 p-0">

                                <CardBody className="m-0">
                                    <Row className="bg-white mb-2">
                                        <Col className="text-right">
                                            <Button type="submit" className="btn btn-sm btn-host float-right">
                                                <i className="fas fa-save" />
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={2}> <FormattedMessage id="PaymentModal.PriceList" /> </Label>
                                                <Col sm={10}>
                                                    <CustomSelect
                                                        isClearable
                                                        placeholder={''}
                                                        value={this.state.cardPriceId ? this.state.priceList.find(pl => pl.value === this.state.cardPriceId) : ''}
                                                        options={this.state.priceList}
                                                        onChange={this.handleSelect.bind(this, 'cardPriceId')} 
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={4}> <FormattedMessage id="PaymentModal.NumberOfVouchers" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="number"
                                                        value={this.state.numberOfVouchers ? this.state.numberOfVouchers : ''}
                                                        readOnly
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={4}> <FormattedMessage id="PaymentModal.Amount" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="number"
                                                        value={this.state.amount ? this.state.amount : ''}
                                                        readOnly
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}
