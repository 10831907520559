const rules = {
    

    'da9f33db-f99c-4f3f-8817-ccc263972d5a': {  // Voucher Admin
        static: [
            "CardClient",
            "salesDashboard:view",
            "operationManagement:add",
            "voucherDetails:sendNotif",
            "voucherDetails:save",
            "redeems:add",
            "onlineSales:send",
            "onlineSales:refund",
            "hotelList:view",
            "hotel:view",
            "paymentManagement:disable",
            "paymentManagement:revoke",
            "paymentManagement:save",
            "manageUser:accessGroup:save",
            "manageUser:add",
            "manageUser:property:save",
            "manageUser:selectedUser:save",
            "manageUser:selectedUser:delete",
            "manageUser:selectedUser:disable",
            "manageUser:selectedUser:enable",
            "editVoucher:view",
            "voucherManagement:add",
            "voucherManagement:remove",
            "voucherManagement:edit",
            "voucherManagement:shop",
            "termsConditions:save",
            "termsConditions:add",
            "voucherIssueAutomation:add",
            "voucherIssueAutomationDetails:save",
            "voucherIssueAutomationDetails:addChannel",
            "voucherIssueAutomationDetails:addRate",
            "voucherIssueAutomationDetails:removeRate",
            "voucherIssueAutomationDetails:removeChannel",
            "referenceData:syncRateChannel",
            "referenceData:syncUsersAndPayments",
            "profileDetail:save",
            "hotelConfig:save",
            "PMSConectivity:add",
            "PMSConectivity:save",
            "PMSConectivity:validate",
            "cardConfigWizard:saveConfig",
            "cardConfigWizard:addService",
            "cardConfigWizard:saveTemplate",
            "cardConfigWizard:savePromote",
            "cardConfigWizard:addImage",
            "cardConfigWizard:deleteImage",
            "cardConfigWizard:saveTermsExcluded",
            "cardConfigWizard:saveTermsIncluded",
            "cardConfigWizard:saveTEC",
            "cardConfigWizard:removeService",
            "cardConfigWizard:saveService",
            "hotel:save",
            "paymentManagement:view",
            "manageUser:view",
            "voucherDetails:editValidUntil",
            "promotions:add",
            "promotions:save",
            "voucherDetails:client:edit",
            "onlineSales:registOnPms",
            "useVoucher:save",
            "HotelConfig:view",
            "hotelConfig:connection:save",
            "hotelConfig:connection:newKey"
        ]
    },
    '7279a2b8-235c-4e18-9906-7d1baebdbf24': { // voucher manager
        static: [
            "CardClient",
            "salesDashboard:view",
            "operationManagement:add",
            "voucherDetails:sendNotif",
            "voucherDetails:save",
            "redeems:add",
            "onlineSales:send",
            "onlineSales:refund",
            "hotelList:view",
            "hotel:view",
            "editVoucher:view",
            "voucherManagement:add",
            "voucherManagement:remove",
            "voucherManagement:edit",
            "voucherManagement:shop",
            "termsConditions:save",
            "termsConditions:add",
            "voucherIssueAutomation:add",
            "voucherIssueAutomationDetails:save",
            "voucherIssueAutomationDetails:addChannel",
            "voucherIssueAutomationDetails:addRate",
            "voucherIssueAutomationDetails:removeRate",
            "voucherIssueAutomationDetails:removeChannel",
            "referenceData:syncRateChannel",
            "referenceData:syncUsersAndPayments",
            "profileDetail:save",
            "hotelConfig:save",
            "PMSConectivity:add",
            "PMSConectivity:save",
            "PMSConectivity:validate",
            "cardConfigWizard:saveConfig",
            "cardConfigWizard:addService",
            "cardConfigWizard:saveTemplate",
            "cardConfigWizard:savePromote",
            "cardConfigWizard:addImage",
            "cardConfigWizard:deleteImage",
            "cardConfigWizard:saveTermsExcluded",
            "cardConfigWizard:saveTermsIncluded",
            "cardConfigWizard:saveTEC",
            "cardConfigWizard:removeService",
            "cardConfigWizard:saveService",
            "promotions:add",
            "promotions:save",
            "voucherDetails:client:edit",
            "onlineSales:registOnPms",
            "useVoucher:save",
            "hotelConfig:connection:save",
            "hotelConfig:connection:newKey"
        ]
    },  
    '0901c0c4-f875-40ea-89bc-603394609a66': { // regular user
        static: [
            "CardClient",
            "salesDashboard:view",
            "hotelList:view",
            "hotel:view",
            "editVoucher:view"
        ]
    },

    'ec96829b-df17-4e86-b85a-460995dc7e50': { // Venda
        static: [
            "CardClient",
            "salesDashboard:view",
            "voucherDetails:sendNotif",
            "hotelList:view",
            "hotel:view",
            "editVoucher:view",
            "voucherManagement:shop"
        ]
    },
    
    'ded4f8f8-e327-4936-a5be-0e15f520906f': {  // operations
        static: [
            "CardClient",
            "salesDashboard:view",
            "operationManagement:add",
            "voucherDetails:sendNotif",
            "voucherDetails:save",
            "redeems:add",
            "onlineSales:send",
            "onlineSales:refund",
            "hotelList:view",
            "hotel:view",
            "paymentManagement:disable",
            "paymentManagement:revoke",
            "paymentManagement:save",
            "adminHotelList:view",
            "adminHotelGroupList:view",
            "paymentKeyManagement:view",
            "apiGatewayUser:view",
            "apiGatewayUserDetails:view",
            "adminUserManagement:view",
            "manageScheduleTask:view",
            "admin:view",
            "manageUser:accessGroup:save",
            "manageUser:add",
            "manageUser:property:save",
            "manageUser:selectedUser:save",
            "manageUser:selectedUser:delete",
            "manageUser:selectedUser:disable",
            "manageUser:selectedUser:enable",
            "editVoucher:view",
            "voucherManagement:add",
            "voucherManagement:remove",
            "voucherManagement:edit",
            "voucherManagement:shop",
            "termsConditions:save",
            "termsConditions:add",
            "allProperties:changeProperty",
            "voucherIssueAutomation:add",
            "voucherIssueAutomationDetails:save",
            "voucherIssueAutomationDetails:addChannel",
            "voucherIssueAutomationDetails:addRate",
            "voucherIssueAutomationDetails:removeRate",
            "voucherIssueAutomationDetails:removeChannel",
            "referenceData:syncRateChannel",
            "referenceData:syncUsersAndPayments",
            "profileDetail:save",
            "hotelConfig:save",
            "PMSConectivity:add",
            "PMSConectivity:save",
            "PMSConectivity:validate",
            "cardConfigWizard:saveConfig",
            "cardConfigWizard:addService",
            "cardConfigWizard:saveTemplate",
            "cardConfigWizard:savePromote",
            "cardConfigWizard:addImage",
            "cardConfigWizard:deleteImage",
            "cardConfigWizard:saveTermsExcluded",
            "cardConfigWizard:saveTermsIncluded",
            "cardConfigWizard:saveTEC",
            "cardConfigWizard:removeService",
            "cardConfigWizard:saveService",
            "licenses:view",
            "hotel:save",
            "hotelLicenses:view",
            "priceList:view",
            "support:view",
            "paymentPosition:view",
            "payment:payed",
            "paymentManagement:view",
            "manageUser:view",
            "voucherDetails:editValidUntil",
            "promotions:add",
            "promotions:save",
            "voucherDetails:client:edit",
            "onlineSales:registOnPms",
            "useVoucher:save",
            "HotelConfig:view",
            "hotelConfig:connection:save",
            "hotelConfig:connection:newKey"
        ]
    },

    '2cc9c7fc-8987-4680-b51b-f082d943058f': {  // admin operations
        static: [
            "CardClient",
            "salesDashboard:view",
            "operationManagement:add",
            "voucherDetails:sendNotif",
            "voucherDetails:save",
            "redeems:add",
            "onlineSales:send",
            "onlineSales:refund",
            "hotelList:view",
            "hotel:view",
            "paymentManagement:disable",
            "paymentManagement:revoke",
            "paymentManagement:save",
            "adminHotelList:view",
            "adminHotelGroupList:view",
            "paymentKeyManagement:view",
            "apiGatewayUser:view",
            "apiGatewayUserDetails:view",
            "adminUserManagement:view",
            "manageScheduleTask:view",
            "admin:view",
            "manageUser:accessGroup:save",
            "manageUser:add",
            "manageUser:property:save",
            "manageUser:selectedUser:save",
            "manageUser:selectedUser:delete",
            "manageUser:selectedUser:disable",
            "manageUser:selectedUser:enable",
            "editVoucher:view",
            "voucherManagement:add",
            "voucherManagement:remove",
            "voucherManagement:edit",
            "voucherManagement:shop",
            "termsConditions:save",
            "termsConditions:add",
            "allProperties:changeProperty",
            "voucherIssueAutomation:add",
            "voucherIssueAutomationDetails:save",
            "voucherIssueAutomationDetails:addChannel",
            "voucherIssueAutomationDetails:addRate",
            "voucherIssueAutomationDetails:removeRate",
            "voucherIssueAutomationDetails:removeChannel",
            "referenceData:syncRateChannel",
            "referenceData:syncUsersAndPayments",
            "profileDetail:save",
            "hotelConfig:save",
            "PMSConectivity:add",
            "PMSConectivity:save",
            "PMSConectivity:validate",
            "cardConfigWizard:saveConfig",
            "cardConfigWizard:addService",
            "cardConfigWizard:saveTemplate",
            "cardConfigWizard:savePromote",
            "cardConfigWizard:addImage",
            "cardConfigWizard:deleteImage",
            "cardConfigWizard:saveTermsExcluded",
            "cardConfigWizard:saveTermsIncluded",
            "cardConfigWizard:saveTEC",
            "cardConfigWizard:removeService",
            "cardConfigWizard:saveService",
            "licenses:view",
            "hotel:save",
            "hotelLicenses:view",
            "hotelLicense:save",
            "priceList:view",
            "priceList:save",
            "priceList:add",
            "priceList:delete",
            "support:view",
            "paymentPosition:view",
            "payment:payed",
            "paymentDashboard:view",
            "paymentManagement:view",
            "manageUser:view",
            "voucherDetails:editValidUntil",
            "promotions:add",
            "promotions:save",
            "voucherDetails:client:edit",
            "onlineSales:registOnPms",
            "useVoucher:save",
            "HotelConfig:view",
            "hotelConfig:connection:save",
            "hotelConfig:connection:newKey"
        ]
    },
};

export default rules;