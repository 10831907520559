import React, { Component } from 'react';
import { getAPI } from '../Base/API';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import { CustomTable } from '../Base/Common/CustomTable';
import { FormattedMessage, injectIntl } from "react-intl";

export class HotelList extends Component {
    static displayName = HotelList.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            hoteList: [],
            currentPage: 0,
            PageSize: 10,
            license: []
        }
    }


    componentDidMount() {
        this.getHotels();
        this.getLicenses();
    }

    getLicenses = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                var list = [];

                data.response && data.response.map(license => {
                    list.push({ value: license.id, label: license.name })
                })
                this.setState({ licenses: list, block: false });
            }
        }, `/api/Voucher/Hotel/v1/License`);
    }

    getHotels = () => {
        this.setState({ block: true });
        var params = '';
        params += this.state.licenseId ? `?licenseId=${this.state.licenseId}` : '';

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ hoteList: data.response[0].hotels, block: false });
            }
        }, '/api/Voucher/Hotel/v1/Hotel' + params);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    render() {

        const columns = [
            {
                dataField: 'name2',
                text: this.props.intl.formatMessage({ id: "HotelList.HotelName" }),
                sort: true
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push('/Hotel/' + row.hotelId)
            }
        };


        return (
            <StyledCard icon={'fas fa-hotel'} title={'HotelList.HotelList'} help={<FormattedMessage id="HotelList.Help"/>} id="hotelList" error={this.state.error} block={this.state.block}>
                <CustomTable
                    keyField='hotelId'
                    data={this.state.hoteList ? this.state.hoteList : []}
                    columns={columns}
                    shadow={false}
                    exportCSV={true}
                    showTotal={true}
                    remote={true}
                    selectRow={selectRow}
                />
            </StyledCard>


        );
    }
}

export default injectIntl(HotelList);