import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Input, Label, Row, Button } from 'reactstrap';
import { getAPI, postAPI } from '../Base/API';
import { StyledCard, getLanguageOptions } from '../Base/Common/CommonUIComponents';
import CustomSelect from '../Base/Common/CustomSelect';
import CustomSelectAsync from '../Base/Common/CustomSelectAsync';
import { handleNotification } from "../Base/Common/Notification";
import countryList from "react-select-country-list";
import { FormattedMessage, injectIntl } from 'react-intl';
import { DateRangePicker } from 'react-dates';
import moment from "moment";
import { getRightVoucherId } from '../Base/Common/ReferenceDataFunctions';

class AddVoucher extends Component {
    static displayName = AddVoucher.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: true,
            voucherConfigList: [], 
            voucherList: [], 
            voucher: {
                voucherTypeIds: [
                    {
                        voucherTypeId: null,
                        quantity: 1,
                        voucherSalePrice: null
                    }
                ],
                HostPaymentCodes: [
                    {
                        hostPaymentCode: null,
                        amount: null
                    }
                ],
                languageCode: getLanguageOptions()[0].value
            },
            voucherConfig: {},
            paymentMethods: [],
            pmsUsers: [],
            registerSalesInPms: false,
            voucherChannels: [],
            isGenericVat: false,
            cancelationModal: false,
            maxValidTo: null
        }
    }

    componentDidMount() {
        const { voucher } = this.state;

        let v = this.props.selectedVoucher;
        v.vouchersValidUntil = v.vouchersValidUntil ? moment().add(v.vouchersValidUntil, 'month') : null;

        voucher.voucherValue = v.voucherValue;
        voucher.vouchersValidFrom = moment();
        voucher.vouchersValidUntil = v.vouchersValidUntil;
        voucher.voucherTypeIds[0].voucherTypeId = v.voucherTypeId;
        voucher.voucherTypeIds[0].voucherSalePrice = v.salePrice;
        voucher.HostPaymentCodes[0].amount = v.salePrice;

        this.setState({ voucher, voucherChannels: v.channels, maxValidTo: moment(v.vouchersValidUntil) });
        
        this.getConfig();
    }

    getConfig() {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let arr = [];
                data.response && data.response.forEach((el) => {
                    if (moment().diff(moment(el.saleValidTo), 'days') <= 0) {
                        arr.push({ value: el.id, label: el.code, channels: el.channels })
                    }
                })

                const voucherConfig = data.response.find(el => el.id === this.state.voucher.voucherTypeId)
                const registerSalesInPms = data.response && data.response.length > 0 ? data.response[0].registerSalesInPms : false
                
                this.setState({ voucherConfigList: arr, voucherConfig, voucherList: data.response, registerSalesInPms, block: false },
                    () => { if (this.state.registerSalesInPms) { this.getPmsData() }});
            }
        }, '/api/Voucher/V1/Config');
    }

    getPmsData = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let arr = [];
                data.response && data.response.map((el) => { return arr.push({ value: el.code, label: el.description }) })

                this.setState({ paymentMethods: arr });
            }
        }, `/api/Voucher/v1/Config/paymentMethods`);

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let users = [];

                data.response && data.response.map((el) => {
                    var obj = {
                        value: el.userCode,
                        label: el.firstName + ' ' + el.lastName
                    }

                    var cashBoxes = [];
                    el.cashBoxes && el.cashBoxes.map(el => {
                        cashBoxes.push({
                            value: el.cashBoxCode,
                            label: el.cashBoxDescription
                        })
                    })

                    obj.cashBoxes = cashBoxes;

                    users.push(obj)
                })
                this.setState({ pmsUsers: users, block: false });
            }
        }, `/api/Voucher/v1/Config/pmsUsers`);

    }

    handleChange(evt) {
        let name = evt.target.name;
        let value = evt.target.value;

        this.setState(prevState => ({
            ...prevState,
            voucher: {
                ...prevState.voucher,
                [name]: value

            },
        }));
    }

    handleDateRangeChange = (startDate, endDate) => {
        this.setState(prevState => ({
            ...prevState,
            voucher: {
                ...prevState.voucher,
                vouchersValidFrom: startDate,
                vouchersValidUntil: endDate

            },
        }));

    }

    updateFilterCombo(val, evt) {
        if (evt) {
            const name = evt.name;
            const value = val.value;

            const v = this.state.voucherList.find(el => el.id === value);

            if (v) {
                const { voucher } = this.state;
                voucher.voucherValue = v.cardAmount;
                voucher.voucherTypeIds[0].voucherTypeId = v.id;
                voucher.voucherTypeIds[0].voucherSalePrice = v.payedAmount;
                voucher.HostPaymentCodes[0].amount = v.payedAmount;
                voucher.vouchersValidUntil = moment(v.saleValidTo)

                this.setState({ voucher, voucherChannels: val.channels, maxValidTo: moment(v.saleValidTo) });
            }
        }
    }

    handleSelect = (val, evt) => {
        if (evt) {
            let name = evt.name;
            let value = val.value;

            if (name === 'hostUserCode') {
                this.setState(prevState => ({
                    ...prevState,
                    voucher: {
                        ...prevState.voucher,
                        hostUserCashboxCode: null
                    },
                }));
            }

            this.setState(prevState => ({
                ...prevState,
                voucher: {
                    ...prevState.voucher,
                    [name]: value
                },
            }));
        }
    }

    saveVoucher(e) {
        e.preventDefault();

        var body = { ...this.state.voucher };
        body.hostPaymentCode = parseInt(body.salePrice) > 0 ? body.hostPaymentCode : null;

        if (this.form.current.reportValidity()) {
            this.setState({ block: true });
            
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    if (error.request && error.request.status === 504) {
                        handleNotification({}, <FormattedMessage id="AddVoucher.TimeoutConsultLogs" />, <FormattedMessage id="generic.timeout" />, 'info');
                    }
                    else {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    }
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else if (data.warnings && data.warnings.length > 0) {
                        handleNotification(data);
                        handleNotification({}, <FormattedMessage id="AddVoucher.VoucherCreatedSucessfuly" />, <FormattedMessage id="AddVoucher.Success" />);
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="AddVoucher.VoucherCreatedSucessfuly" />, <FormattedMessage id="AddVoucher.Success" />);
                    }
                    this.setState({ block: false }, () => {
                        if(this.props.operations)
                            this.props.getVouchers();
                        else
                            this.props.setSoldVouchers(data.response ? data.response : []);
                    });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, '/api/Voucher/Voucher/v1/voucher', { "request": this.state.voucher });
        }
    }

    handleSalePriceChange(evt) {
        if (evt.target) {
            const { value } = evt.target;
            const { voucher } = this.state;
            voucher.voucherTypeIds[0].voucherSalePrice = value;
            voucher.HostPaymentCodes[0].amount = value * voucher.voucherTypeIds[0].quantity;

            this.setState({ voucher });
        }
    }

    handlePaymentCodeSelect = (val, evt) => {
        if (evt) {
            const { value }= val;
            const { voucher } = this.state;
            voucher.HostPaymentCodes[0].hostPaymentCode = value;

            this.setState({ voucher });
        }
    }

    handleQuantityChange(evt) {
        if (evt && evt.target) {
            const { value } = evt.target;
            const { voucher } = this.state;
            voucher.voucherTypeIds[0].quantity = parseInt(value);
            voucher.HostPaymentCodes[0].amount = voucher.voucherTypeIds[0].voucherSalePrice * voucher.voucherTypeIds[0].quantity;
            this.setState({ voucher });
        }
    }

    handleFinalConsumerVat = (e) => {
        const { checked, name } = e.target;

        this.setState({
            [name]: checked,
            voucher: {
                ...this.state.voucher,
                customerNif: checked ? '999999990' : ''
            }
        });
    }

    loadClients = (name, cb) => {
        let options = [];
        if (name.length === 0 || name.length > 2) {
            getAPI(result => {
                const { data } = result;
                if (data && data.response && data.response.length > 0) {
                    data.response.forEach(resp => {
                        resp.value = resp.id;
                        resp.label = `${resp.firstName} ${resp.lastName}${resp.email ? ` (${resp.email})` : ``}${resp.phone ? ` (${this.props.intl.formatMessage({ id: 'AddVoucher.Phone' })}: ${resp.phone})` : ``} ${resp.fiscalNumber ? ` (${this.props.intl.formatMessage({ id: 'AddVoucher.CustomerNif' })}: ${resp.fiscalNumber})` : ``}`
                    });
                    options = data.response;
                }
                cb(options);
            }, `/api/Voucher/Profile/v1/Profile?pageIndex=0&pageSize=10&openToSearch=true${name ? `&filterToSearch=${name}` : ``}`);
        }
        else {
            cb(options);
        }
    }

    handleSelectProfile = (profile) => {
        const { voucher } = this.state;
        if (profile) {
            voucher.customerFirstName = profile.firstName;
            voucher.customerLastName = profile.lastName;
            voucher.customerMail = profile.email;
            voucher.customerNif = profile.fiscalNumber;
            voucher.customerPhone = profile.phone;
            voucher.customerCountryCode = profile.countryCode;
            voucher.customerPostCode = profile.postCode;
            voucher.customerCity = profile.city;
            voucher.address = profile.address;
        }
        else {
            voucher.customerFirstName = null;
            voucher.customerLastName = null;
            voucher.customerMail = null;
            voucher.customerNif = null;
            voucher.customerPhone = null;
            voucher.customerCountryCode = null;
            voucher.customerPostCode = null;
            voucher.customerCity = null;
            voucher.address = null;
        }
        this.setState({ voucher });
    }

    handleLanguageCode = (combo, evt) => {
        if (evt) {
            const { voucher } = this.state;
            voucher.languageCode = combo && combo.value;
            this.setState({ voucher });
        }
    }

    render() {
        return (
            <StyledCard icon={'fas fa-gift'} title={'AddVoucher.title'} error={this.state.error} block={this.state.block}>
                {this.state.voucher ?
                    <form ref={this.form}>
                        <Row>
                            <Col>
                                {
                                    this.props.soldVouchers?.map((soldVoucher, key) =>
                                        <div key={key} className="mb-4">
                                            <b><FormattedMessage id="AddVoucher.soldVoucher" /> </b>  <Link to={`/VoucherOperationDetails/${soldVoucher.id}`} style={{ cursor: "pointer", color: "blue" }}> {getRightVoucherId(soldVoucher.voucherId, soldVoucher.voucherIdFirst8)} </Link>
                                        </div>
                                    )
                                }
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <Label>
                                    <FormattedMessage id="AddVoucher.SelectLanguage" />
                                </Label>
                            </Col>
                            <Col className="col-4">
                                <CustomSelect options={getLanguageOptions()} value={getLanguageOptions().find(lOpt => lOpt.value === this.state.voucher.languageCode) || ''} onChange={this.handleLanguageCode} />
                            </Col>
                        </Row> 
                        <Row className="mb-3">
                            <Col className="col-2">
                                <Label>
                                    <FormattedMessage id="generic.VoucherType" />
                                </Label>
                            </Col>
                            <Col className="col-4">
                                <CustomSelect
                                    options={this.state.voucherConfigList}
                                    value={this.state.voucherConfigList.find(el => el.value === this.state.voucher.voucherTypeIds[0].voucherTypeId) || ''}
                                    name="voucherTypeId"
                                    onChange={this.updateFilterCombo.bind(this)}
                                    placeholder={<FormattedMessage id="generic.VoucherType" />}
                                    required
                                />
                            </Col>
                            <Col className="col-2">
                                <Label>
                                    <FormattedMessage id="generic.Quantity" />
                                </Label>
                            </Col>
                            <Col className="col-4">
                                <Input type="number" id="quantity" name="quantity" value={this.state.voucher.voucherTypeIds[0].quantity || ''} onChange={this.handleQuantityChange.bind(this)} min="1" required />
                            </Col>
                        </Row>


                        <Row>
                            <Col>
                                <h4>
                                    <FormattedMessage id="AddVoucher.Price&Validity" />
                                </h4>
                            </Col>
                        </Row>
                        <hr className="mb-2 mt-0" />
                        <Row className="mb-2">
                            <Col className="col-2"><Label><FormattedMessage id="generic.salePrice" /></Label></Col>
                            <Col className="col-4">
                                <FormattedMessage id="generic.salePrice">
                                    {
                                        placeholder =>
                                            <Input
                                                type="number"
                                                required
                                                value={this.state.voucher.voucherTypeIds[0].voucherSalePrice || ''}
                                                name="salePrice"
                                                onChange={this.handleSalePriceChange.bind(this)}
                                                placeholder={placeholder}
                                                min={0}
                                            />
                                    }
                                </FormattedMessage>
                            </Col>
                            <Col className="col-2"><Label><FormattedMessage id="generic.voucherValue" /></Label></Col>
                            <Col className="col-4">
                                <FormattedMessage id="generic.voucherValue">
                                    {
                                        placeholder => <Input type="number" required value={this.state.voucher.voucherValue ? this.state.voucher.voucherValue : ''} name="voucherValue" onChange={this.handleChange.bind(this)} placeholder={placeholder} />
                                    }
                                </FormattedMessage>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2"><Label><FormattedMessage id="generic.invoice" /></Label></Col>
                            <Col className="col-4">
                                <FormattedMessage id="generic.invoice">
                                    {
                                        placeholder => <Input type="text" value={this.state.voucher.invoiceReference || ''} name="invoiceReference" onChange={this.handleChange.bind(this)} placeholder={placeholder} />
                                    }
                                </FormattedMessage>
                            </Col>
                            <Col className="col-2">
                                <Label>
                                    <FormattedMessage id="VoucherOperationDetails.Vality" />
                                </Label>
                            </Col>
                            <Col className="col-4">
                                <DateRangePicker
                                    startDateId="vouchersValidFrom"
                                    startDate={this.state.voucher && this.state.voucher.vouchersValidFrom ? this.state.voucher.vouchersValidFrom : null}
                                    isOutsideRange={day => day < moment() || day > this.state.maxValidTo}
                                    endDate={this.state.voucher && this.state.voucher.vouchersValidUntil ? this.state.voucher.vouchersValidUntil : null}
                                    endDateId="vouchersValidUntil"
                                    onDatesChange={({ startDate, endDate }) => this.handleDateRangeChange(startDate, endDate)}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    small={true}
                                    required
                                    numberOfMonths={1}
                                    showDefaultInputIcon={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-2">
                                <Label>
                                    <FormattedMessage id="generic.notes" />
                                </Label>
                            </Col>
                            <Col className="col-10">
                                <FormattedMessage id="generic.notes">
                                    {
                                        placeholder => <Input type="textarea" value={this.state.voucher.notes !== null ? this.state.voucher.notes : ''} name="notes" onChange={this.handleChange.bind(this)} placeholder={placeholder} />
                                    }
                                </FormattedMessage>
                            </Col>
                        </Row>


                        <Row className="mt-4">
                            <Col>
                                <h4>
                                    <FormattedMessage id="AddVoucher.ClientInfo" />
                                </h4>
                            </Col>
                        </Row>
                        <hr className="mb-2 mt-0" />
                        <Row className="mb-2">
                            <Col className="col-2">
                                <FormattedMessage id="AddVoucher.SearchClients" />
                            </Col>
                            <Col className="col-10">
                                <CustomSelectAsync
                                    cacheOptions
                                    loadOptions={this.loadClients}
                                    defaultOptions
                                    onChange={this.handleSelectProfile}
                                />                               
                            </Col>
                        </Row>
                        <hr className="mb-2 mt-0" />
                        <Row className="mb-2">
                            <Col className="col-2">
                                <Label>
                                    <FormattedMessage id="AddVoucher.CustomerFirstName" /> *
                                </Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="AddVoucher.CustomerFirstName">
                                    {
                                        placeholder => <Input type="text" required value={this.state.voucher?.customerFirstName || ''} name="customerFirstName" onChange={this.handleChange.bind(this)} placeholder={placeholder} />
                                    }
                                </FormattedMessage>
                            </Col>
                            <Col className="col-2">
                                <Label>
                                    <FormattedMessage id="AddVoucher.CustomerLastName" /> *
                                </Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="AddVoucher.CustomerLastName">
                                    {
                                        placeholder => <Input type="text" required value={this.state.voucher?.customerLastName || ''} name="customerLastName" onChange={this.handleChange.bind(this)} placeholder={placeholder} />
                                    }
                                </FormattedMessage>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <Label>
                                    <FormattedMessage id="AddVoucher.CustomerMail" /> *
                                </Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="AddVoucher.CustomerMail">
                                    {
                                        placeholder => <Input type="email" required value={this.state.voucher?.customerMail || ''} name="customerMail" onChange={this.handleChange.bind(this)} placeholder={placeholder} />
                                    }
                                </FormattedMessage>
                            </Col>
                            <Col className="col-2">
                                <Label>
                                    <FormattedMessage id="AddVoucher.CustomerNif" /> *
                                </Label>
                            </Col>
                            <Col className="col-2">
                                <FormattedMessage id="AddVoucher.CustomerNif">
                                    {
                                        placeholder => <Input type="text" required value={this.state.voucher?.customerNif || ''} name="customerNif" onChange={this.handleChange.bind(this)} placeholder={placeholder} readOnly={this.state.isGenericVat}/>
                                    }
                                </FormattedMessage>
                            </Col>

                            <Col className="col-2 d-flex align-items-center">
                                <Input type="checkbox" name="isGenericVat"
                                    className="mb-2"
                                    checked={this.state.isGenericVat}
                                    onChange={(e) => this.handleFinalConsumerVat(e)}
                                />
                                <Label className="mb-1"><FormattedMessage id="AddVoucher.FinalConsumer" /></Label>
                            </Col>
                        </Row>


                        <Row className="mb-2">
                            <Col className="col-2">
                                <Label>
                                    <FormattedMessage id="AddVoucher.Phone" />
                                </Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="AddVoucher.Phone">
                                    {
                                        placeholder => <Input type="text" name="customerPhone"
                                            value={this.state.voucher?.customerPhone || ''}
                                            onChange={this.handleChange.bind(this)}
                                            placeholder={placeholder}
                                        />
                                    }
                                </FormattedMessage>
                            </Col>
                            <Col className="col-2">
                                <Label>
                                    <FormattedMessage id="AddVoucher.CustomerCountryCode" />
                                </Label>
                            </Col>
                            <Col sm={4}>
                                <CustomSelect
                                    options={countryList().getData()}
                                    name="customerCountryCode"
                                    isClearable 
                                    isSearchable
                                    placeholder={<FormattedMessage id="AddVoucher.CustomerCountryCode" />}
                                    onChange={this.handleSelect.bind(this)}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col className="col-2">
                                <Label>
                                    <FormattedMessage id="AddVoucher.CustomerPostCode" />
                                </Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="AddVoucher.CustomerPostCode">
                                    {
                                        placeholder => <Input type="text" name="customerPostCode"
                                            value={this.state.voucher?.customerPostCode || ''}
                                            onChange={this.handleChange.bind(this)}
                                            placeholder={placeholder}
                                        />
                                    }
                                </FormattedMessage>
                            </Col>
                            <Col className="col-2">
                                <Label>
                                    <FormattedMessage id="AddVoucher.CustomerCity" />
                                </Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="AddVoucher.CustomerCity">
                                    {
                                        placeholder =>
                                            <Input type="text" name="customerCity"
                                                value={this.state.voucher?.customerCity || ''}
                                                onChange={this.handleChange.bind(this)}
                                                placeholder={placeholder}
                                            />
                                    }
                                </FormattedMessage>
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col className="col-2">
                                <Label>
                                    <FormattedMessage id="AddVoucher.Address" />
                                </Label>
                            </Col>
                            <Col className="col-10">
                                <FormattedMessage id="AddVoucher.Address">
                                    {
                                        placeholder =>
                                            <Input type="text" name="customerAddress"
                                                value={this.state.voucher?.address || ''}
                                                onChange={this.handleChange.bind(this)}
                                                placeholder={placeholder}
                                            />
                                    }
                                </FormattedMessage>
                            </Col>
                        </Row>


                        {this.state.registerSalesInPms && this.state.voucher.voucherTypeIds[0].voucherSalePrice > 0 ? 
                            <div>
                                <Row className="mt-4">
                                    <Col>
                                        <h4>
                                            <FormattedMessage id="AddVoucher.OperatorData" />
                                        </h4>
                                    </Col>
                                </Row>
                                <hr className="mb-2 mt-0" />
                                <Row className="mb-2">
                                    <Col className="col-2">
                                        <Label>
                                            <FormattedMessage id="AddVoucher.HostUserCode" /> *
                                        </Label>
                                    </Col>
                                    <Col className="col-4">
                                        <CustomSelect
                                            options={this.state.pmsUsers}
                                            value={this.state.pmsUsers.find(el => el.value === this.state.voucher.hostUserCode) || ''}
                                            name="hostUserCode"
                                            onChange={this.handleSelect.bind(this)}
                                            placeholder={<FormattedMessage id="AddVoucher.HostUserCode" />}
                                            required
                                        />
                                    </Col>
                                    <Col className="col-2">
                                        <Label>
                                            <FormattedMessage id="AddVoucher.HostUserCashboxCode" /> *
                                        </Label>
                                    </Col>
                                    <Col className="col-4">
                                        <CustomSelect
                                            options={this.state.voucher.hostUserCode ? this.state.pmsUsers.find(el => el.value === this.state.voucher.hostUserCode).cashBoxes : ''}
                                            name="hostUserCashboxCode"
                                            value={this.state.voucher.hostUserCode && this.state.voucher.hostUserCashboxCode ? this.state.pmsUsers.find(el => el.value === this.state.voucher.hostUserCode).cashBoxes.find(el => el.value === this.state.voucher.hostUserCashboxCode) : null}
                                            onChange={this.handleSelect.bind(this)}
                                            placeholder={<FormattedMessage id="AddVoucher.HostUserCashboxCode" />}
                                            isDisabled={this.state.voucher.hostUserCode ? false : true}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-2">
                                        <Label>
                                            <FormattedMessage id="AddVoucher.HostPaymentCode" /> *
                                        </Label>
                                    </Col>
                                    <Col className="col-4">

                                        <CustomSelect
                                            options={this.state.paymentMethods}
                                            value={this.state.paymentMethods.find(el => el.value === this.state.voucher.HostPaymentCodes[0].hostPaymentCode) || ''}
                                            name="hostPaymentCode"
                                            onChange={this.handlePaymentCodeSelect.bind(this)}
                                            placeholder={<FormattedMessage id="AddVoucher.HostPaymentCode" />}
                                            required
                                        />
                                    </Col>
                                    <Col className="col-2">
                                        <Label> 
                                            <FormattedMessage id="AddVoucher.HostUserPassword" />*
                                        </Label>
                                    </Col>
                                    <Col className="col-4">
                                        <FormattedMessage id="AddVoucher.HostUserPassword">
                                            {
                                                placeholder => <Input type="password" required value={this.state.voucher.hostUserPassword !== null ? this.state.voucher.hostUserPassword : ''} name="hostUserPassword" onChange={this.handleChange.bind(this)} placeholder={placeholder} />
                                            }
                                        </FormattedMessage>
                                    </Col>
                                </Row>
                            </div>
                        : ''}

                        <Row className="mt-4">
                            <Col className="text-right">
                                <Button className="btn-host btn-sm" onClick={this.saveVoucher.bind(this)} disabled={(this.state.voucherChannels && !this.state.voucherChannels.some(c => c.channelConfigId === 2)) || this.props.soldVouchers?.length > 0}>
                                    <i className="fas fa-save " />
                                </Button>
                            </Col>
                        </Row>
                    </form>
                    :
                    ''
                }
            </StyledCard>
        )
    }
}
export default injectIntl(AddVoucher);