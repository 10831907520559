import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Row, Col, FormGroup, Label, Input, Button, Form, CustomInput } from 'reactstrap';
import { getLicenseModules } from "../../Base/Common/ReferenceDataFunctions";
import { handleNotification } from "../../Base/Common/Notification";
import CustomSelect from '../../Base/Common/CustomSelect';
import { ErrorAlert } from "../../Base/ErrorAlert";
import { postAPI } from "../../Base/API";
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';

export class LicenseDetails extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            license: this.props.license ? this.props.license : {modules: []},
        };
    }

    componentDidMount() {
    }

    handleSwitch = (evt) => {
        const { name, checked } = evt.target;

        this.setState({
            license: {
                ...this.state.license,
                [name]: checked
            }
        });
    };

    handleModules = (combo) => {
        const { license } = this.state;
        license.modules = combo !== null ? combo.map(opt => opt.value) : [];
        this.setState({ license });
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState(prevState => ({
            license: {
                ...prevState.license,
                [name]: value
            }
        }) );
    }

    saveLicense = (e) => {
        e.preventDefault();

        this.setState({ block: true });
        var body = { ...this.state.license };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(({ block: false, error: errorMessage }));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="LicenseDetails.LicenseSaved" />, <FormattedMessage id="generic.success" />);

                    this.props.updateTable(data.response[0]);
                    this.setState(({ block: false }));
                    this.props.toggle();
                }
                this.setState(({ block: false }));
            }
            this.setState(({ block: false }));
        }, '/api/Voucher/Hotel/v1/License', JSON.stringify({ request: body }));
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-xl">
                <ModalHeader toggle={this.props.toggle}>
                    {this.props.license ? <FormattedMessage id="LicenseDetails.EditLicense" /> : <FormattedMessage id="LicenseDetails.CreateLicense" />}
                </ModalHeader>

                <ModalBody className="p-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={this.saveLicense} innerRef={this.form}>
                            <Card className="shadow border-0 p-0">

                                <CardBody className="m-0">
                                    <Row className="bg-white mb-2">
                                        <Col className="text-right">
                                            <Button type="submit" className="btn btn-sm btn-host float-right">
                                                <i className="fas fa-save" />
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={4}> <FormattedMessage id="Licenses.Name" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        className="text-sm"
                                                        value={this.state.license ? this.state.license.name : ''}
                                                        onChange={this.handleChange}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={10}> <FormattedMessage id="generic.active" /> </Label>
                                                <Col sm={2} className="d-flex align-items-center justify-content-end">
                                                    <CustomInput
                                                        type="switch"
                                                        name="active"
                                                        id="active"
                                                        placeholder={''}
                                                        checked={this.state.license && this.state.license.active}
                                                        onChange={this.handleSwitch.bind(this)}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={4}> <FormattedMessage id="Licenses.Transactions" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="number"
                                                        name="transactions"
                                                        className="text-sm"
                                                        value={this.state.license ? this.state.license.transactions : ''}
                                                        min="0"
                                                        onChange={this.handleChange}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={4}> <FormattedMessage id="Licenses.Modules" /> </Label>
                                                <Col sm={8}>
                                                    <CustomSelect
                                                        isMulti
                                                        isClearable
                                                        name={'modules'}
                                                        placeholder={''}
                                                        value={this.state.license && this.state.license.modules ? getLicenseModules().filter(opt => this.state.license.modules.find(m => opt.value === m) !== undefined) : ''}
                                                        options={getLicenseModules()}
                                                        onChange={this.handleModules.bind(this)} /> 
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}
