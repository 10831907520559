import React, { Component } from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import CustomSelect from '../Base/Common/CustomSelect';
import { getAPI, putAPI } from '../Base/API';
import { handleNotification } from '../Base/Common/Notification';

export class RegistSaleOnPmsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blockPaymentMethods: false,
            blockPmsUsers: false,
            blockSave: false,
            register: {
                voucherTypeIds: [],
                hostPaymentCodes: [
                    {
                        hostPaymentCode: null,
                        amount: null
                    }
                ],
                voucherIds: []
            },
            paymentMethods: [],
            pmsUsers: [],
            voucherTypes: []
        };
        this.form = React.createRef();
        this.getPmsData = this.getPmsData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handlePaymentCodeSelect = this.handlePaymentCodeSelect.bind(this);
        this.saveRegist = this.saveRegist.bind(this);
    }

    componentDidMount() {
        const { vouchers, name, email } = this.props;
        const { register } = this.state;
        const voucherTypeIds = [];

        vouchers.forEach(voucher => {
            const v = voucherTypeIds.find(voucherType => voucher.voucherTypeId === voucherType.voucherTypeId);
            if (v) {
                v.quantity += 1;
                v.voucherSalePrice += voucher.salePrice;
            }
            else {
                const voucherTypeId = {
                    voucherTypeId: voucher.voucherTypeId,
                    quantity: 1,
                    voucherSalePrice: voucher.salePrice,
                    code: voucher.voucherTypeCode
                };
                voucherTypeIds.push(voucherTypeId);
            }
            register.voucherIds.push(voucher.id);
        });

        register.voucherTypeIds = voucherTypeIds;
        if (name) {
            const names = name.split(' ');
            if (names.length > 1) {
                register.customerFirstName = names[0];
                register.customerLastName = names.slice(1).reduce((acc, curr) => acc.concat(curr));
            }
            else {
                register.customerFirstName = name;
            }
        }
        register.customerMail = email;
        register.hostPaymentCodes[0].amount = register.voucherTypeIds.reduce((acc, curr) => acc + curr.voucherSalePrice, 0);
        this.setState({ register });

        this.getPmsData();
    }

    getPmsData() {
        this.setState({ blockPaymentMethods: true, blockPmsUsers: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockPaymentMethods: false });
                return;
            }
            if (data.response && data.response.length > 0) {
                const paymentMethods = [];
                data.response.forEach(el => {
                    if (!paymentMethods.find(pm => pm.value === el.code)) {
                        const opt = {
                            value: el.code,
                            label: el.description
                        };
                        paymentMethods.push(opt);
                    }
                })
                this.setState({ paymentMethods: paymentMethods });
            }
            this.setState({ error: errorMessage, blockPaymentMethods: false });
        }, `/api/Voucher/v1/Config/paymentMethods`);

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockPmsUsers: false });

                return;
            }
            if (data.response && data.response.length > 0) {
                const users = data.response.map((el) => {
                    const cashBoxes = el.cashBoxes && el.cashBoxes.map(cashBox => {
                        const opt = {
                            value: cashBox.cashBoxCode,
                            label: cashBox.cashBoxDescription
                        };
                        return opt;
                    });

                    const obj = {
                        value: el.userCode,
                        label: `${el.firstName} ${el.lastName}`,
                        cashBoxes: cashBoxes
                    };
                    return obj;
                });
                this.setState({ pmsUsers: users });
            }
            this.setState({ error: errorMessage, blockPmsUsers: false });
        }, `/api/Voucher/v1/Config/pmsUsers`);
    }

    handleChange(evt) {
        if (evt.target) {
            const { name, value } = evt.target;
            const { register } = this.state;
            register[name] = value;
            this.setState({ register });
        }
    }

    handleSelect(combo, evt) {
        if (evt) {
            const { register } = this.state;
            const name = evt.name;
            register[name] = combo && combo.value;
            this.setState({ register });
        }
    }

    handlePaymentCodeSelect = (val, evt) => {
        if (evt) {
            const { register } = this.state;
            const { value } = val;
            register.hostPaymentCodes[0].hostPaymentCode = value;
            this.setState({ register });
        }
    }

    saveRegist(e) {
        const { register } = this.state;

        e.preventDefault();

        if (this.form.current.reportValidity()) {
            this.setState({ blockSave: true });

            const request = {
                request: register
            };

            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockSave: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="RegistSaleOnPmsModal.SucessfulMessage" />, <FormattedMessage id="generic.success" />);
                    }
                }
                this.setState({ error: errorMessage, blockSave: false });
            }, `/api/Voucher/Voucher/v1/onlineSale/registerSaleOnPms`, request);
        }
    }

    render() {
        const { isOpen, toggle } = this.props;
        const { blockPaymentMethods, blockPmsUsers, blockSave, register, pmsUsers, paymentMethods } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="OnlineSalesDetail.RegistOnPms" />
                </ModalHeader>
                <ModalBody>
                    <StyledCard block={blockPaymentMethods || blockPmsUsers || blockSave}>
                        <form ref={this.form}>
                            <Row className="mt-4">
                                <Col>
                                    <h4>
                                        <FormattedMessage id="RegistSaleOnPmsModal.Vouchers" />
                                    </h4>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-host btn-sm" onClick={this.saveRegist}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <hr className="mb-2 mt-0" />
                            <Row className="mb-2">
                                <Col>
                                    <FormattedMessage id="RegistSaleOnPmsModal.Quantity" />
                                </Col>
                                <Col>
                                    <FormattedMessage id="RegistSaleOnPmsModal.Code" />
                                </Col>
                                <Col>
                                    <FormattedMessage id="RegistSaleOnPmsModal.Total" />
                                </Col>
                            </Row>  
                            {
                                register.voucherTypeIds.map((voucherType, key) =>
                                    <Row key={key} className="mb-2">
                                        <Col>
                                            {voucherType.quantity}
                                        </Col>
                                        <Col>
                                            {voucherType.code}
                                        </Col>
                                        <Col>
                                            {voucherType.voucherSalePrice}
                                        </Col>
                                    </Row>    
                                )
                            }
                            <Row className="mt-4">
                                <Col>
                                    <h4>
                                        <FormattedMessage id="RegistSaleOnPmsModal.CustomerData" />
                                    </h4>
                                </Col>
                            </Row>
                            <hr className="mb-2 mt-0" />
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="RegistSaleOnPmsModal.CustomerFirstName" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="RegistSaleOnPmsModal.CustomerFirstName">
                                        {
                                            placeholder => <Input type="text" required value={register.customerFirstName !== null ? register.customerFirstName : ''} name="customerFirstName" onChange={this.handleChange} placeholder={placeholder} />
                                        }
                                    </FormattedMessage>
                                </Col>
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="RegistSaleOnPmsModal.CustomerLastName" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="RegistSaleOnPmsModal.CustomerLastName">
                                        {
                                            placeholder => <Input type="text" required value={register.customerLastName !== null ? register.customerLastName : ''} name="customerLastName" onChange={this.handleChange} placeholder={placeholder} />
                                        }
                                    </FormattedMessage>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="RegistSaleOnPmsModal.CustomerMail" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="RegistSaleOnPmsModal.CustomerMail">
                                        {
                                            placeholder => <Input type="email" required value={register.customerMail !== null ? register.customerMail : ''} name="customerMail" onChange={this.handleChange} placeholder={placeholder} />
                                        }
                                    </FormattedMessage>
                                </Col>
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="RegistSaleOnPmsModal.CustomerNif" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="RegistSaleOnPmsModal.CustomerNif">
                                        {
                                            placeholder => <Input type="text" required value={register.customerNif !== null ? register.customerNif : ''} name="customerNif" onChange={this.handleChange} placeholder={placeholder} />
                                        }
                                    </FormattedMessage>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <h4>
                                        <FormattedMessage id="RegistSaleOnPmsModal.OperatorData" />
                                    </h4>
                                </Col>
                            </Row>
                            <hr className="mb-2 mt-0" />
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="RegistSaleOnPmsModal.HostUserCode" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <CustomSelect
                                        options={pmsUsers}
                                        value={pmsUsers.find(el => el.value === register.hostUserCode)}
                                        name="hostUserCode"
                                        onChange={this.handleSelect}
                                        placeholder={<FormattedMessage id="RegistSaleOnPmsModal.HostUserCode" />}
                                        required
                                    />
                                </Col>
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="RegistSaleOnPmsModal.HostUserCashboxCode" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <CustomSelect
                                        options={register.hostUserCode ? pmsUsers.find(el => el.value === register.hostUserCode).cashBoxes : []}
                                        name="hostUserCashboxCode"
                                        value={register.hostUserCode && register.hostUserCashboxCode ? pmsUsers.find(el => el.value === register.hostUserCode).cashBoxes.find(el => el.value === register.hostUserCashboxCode) : null}
                                        onChange={this.handleSelect}
                                        placeholder={<FormattedMessage id="RegistSaleOnPmsModal.HostUserCashboxCode" />}
                                        isDisabled={!register.hostUserCode}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="RegistSaleOnPmsModal.HostPaymentCode" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <CustomSelect
                                        options={paymentMethods}
                                        value={paymentMethods.find(el => el.value === register.hostPaymentCodes[0].hostPaymentCode)}
                                        name="hostPaymentCode"
                                        onChange={this.handlePaymentCodeSelect.bind(this)}
                                        placeholder={<FormattedMessage id="RegistSaleOnPmsModal.HostPaymentCode" />}
                                        required
                                    />
                                </Col>
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="RegistSaleOnPmsModal.HostUserPassword" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="RegistSaleOnPmsModal.HostUserPassword">
                                        {
                                            placeholder => <Input type="password" required value={register.hostUserPassword !== null ? register.hostUserPassword : ''} name="hostUserPassword" onChange={this.handleChange} placeholder={placeholder} />
                                        }
                                    </FormattedMessage>
                                </Col>
                                </Row>
                        </form>
                    </StyledCard>
                </ModalBody>
            </Modal>    
        );
    }
}