import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import { CustomTable } from '../../Base/Common/CustomTable';
import CustomSelect from '../../Base/Common/CustomSelect';
import { FormattedMessage } from 'react-intl';

export class AdminHotelList extends Component {
    static displayName = AdminHotelList.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            hoteList: [],
            currentPage: 0,
            PageSize: 10,
            licenses: [],
            licenseId: null
        }
    }


    componentDidMount() {
        this.getHotels();
        this.getLicenses();
    }
    
    getLicenses = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                var list = [];

                data.response && data.response.map(license => {
                    list.push({ value: license.id, label: license.name })
                })
                this.setState({ licenses: list, block: false });
            }
        }, `/api/Voucher/Hotel/v1/License`);
    }

    getHotels = () => {
        this.setState({ block: true });
        var params = '';
        params += this.state.licenseId ? `?licenseId=${this.state.licenseId}` : '';

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ hoteList: data.response, block: false });
            }
        }, '/api/Voucher/Hotel/v1/AllHotel' + params);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }
 
    render() {

        const columns = [
            {
                dataField: 'hotelId',
                text: <FormattedMessage id="AdminHotelList.HotelId" />,
                sort: true
            },
            {
                dataField: 'name2',
                text: <FormattedMessage id="AdminHotelList.HotelName" />,
                sort: true
            },
            {
                dataField: 'hotelGroupName',
                text: <FormattedMessage id="AdminHotelList.HotelGroupName" />,
                sort: true
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push('/Hotel/' + row.hotelId)
            }
        };

        const help = <div> </div>
        return (

            <StyledCard icon={'fas fa-gift'} title={'AdminHotelList.Hotels'} help={help} error={this.state.error} block={this.state.block}>
                <Row className="mb-3">
                    <Col className="col-3">
                        <CustomSelect
                            options={this.state.licenses}
                            isClearable
                            placeholder={<FormattedMessage id="AdminHotelList.License" />}
                            onChange={this.handleSelect.bind(this, 'licenseId')} 
                        />

                    </Col>
                    <Col className="text-right">
                        <Button className="btn-sm btn-host" onClick={() => this.props.history.push('/Hotel')}><i className="fas fa-plus"></i></Button>
                        <Button className="btn-sm btn-host ml-2" onClick={this.getHotels}><i className="fas fa-search"></i></Button>
                    </Col>
                </Row>
                <CustomTable
                    keyField='hotelId'
                    data={this.state.hoteList ? this.state.hoteList : []}
                    columns={columns}
                    shadow={false}
                    exportCSV={false}
                    showTotal={true}
                    remote={false}
                    selectRow={selectRow}
                />
            </StyledCard>


        );
    }
}
