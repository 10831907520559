
import axios from 'axios';
import { getToken, adalConfig, authContext } from '../../Utils/AdalConfig';
import { HandleAPIError, HandleExceededTimeAPIError } from '../../Utils/EventGA';
import moment from 'moment';


const base = "";
// Add a request interceptor

const instance = axios.create(adalConfig)
instance.interceptors.request.use((config) => {
    // Check and acquire a token before the request is sent
    return new Promise((resolve, reject) => {
        authContext.acquireToken(adalConfig.endpoints.api, (message, token, msg) => {
            if (!!token) {
                config.headers.Authorization = `bearer ${token}`;
                resolve(config)
            } else {
                reject(config)
            }
        })
    })
}, function (error) {

    return Promise.reject(error)
})

export function getAPI(callback, url) {
    const timerStart = moment();
    
    instance.get(base+url)
        .then(res => {
            callback({ data: res.data, isLoading: false });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if (diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url);
            callback({ error: err, isLoading: false });
        });
}


export function postAPI(callback, url, body) {
    const timerStart = moment();
    
    instance.post(base +url, body, { withCredentials: true, headers: { 'Authorization': 'bearer ' + getToken(), 'Content-Type': 'application/json' } })
        .then(res => {
            callback({ data: res.data, isLoading: false });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if (diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url);

            callback({ error: err, isLoading: false })
        });
}

export function putAPI(callback, url, body) {
    const timerStart = moment();

    instance.put(base +url, body, { withCredentials: true, headers: { 'Authorization': 'bearer ' + getToken(), 'Content-Type': 'application/json' } })
        .then(res => {
            callback({ data: res.data, isLoading: false });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if (diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url);
            callback({ error: err, isLoading: false });
        });
}


export function patchAPI(callback, url, body) {
    const timerStart = moment();

    instance.patch(base +url, body, { withCredentials: true, headers: { 'Authorization': 'bearer ' + getToken() } })
        .then(res => {
            callback({ data: res.data, isLoading: false });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if (diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url);
            callback({ error: err, isLoading: false });
        });
}

export function postMultiPartAPI(callback, url, body) {
    const timerStart = moment();
    
    instance.post(base +url, body, { withCredentials: true, headers: { 'Authorization': 'bearer ' + getToken(), 'Content-Type': 'multipart/form-data' } })
        .then(res => {
            callback({ data: res.data, isLoading: false });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if (diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url);
            callback({ error: err, isLoading: false });
        });
}

export function deleteAPI(callback, url, body) {
    const timerStart = moment();

    instance.delete(base +url, body, { withCredentials: true, headers: { 'Authorization': 'bearer ' + getToken() ,'Content-Type': 'application/json' } })
        .then(res => {
            callback({ data: res.data, isLoading: false });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if (diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url);
            callback({ error: err, isLoading: false });
        });
}

