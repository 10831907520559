import React, { Component } from 'react';
import SalesTable from './SalesTable';
import { FormattedMessage } from 'react-intl';

export class Shopping extends Component {

    render() {
        const { history } = this.props;

        return (
            <SalesTable
                icon="fas fa-shopping-cart"
                title="navbar.Shopping"
                channel={2}
                history={history}
                help={<FormattedMessage id="Shopping.Help" />}
                id="shopping"
            />
        );
    }
}