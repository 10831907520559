import React, { Component } from 'react';
import { Badge, Button, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { getAPI } from '../Base/API';
import { ActiveInactiveStatusBadge, StyledCard } from '../Base/Common/CommonUIComponents';
import { CustomTable } from '../Base/Common/CustomTable';
import CustomSelect from '../Base/Common/CustomSelect';
import { PromotionModal } from './PromotionModal';
import Authorization from '../Base/Authorization';

export class Promotions extends Component {

    constructor(props) {
        super(props);
        this.getPromotions = this.getPromotions.bind(this);
        this.getVoucherTypes = this.getVoucherTypes.bind(this);
        this.getChannels = this.getChannels.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.setCombo = this.setCombo.bind(this);
        this.togglePromotionModal = this.togglePromotionModal.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.state = {
            block: true,
            blockVoucherTypes: true,
            blockChannels: true,
            promotions: [],
            voucherTypes: [],
            voucherTypeId: null,
            channels: [],
            channelId: null,
            promotionModal: false,
            promotion: null,
            title: null
        };
    }

    componentDidMount() {
        this.getPromotions();
        this.getVoucherTypes();
        this.getChannels();
    }

    getPromotions() {
        const { voucherTypeId, channelId } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                this.setState({ error: errorMessage, block: false, promotions: data.response });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Voucher/v1/Promotion/promotion?voucherTypeId=${voucherTypeId !== null ? `${voucherTypeId}` : ``}&channelId=${channelId !== null ? `${channelId}` : ``}`);
    }

    getVoucherTypes() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockVoucherTypes: false });
                return;
            }
            if (data && data.response) {
                const voucherTypes = data.response.filter(v => v.channels.length > 0).map(v => {
                    const opt = {
                        value: v.id,
                        label: v.code,
                        channels: v.channels.map(c => {
                            const cOpt = {
                                value: c.id,
                                label: c.name
                            };
                            return cOpt;
                        })
                    };
                    return opt;
                });
                this.setState({ error: errorMessage, blockVoucherTypes: false, voucherTypes: voucherTypes });
            }
            else {
                this.setState({ error: errorMessage, blockVoucherTypes: false });
            }
        }, `/api/Voucher/v1/Config`);
    }

    getChannels() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockChannels: false });
                return;
            }
            if (data && data.response) {
                const channels = data.response.map(v => {
                    const opt = {
                        value: v.id,
                        label: v.name
                    };
                    return opt;
                });
                this.setState({ error: errorMessage, blockChannels: false, channels: channels });
            }
            else {
                this.setState({ error: errorMessage, blockChannels: false });
            }
        }, `/api/Voucher/v1/Config/getChannels`);
    }

    doSearch() {
        this.setState({ block: true }, this.getPromotions);
    }

    setCombo(combo, evt) {
        if (evt) {
            const { name } = evt;
            const value = combo && combo.value;
            this.setState({ [name]: value });
        }
    }

    togglePromotionModal() {
        this.setState(prevState => ({ promotionModal: !prevState.promotionModal, promotion: null, title: <FormattedMessage id="Promotions.CreatePromotion" /> }));
    }

    updateTable(newPromotion) {
        const { promotions } = this.state;
        const promotion = promotions.find(p => p.id === newPromotion.id);
        if (promotion) {
            Object.assign(promotion, newPromotion);
            this.setState({ promotions: [] }, _ => this.setState({ promotions }));
        }
        else {
            promotions.push(newPromotion);
            this.setState({ promotions });
        }
    }

    render() {
        const { block, blockVoucherTypes, blockChannels, error, promotions, voucherTypes, voucherTypeId, channels, channelId, promotion, promotionModal, title } = this.state;

        const columns = [
            {
                dataField: 'name',
                text: <FormattedMessage id="Promotions.Name"/>
            },
            {
                dataField: 'voucherTypeCodes',
                text: <FormattedMessage id="Promotions.VoucherType" />,
                formatter: cell => cell ? cell.map(code => <Badge color="primary" className="mr-1">{code}</Badge>) : ``
            },
            {
                dataField: 'channelCodes',
                text: <FormattedMessage id="Promotions.ChannelInstance" />,
                formatter: cell => cell ? cell.map(code => <Badge color="primary" className="mr-1">{code}</Badge>) : ``
            },
            {
                dataField: 'startDate',
                text: <FormattedMessage id="Promotions.StartDate" />,
                formatter: cell => moment(cell).format("YYYY-MM-DD")
            },
            {
                dataField: 'endDate',
                text: <FormattedMessage id="Promotions.EndDate" />,
                formatter: cell => moment(cell).format("YYYY-MM-DD")
            },
            {
                dataField: 'discount',
                text: <FormattedMessage id="Promotions.Discount"/>
            },
            {
                dataField: 'active',
                text: <FormattedMessage id="Promotions.Status" />,
                formatter: cell => <ActiveInactiveStatusBadge status={cell} />
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: row => this.setState({ promotion: row, promotionModal: true, title: <FormattedMessage id="Promotions.EditPromotion" /> })
        };

        return (
            <StyledCard block={block || blockVoucherTypes || blockChannels} error={error} title={"navbar.Promotions"} icon="fas fa-percentage" help={<FormattedMessage id="Promotions.Help" />} id="promotions">
                {
                    promotionModal ?
                        <PromotionModal
                            isOpen={promotionModal}
                            toggle={this.togglePromotionModal}
                            title={title}
                            promotion={promotion}
                            voucherTypes={voucherTypes}
                            channels={channels}
                            updateTable={this.updateTable}
                        />
                        :
                        <div />
                }
                <Row className="mb-3">
                    <Col className="col-3">
                        <CustomSelect
                            name="voucherTypeId"
                            options={voucherTypes}
                            value={voucherTypes.find(v => v.value === voucherTypeId)}
                            placeholder={<FormattedMessage id="Promotions.VoucherType" />}
                            onChange={this.setCombo}
                            isClearable
                        />
                    </Col>
                    <Col className="col-3">
                        <CustomSelect
                            name="channelId"
                            options={channels}
                            value={channels.find(c => c.value === channelId)}
                            placeholder={<FormattedMessage id="Promotions.ChannelInstance" />}
                            onChange={this.setCombo}
                            isClearable
                        />
                    </Col>
                    <Col className="text-right">
                        <Authorization
                            perform="promotions:add"
                            yes={() => (
                                <Button className="btn btn-host btn-sm float-right" onClick={this.togglePromotionModal}>
                                    <i className="fas fa-plus" />
                                </Button>
                            )}
                            no={() => <div />}
                        />
                        <Button className="btn btn-host btn-sm float-right mr-1" onClick={this.doSearch}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CustomTable
                            data={promotions}
                            columns={columns}
                            selectRow={selectRow}
                            showTotal={true}
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}