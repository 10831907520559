import React from "react";
import { DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { IntlContext } from "../../Utils/IntlProviderWrapper";
import { FormattedMessage } from 'react-intl';



const LanguageSwitch = () => {
    const { switchToLangPT } = React.useContext(IntlContext);
    const { switchToLangEN } = React.useContext(IntlContext);
    const { switchToLangES } = React.useContext(IntlContext);
    const { locale } = React.useContext(IntlContext);
    const cssclasses = (locale === 'pt' ? 'flag-icon flag-icon-pt pr-2' : (locale === 'es' ? 'flag-icon flag-icon-es mr-2' : 'flag-icon flag-icon-us mr-2'));
    

    return (
       
        <div >

            <UncontrolledDropdown direction="left" >
                <DropdownToggle tag="a" className="dropdown-item" caret>
                    <i className={cssclasses} /> {locale} 
          </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header><FormattedMessage id="LanguageSwitch.ChangeLanguage" /></DropdownItem>
                    <DropdownItem onClick={switchToLangPT} active={locale === 'pt' ? true : false} ><span className="flag-icon flag-icon-pt"  />PT</DropdownItem>
                    <DropdownItem onClick={switchToLangEN} active={locale === 'en' ? true : false}><span className="flag-icon flag-icon-us" />EN</DropdownItem>
                    <DropdownItem onClick={switchToLangES} active={locale === 'es' ? true : false}><span className="flag-icon flag-icon-es" />ES</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>

            
        </div>



             
           
      
    );
};


export default LanguageSwitch;