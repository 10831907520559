import React, { Component } from 'react';
import { getAPI } from '../Base/API';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import { CustomTable } from '../Base/Common/CustomTable';
import { FormattedMessage, injectIntl } from "react-intl";
import { handleNotification } from '../Base/Common/Notification';
import { Col, Input, Row, Button } from 'reactstrap';
import { getHotelGroupModules } from '../Base/Common/ReferenceDataFunctions';

class ProfilesDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            profiles: [],
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0,
            name: null,
            email: null,
            phone: null,
            fiscalNumber: null,
            columns: [
                {
                    dataField: 'firstName',
                    text: <FormattedMessage id="ProfilesDashboard.FirstName" />
                },
                {
                    dataField: 'lastName',
                    text: <FormattedMessage id="ProfilesDashboard.LastName" />
                },
                {
                    dataField: 'email',
                    text: <FormattedMessage id="ProfilesDashboard.Email" />
                },
                {
                    dataField: 'phone',
                    text: <FormattedMessage id="ProfilesDashboard.Phone" />
                },
                {
                    dataField: 'address',
                    text: <FormattedMessage id="ProfilesDashboard.Address" />
                },
                {
                    dataField: 'fiscalNumber',
                    text: <FormattedMessage id="ProfilesDashboard.FiscalNumber" />
                }
            ]
        }
        this.getProfiles = this.getProfiles.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                //Display external ids columns if hotel group has PNIntegration module
                if (global.modules && global.modules.includes(getHotelGroupModules(this.props.intl)[0].value)) {
                    let { columns } = this.state;
                    const externalIdsColumns = [
                        {
                            dataField: 'externalId',
                            text: <FormattedMessage id="ProfilesDashboard.ExternalId" />
                        },
                        {
                            dataField: 'ExternalCrmId',
                            text: <FormattedMessage id="ProfilesDashboard.ExternalCrmId" />
                        }
                    ];
                    columns = columns.concat(externalIdsColumns);
                    this.setState({ columns });
                }
            },
            global.modules ? 0 : 2500
        );
        this.getProfiles();
    }

    getProfiles() {
        const { pageSize, pageIndex, name, email, phone, fiscalNumber } = this.state;
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response) {
                    this.setState({ profiles: data.response, totalItems: data.count });
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/Voucher/Profile/v1/Profile?pageSize=${pageSize}&pageIndex=${pageIndex}&name=${name !== null ? name : ``}&email=${email !== null ? email : ``}&phone=${phone !== null ? phone : ``}&fiscalNumber=${fiscalNumber !== null ? fiscalNumber : ``}`);
    }

    handleTableChange = (_, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, () => { this.getProfiles() });
    }

    handleSearchChange(evt) {
        if (evt.target) {
            const { name, value } = evt.target;
            this.setState({ [name]: value });
        }
    }

    handleSearch() {
        this.setState({ pageIndex: 0 }, this.getProfiles);
    }

    render() {
        const { block, error, profiles, pageIndex, pageSize, totalItems, name, email, phone, fiscalNumber, columns } = this.state;

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row) => {
                this.props.history.push(`/Profiles/${row.id}`)
            }
        };

        return (
            <StyledCard icon={'fas fa-user-friends'} title={'ProfilesDashboard.Title'} help={<FormattedMessage id="ProfilesDashboard.Help" />} id="profilesDashboard" error={error} block={block}>
                <Row className="mb-2">
                    <Col>
                        <FormattedMessage id="ProfilesDashboard.Name">
                            {
                                placeholder => <Input type="text" id="name" name="name" placeholder={placeholder} value={name ? name : ''} onChange={this.handleSearchChange} />
                            }
                        </FormattedMessage>
                    </Col>
                    <Col>
                        <FormattedMessage id="ProfilesDashboard.Email">
                            {
                                placeholder => <Input type="text" id="email" name="email" placeholder={placeholder} value={email ? email : ''} onChange={this.handleSearchChange} />
                            }
                        </FormattedMessage>
                    </Col>
                    <Col>
                        <FormattedMessage id="ProfilesDashboard.Phone">
                            {
                                placeholder => <Input type="text" id="phone" name="phone" placeholder={placeholder} value={phone ? phone : ''} onChange={this.handleSearchChange} />
                            }
                        </FormattedMessage>
                    </Col>
                    <Col>
                        <FormattedMessage id="ProfilesDashboard.FiscalNumber">
                            {
                                placeholder => <Input type="text" id="fiscalNumber" name="fiscalNumber" placeholder={placeholder} value={fiscalNumber ? fiscalNumber : ''} onChange={this.handleSearchChange} />
                            }
                        </FormattedMessage>
                    </Col>
                    <Col className="text-right">
                        <Button className="btn btn-host btn-sm" onClick={this.handleSearch}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>
                <CustomTable
                    data={profiles}
                    columns={columns}
                    showTotal={true}
                    remote={true}
                    page={pageIndex + 1}
                    sizePerPage={pageSize}
                    onTableChange={this.handleTableChange}
                    totalSize={totalItems}
                    selectRow={selectRow}
                />
            </StyledCard>    
        );
    }
}

export default injectIntl(ProfilesDashboard);