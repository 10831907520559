import React, { Component } from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import { BlankCard } from './Base/Common/CommonUIComponents';

export class SubscribeToCard extends Component {

    render() {
        return (
            <Row className="pt-5" style={{ height: '97VH', width: '100%' }}>
                <Col>
                    <Row>
                        <Col>
                            <div className="p-4">
                                <h1 className="mb-4"><i className="fas fa-ban text-danger"></i> You do not have Access to <span className="color-host">Card</span></h1>
                                <p className="lead mb-3">Whant to increases your sales and client loyalty ?</p>
                                <Row>
                                    <Col className="text-center">
                                        <b > Create Monetary Cards</b>
                                        <hr className="my-1" />
                                        <div className="text-left">
                                            <div className="mb-2">
                                                <i className=" text-success fas fa-check mr-2"></i> Create multi use monetary Cards
                                            </div>
                                            <div className="mb-2">
                                                <i className=" text-success fas fa-check mr-2"></i> Integrated with PMS
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="text-center">
                                        <b>Create Service Vouchers </b>
                                        <hr className="my-1" />
                                        <div className="text-left">
                                            <div className="mb-2">
                                                <i className=" text-success fas fa-check mr-2"></i> Create Multi use service voucher
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="text-center">
                                        <b> Automate</b>
                                        <hr className="my-1" />
                                        <div className="text-left">
                                            <div className="mb-2">
                                                <i className=" text-success fas fa-check mr-2"></i> Send voucher based on your reservations
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col>
                            <div className="p-4">
                                <BlankCard>
                                    <div className="p-2">
                                        <Row className="mb-3">
                                            <Col className="text-center">
                                                <h4><b>Request for Demo</b></h4>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <Input className="input-host" type="text" placeholder="Name" required />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <Input className="input-host" type="email" placeholder="Email" required />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <Input type="number" className="input-host" placeholder="Nr. properties / rooms" required />
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2">
                                            <Col>
                                                <p className="lead text-center ">
                                                    <Button color="primary" className="btn-host">Request Demo</Button>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </BlankCard>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}