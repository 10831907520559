import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, ModalBody, Row, Col, Button, CardBody } from 'reactstrap';
import BlockUi from 'react-block-ui';
import { getAPI } from '../Base/API';
import moment from 'moment';


class EmailActivity extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: false,
            activityData: null,
        };
    }

    componentDidMount() {
        this.getEmailActivity();
    }

    getEmailActivity = () => {
        const { messageId } = this.props;

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data && data.response) {
                    this.setState({ activityData: data.response, block: false });
                } else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/Voucher/SendGrid/v1/messages/${messageId}`);
        });
    }


    render() {
        const { block, error, activityData } = this.state;
        const { modal } = this.props;


        return (
            <Modal isOpen={modal} toggle={this.props.toggleModal} fade={false} size={"lg"} >
                <ModalBody style={{ minHeight: '200px' }}>
                    <BlockUi tag="div" blocking={block || this.state.block}>
                        <CardBody error={error} className='activityDetailCard'>
                            <Row className='mb-4'>
                                <Col>
                                    <h5>
                                        <FormattedMessage id="EmailActivity.EmailInformation" />
                                    </h5>
                                </Col>
                            </Row>

                            <Row className='mb-3 align-items-center'>
                                {activityData && activityData.from_email ?
                                    <Col className='d-flex flex-column'>
                                        <span className='font-weight-bold'><FormattedMessage id="EmailActivity.From" /></span>
                                        <span> {activityData.from_email} </span>
                                    </Col>
                                    : ''}
                                {activityData && activityData.to_email ?
                                    <Col className='d-flex flex-column'>
                                        <span className='font-weight-bold'><FormattedMessage id="EmailActivity.To" /></span>
                                        <span> {activityData.to_email} </span>
                                    </Col>
                                    : ''}
                            </Row>

                            <Row className='mb-5 align-items-center'>
                                {activityData && activityData.subject ?
                                    <Col className='d-flex flex-column'>
                                        <span className='font-weight-bold'><FormattedMessage id="EmailActivity.Subject" /></span>
                                        <span> {activityData.subject} </span>
                                    </Col>
                                : ''}
                            </Row>

                            {activityData && activityData.events && activityData.events.length > 0  ?
                                <Row>
                                    <Col className='col-12 d-flex align-items-center mb-2'>
                                        <span className='font-weight-bold'><FormattedMessage id="EmailActivity.EventHistory" /></span>
                                    </Col>
                                </Row>
                                : ''}

                            <div className='verticalScroll py-3 pr-3' style={{ maxHeight: '40vh', overflow: 'auto', overflowX: 'hidden' }}>
                                {activityData && activityData.events && activityData.events.length > 0 ?
                                    activityData.events.map((event, index) =>
                                        <div key={index}>
                                            <Row>
                                                <Col >
                                                    <span className='text-muted'>
                                                        {(event.event_name === 'processed' || event.event_name === 'delivered') ?
                                                            <i className="fas fa-check-circle text-success mx-1" />
                                                            : event.event_name === 'open' ?
                                                                <i class="fas fa-arrow-circle-right text-primary mx-1"></i>
                                                                : event.event_name === 'click' ?
                                                                    <span className='iconCircle'><i className="iconStyle fas fa-mouse-pointer text-light ml-2 fa-xs"></i></span>
                                                                    : event.event_name === 'deferred' || event.event_name === 'spam_report' ?
                                                                        <i className="fas fa-exclamation-circle text-warning mx-1" />
                                                                        :
                                                                        <i className="fas fa-times-circle text-danger mx-1" />}
                                                        <FormattedMessage id={`EmailActivity.${event.event_name}`} />
                                                    </span>
                                                </Col>
                                                <Col className='text-right'>
                                                    {moment(event.processed).format('DD/MM/YYYY hh:mm')}
                                                </Col>

                                            </Row>
                                            {index < activityData.events.length - 1 && (
                                                <div className='vertical-line'></div>
                                            )}
                                        </div>
                                    )
                                    : ''}
                            </div>

                        </CardBody>
                    </BlockUi>
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(EmailActivity);