import React, { Component } from 'react';
import { Card, Row, Col, Button, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Authorization from '../Base/Authorization';
import moment from 'moment';

export class CashVoucherCard extends Component {

    render() {
        const { el, removeVoucher, shopVoucher, history, isShoppingCartMode, addVoucher, unselectVoucher, vouchersToSell, icon } = this.props;

        const isEmitVoucherDisabled = !el.active || !(el.sold < el.maxQuantity) || el.channels.find(c => c.channelConfigId === 2) === undefined || moment().diff(moment(el.saleValidTo), 'days') > 0;
        const isPlusButtonDisabled = !el.active || !(el.sold < el.maxQuantity) || el.channels.find(c => c.channelConfigId === 2) === undefined || moment().diff(moment(el.saleValidTo), 'days') > 0 || vouchersToSell.filter(v => v.voucherTypeId === el.id && v.quantity >= (el.maxQuantity - el.sold)).length > 0;

        return (
            <Card className="shadow h-100 p-3" body>
                <Row className="h-100">
                    <Col className="d-flex flex-column justify-content-between">
                        <Row className="align-items-center">
                            <Col className="text-ellipsis">
                                <i className={`${icon} mr-2`} /><b title={el.code}> {el.code}</b>
                            </Col>
                            <Col className="col-4 pl-0">
                                { el.sold > 0 ? ''
                                    :
                                    <Authorization
                                        perform="voucherManagement:remove"
                                        yes={() => (
                                            <span onClick={removeVoucher} style={{ cursor: 'pointer' }}><i className="fas fa-times color-host float-right ml-2"></i></span>
                                        )}
                                        no={() => ''}
                                    />
                                }
                                <Authorization
                                    perform="voucherManagement:edit"
                                    yes={() => (
                                        <span onClick={() => history.push('/CardConfigWizard/' + el.id)} style={{ cursor: 'pointer' }}>
                                            <i className="fas fa-edit color-host float-right" />
                                        </span>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Col>
                        </Row>

                    </Col>
                </Row>

                <div className="my-4" style={{ fontSize: '14px' }} >{el.description}</div>

                <Row className="align-items-center pt-1">
                    <Col>{el.sold} / {el.maxQuantity}</Col>
                    <Col>
                        <Authorization
                            perform="voucherManagement:shop"
                            yes={() => (
                                <div>
                                    {
                                        isShoppingCartMode ?
                                            <span>
                                                <Button id={`shoppingBasket${el.id}`} className="btn-host btn-sm float-right" disabled={isPlusButtonDisabled} onClick={_ => addVoucher(el)}>
                                                    <i className="fas fa-plus" />
                                                </Button>
                                                <Button id={`removeVoucher{el.id}`} className="btn-host btn-sm float-right mr-1" disabled={!el.active || !(el.sold < el.maxQuantity) || el.channels.find(c => c.channelConfigId === 2) === undefined || vouchersToSell.filter(v => v.voucherTypeId === el.id && v.quantity > 0).length === 0} onClick={_ => unselectVoucher(el)}>
                                                    <i className="fas fa-minus" />
                                                </Button>
                                                <UncontrolledTooltip placement="bottom" target={`shoppingBasket${el.id}`}>
                                                    <FormattedMessage id="VoucherManagement.AddVoucher" />
                                                </UncontrolledTooltip>
                                                {
                                                    vouchersToSell.filter(v => v.voucherTypeId === el.id).map((v, key) =>
                                                        <Button className="btn-host btn-sm float-right mr-1" key={key}>
                                                            {v.quantity}
                                                        </Button>
                                                    )
                                                }
                                            </span>
                                            :
                                            <span>
                                                <Button id={`shoppingBasket${el.id}`} className="btn-host btn-sm float-right" disabled={isEmitVoucherDisabled} onClick={shopVoucher}>
                                                    <i className="fas fa-shopping-basket" />
                                                </Button>
                                                <UncontrolledTooltip placement="bottom" target={`shoppingBasket${el.id}`}>
                                                    <FormattedMessage id="VoucherManagement.EmitNewVoucher" />
                                                </UncontrolledTooltip>
                                            </span>
                                    }
                                </div>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>
            </Card>    
        );
    }
}


export class RebateVoucherCard extends Component {

    render() {
        const { el, removeVoucher, shopVoucher, history, isShoppingCartMode, addVoucher, unselectVoucher, vouchersToSell, icon } = this.props;

        const isEmitVoucherDisabled = !el.active || !(el.sold < el.maxQuantity) || el.channels.find(c => c.channelConfigId === 2) === undefined || moment().diff(moment(el.saleValidTo), 'days') > 0;
        const isPlusButtonDisabled = !el.active || !(el.sold < el.maxQuantity) || el.channels.find(c => c.channelConfigId === 2) === undefined || moment().diff(moment(el.saleValidTo), 'days') > 0 || vouchersToSell.filter(v => v.voucherTypeId === el.id && v.quantity >= (el.maxQuantity - el.sold)).length > 0;

        return (
            <Card className="shadow h-100 p-3" body>
                <Row className="h-100">
                    <Col className="d-flex flex-column justify-content-between">
                        <div>
                            <Row className="align-items-center">
                                <Col className="text-ellipsis">
                                    <i className={`${icon} mr-2`} /><b title={el.code}> {el.code}</b>
                                </Col>
                                <Col className="col-4 pl-0">
                                    {el.sold > 0 ? ''
                                        :
                                        <Authorization
                                            perform="voucherManagement:remove"
                                            yes={() => (
                                                <span onClick={removeVoucher} style={{ cursor: 'pointer' }}><i className="fas fa-times color-host float-right ml-2"></i></span>
                                            )}
                                            no={() => ''}
                                        />
                                    }
                                    <Authorization
                                        perform="voucherManagement:edit"
                                        yes={() => (
                                            <span onClick={() => history.push('/CardConfigWizard/' + el.id)} style={{ cursor: 'pointer' }}>
                                                <i className="fas fa-edit color-host float-right" />
                                            </span>
                                        )}
                                        no={() => <div></div>}
                                    />
                                </Col>
                            </Row>
                            <div className="my-3" style={{ fontSize: '14px' }}>
                                {el.voucherRebate && el.voucherRebate.length > 0 ?
                                    el.voucherRebate.map((rebate, key) =>
                                        <div key={key}>
                                            - {rebate.code}
                                        </div>
                                    )
                                    :
                                    <span>{el.description}</span>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="align-items-center pt-1">
                    <Col>{el.sold} / {el.maxQuantity}</Col>
                    <Col>
                        <Authorization
                            perform="voucherManagement:shop"
                            yes={() => (
                                <div>
                                    {
                                        isShoppingCartMode ?
                                            <span>
                                                <Button id={`shoppingBasket${el.id}`} className="btn-host btn-sm float-right" disabled={isPlusButtonDisabled} onClick={_ => addVoucher(el)}>
                                                    <i className="fas fa-plus" />
                                                </Button>
                                                <Button id={`removeVoucher{el.id}`} className="btn-host btn-sm float-right mr-1" disabled={!el.active || !(el.sold < el.maxQuantity) || el.channels.find(c => c.channelConfigId === 2) === undefined || vouchersToSell.filter(v => v.voucherTypeId === el.id && v.quantity > 0).length === 0} onClick={_ => unselectVoucher(el)}>
                                                    <i className="fas fa-minus" />
                                                </Button>
                                                <UncontrolledTooltip placement="bottom" target={`shoppingBasket${el.id}`}>
                                                    <FormattedMessage id="VoucherManagement.AddVoucher" />
                                                </UncontrolledTooltip>
                                                {
                                                    vouchersToSell.filter(v => v.voucherTypeId === el.id).map((v, key) =>
                                                        <Button className="btn-host btn-sm float-right mr-1" key={key}>
                                                            {v.quantity}
                                                        </Button>
                                                    )
                                                }
                                            </span>
                                            :
                                            <span>
                                                <Button id={`shoppingBasket${el.id}`} className="btn-host btn-sm float-right" disabled={isEmitVoucherDisabled} onClick={shopVoucher}>
                                                    <i className="fas fa-shopping-basket" />
                                                </Button>
                                                <UncontrolledTooltip placement="bottom" target={`shoppingBasket${el.id}`}>
                                                    <FormattedMessage id="VoucherManagement.EmitNewVoucher" />
                                                </UncontrolledTooltip>
                                            </span>
                                    }
                                </div>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>
            </Card>
        );
    }
}
