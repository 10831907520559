import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { CustomTable } from '../Base/Common/CustomTable';
import { getStatus } from '../Base/Common/ReferenceDataFunctions';

export class UseVoucherList extends Component {

    render() {
        const { isOpen, selectVoucher, vouchers } = this.props;

        const columns = [
            {
                dataField: 'voucherId',
                text: this.props.intl.formatMessage({ id: "OperationManagement.CardId" }),
                sort: true
            },
            {
                dataField: 'saleOrigin',
                text: this.props.intl.formatMessage({ id: "OperationManagement.Channel" }),
                sort: true
            },
            {
                dataField: 'ordinal',
                text: this.props.intl.formatMessage({ id: "OperationManagement.Number" }),
                sort: true
            },
            {
                dataField: 'voucherTypeCode',
                text: this.props.intl.formatMessage({ id: "OperationManagement.CardType" }),
                sort: true
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "OperationManagement.Status" }),
                sort: true,
                formatter: (cell) => {
                    if (cell) {
                        const status = getStatus().find(s => s.value === cell);
                        return status ? status.label : ''
                    }
                }
            },
            {
                dataField: 'validUntil',
                text: this.props.intl.formatMessage({ id: "OperationManagement.ValidUntil" }),
                sort: true
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: row => selectVoucher(row)
        };

        return (
            <Modal isOpen={isOpen} className="modal-xl">
                <ModalHeader>
                    <FormattedMessage id="UseVoucher.SelectVoucher" />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <CustomTable
                                data={vouchers}
                                columns={columns}
                                selectRow={selectRow}
                            />
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(UseVoucherList);