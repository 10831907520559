import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Row, Col, FormGroup, Label, Input, Button, Form, CustomInput } from 'reactstrap';
import { getPaymentType } from "../../Base/Common/ReferenceDataFunctions";
import { handleNotification } from "../../Base/Common/Notification";
import CustomSelect from '../../Base/Common/CustomSelect';
import Authorization from '../../Base/Authorization';
import { ErrorAlert } from "../../Base/ErrorAlert";
import { getAPI, putAPI } from "../../Base/API";
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';

export class HotelLicenseDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            hotelData: this.props.selectedHotel ? this.props.selectedHotel : {},
            licenses: []
        };
    }

    componentDidMount() {
        this.getLicenses();
    }

    getLicenses = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                var list = [];

                data.response && data.response.map(license => {
                    list.push({ value: license.id, label: license.name, modules: license.modules, transactions: license.transactions, name: license.name })
                })
                this.setState({ licenses: list, block: false });
            }
        }, `/api/Voucher/Hotel/v1/License`);
    }

    handleSwitch = (evt) => {
        const { name, checked } = evt.target;

        this.setState({
            hotelData: {
                ...this.state.license,
                [name]: checked
            }
        });
    };

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState(prevState => ({
            hotelData: {
                ...prevState.hotelData,
                [name]: value
            }
        }));
    }

    associateLicense = (evt) => {
        evt.preventDefault();
        this.setState({ block: true });
        var params = '';
        params += this.state.hotelData.license ? `?licenseId=${this.state.hotelData.license.id}` : '';
        params += this.state.hotelData.paymentType ? `&paymentType=${this.state.hotelData.paymentType}` : ''; 

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="HotelLicenseDetails.LicenseAssociated" />, <FormattedMessage id="Hotel.Success" />);
                    this.props.updateTable(this.state.hotelData.license, this.state.hotelData.paymentType, this.state.hotelData.hotelId);
                    this.props.toggle();
                }
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Voucher/Hotel/v1/Hotel/${this.state.hotelData.hotelId}/License/${this.state.hotelData.license.id}` + params);
    }

    handleLicense = (combo, evt) => {
        var hotelData = { ...this.state.hotelData };

        hotelData.license = {
            id: combo ? combo.value : '',
            transactions: combo ? combo.transactions : '',
            modules: combo ? combo.modules : '',
            name: combo ? combo.name : ''
        }
        this.setState({ hotelData });
    }

    handleSelect = (name, combo) => {
        this.setState({
            hotelData: {
                ...this.state.hotelData,
                [name]: combo ? combo.value : null
            }
        })
    }

    render() {
        const { hotelData } = this.state;

        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-xl">
                <ModalHeader toggle={this.props.toggle}>
                    <FormattedMessage id="HotelLicenseDetails.EditLicense" />
                </ModalHeader>

                <ModalBody className="p-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={this.associateLicense}>
                            <Card className="shadow border-0 p-0">

                                <CardBody className="m-0">
                                    <Row className="bg-white mb-2">
                                        <Col className="text-right">
                                            <Authorization
                                                perform="hotelLicense:save"
                                                yes={() => (
                                                    <Button type="submit" className="btn btn-sm btn-host float-right">
                                                        <i className="fas fa-save" />
                                                    </Button>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={4}> <FormattedMessage id="HotelLicenseDetails.Hotel" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="text"
                                                        name="hotel"
                                                        className="text-sm"
                                                        value={hotelData.name2 ? hotelData.name2 : ''}
                                                        disabled
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col/>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={4}> <FormattedMessage id="HotelLicenseDetails.License" /> </Label>
                                                <Col sm={8}>
                                                    <CustomSelect
                                                        options={this.state.licenses}
                                                        name="license"
                                                        required={true}
                                                        isSearchable
                                                        value={hotelData.license ? this.state.licenses.find(license => license.value === hotelData.license.id) : ''}
                                                        placeholder=''
                                                        onChange={this.handleLicense}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={4}> <FormattedMessage id="HotelLicenses.PaymentType" /> </Label>
                                                <Col sm={8}>
                                                    <CustomSelect
                                                        options={getPaymentType()}
                                                        name="license"
                                                        isClearable isSearchable
                                                        value={hotelData.paymentType ? getPaymentType().find(license => license.value === hotelData.paymentType) : ''}
                                                        placeholder=''
                                                        onChange={this.handleSelect.bind(this, 'paymentType')}
                                                        required={true}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={4}> <FormattedMessage id="Licenses.Transactions" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="number"
                                                        name="transactions"
                                                        className="text-sm"
                                                        value={hotelData.license ? hotelData.license.transactions : ''}
                                                        disabled
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={4}> <FormattedMessage id="Licenses.Modules" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="text"
                                                        name="modules"
                                                        className="text-sm"
                                                        value={hotelData.license ? hotelData.license.modules.join(', ') : ''}
                                                        disabled
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}
