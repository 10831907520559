import React, { Component } from 'react';
import { Button, Col, Row, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';
import { getAPI } from '../Base/API';
import moment from "moment";
import { getMultiLangEmpty } from '../Base/Common/CommonUIComponents';

export class PrintableVoucher extends Component {
    static displayName = PrintableVoucher.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            color: '#0000ff',
            col: 3,
            mode: 'card',
            voucherTemplate: ''
        }
    }
    
    componentDidMount() {
        this.getVoucherTemplate();
    }

    getVoucherTemplate = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ voucherTemplate: data.response[0], error: data.erros });
                }
            }
            this.setState({ block: false });
        }, `/api/Voucher/v1/Notification/voucherNotification/${this.props.voucherId}?hasPdf=false${this.props.language ? `&lang=${getMultiLangEmpty().filter(lang => lang.language === this.props.language).map((lang) => lang.languageCode)}` : ''}`);
    }

    prt() {
        var divContents = document.getElementById("printThis").innerHTML;
        var a = window.open('', 'PRINT');
        a.document.write(divContents);
        a.document.close();

        a.onafterprint = a.close;


        var is_chrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;

        a.onload = function () { // necessary if the div contain images
            a.focus(); 
            a.print();
            a.close();
        };
    }

    render() {
        return (
            <div>
                <div className="my-2 mr-2 text-right">
                    <Button className="btn btn-sm btn-host" onClick={this.prt.bind(this)}>
                        <i className="fas fa-print" />
                    </Button>
                </div>
                <div id="printThis">
                    {ReactHtmlParser(this.state.voucherTemplate && this.state.voucherTemplate.template)}
                </div>
            </div>
        );
    }
}
