import React, { Component } from 'react'
class DragAndDrop extends Component {
    dragCounter = 0;
    state = {
        drag: false
    }

    dropRef = React.createRef()

    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++

        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ drag: true })
        }
    }
    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter--

        if (this.dragCounter === 0) {
            this.setState({ drag: false })
        }
    }
    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ drag: false })
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files)
            e.dataTransfer.clearData()
            this.dragCounter = 0
        } else {
            this.props.handleDrop(e.dataTransfer);
            e.dataTransfer.clearData();
            this.dragCounter = 0
        }
    }

    componentDidMount() {
        let div = this.dropRef.current
        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)
    }
    componentWillUnmount() {
        let div = this.dropRef.current
        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)
    }

    

    render() {
        return (
            <div
                className={this.props.className || ''}
                style={this.props.divStyle ? this.props.divStyle : { display: 'inline-block', position: 'relative' }}
                ref={this.dropRef}
            >
                { this.state.drag &&
                    <div
                        style={this.props.dragDivStyle || {
                            border: this.props.transparent ? '' : 'dashed grey 2px',
                            //backgroundColor: 'rgba(255,255,255,.8)',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 9999
                        }}
                    >
                        {this.props.transparent ?
                            <div style={{ height: 'fit-content', padding: '15px' }}>
                                Drop here
                            </div>
                            :
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'grey',
                                    fontSize: 36,
                                    height: '100%'
                                }}
                            >
                                <div style={{ backgroundColor: '#ffffffe8', height: 'fit-content', padding: '15px' }}>
                                    Drop here
                                </div>
                            </div>
                        }
                    </div>
                }                
                {this.props.children}
            </div>
        )
    }
}
export default DragAndDrop