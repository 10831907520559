import React, { Component } from 'react';
import { Row, Col, Button, Input, Label, UncontrolledTooltip, Form, Card } from 'reactstrap';
import { handleNotification } from '../Base/Common/Notification';
import { BlankCard } from '../Base/Common/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../Base/Common/CustomSelect';
import Authorization from '../Base/Authorization';
import { deleteAPI, getAPI, postAPI, postMultiPartAPI } from '../Base/API';
import DragAndDrop from '../Base/Common/DragAndDrop';
import { TemplatePreviewModal } from './TemplatePreviewModal';


const dragAndDropStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffffb0',
    fontSize: '20px'
}


class VoucherConfigStep8 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            blockEmail: false,
            emailTemplateList: [],
            voucherConfig: this.props.voucherConfig || { active: true }
        }
    }

    componentDidMount() {
        this.getTemplates();
        this.getTemplateImages();
    }

    getTemplates = () => {
        this.setState({ blockEmail: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockEmail: false });

                return;
            }
            if (data) {
                let arr = [];

                if (data.response) {
                    data.response.filter(el => el.type === "Voucher").map(el => arr.push({ label: el.name, value: el.id, templateHtml: el.templateHtml }));
                }

                this.setState({ templateList: data.response, emailTemplateList: arr, blockEmail: false }, () => this.updateTemplateImages());
            }
        }, '/api/Voucher/v1/Config/notificationTemplates');
    }

    getTemplateImages = () => {
        this.setState({ blockTemplateImages: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockTemplateImages: false });
                return;
            }
            if (data) {
                this.setState({ error: errorMessage, blockTemplateImages: false, templateImages: data.response });
            }
            else {
                this.setState({ error: errorMessage, blockTemplateImages: false });
            }
        }, `/api/Voucher/v1/Notification/TemplateImages`);
    }


    saveEmailTemplate = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const payload = { ...this.state.voucherConfig };


        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="EditVoucher.TemplateAdded" />, <FormattedMessage id="EditVoucher.Success" />);
                this.setState({ block: false, voucherRebate: this.props.getNewVoucherRebate() }, () => this.getConfigDetails(this.state.voucherConfig.id));
            }
        }, '/api/Voucher/v1/Config/voucherType/' + this.state.voucherConfig.id + '/templateConfig', payload);
    }

    handleTemplateImageDrop = (files) => {
        if (!files || !files.length || files[0]['type'].split('/')[0] !== 'image') {
            handleNotification({ errors: [{ message: <FormattedMessage id="CardConfigWizard.InvalidImage" /> }] });
            return;
        }

        if (files.length > 0) {
            if (!files[0] && files[0].size > 1048576) { // > 1MB
                handleNotification('', <FormattedMessage id="CardConfigWizard.PleaseSelectAFileLessThan" />, <FormattedMessage id="CardConfigWizard.SelectedFileIsTooBig" />, 'info');
                return;
            }
        }
        this.setState({ blockTemplateImages: true });

        const formData = new FormData();
        formData.append('file', files[0]);

        postMultiPartAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, blockTemplateImages: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, blockTemplateImages: false });
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="CardConfigWizard.ImageSaveSuccess" />, <FormattedMessage id="generic.success" />);

                    this.setState(prevState => ({ error: errorMessage, blockTemplateImages: false, templateImages: [...prevState.templateImages, data.response[0]] }));
                }
            }
            else {
                this.setState({ error: errorMessage, blockTemplateImages: false });
            }
        }, `/api/Voucher/v1/Notification/TemplateImages`, formData);
    }

    deleteTemplateImage(id) {
        this.setState({ blockTemplateImages: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockTemplateImages: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, blockTemplateImages: false });
                }
                else {
                    handleNotification(data, <FormattedMessage id="EditVoucher.ImageDeleted" />, <FormattedMessage id="generic.success" />);

                    this.setState(prevState => ({ error: errorMessage, blockTemplateImages: false, templateImages: prevState.templateImages.filter(el => el.id !== id) }));
                }
            }
            else {
                this.setState({ error: errorMessage, blockTemplateImages: false });
            }
        }, `/api/Voucher/v1/Notification/TemplateImages/${id}`);
    }

    updateComboForm = (combo, name) => {
        this.setState(prevState => ({
            voucherConfig: {
                ...prevState.voucherConfig,
                [name]: combo ? combo.value : null
            }
        }), () => { if (name === 'notificationTemplateId') { this.updateTemplateImages() } });
    }

    updateForm = (evt) => {
        const { value, name } = evt.target;

        this.setState(prevState => ({
            voucherConfig: {
                ...prevState.voucherConfig,
                [name]: value 
            }
        }), () => this.updateTemplateImages());
    }

    updateTemplateImages = () => {
        var bgImage = document.getElementById("backgroundImageVoucher");
        var logoImage = document.getElementById("logoImageVoucher");

        if (bgImage && logoImage) {
            bgImage.src = this.state.voucherConfig?.backgroundImage;
            logoImage.src = this.state.voucherConfig?.logoImage;
        }
    }

    deleteImageUrl = (imageId, name, evt) => {
        this.setState(prevState => ({
            voucherConfig: {
                ...prevState.voucherConfig,
                [name]: null,
                [imageId]: null
            }
        }), this.updateTemplateImages)
    }

    updateImageUrl(imageId, name, evt) {

        const { templateImage } = this.state;

        if (evt && evt.files && templateImage) {
            this.setState(prevState => ({
                voucherConfig: {
                    ...prevState.voucherConfig,
                    [name]: templateImage.url,
                    [imageId]: templateImage.id
                }
            }), this.updateTemplateImages)
        }
    }

    toggleTemplatePreviewModal = () => {
        this.setState(prevState => ({ templatePreviewModal: !prevState.templatePreviewModal }));
    }


    render() {
        const { block, voucherConfig } = this.state;

        const booleanOptions = [
            {
                value: true,
                label: <FormattedMessage id="CardConfigWizard.Yes" />
            },
            {
                value: false,
                label: <FormattedMessage id="CardConfigWizard.No" />
            },
        ];

        return (
            <BlankCard block={this.state.blockEmail || this.state.blockTemplateImages || this.state.block}>
                <Row>
                    <Col>
                        <Form onSubmit={this.saveEmailTemplate}>
                            <Row>
                                <Col>
                                    <h5>
                                        <FormattedMessage id="CardConfigWizard.EmailTemplate" />
                                    </h5>
                                </Col>
                                <Col className="text-right">
                                    <Authorization
                                        perform="cardConfigWizard:saveTemplate"
                                        yes={() => (
                                            <span>
                                                <Button className="btn-host btn-sm mr-1" id="templatePreviewButton" onClick={this.toggleTemplatePreviewModal}>
                                                    <i className="fas fa-file-alt" />
                                                </Button>
                                                <UncontrolledTooltip target="templatePreviewButton" placement="bottom">
                                                    <FormattedMessage id="CardConfigWizard.TemplatePreview" />
                                                </UncontrolledTooltip>
                                                <Button className="btn-host btn-sm" type="submit">
                                                    <i className="fas fa-save" />
                                                </Button>
                                            </span>

                                        )}
                                        no={() => <div></div>}
                                    />
                                </Col>
                            </Row>

                            <hr className="mt-1 border-host" />

                            <Row className="align-items-center">
                                <Col className="col-3">
                                    <Label><FormattedMessage id="CardConfigWizard.Template" /></Label>
                                </Col>
                                <Col className="col-3 pr-0">
                                    <CustomSelect required
                                        options={this.state.emailTemplateList}
                                        value={this.state.emailTemplateList.find(el => el.value === voucherConfig.notificationTemplateId) || ''}
                                        onChange={(e) => this.updateComboForm(e, 'notificationTemplateId')}
                                        placeholder={<FormattedMessage id="CardConfigWizard.Template" />}
                                    />
                                </Col>
                                <Col className="col-3 pr-0 text-center">
                                    <Label><FormattedMessage id="CardConfigWizard.showPrice" /></Label>
                                </Col>
                                <Col className="col-3">
                                    <CustomSelect
                                        options={booleanOptions}
                                        value={booleanOptions.find(opt => opt.value === voucherConfig.showPrice) || ''}
                                        onChange={(e) => this.updateComboForm(e, 'showPrice')}
                                        placeholder={<FormattedMessage id="CardConfigWizard.showPrice" />}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="my-2">
                                <Col className="col-3">
                                    <Label><FormattedMessage id="CardConfigWizard.Subject" /></Label>
                                </Col>
                                <Col className="col-9">
                                    <FormattedMessage id="CardConfigWizard.SubjectMessage" >
                                        {placeholder =>
                                            <Input
                                                type="textarea"
                                                placeholder={placeholder}
                                                minLength="1" maxLength="100"
                                                name="subjectMessage"
                                                value={voucherConfig.subjectMessage || ''}
                                                onChange={this.updateForm}
                                            />
                                        }
                                    </FormattedMessage>
                                </Col>
                            </Row>

                            <Row className="my-2">
                                <Col className="col-3">
                                    <Label><FormattedMessage id="CardConfigWizard.Images" /></Label>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className="col-6">
                                            <SelectedImageCard
                                                label="logoImage"
                                                image={voucherConfig.logoImage}
                                                handleDrop={this.updateImageUrl.bind(this, 'logoTemplateImageId', 'logoImage')}
                                                removeImage={this.deleteImageUrl.bind(this, 'logoTemplateImageId', 'logoImage')}
                                            />
                                        </Col>
                                        <Col className="col-6">
                                            <SelectedImageCard
                                                label="backgroundImage"
                                                image={voucherConfig?.backgroundImage}
                                                handleDrop={this.updateImageUrl.bind(this, 'backgroundTemplateImageId', 'backgroundImage')}
                                                removeImage={this.deleteImageUrl.bind(this, 'backgroundTemplateImageId', 'backgroundImage')}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <h5>
                                    <FormattedMessage id="CardConfigWizard.UploadedImages" />
                                </h5>
                            </Col>
                        </Row>

                        <hr className="mt-1 border-host" />

                        <Row className="verticalScroll" style={{ height: '65vh' }}>
                            <Col className="col-3 mb-3" >
                                <DragAndDrop handleDrop={this.handleTemplateImageDrop} className="mt-1" divStyle={{ position: 'relative' }} dragDivStyle={dragAndDropStyle} transparent={true}>
                                    <div className="d-flex align-items-center " style={{ border: 'dashed grey 2px', borderRadius: '10px', maxWidth: '100%', height: 180 }}>
                                        <div className="text-muted p-3 text-center w-100"><FormattedMessage id="CardConfigWizard.AddImage" /></div>
                                    </div>
                                </DragAndDrop>
                            </Col>

                            {this.state.templateImages && this.state.templateImages.map((file, k) =>
                                <Col className="col-3 mb-3" key={`templateImages${k}`} onDragEnterCapture={() => this.setState({ endIdx: k })}>
                                    <Card className="p-0 " body style={{ borderRadius: '10px' } }>
                                        <Authorization
                                            perform="cardConfigWizard:deleteImage"
                                            yes={() => (
                                                <span style={{ position: 'absolute',
                                                    backgroundColor: '#ffffffb5',
                                                    padding: '3px 8px',
                                                    borderRadius: '20px',
                                                    right: '0'
                                                }}>
                                                    <i className="fas fa-times text-danger" style={{ cursor: 'pointer' }} onClick={this.deleteTemplateImage.bind(this, file.id)} />
                                                </span>
                                            )}
                                            no={() => <div></div>}
                                        />
                                        <img
                                            src={file.url}
                                            style={{ maxWidth: '100%', height: 180, objectFit: 'cover', borderRadius: '10px' }}
                                            draggable="true"
                                            onDragStart={_ => this.setState({ templateImage: file, isDragMode: true })}
                                            onDragOver={evt => evt.preventDefault()}
                                        />
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>


                {this.state.templatePreviewModal ?
                    <TemplatePreviewModal
                        isOpen={this.state.templatePreviewModal}
                        toggle={this.toggleTemplatePreviewModal}
                        templateList={this.state.emailTemplateList}
                        voucherConfig={this.state.voucherConfig}
                    />
                : '' }

            </BlankCard>
        );
    }
}

export default injectIntl(VoucherConfigStep8)


const SelectedImageCard = ({ label, image, handleDrop, removeImage }) => {
    return (
        <Card className="p-2 " body style={{ height: 255 }}>
            <Row className="px-1 mb-2">
                <Col> <span><FormattedMessage id={`CardConfigWizard.${label}`} /></span></Col>
                {image ?
                    <Col className="col-2">
                        <div className="text-right w-100">
                            <i className="fas fa-times text-danger" style={{ cursor: 'pointer' }} onClick={removeImage} />
                        </div>
                    </Col>
                    : ''}
            </Row>

            <DragAndDrop handleDrop={handleDrop} className="mt-1" divStyle={{ position: 'relative', height: '100%' }} dragDivStyle={dragAndDropStyle} transparent={true}>
                {image ?
                    <div>
                        <img id="logoImageVoucher" src={image} style={{ width: '100%', height: '100%', objectFit: 'cover', height: 200, borderRadius: '10px' }} />
                    </div>
                    :
                    <div className="d-flex align-items-center text-center pb-2 px-3 text-muted h-100">
                        <FormattedMessage id={`CardConfigWizard.DragHere${label}`} />
                    </div>
                }
            </DragAndDrop>
        </Card>
    )
}