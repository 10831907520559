import React, { Component } from 'react';
import { Input, InputGroupAddon, InputGroup, Row, Col, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import { CustomTable } from '../../Base/Common/CustomTable';
import { getAPI } from '../../Base/API';

class Backends extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            backends: [],
            skip: 0,
            pageSize: 10,
            totalItems: 0
        }
    }

    componentDidMount() {
        this.getBackends();
    }

    getBackends = () => {
        this.setState({ block: true });
        let params = ''
        params += this.state.name ? `&name=${this.state.name}` : '';
        params += this.state.url ? `&url=${this.state.url}` : '';

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({
                    backends: data.value,
                    totalItems: data.count,
                    block: false
                });
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Voucher/ApiGateway/v1/backend/service?skip=${this.state.skip}&top=${this.state.pageSize}` + params)
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState({ [name]: value });
    }

    doSearch = () => {
        this.setState({
            skip: 0
        }, () => this.getBackends());
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            skip: (page - 1) * this.state.pageSize,
            pageSize: sizePerPage,
        }, () => { this.getBackends() });
    }

    render() {
        const help = <div>TODO</div>

        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "Backends.Name" }),
            },
            {
                dataField: 'properties.title',
                text: this.props.intl.formatMessage({ id: "Backends.Title" })
            },
            {
                dataField: 'properties.description',
                text: this.props.intl.formatMessage({ id: "Backends.Description" })
            },
            {
                dataField: 'properties.url',
                text: this.props.intl.formatMessage({ id: "Backends.Url" })
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push({
                    pathname: '/BackendDetails/' + row.id.split("/").pop()
                });
            }
        };

        return (
            <StyledCard icon="fas fa-chalkboard" block={this.state.block} error={this.state.error} help={help} title={`navbar.Backends`}>
                <Row className="mb-4">
                    <Col className="pr-1 col-3">
                        <InputGroup size="sm text-right">
                            <InputGroupAddon addonType="prepend">
                                <span className="input-group-text"><span className="fas fa-id-card fa-fw"></span></span>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                name="name"
                                className="border-left-0"
                                placeholder={this.props.intl.formatMessage({ id: "Backends.Name" })}
                                onChange={this.handleChange}
                            />
                        </InputGroup>
                    </Col>
                    <Col className="px-1 col-3">
                        <InputGroup size="sm text-right">
                            <InputGroupAddon addonType="prepend">
                                <span className="input-group-text"><span className="fas fa-link fa-fw"></span></span>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                name="url"
                                className="border-left-0"
                                placeholder={this.props.intl.formatMessage({ id: "Backends.Url" })}
                                onChange={this.handleChange}
                            />
                        </InputGroup>
                    </Col>

                    <Col className="text-right">
                        <Button className="btn btn-sm btn-host mr-2" onClick={() =>  this.props.history.push({ pathname: '/BackendDetails' })}>
                            <i className="fas fa-plus" />
                        </Button>
                        <Button className="btn btn-sm btn-host" onClick={this.doSearch}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CustomTable
                            data={this.state.backends ? this.state.backends : []}
                            columns={columns}
                            showTotal={true}
                            selectRow={selectRow}
                            remote={true}
                            sizePerPage={this.state.pageSize}
                            onTableChange={this.handleTableChange}
                            totalSize={this.state.totalItems}
                            rowStyle={{ cursor: 'pointer' }}
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(Backends)