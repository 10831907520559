
import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, CardBody, Row, Col, Button, Label, Input, CustomInput, UncontrolledTooltip } from 'reactstrap';
import { getAPI, postAPI } from '../Base/API';
import { StyledCard, SelectHotel, ActiveInactiveStatusBadge } from '../Base/Common/CommonUIComponents';
import { CustomTable } from '../Base/Common/CustomTable';
import CustomSelect from '../Base/Common/CustomSelect';
import { handleNotification } from "../Base/Common/Notification";
import { getConnectionType } from '../Base/Common/ReferenceDataFunctions';
import { FormattedMessage, injectIntl } from "react-intl";
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../Base/ErrorAlert";
import Authorization from '../Base/Authorization';

export class PMSConnectivity extends Component {
    static displayName = PMSConnectivity.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            block: true,
            modal: false,
            pmsConnection: [],
            selectedConnection: {
                "hotelId": undefined,
                "active": false,
                "pmsResApiKey": undefined,
                "externalAccountType": undefined
            },
            currentPage: 0,
            PageSize: 10,
            editMode: false
        }
    }


    componentDidMount() {
        this.getPmsConnection();

    }

    getPmsConnection() {
        this.setState({ block: true });


        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ pmsConnection: data.response, block: false });
            }
        }, '/api/Voucher/ExternalConnectionConfig/v1/ExternalConnectionConfiguration');
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedConnection: {
                "hotelId": undefined,
                "active": false,
                "pmsResApiKey": undefined,
                "externalAccountType": undefined
            },
            editMode: false
        }));
    }
    saveConnection(e) {

        e.preventDefault();

        if (!this.form.current.reportValidity()) {
            return;
        }
        this.setState({ block: true });
        let req = {
            "request": this.state.selectedConnection
        };

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="PMSConnectivity.saved" />, <FormattedMessage id="EditVoucher.Success" />);
                this.setState({ pmsConnection: data.response, block: false }, () => this.getPmsConnection());
            }
        }, '/api/Voucher/ExternalConnectionConfig/v1/ExternalConnectionConfiguration', req) ;

    }

    updateForm(f, evt) {

        let name = evt.target.name;
        let value = evt.currentTarget.type == 'checkbox' ? evt.target.checked : evt.target.value;

        this.setState(prevState => ({
            ...prevState,
            [f]: {
                ...prevState[f],
                [name]: value

            },
        }));
    }

    updateComboForm(f, val, evt) {
       

        if (evt != undefined) {
            let name = evt.name;
            let value = val ? val.value : null;

            this.setState(prevState => ({
                ...prevState,
                [f]: {
                    ...prevState[f],
                    [name]: value

                },
            }));
        }
    }

    validateConnectivity() {
        const { selectedConnection } = this.state;
        this.setState({ block: true });
        getAPI(result => {
            const { error, data } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="PMSConnectivity.ValidatePmsConnectivityWithSuccess" />, <FormattedMessage id="EditVoucher.Success" />);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/Voucher/ExternalConnectionConfig/v1/ExternalConnectionConfiguration/ValidateConnectivity?hotelId=${selectedConnection.hotelId}`);
    }

    render() {

        const columns = [
            {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "PMSConnectivity.HotelName" }),
                sort: true
            },
            {
                dataField: 'externalAccountType',
                text: this.props.intl.formatMessage({ id: "PMSConnectivity.type" }),
                sort: true,
                formatter: (cell, row) => this.props.intl.formatMessage({ id: "ReferenceDataFunctions." + cell })
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "PMSConnectivity.status" }),
                sort: true,
                formatter: (cell, row) => <ActiveInactiveStatusBadge status={cell} />
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ modal: true, selectedConnection: row, editMode: true });
            }
        };

        const help = <div> </div>
        return (
            <StyledCard icon={'fas fa-plug'} title={'PMSConnectivity.title'} help={<FormattedMessage id="PMSConnectivity.Help" />} id="pmsConnectivity" error={this.state.error} block={this.state.block}>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-xl" >
                    <ModalHeader toggle={this.toggleModal}>
                        <FormattedMessage id="PMSConnectivity.viewdetail" />
                    </ModalHeader>
                    <ModalBody className="modal-xl p-0">
                        <BlockUi tag="div" blocking={this.state.block}>
                            <ErrorAlert error={this.state.error} />
                            <CardBody>
                                <form ref={this.form}>
                                    <Row className="mb-1">
                                        <Col className="text-right">
                                            <Authorization
                                                perform="PMSConectivity:validate"
                                                yes={() => (
                                                    <>
                                                        <Button id="validatePmsConnectivity" className="btn-sm btn-host mb-1 mr-1" onClick={this.validateConnectivity.bind(this)} disabled={!this.state.selectedConnection.id}>
                                                            <i className="fas fa-check" />
                                                        </Button>
                                                        <UncontrolledTooltip placement="bottom" target="validatePmsConnectivity">
                                                            <FormattedMessage id="PMSConnectivity.ValidatePmsConnectivity" />
                                                        </UncontrolledTooltip>
                                                    </>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            <Authorization
                                                perform="PMSConectivity:save"
                                                yes={() => (
                                                    <Button className="btn-sm btn-host mb-1" onClick={this.saveConnection.bind(this)}>
                                                        <i className="fas fa-save" />
                                                    </Button>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-1">
                                        <Label for="hotelId" sm={2}>
                                            Hotel 
                                        </Label>
                                            <Col sm={4}>
                                                <SelectHotel onChangeFunc={this.updateComboForm.bind(this, 'selectedConnection')} required name="hotelId" value={this.state.selectedConnection.hotelId ? this.state.selectedConnection.hotelId : ''} isDisabled={this.state.editMode}/>
                                        </Col>
                                        <Label for="hotelId" sm={2}>
                                            <FormattedMessage id="PMSConnectivity.type" />
                                        </Label>
                                        <Col sm={4}>
                                                <CustomSelect required options={getConnectionType()} value={getConnectionType().find(el => el.value === this.state.selectedConnection.externalAccountType)} onChange={this.updateComboForm.bind(this, 'selectedConnection')} name="externalAccountType" placeholder={""} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-1">
                                            <Label for="active" sm={2}>
                                            <FormattedMessage id="PMSConnectivity.status" />
                                        </Label>
                                            <Col sm={4}>
                                                <CustomInput type="switch" name="active" id="active"  placeholder={''} checked={this.state.selectedConnection && this.state.selectedConnection.active} onChange={this.updateForm.bind(this, 'selectedConnection')} />
                                        </Col>
                                            <Label for="pmsResApiKey" sm={2}>
                                            Password
                                        </Label>
                                            <Col sm={4}>
                                                <Input type="password" name="pmsResApiKey" id="pmsResApiKey" minLength="5" required={true} placeholder={''} value={this.state.selectedConnection && this.state.selectedConnection.pmsResApiKey} onChange={this.updateForm.bind(this, 'selectedConnection')} />
                                        </Col>
                                        </Row>
                                </form>
                            </CardBody>
                        </BlockUi>
                    </ModalBody>
                </Modal>

                <Row>
                    <Col className="text-right">
                        <Authorization
                            perform="PMSConectivity:add"
                            yes={() => (
                                <Button className="btn-sm btn-host mb-1" onClick={this.toggleModal}>
                                    <i className="fas fa-plus" />
                                </Button>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>

                </Row>
                <CustomTable
                    keyField='hotelId'
                    data={this.state.pmsConnection ? this.state.pmsConnection : []}
                    columns={columns}
                    shadow={false}
                    showTotal={true}
                    selectRow={selectRow}
                />
            </StyledCard>


        );
    }
}

export default injectIntl(PMSConnectivity);