import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Col, Row, Badge, Button, Modal, ModalBody, ModalHeader, Input, UncontrolledTooltip, Label } from 'reactstrap';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import EmailActivity from './EmailActivity';


class NotificationCard extends Component {
    static displayName = NotificationCard.name;
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            modal: false,
        }
    }

    toggleModal = (msgId) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedMessageId: msgId
        }))
    }

    render() {
        const { block, list } = this.props;

        return (
            <StyledCard icon={'fas fa-at'} title={'CommonUIComponents.Notifications'} block={block}>
                <Row className="mb-2">
                    <Col><b><FormattedMessage id="CommonUIComponents.NotificationType" /></b></Col>
                    <Col><b><FormattedMessage id="CommonUIComponents.SendDate" /></b></Col>
                    <Col><b><FormattedMessage id="EmailActivity.Details" /></b></Col>
                </Row>

                {list && list.map((el, k) =>
                    <Row key={k}>
                        <Col>{el.type}</Col>
                        <Col>{el.createdAt}</Col>
                        <Col className="">
                            {el.messageId ?
                                <Button className="btn btn-host btn-sm float-right" onClick={() => this.toggleModal(el.messageId)}>
                                    <i className="fas fa-eye" />
                                </Button>
                                :
                                el.response || '-'
                            }
                        </Col>
                    </Row>
                )}


                {this.state.modal &&
                    <EmailActivity
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        messageId={this.state.selectedMessageId}
                    />
                }
            </StyledCard>
        );
    }
}


export default injectIntl(NotificationCard);