import React, { Component } from 'react';
import { Button, Col, CustomInput, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { EmptyCard, FormatAmountNumber } from '../Base/Common/CommonUIComponents';
import { getAPI, postAPI } from '../Base/API';
import CustomSelect from '../Base/Common/CustomSelect';
import { handleNotification } from '../Base/Common/Notification';
import countryList from "react-select-country-list";
import { Link } from 'react-router-dom';
import CustomSelectAsync from '../Base/Common/CustomSelectAsync';

export class ShoppingCartCheckOut extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.getPmsData = this.getPmsData.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
        this.handleFinishSale = this.handleFinishSale.bind(this);
        const total = parseFloat(this.props.vouchersToSell.reduce((a, b) => parseFloat(a) + parseFloat(b.voucherSalePrice), 0)).toFixed(2);
        this.state = {
            block: false,
            total: total,
            customerFirstName: null,
            customerLastName: null,
            customerMail: null,
            customerNif: null,
            isGift: false,
            sender: null,
            recipient: null,
            message: null,
            pmsUsers: [],
            hostUserCode: null,
            hostUserCashboxCode: null,
            paymentMethods: [],
            hostPaymentCodes: [
                {
                    hostPaymentCode: null,
                    amount: total
                }
            ],
            hostUserPassword: null,
            onlinePaymentId: null,
            isGenericVat: false
        };
    }

    componentDidMount() {
        if (this.props.registerSalesInPms) {
            this.getPmsData();
        }
    }

    getPmsData() {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let arr = [];
                data.response.map((el) => { return arr.push({ value: el.code, label: el.description }) })

                this.setState({ paymentMethods: arr });
            }
        }, `/api/Voucher/v1/Config/paymentMethods`);

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let users = [];

                data.response.map((el) => {
                    var obj = {
                        value: el.userCode,
                        label: el.firstName + ' ' + el.lastName
                    }

                    var cashBoxes = [];
                    el.cashBoxes && el.cashBoxes.map(el => {
                        cashBoxes.push({
                            value: el.cashBoxCode,
                            label: el.cashBoxDescription
                        })
                    })

                    obj.cashBoxes = cashBoxes;

                    users.push(obj)
                })
                this.setState({ pmsUsers: users, block: false });
            }
        }, `/api/Voucher/v1/Config/pmsUsers`);
    }

    handleTextChange(evt) {
        if (evt && evt.target) {
            const { name, value } = evt.target;
            this.setState({ [name]: value });
        }
    }

    handleChangeSwitch(evt) {
        if (evt && evt.target) {
            const { name, checked } = evt.target;
            this.setState({ [name]: checked });
        }
    }

    handleSelect = (val, evt) => {
        if (evt) {
            let name = evt.name;
            let value = val.value;

            if (name === 'hostUserCode') {
                this.setState({ hostUserCashboxCode: null });
            }

            this.setState({ [name]: value });
        }
    }

    handlePaymentCodeSelect = (val, evt) => {
        if (evt) {
            const { value } = val;
            const { hostPaymentCodes } = this.state;
            hostPaymentCodes[0].hostPaymentCode = value;

            this.setState({ hostPaymentCodes });
        }
    }

    handleFinishSale(evt) {
        evt.preventDefault();
        this.setState({ block: true });

        if (!this.form.current.reportValidity()) {
            return;
        }

        const { vouchersToSell, registerSalesInPms } = this.props;
        const { customerFirstName, customerLastName, customerMail, customerNif, customerPhone, customerCountryCode, customerPostCode, customerCity, customerAddress, isGift, sender, recipient, message, hostUserCode, hostUserPassword, hostUserCashboxCode, hostPaymentCodes, from, to } = this.state;

        const request = {
            request: {
                customerLastName: customerLastName,
                customerFirstName: customerFirstName,
                customerMail: customerMail,
                customerNif: customerNif,
                voucherTypeIds: vouchersToSell,
                customerPhone: customerPhone,
                customerCountryCode: customerCountryCode,
                customerPostCode: customerPostCode,
                customerCity: customerCity,
                customerAddress: customerAddress
            }
        };
        if (isGift) {
            request.request.sender = sender;
            request.request.recipient = recipient;
            request.request.message = message;
            request.request.from = from;
            request.request.to = to;
        }
        if (registerSalesInPms) {
            request.request.hostUserCode = hostUserCode;
            request.request.hostUserPassword = hostUserPassword;
            request.request.hostUserCashboxCode = hostUserCashboxCode;
            request.request.hostPaymentCodes = hostPaymentCodes;
        }

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                if (error.request && error.request.status === 504) {
                    handleNotification({}, <FormattedMessage id="VoucherManagement.TimeoutConsultLogs" />, <FormattedMessage id="generic.timeout" />, 'info');
                }
                else {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                }
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.warnings && data.warnings.length > 0) {
                    handleNotification(data);
                    if (data.response && data.response.length > 0) {
                        handleNotification({}, <FormattedMessage id="VoucherManagement.VoucherCreatedSucessfuly" />, <FormattedMessage id="generic.success" />);
                        this.setState({ error: errorMessage, block: false, onlinePaymentId: data.response[0].id });
                    }
                    else {
                        this.setState({ error: errorMessage, block: false });
                    }
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="VoucherManagement.VoucherCreatedSucessfuly" />, <FormattedMessage id="generic.success" />);
                    this.setState({ error: errorMessage, block: false, onlinePaymentId: data.response[0].id });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Voucher/Voucher/v1/voucher/shoppingcart`, request);
    }

    loadClients = (name, cb) => {
        let options = [];
        if (name.length === 0 || name.length > 2) {
            let filter = `&name=${name}`;
            if (filter.includes('@')) {
                filter = `&email=${name}`;
            }
            else if (!isNaN(name)) {
                filter = `&phone=${name}`;
            }

            getAPI(result => {
                const { data } = result;
                if (data && data.response && data.response.length > 0) {
                    data.response.forEach(resp => {
                        resp.value = resp.id;
                        resp.label = `${resp.firstName} ${resp.lastName}${resp.email ? ` (${resp.email})` : ``}${resp.phone ? ` (${this.props.intl.formatMessage({ id: 'AddVoucher.Phone' })}: ${resp.phone})` : ``}`
                    });
                    options = data.response;
                }
                cb(options);
            }, `/api/Voucher/Profile/v1/Profile?pageIndex=0&pageSize=10${filter}`);
        }
        else {
            cb(options);
        }
    }

    handleFinalConsumerVat = (e) => {
        const { checked, name } = e.target;

        this.setState({
            [name]: checked,
            customerNif: checked ? '999999990' : ''
        });
    }

    handleSelectProfile = (profile) => {
        let { customerFirstName, customerLastName, customerMail, customerNif, customerPhone, customerCountryCode, customerPostCode, customerCity, customerAddress } = this.state;

        if (profile) {
            customerFirstName = profile.firstName;
            customerLastName = profile.lastName;
            customerMail = profile.email;
            customerNif = profile.fiscalNumber;
            customerPhone = profile.phone;
            customerCountryCode = profile.countryCode;
            customerPostCode = profile.postCode;
            customerCity = profile.city;
            customerAddress = profile.address;
        }
        else {
            customerFirstName = null;
            customerLastName = null;
            customerMail = null;
            customerNif = null;
            customerPhone = null;
            customerCountryCode = null;
            customerPostCode = null;
            customerCity = null;
            customerAddress = null;
        }
        this.setState({ customerFirstName, customerLastName, customerMail, customerNif, customerPhone, customerCountryCode, customerPostCode, customerCity, customerAddress });
    }

    render() {
        const { isOpen, toggle, vouchersToSell, registerSalesInPms } = this.props;
        const { block, error, total, customerFirstName, customerLastName, customerMail, customerNif, isGift, sender, recipient, message, pmsUsers, hostUserCode, hostUserCashboxCode, paymentMethods, hostPaymentCodes, hostUserPassword, onlinePaymentId, from, to } = this.state;
        
        return (
            <Modal isOpen={isOpen} toggle={toggle} className="modal-xl">
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="VoucherManagement.Summary" />
                </ModalHeader>
                <ModalBody>
                    <EmptyCard block={block} error={error}>
                        <form ref={this.form}>
                            <Row>
                                <Col>
                                    {
                                        onlinePaymentId !== null ?
                                            <span>
                                                <Link to={`/OnlineSalesDetail/${onlinePaymentId}`} style={{ cursor: "pointer", color: "blue" }}>
                                                    <b>
                                                        <FormattedMessage id="VoucherManagement.SoldVoucher" />
                                                    </b>
                                                    {` ${onlinePaymentId}`}
                                                </Link>
                                            </span>
                                            :
                                            <span />
                                    }
                                </Col>
                            </Row>
                            {
                                vouchersToSell.map((voucher, key) =>
                                    <Row key={key}>
                                        <Col className="col-7">{voucher.quantity} x {voucher.code}</Col>
                                        <Col className="text-right">
                                            <FormatAmountNumber value={voucher.voucherSalePrice} />
                                        </Col>
                                    </Row>
                                )
                            }
                            <hr className="my-1" />
                            <Row className="my-2">
                                <Col className="col-5">
                                    <b>
                                        <FormattedMessage id="VoucherManagement.Total" />
                                    </b>
                                </Col>
                                <Col className="text-right">
                                    <FormatAmountNumber value={total} />
                                </Col>
                            </Row>
                            <Row className="my-3">
                                <Col>
                                    <CustomInput type="switch" id="isGift" name="isGift" checked={isGift} label={<FormattedMessage id="VoucherManagement.IsGift" />} onChange={this.handleChangeSwitch} />
                                </Col>
                            </Row>
                            { isGift ?
                                <div>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label sm={4}>
                                                    <FormattedMessage id="VoucherManagement.From" />
                                                </Label>
                                                <Col sm={8}>
                                                    <Input type="text" id="from" name="from" value={from}
                                                        onChange={this.handleTextChange}
                                                        placeholder={this.props.intl.formatMessage({ id: "VoucherManagement.YourName" })}
                                                        required
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row>
                                                <Label sm={4}>
                                                    <FormattedMessage id="VoucherManagement.To" />
                                                </Label>
                                                <Col sm={8}>
                                                    <Input type="text" id="to" name="to" value={to}
                                                        onChange={this.handleTextChange}
                                                        placeholder={this.props.intl.formatMessage({ id: "VoucherManagement.RecipientName" })}
                                                        required
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label sm={4}>
                                                    <FormattedMessage id="VoucherManagement.Sender" />
                                                </Label>
                                                <Col sm={8}>
                                                    <Input type="text" id="sender" name="sender" value={sender} onChange={this.handleTextChange}
                                                        placeholder={this.props.intl.formatMessage({ id: "VoucherManagement.Sender" })}/>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row>
                                                <Label sm={4}>
                                                    <FormattedMessage id="VoucherManagement.Recipient" />
                                                </Label>
                                                <Col sm={8}>
                                                    <Input type="text" id="recipient" name="recipient" value={recipient} onChange={this.handleTextChange}
                                                        placeholder={this.props.intl.formatMessage({ id: "VoucherManagement.Recipient" })}/>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label sm={2}>
                                                    <FormattedMessage id="VoucherManagement.Message" />
                                                </Label>
                                                <Col sm={10}>
                                                    <Input type="textarea" id="message" name="message" value={message} onChange={this.handleTextChange} required
                                                        placeholder={this.props.intl.formatMessage({ id: "VoucherManagement.Message" })}/>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                                :
                                <div />
                            }
                            <Row>
                                <Col>
                                    <h6>
                                        <FormattedMessage id="VoucherManagement.BillingData" />
                                    </h6>
                                </Col>
                            </Row>
                            <FormGroup row className="mb-2 mt-2">
                                <Col className="col-2">
                                    <FormattedMessage id="AddVoucher.SearchClients" />
                                </Col>
                                <Col className="col-10">
                                    <CustomSelectAsync
                                        cacheOptions
                                        loadOptions={this.loadClients}
                                        defaultOptions
                                        onChange={this.handleSelectProfile}
                                    />
                                </Col>
                            </FormGroup>
                            <hr className="mb-2 mt-0" />
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}>
                                            <FormattedMessage id="VoucherManagement.Name" /> * 
                                        </Label>
                                        <Col sm={8}>
                                            <Input type="text" id="customerFirstName" name="customerFirstName" value={customerFirstName} onChange={this.handleTextChange} required
                                                placeholder={this.props.intl.formatMessage({ id: "VoucherManagement.Name" })}/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}>
                                            <FormattedMessage id="VoucherManagement.CustomerLastName" /> * 
                                        </Label>
                                        <Col sm={8}>
                                            <Input type="text" id="customerLastName" name="customerLastName" value={customerLastName} onChange={this.handleTextChange} required
                                                placeholder={this.props.intl.formatMessage({ id: "VoucherManagement.CustomerLastName" })}/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}>
                                            <FormattedMessage id="VoucherManagement.Email" /> * 
                                        </Label>
                                        <Col sm={8}>
                                            <Input type="email" id="customerMail" name="customerMail" value={customerMail} onChange={this.handleTextChange} placeholder={this.props.intl.formatMessage({ id: "VoucherManagement.Email" })}/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}>
                                            <FormattedMessage id="VoucherManagement.CustomerNif" /> * 
                                        </Label>
                                        <Col sm={4}>
                                            <Input type="text" id="customerNif" name="customerNif" value={customerNif} onChange={this.handleTextChange} required placeholder={this.props.intl.formatMessage({ id: "VoucherManagement.CustomerNif" })} readOnly={this.state.isGenericVat}/>
                                        </Col>

                                        <Col sm={4} className="d-flex align-items-center">
                                            <Input type="checkbox" name="isGenericVat"
                                                className="mb-2"
                                                checked={this.state.isGenericVat}
                                                onChange={(e) => this.handleFinalConsumerVat(e)}
                                            />
                                            <Label className="mb-1"><FormattedMessage id="AddVoucher.FinalConsumer" /></Label>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>


                            <FormGroup row >
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="AddVoucher.Phone" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="AddVoucher.Phone">
                                        {
                                            placeholder => <Input type="text" name="customerPhone"
                                                value={this.state.customerPhone || ''}
                                                onChange={this.handleTextChange}
                                                placeholder={placeholder}
                                            />
                                        }
                                    </FormattedMessage>
                                </Col>
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="AddVoucher.CustomerCountryCode" />
                                    </Label>
                                </Col>
                                <Col sm={4}>
                                    <CustomSelect
                                        options={countryList().getData()}
                                        name="customerCountryCode"
                                        isClearable
                                        isSearchable
                                        placeholder={<FormattedMessage id="AddVoucher.CustomerCountryCode" />}
                                        onChange={this.handleSelect.bind(this)}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="AddVoucher.CustomerPostCode" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="AddVoucher.CustomerPostCode">
                                        {
                                            placeholder => <Input type="text" name="customerPostCode"
                                                value={this.state.customerPostCode || ''}
                                                onChange={this.handleTextChange.bind(this)}
                                                placeholder={placeholder}
                                            />
                                        }
                                    </FormattedMessage>
                                </Col>
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="AddVoucher.CustomerCity" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="AddVoucher.CustomerCity">
                                        {
                                            placeholder =>
                                                <Input type="text" name="customerCity"
                                                    value={this.state.customerCity || ''}
                                                    onChange={this.handleTextChange}
                                                    placeholder={placeholder}
                                                />
                                        }
                                    </FormattedMessage>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="AddVoucher.Address" />
                                    </Label>
                                </Col>
                                <Col className="col-10">
                                    <FormattedMessage id="AddVoucher.Address">
                                        {
                                            placeholder =>
                                                <Input type="text" name="customerAddress"
                                                    value={this.state.customerAddress || ''}
                                                    onChange={this.handleTextChange}
                                                    placeholder={placeholder}
                                                />
                                        }
                                    </FormattedMessage>
                                </Col>
                            </FormGroup>

                            {
                                registerSalesInPms ?
                                    <div>
                                        <Row className="mt-4">
                                            <Col>
                                                <h6>
                                                    <FormattedMessage id="AddVoucher.OperatorData" />
                                                </h6>
                                            </Col>
                                        </Row>
                                        <hr className="mb-2 mt-0" />
                                        <Row>
                                            <Col>
                                                <FormGroup row>
                                                    <Label sm={4}>
                                                        <FormattedMessage id="AddVoucher.HostUserCode" /> *
                                                    </Label>
                                                    <Col sm={8}>
                                                        <CustomSelect
                                                            options={pmsUsers}
                                                            value={pmsUsers.find(el => el.value === hostUserCode)}
                                                            name="hostUserCode"
                                                            onChange={this.handleSelect.bind(this)}
                                                            placeholder={<FormattedMessage id="AddVoucher.HostUserCode" />}
                                                            required
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup row>
                                                    <Label sm={4}>
                                                        <FormattedMessage id="AddVoucher.HostUserCashboxCode" /> *
                                                    </Label>
                                                    <Col sm={8}>
                                                        <CustomSelect
                                                            options={hostUserCode ? pmsUsers.find(el => el.value === hostUserCode).cashBoxes : []}
                                                            name="hostUserCashboxCode"
                                                            value={hostUserCode && hostUserCashboxCode ? pmsUsers.find(el => el.value === hostUserCode).cashBoxes.find(el => el.value === hostUserCashboxCode) : null}
                                                            onChange={this.handleSelect.bind(this)}
                                                            placeholder={<FormattedMessage id="AddVoucher.HostUserCashboxCode" />}
                                                            isDisabled={hostUserCode ? false : true}
                                                            required
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {
                                                total > 0 ?
                                                    <Col>
                                                        <FormGroup row>
                                                            <Label sm={4}>
                                                                <FormattedMessage id="AddVoucher.HostPaymentCode" />*
                                                            </Label>
                                                            <Col sm={8}>
                                                                <CustomSelect
                                                                    options={paymentMethods}
                                                                    value={paymentMethods.find(el => el.value === hostPaymentCodes[0].hostPaymentCode)}
                                                                    name="hostPaymentCode"
                                                                    onChange={this.handlePaymentCodeSelect.bind(this)}
                                                                    placeholder={<FormattedMessage id="AddVoucher.HostPaymentCode" />}
                                                                    required
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                    :
                                                    <Col />
                                            }
                                            <Col>
                                                <FormGroup row>
                                                    <Label sm={4}>
                                                        <FormattedMessage id="AddVoucher.HostUserPassword" />*
                                                     </Label>
                                                    <Col sm={8}>
                                                        <FormattedMessage id="AddVoucher.HostUserPassword">
                                                            {
                                                                placeholder => <Input type="password" required value={hostUserPassword !== null ? hostUserPassword : ''} id="hostUserPassword" name="hostUserPassword" onChange={this.handleTextChange} placeholder={placeholder} />
                                                            }
                                                        </FormattedMessage>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    :
                                    <div />
                            }
                            <Row>
                                <Col>
                                    <Button className="btn-host btn-sm btn-block mt-3" disabled={vouchersToSell.length === 0 || onlinePaymentId !== null} onClick={this.handleFinishSale}>
                                        <FormattedMessage id="VoucherManagement.FinishSell" />
                                    </Button>
                                </Col>
                                </Row>
                        </form>
                    </EmptyCard>        
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(ShoppingCartCheckOut)