import { FormattedMessage } from 'react-intl';
import React, { Component } from 'react';
import { getMultiLangEmpty } from './CommonUIComponents';


const HelpLink = [
    { id: 'manageruserhelp', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1050214439/Gerir+Utilizadores' },
    { id: 'voucherManagementHelp', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1046085665/Cart+o' },
    { id: 'dashboard', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1046216718/Dashboard' },
    { id: 'terms&conditions', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1046642708/Termos+e+Condi+es' },
    { id: 'voucherTemplates', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1050116119/Templates+de+Email' },
    { id: 'voucherIssueAutomation', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1050050616/Emiss+o+Autom+tica' },
    { id: 'referenceData', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1050214456/Dados+de+Refer+ncia' },
    { id: 'promotions', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1911652353/Promo+es' },
    { id: 'logsDashboard', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1050378276/Hist+rico' },
    { id: 'operationManagement', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1050345622/Todos+os+Cart+es' },
    { id: 'shopping', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/2625241110/Shopping' },
    { id: 'onlineSales', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1050673272/Vendas+Online' },
    { id: 'reservationVoucher', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1050050681/Cart+o+de+Reservas' },
    { id: 'useVoucher', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1050083447/Utilizar+Cart+o' },
    { id: 'profilesDashboard', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1422098445/Perfis' },
    { id: 'hotelList', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1050050738/Lista+de+Hot+is' },
    { id: 'hotelConfig', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1050247359/Configura+o+do+Hotel' },
    { id: 'pmsConnectivity', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1050280081/Conectividade+PMS' },
    { id: 'paymentManagement', link: 'https://hostpms.atlassian.net/wiki/spaces/HEYC/pages/1045987366/Pagamentos' }
];


export function getVoucherTypes() {
    let opt = [
        { value: true, label: <FormattedMessage id="ReferenceDataFunctions.Payment" /> },
        { value: false, label: <FormattedMessage id="ReferenceDataFunctions.Service" /> }
    ];
    return opt;
}

export function getStatus() {
    let opt = [
        { value: 'created', label: <FormattedMessage id="ReferenceDataFunctions.Created" /> },
        { value: 'sold', label: <FormattedMessage id="ReferenceDataFunctions.Sold" /> },
        { value: 'redeemed', label: <FormattedMessage id="ReferenceDataFunctions.Redeemed" /> },
        { value: 'partialRedeemed', label: <FormattedMessage id="ReferenceDataFunctions.PartialRedeemed" /> },
        
        { value: 'cancelled', label: <FormattedMessage id="ReferenceDataFunctions.Canceled" /> },
        { value: 'refunded', label: <FormattedMessage id="ReferenceDataFunctions.Refunded" />},
        { value: 'partialRefund', label: <FormattedMessage id="ReferenceDataFunctions.PartialRefund" /> }
    ];
    return opt;

}

export function getPmsStatus() {
    let opt = [
        { value: 0, label: <FormattedMessage id="ReferenceDataFunctions.Created" /> },
        { value: 4, label: <FormattedMessage id="ReferenceDataFunctions.Pending" /> },
        { value: 6, label: <FormattedMessage id="ReferenceDataFunctions.Canceled" /> },
        { value: 7, label: <FormattedMessage id="ReferenceDataFunctions.NoShow" /> },
        { value: 9, label: <FormattedMessage id="ReferenceDataFunctions.Refused" /> },
        { value: 10, label: <FormattedMessage id="ReferenceDataFunctions.CheckIn" />},
        { value: 20, label: <FormattedMessage id="ReferenceDataFunctions.CheckOut" /> }
    ];
    return opt;

}

export function getChannels() {
    const opt = [
        {
            value: 'Frontend',
            label: <FormattedMessage id="ReferenceDataFunctions.Local" />
        },
        {
            value: 'Online',
            label: <FormattedMessage id="ReferenceDataFunctions.Online" />
        },
        {
            value: 'Reservation',
            label: <FormattedMessage id="ReferenceDataFunctions.Reservation" />
        }
    ];
    return opt;
}

export function getCurrency() {
    let opt = [
        {
            "value": "€",
            "label": "EUR"
        },
        {
            "value": "$",
            "label": "USD"
        },
        {
            "value": "R$",
            "label": "BRL"
        },
        {
            "value": "C$",
            "label": "CAD"
        },
        {
            "value": "£",
            "label": "GBP"
        },
        {
            "value": "Kz",
            "label": "AOA"
        }
    ];
    return opt;

}

export function getStripeRefundReasons() {
    let opt = [
        { label: 'Requested by Customer', value : 'requested_by_customer' },
        { label: 'Duplicate', value: 'duplicate' },
        { label: 'Fraudulent', value: 'fraudulent' }
        
    ];
    return opt;

}


export function getPromoteTypes() {
    let opt = [
        { label: 'Deal of the Week', value: 'dealweek' },
        { label: 'Hot Deal', value: 'hotdeal' }

    ];
    return opt;

}

export function getHelpLink(id) {
    let lnk = HelpLink.find(el => el.id === id);

    if (lnk) return lnk.link
    else return null
}

export function getAutoEmissionType() {
    let opt = [
        { value: 'Room', label: <FormattedMessage id="ReferenceDataFunctions.Room" /> },
        { value: 'Reservation', label: <FormattedMessage id="ReferenceDataFunctions.Reservation" /> },
        { value: 'Guest', label: <FormattedMessage id="ReferenceDataFunctions.Guest" /> }
    ];
    return opt;
}

export function getAutoValidityType() {
    let opt = [
        { value: 'ReservationDates', label: <FormattedMessage id="ReferenceDataFunctions.ReservationDates" /> },
        { value: 'ConfigurationDates', label: <FormattedMessage id="ReferenceDataFunctions.ConfigurationDates" /> }
    ];
    return opt;
}

export function getAutoEmissionScheduleType() {
    let opt = [
        { value: 'BeforeCheckIn', label: <FormattedMessage id="ReferenceDataFunctions.BeforeCheckIn" /> },
        { value: 'AfterCheckOut', label: <FormattedMessage id="ReferenceDataFunctions.AfterCheckOut" /> }
    ];
    return opt;
} 

export function getConnectionType() {
    let opt = [
        { value: 'PmsReservations', label: <FormattedMessage id="ReferenceDataFunctions.PmsReservations" /> }
    ];
    return opt;
}

export function getFonts() {
    const fonts = [
        {
            value: 'Agency FB',
            label: 'Agency FB'
        },
        {
            value: 'Albertina',
            label: 'Albertina'
        },
        {
            value: 'Antiqua',
            label: 'Antiqua'
        },
        {
            value: 'Architect',
            label: 'Architect'
        },
        {
            value: 'Arial',
            label: 'Arial'
        },
        {
            value: 'BankFuturistic',
            label: 'BankFuturistic'
        },
        {
            value: 'BankGothic',
            label: 'BankGothic'
        },
        {
            value: 'Blackletter',
            label: 'Blackletter'
        },
        {
            value: 'Blagovest',
            label: 'Blagovest'
        },
        {
            value: 'Calibri',
            label: 'Calibri'
        },
        {
            value: 'Comic Sans MS',
            label: 'Comic Sans MS'
        },
        {
            value: 'Consolas',
            label: 'Consolas'
        },
        {
            value: 'Courier',
            label: 'Courier'
        },
        {
            value: 'Cursive',
            label: 'Cursive'
        },
        {
            value: 'Decorative',
            label: 'Decorative'
        },
        {
            value: 'Fantasy',
            label: 'Fantasy'
        },
        {
            value: 'Fraktur',
            label: 'Fraktur'
        },
        {
            value: 'Frosty',
            label: 'Frosty'
        },
        {
            value: 'Garamond',
            label: 'Garamond'
        },
        {
            value: 'Georgia',
            label: 'Georgia'
        },
        {
            value: 'Helvetica',
            label: 'Helvetica'
        },
        {
            value: 'Impact',
            label: 'Impact'
        },
        {
            value: 'Minion',
            label: 'Minion'
        },
        {
            value: 'Modern',
            label: 'Modern'
        }, 
        {
            value: 'Monospace',
            label: 'Monospace'
        },
        {
            value: 'Montserrat',
            label: 'Montserrat'
        },
        {
            value: 'Open Sans',
            label: 'Open Sans'
        },
        {
            value: 'Palatino',
            label: 'Palatino'
        },
        {
            value: 'Perpetua',
            label: 'Perpetua'
        },
        {
            value: 'Roman',
            label: 'Roman'
        },
        {
            value: 'Sans-serif',
            label: 'Sans-serif'
        },
        {
            value: 'Serif',
            label: 'Serif'
        },
        {
            value: 'Script',
            label: 'Script'
        },
        {
            value: 'Swiss',
            label: 'Swiss'
        },
        {
            value: 'Tahoma',
            label: 'Tahoma'
        },
        {
            value: 'Times',
            label: 'Times'
        },
        {
            value: 'Times New Roman',
            label: 'Times New Roman'
        },
        {
            value: 'Tw Cen MT',
            label: 'Tw Cen MT'
        },
        {
            value: 'Verdana',
            label: 'Verdana'
        }
    ];
    return fonts;
}

export function getLicenseModules() {
    let opt = [
        { value: 'PMSIntegration', label: <FormattedMessage id="ReferenceDataFunctions.PMSIntegration" /> }
    ];
    return opt;
} 

export function getPaymentType() {
    let opt = [
        { value: 'Prepay', label: <FormattedMessage id="ReferenceDataFunctions.Prepay" /> },
        { value: 'Postpaid', label: <FormattedMessage id="ReferenceDataFunctions.Postpaid" /> },
        { value: 'Monthlyfee', label: <FormattedMessage id="ReferenceDataFunctions.Monthlyfee" /> }
    ];
    return opt;
}

export function getTaxCodes() {
    let opt = [
        { value: 'RED', label: 'IVA Reduzido' },
        { value: 'INT', label: 'IVA Intermédio' },
        { value: 'NOR', label: 'IVA Normal' },
        { value: 'ISE', label: 'IVA Isento' }
    ];
    return opt;
}

export function processMultilingualTexts(item) {
    const multiLangArray = getMultiLangEmpty();
    if (item.multiLingual.length === 0) {
        item.multiLingual = multiLangArray;
    }
    else if (item.multiLingual.length !== multiLangArray.length) {
        multiLangArray.forEach(multiLang => {
            const language = item.multiLingual.find(ml => ml.languageCode === multiLang.languageCode);
            if (!language) {
                item.multiLingual.push(multiLang);
            }
            else {
                language.language = multiLang.language
            }
        });
    }
    else {
        item.multiLingual.forEach(multiLingual => {
            const lang = multiLangArray.find(ml => ml.languageCode === multiLingual.languageCode);
            if (lang) {
                multiLingual.language = lang.language
            }
        });
    }
}

export function getHotelGroupModules(intl) {
    const opt = [
        { value: 'PNIntegration', label: intl.formatMessage({ id: "ReferenceDataFunctions.PNIntegration" }) },
        { value: 'ShortId', label: intl.formatMessage({ id: "ReferenceDataFunctions.ShortId" }) },
        { value: 'SendGrid', label: "SendGrid" },
    ];
    return opt;
}

export function getCodeGenerationTypes(intl) {
    const opt = [
        { value: 'BarCode', label: intl.formatMessage({ id: "ReferenceDataFunctions.BarCode" }) },
        { value: 'QrCode', label: intl.formatMessage({ id: "ReferenceDataFunctions.QrCode" }) }
    ];
    return opt;
}

export function getRebateActions(intl) {
    const opt = [
        { value: 'Discount', label: intl.formatMessage({ id: "ReferenceDataFunctions.Discount" }) },
        { value: 'Bill', label: intl.formatMessage({ id: "ReferenceDataFunctions.Bill" }) }
    ];
    return opt;
}

export function getRightVoucherId(voucherGuid, shortVoucherId) {
    return global.shortId ? shortVoucherId : voucherGuid
}


export function getWidgetLanguages(intl) {
    return [
        { value: 'pt', label: intl.formatMessage({ id: "MultiLang.Portuguese" }) },
        { value: 'en', label: intl.formatMessage({ id: "MultiLang.English" }) }
    ];
}