import React, { Component } from 'react';
import { Modal, ModalFooter, ModalBody, Row, Col, Label, Button, Form } from 'reactstrap';
import { handleNotification } from "../Base/Common/Notification";
import { SingleDatePicker } from 'react-dates';
import { ErrorAlert } from "../Base/ErrorAlert";
import { postAPI, getAPI } from "../Base/API";
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import moment from 'moment';

export class ConfirmationModal extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            payment: this.props.selectedPayment ? this.props.selectedPayment : {},
            isPayed: this.props.isPayed ? this.props.isPayed : false
        };
    }

    componentDidMount() {
    }

    cancelPayment = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const { payment } = this.state;
        payment.status = 'Canceled';

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(({ block: false, error: errorMessage }));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    this.props.updateTable(data.response[0]);
                    handleNotification(data, <FormattedMessage id="PaymentDashboard.PaymentCanceled" />, <FormattedMessage id="generic.success" />);
                    this.props.toggleModal('confirmationModal', null, false)
                }
                this.setState(({ error: [] }));
            }
            this.setState(({ block: false }));
        }, `/api/Voucher/v1/Config/clientCashDeposit`, JSON.stringify({ request: payment }));
    }

    markAsPayed = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const { payment } = this.state;
        payment.status = 'Payed';
        payment.payday = this.state.payday.format('YYYY-MM-DD');

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(({ block: false, error: errorMessage }));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    this.props.updateTable(data.response[0]);
                    handleNotification(data, <FormattedMessage id="PaymentDashboard.PaymentMarkAsPayed" />, <FormattedMessage id="generic.success" />);
                    this.props.toggleModal('confirmationModal', null, false);
                }
                this.setState(({ error: [] }));
            }
            this.setState(({ block: false }));
        }, `/api/Voucher/v1/Config/clientCashDeposit`, JSON.stringify({ request: payment }));
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={() => this.props.toggleModal('confirmationModal', null, false)} className="modal-md">
                <BlockUi tag="div" blocking={this.state.block}>
                    <Form onSubmit={this.state.isPayed ? this.markAsPayed : this.cancelPayment}>
                        <ModalBody>
                            {this.state.isPayed ?
                                <Row className="my-2 text-center">
                                    <Col className="col-6">
                                        <Label>
                                            <FormattedMessage id="PaymentDashboard.Payday" />
                                        </Label>
                                    </Col>
                                    <Col className="col-6">
                                        <SingleDatePicker
                                            id="Date"
                                            isOutsideRange={day => day > moment()}
                                            date={this.state.payday && this.state.payday ? this.state.payday : null}
                                            onDateChange={(date) => this.setState({ payday: date })}
                                            focused={this.state.focused}
                                            onFocusChange={({ focused }) => this.setState({ focused })}
                                            small={true}
                                            numberOfMonths={1}
                                            required={true}
                                            showDefaultInputIcon={true}
                                        />
                                    </Col>
                                </Row>
                                :
                                <Row className="mb-2 text-center">
                                    <Col className="text-center">
                                        <p className="m-0">Tem a certeza de que deseja cancelar este pagamento?</p>
                                    </Col>
                                </Row>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type="submit" >{this.state.isPayed ? <span> Marcar como Pago </span>: <span>Cancelar Pagamanto</span> }</Button>{' '}
                            <Button color="secondary" onClick={() => this.props.toggleModal('confirmationModal', null, false)}>Fechar</Button>
                        </ModalFooter>
                    </Form>
                </BlockUi >
            </Modal>
        );
    }
}
