import React, { Component } from 'react';
import SalesTable from './SalesTable';
import { FormattedMessage } from 'react-intl';


export class OnlineSales extends Component {

    render() {
        const { history } = this.props;

        return (
            <SalesTable
                icon="fas fa-gift"
                title="navbar.OnlineSales"
                channel={3}
                history={history}
                help={<FormattedMessage id="OnlineSales.Help" />}
                id="onlineSales"
            />
        );
    }
}
