import moment from "moment";
import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ReactHtmlParser from 'react-html-parser';
import { Button, Card, CardBody, Col, Collapse, CustomInput, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { deleteAPI, getAPI, postAPI, postMultiPartAPI } from '../Base/API';
import { BlankCard, StyledCard, MultiLangText, MultiLangTextWithTitle, getMultiLangEmpty } from '../Base/Common/CommonUIComponents';
import { getPromoteTypes, processMultilingualTexts } from '../Base/Common/ReferenceDataFunctions';
import DragAndDrop from "../Base/Common/DragAndDrop";
import { handleNotification } from "../Base/Common/Notification";
import { VoucherPreview } from "./VoucherPreview";
import CustomSelect from "../Base/Common/CustomSelect";
import { FormattedMessage } from 'react-intl';

export class EditVoucher extends Component {
    static displayName = EditVoucher.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleModalPreview = this.toggleModalPreview.bind(this);
        this.handleSwitched = this.handleSwitched.bind(this);
        this.updateTerm = this.updateTerm.bind(this);
        this.getPaymentGateways = this.getPaymentGateways.bind(this);
        this.form = React.createRef();
        this.formRebate = React.createRef();
        this.state = {
            block: true,
            modal: false,
            modalPreview: false,
            activeTab: 1,
            files : [],
            hotelList: [],
            addedHotels: [],
            voucherRebate: this.getNewVoucherRebate(),
            voucherConfig: {
                multiLingual: getMultiLangEmpty()
            },
            voucherRebateList: [],
            voucherHotelList: [],
            voucherImageList: [],
            voucherTermsList: [],
            voucherChannelList: [],
            ReveneuCatList: [],
            paymentGateways: []
        }
    }

    getNewVoucherRebate() {
        const voucherRebate = {
            id: null,
            multiLingual: getMultiLangEmpty()
        };
        return voucherRebate;
    }

    componentDidMount() {
        if (this.props.match.params.id)
            this.getConfigDetails(this.props.match.params.id)
        else {
            this.setState({ block: false });
        }
        this.getHotels();
        this.getTerms();
        this.getChannels();
        this.getReveneuCategories();
        this.getPaymentGateways();
    }

    getTerms() {
        this.setState({ blockCfg: true });
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                data.response.forEach(processMultilingualTexts);
                this.setState({ termsList: data.response, block: false });
            }
        }, '/api/Voucher/v1/Config/getTermsAndConditions');
    }

    getChannels() {
        this.setState({ blockCfg: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                this.setState({ channelList: data.response, block: false });
            }
        }, '/api/Voucher/v1/Config/getChannels');
    }

    getPaymentGateways() {
        this.setState({ blockCfg: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockCfg: false });
                return;
            }
            if (data) {
                this.setState({ blockCfg: false, paymentGateways: data.response });
            }
        }, '/api/Voucher/Payment/v1/PaymentGateways');
    }

    getConfigDetails(id) {
        this.setState({  block: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const voucher = data.response[0];
                processMultilingualTexts(voucher);
                
                voucher.voucherRebates.forEach(processMultilingualTexts);
                this.populateVoucherTerms(voucher.voucherTerms, voucher.id);
                
                this.setState({
                    voucherConfig: voucher,
                    voucherRebateList: voucher.voucherRebates,
                    voucherHotelList: voucher.validHotels,
                    voucherImageList: voucher.voucherImages,
                    voucherTermsList: voucher.voucherTerms,
                    voucherChannelList: voucher.channels,
                    block: false
                });
            }
        }, '/api/Voucher/v1/Config/getVoucherType/' + id);
    }

    populateVoucherTerms(voucherTerms, voucherTypeId) {
        this.checkVoucherTerms(voucherTerms, "Included", voucherTypeId);
        this.checkVoucherTerms(voucherTerms, "Excluded", voucherTypeId);
    }

    checkVoucherTerms(voucherTerms, type, voucherTypeId) {
        const voucherTermsIncluded = voucherTerms
            .filter(term => term.type === type)
            .map(processMultilingualTexts);
        if (voucherTermsIncluded.length === 0) {
            voucherTerms.push({
                voucherTypeId: voucherTypeId,
                code: null,
                type: type,
                multiLingual: getMultiLangEmpty()
            });
        }
    }

    toggleTab(id) {
        this.setState({ activeTab: id });
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
  
    toggleModalPreview() {
        this.setState(prevState => ({
            modalPreview: !prevState.modalPreview
        }));
    }

    handleCheckInDatesChange(type, start, end) {
        if (type === 's') {
            this.setState(prevState => ({
                ...prevState,
                voucherRebate: {
                    ...prevState.voucherRebate,
                    saleValidTo: end,
                    saleValidFrom: start

                },
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                voucherRebate: {
                    ...prevState.voucherRebate,
                    redeemValidTo: end,
                    redeemValidFrom: start

                },
            }));
        }
    }

    handleSwitched(f, evt) {
        const name = evt.target.name;
        const value = evt.target.checked;

        this.setState(prevState => ({
            ...prevState,
            [f]: {
                ...prevState[f],
                [name]: value

            },
        }));
    }

    handleCheck(evt) {
        let name = evt.target.name;
        let value = evt.target.checked;
        if (value === true)
            this.setState({ addedHotels: [...this.state.addedHotels, name] });
        else
            this.setState({ addedHotels: [...this.state.addedHotels.filter(el => el !== name)] });
    }

    handleDatesChange(type,start, end) {
        if (type === 'r') {
            this.setState(prevState => ({
                ...prevState,
                voucherRebate: {
                    ...prevState.voucherRebate,
                    saleValidTo: end,
                    saleValidFrom: start

                },
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                voucherConfig: {
                    ...prevState.voucherConfig,
                    saleValidTo: end,
                    saleValidFrom: start

                },
            }));
        }
    }

    saveVoucher(e) {
        e.preventDefault();

        if (this.form.current.reportValidity()) {
            this.setState({ block: true });

            let temp = this.state.voucherConfig;
            delete temp['validHotels'];
            delete temp['voucherRebates'];
            if (temp.id === undefined)
                temp.id = null

            if (this.state.voucherConfig.tec) {
                temp.termsConditionsId = this.state.voucherConfig.tec.id;
            }

            let payload = { request: temp };

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                        this.setState(({ block: false, error: errorMessage }));
                        return;
                    }
                    if (data.response && data.response.length > 0) {
                        const voucherTermsList = [...this.state.voucherTermsList];
                        if (voucherTermsList.length === 0) {
                            this.populateVoucherTerms(voucherTermsList, data.response[0].id);
                            this.setState({ voucherTermsList });
                        }
                        this.setState({ voucherConfig: data.response[0] });
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, '/api/Voucher/v1/Config/setVoucherType', payload);

        }
    }

    saveRebate(e) {
        e.preventDefault();

        if (this.formRebate.current.reportValidity()) {
            this.setState({ block: true });
            let temp = this.state.voucherRebate;
           
            if (temp.id === undefined) {
                temp.id = null;
            }
            temp.voucherTypeId = this.state.voucherConfig.id;

            const payload = { request: temp };

            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="EditVoucher.RebateAdded" />, <FormattedMessage id="EditVoucher.Success" />);
                    this.setState({ block: false, voucherRebate: this.getNewVoucherRebate() }, () => this.getConfigDetails(this.state.voucherConfig.id));
                }
            }, '/api/Voucher/Voucher/v1/voucherrebate', payload);
        }
    }

    removeRebate(id) {

        deleteAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="EditVoucher.RebateRemoved" />, <FormattedMessage id="EditVoucher.Success" />); 
                
                this.setState({ block: false }, () => this.getConfigDetails(this.state.voucherConfig.id));
            }
        }, '/api/Voucher/Voucher/v1/voucherrebate/'+id);
        
    }

    getHotels() {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
               
                this.setState({ hotelList: data.response[0], block: false });
            }
        }, '/api/Voucher/Hotel/v1/hotel' );
        
    }

    updateForm(f,evt) {
        
        let name = evt.target.name;
        let value = evt.target.value;

        this.setState(prevState => ({
            ...prevState,
            [f]: {
                ...prevState[f],
                [name]: value

            },
        }));
    }

    updateComboForm(f, val, evt) {
        if (evt != undefined) { 
        let name = evt.name;
        let value = val.value;

        this.setState(prevState => ({
            ...prevState,
            [f]: {
                ...prevState[f],
                [name]: value

            },
        }));
    }
    }

    manageHotel(hotelId, evt) {

        this.setState({ block: true });

        let payload = {
            "request": {
                "id": this.state.voucherConfig.id,
                "hotels": [
                    hotelId
                ]
            }
        }

        if (evt.target.checked) {
            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="EditVoucher.HotelAdded" />, <FormattedMessage id="EditVoucher.Success" />);
                    this.setState({ voucherHotelList: data.response[0].hotels,   block: false });
                }
            }, '/api/Voucher/Voucher/v1/vouchervalidity', payload);

        }
        else { 
        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="EditVoucher.HotelRemoved" />, <FormattedMessage id="EditVoucher.Success" />);
                this.setState({ voucherHotelList: data.response[0].hotels, block: false });
            }
        }, '/api/Voucher/Voucher/v1/removevouchervalidity', payload);
    }
        
    }

    getFilteredHotels() {
        let res = [];
        if (this.state.hotelList.hotels) {
            res = this.state.hotelList.hotels.filter(el => this.state.voucherHotelList.find(item => item.guid === el.hotelId) === undefined);
        }
        return res === undefined ? [] : res;
    }

    handleDrop = (files) => {
        let fileList = this.state.files;
        for (var i = 0; i < files.length; i++) {
            //if (!files[i].name) return
            fileList.push(URL.createObjectURL(files[i]))
        }

        const formData = new FormData();
        formData.append('file', files[0]);

        var url = '/api/Voucher/v1/Config/UploadImages/' + this.state.voucherConfig.id;

        postMultiPartAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ errorModal: errorMessage, block: false });
                return;
            }
            if (data) {
                errorMessage.push({ message: "Photo Updated sucessfuly", messageType: 'success' });
                
                this.setState(prevState => ({ block: false, voucherImageList: [...prevState.voucherImageList, data.response[0]] }));
            }
        }, url, formData);
        //this.setState({  })
    }

    handleDropTEC = (e) => {
        let evtData = JSON.parse(e.getData('TEC'));
        let req = {
            request: {
                id: this.state.voucherConfig.id,
                termsConditionsId: evtData.id
            }
        }

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="EditVoucher.TermsAndConditionsUpdated" />, <FormattedMessage id="EditVoucher.Success" />);
                this.setState(prevState => ({
                    block: false,
                    ...prevState,
                    voucherConfig: {
                        ...prevState.voucherConfig,
                        tec: evtData

                    },
                }));
            }
        }, '/api/Voucher/v1/Config/setVoucherTypeOnlineProp', req);
    }

    deleteImage(id) {

        deleteAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="EditVoucher.ImageDeleted" />, <FormattedMessage id="EditVoucher.Success" />);

                this.setState(prevState => ({ block: false, voucherImageList: prevState.voucherImageList.filter(el => el.id !== data.response[0].id) }));
            }
        }, '/api/Voucher/v1/Config/DeleteImage/' + id);

        
    }

    manageChannel(id, ciid, code, evt) {
        if (code === "Online" && !this.state.paymentGateways.find(pg => pg.active)) {
            const data = {
                errors: [
                    {
                        message: <FormattedMessage id="EditVoucher.NoPaymentGateways" />
                    }
                ]
            };
            handleNotification(data);
            return;
        }

        this.setState({ block: true });
        if (evt.target.checked) {
            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="EditVoucher.ChannelAdded" />, <FormattedMessage id="EditVoucher.Success" />);
                    this.setState(prevState => ({ block: false, voucherChannelList: [...prevState.voucherChannelList.filter(el => el.id !== data.response[0].id), data.response[0]] }));
                }
            }, '/api/Voucher/v1/Config/addChannelInstance/' + this.state.voucherConfig.id + '/' + id);
        }
        else {
            deleteAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="EditVoucher.ChannelRemoved" />, <FormattedMessage id="EditVoucher.Success" />);
                    this.setState(prevState => ({ block: false, voucherChannelList: [...prevState.voucherChannelList.filter(el => el.id !== data.response[0].id)] }));
                }
            }, '/api/Voucher/v1/Config/removeChannelInstance/' + ciid);
        }
    }

    changeTerms(type, evt) {

        let t = type;
        let value = evt.target.value;

        this.setState(prevState => ({
            block: false,
            ...prevState,
            voucherTermsList: 
                [...prevState.voucherTermsList.filter(el => el.type !== type),
                    { description: value, type: t, voucherTypeId: this.state.voucherConfig.id}

                ]

            }
        ));
    }

    saveTerms(type, evt) {
        const req = { request: this.state.voucherTermsList.find(el => el.type === type) };
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="EditVoucher.TermsSaved" />, <FormattedMessage id="EditVoucher.Success" />);
                this.setState({ block: false });
            }
        }, '/api/Voucher/v1/Config/addVoucherTypeTerms', req);
    }

    getReveneuCategories() {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

            let arr =[];

                data.response.map(el => arr.push({ label: el.description, value: el.id }));

                this.setState({ ReveneuCatList:arr, block: false });
            }
        }, '/api/Voucher/v1/Config/ReveneuCategories');

    }

    savePromote() {
        this.setState({ block: true });
        let req = {
            "request": {
                "id": this.state.voucherConfig.id,
                "promote": this.state.voucherConfig.promote,
                "promoteType": this.state.voucherConfig.promoteType,
                "promoCode": this.state.voucherConfig.promoCode
            }
        }
        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="EditVoucher.PromotionConditionsUpdated" />, <FormattedMessage id="EditVoucher.Success" />);
                this.setState({  block: false });
            }
        }, '/api/Voucher/v1/Config/setVoucherTypeOnlineProp', req);


    }

    udpateText(evt, lang) {
        const multiLingual = [...this.state.voucherConfig.multiLingual];
        const idx = multiLingual.indexOf(multiLingual.find(ml => ml.language === lang));
        if (idx >= 0) {
            const ml = multiLingual[idx];
            ml[evt.target && evt.target.name] = evt.target && evt.target.value;
            this.setState(prevState => ({
                ...prevState,
                voucherConfig: {
                    ...prevState.voucherConfig,
                    multiLingual: multiLingual
                }
            }));
        }
    }

    updateRebateTerms(evt, lang) {
        const multiLingual = [...this.state.voucherRebate.multiLingual];
        const idx = multiLingual.indexOf(multiLingual.find(ml => ml.language === lang));
        if (idx >= 0) {
            const ml = multiLingual[idx];
            ml[evt.target && evt.target.name] = evt.target && evt.target.value;
            this.setState(prevState => ({
                ...prevState,
                voucherRebate: {
                    ...prevState.voucherRebate,
                    multiLingual: multiLingual
                }
            }));
        }
    }

    updateIncludedTerm(evt, lang) {
        this.updateTerm(evt, lang, "Included");
    }

    updateExcludedTerm(evt, lang) {
        this.updateTerm(evt, lang, "Excluded");
    }

    updateTerm(evt, lang, type) {
        const voucherTermsList = [...this.state.voucherTermsList];
        const voucherTerm = voucherTermsList.find(term => term.type === type);
        const multiLingual = voucherTerm && voucherTerm.multiLingual;
        const idx = multiLingual && multiLingual.indexOf(multiLingual.find(ml => ml.language === lang));
        if (idx >= 0) {
            const ml = multiLingual[idx];
            ml[evt.target && evt.target.name] = evt.target && evt.target.value;
            this.setState(({ voucherTermsList }));
        }
    }

    render() {

        function getTerm(voucherTermsList, type) {
            const term = voucherTermsList && voucherTermsList.find(term => term.type === type);
            return term ? term.multiLingual : []
        }

        function getTecMultilingual(termsList, tec) {
            const term = termsList && tec && termsList.find(term => term.id === tec.id);
            return term ? term.multiLingual : []
        }

        return (
            <>
                <BlankCard title='EditVoucher.EditVoucher' block={this.state.block} error={this.state.error}>
                    <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-lg" >
                        <ModalHeader toggle={this.toggleModal} className="py-2">
                        
                        </ModalHeader>
                        <ModalBody className="modal-lg p-0">
                            <StyledCard title="EditVoucher.AddRebate">
                                <Row className="mb-2">
                                    <Col className="text-right">
                                        <Button className="btn-host btn-sm" onClick={this.saveRebate.bind(this)}> <i class="fas fa-save " /></Button>
                                    </Col>
                                </Row>
                                <form ref={this.formRebate}>
                                    <Row className="mb-2">
                                        <Col className="col-2">
                                            <Label><FormattedMessage id="EditVoucher.Code" /></Label>
                                        </Col>
                                        <Col className="col-4">
                                            <FormattedMessage id="EditVoucher.Code" >
                                                {
                                                    placeholder => <Input type="text" placeholder={placeholder} required minLength="1" maxLength="50" name="code" value={this.state.voucherRebate.code} onChange={this.updateForm.bind(this, 'voucherRebate')} />
                                                }
                                            </FormattedMessage>
                                        </Col>
                                        <Col className="col-2">
                                            <Label><FormattedMessage id="EditVoucher.MaxQuantity" /></Label>
                                        </Col>
                                        <Col className="col-4">
                                            <FormattedMessage id="EditVoucher.MaxQuantity" >
                                                {
                                                    placeholder => <Input type="number" min="1" max="10000" required placeholder={placeholder} name="quantity" value={this.state.voucherRebate.quantity} onChange={this.updateForm.bind(this, 'voucherRebate')} />
                                                }
                                            </FormattedMessage>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col className="col-2">
                                            <Label><FormattedMessage id="EditVoucher.Description" /></Label>
                                        </Col>
                                        <Col className="col-10">
                                            <MultiLangText textList={this.state.voucherRebate.multiLingual} udpateTerms={this.updateRebateTerms.bind(this)} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col className="col-2">
                                            <Label><FormattedMessage id="EditVoucher.RevenewCategory" /></Label>
                                        </Col>
                                        <Col className="col-4">
                                            <CustomSelect required options={this.state.ReveneuCatList} value={this.state.ReveneuCatList.find(el => el.value === this.state.voucherRebate.revenueCategoryId)} name={'revenueCategoryId'} onChange={this.updateComboForm.bind(this, 'voucherRebate')} placeholder={<FormattedMessage id="EditVoucher.RevenewCategory" />} />
                                        </Col>
                                        <Col className="col-2">
                                            <Label><FormattedMessage id="EditVoucher.Use" /></Label>
                                        </Col>
                                        <Col className="col-4">
                                            <DateRangePicker
                                                startDate={this.state.voucherRebate.redeemValidFrom ? moment(this.state.voucherRebate.redeemValidFrom) : null} 
                                                startDateId="redeemValidFrom"
                                                isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment().add(6, 'month')}
                                                endDate={this.state.voucherRebate.redeemValidTo ? moment(this.state.voucherRebate.redeemValidTo) : null}
                                                endDateId="redeemValidTo"
                                                onDatesChange={({ startDate, endDate }) => this.handleCheckInDatesChange('r',startDate, endDate)}
                                                focusedInput={this.state.focusedInput2}
                                                onFocusChange={focusedInput => this.setState({ focusedInput2:focusedInput })}
                                                small={true}
                                                required
                                                numberOfMonths={2}
                                                showDefaultInputIcon={true}
                                            />
                                        </Col>
                                    </Row>
                                </form>
                            </StyledCard>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={this.state.modalPreview} toggle={this.toggleModalPreview} fade={false} className="modal-xl" >
                        <ModalBody className="modal-xl p-0">
                            <VoucherPreview
                                voucherConfig={this.state.voucherConfig}
                                voucherRebateList={this.state.voucherRebateList}
                                voucherHotelList={this.state.voucherHotelList}
                                voucherImageList={this.state.voucherImageList}
                                voucherTermsList={this.state.voucherTermsList}
                            />
                        </ModalBody>
                    </Modal>

                    <form ref={this.form}>
                        <Row >
                            <Col>
                                <h5><FormattedMessage id="EditVoucher.VoucherConfig" /></h5>
                            </Col>
                                <Col className="text-right">
                                <Button className="btn-host btn-sm mr-1" onClick={this.toggleModalPreview} disabled={this.state.voucherChannelList && this.state.voucherChannelList.find(el => el.name === 'Online') === undefined}>
                                    <i className="far fa-eye" />
                                </Button>
                                <Button className="btn-host btn-sm" onClick={this.saveVoucher.bind(this)}>
                                    <i className="fas fa-save " />
                                </Button>
                            </Col>
                        </Row>
                        <hr className="my-1 border-host" />
                        <Row className="mb-2">
                            <Col className="col-2">
                                <Label>
                                    <b><FormattedMessage id="EditVoucher.Code" /></b>
                                </Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="EditVoucher.Code" >
                                    {
                                        placeholder => <Input type="text" placeholder={placeholder} required minLength="1" maxLength="50" value={this.state.voucherConfig.code ? this.state.voucherConfig.code : ''} name="code" onChange={this.updateForm.bind(this, 'voucherConfig')} />
                                    }
                                </FormattedMessage>
                            </Col>
                            <Col className="col-2">
                                <Label>
                                    <b><FormattedMessage id="EditVoucher.MaxQuantity" /></b>
                                </Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="EditVoucher.MaxQuantity" >
                                    {
                                        placeholder => <Input type="number" placeholder={placeholder} required min="1" max="10000" value={this.state.voucherConfig.maxQuantity} name="maxQuantity" onChange={this.updateForm.bind(this, 'voucherConfig')} />
                                    }
                                </FormattedMessage>
                            </Col>
                           
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                
                            </Col>
                            <Col>
                                <MultiLangTextWithTitle textList={this.state.voucherConfig.multiLingual} updateTitle={this.udpateText.bind(this)} udpateDescription={this.udpateText.bind(this)} />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <Label><b><FormattedMessage id="EditVoucher.PayedAmount" /></b></Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="EditVoucher.PayedAmount" >
                                    {
                                        placeholder => <Input type="number" placeholder={placeholder} required min="0" max="10000" step="0.01" value={this.state.voucherConfig.payedAmount} name="payedAmount" onChange={this.updateForm.bind(this, 'voucherConfig')} />
                                    }
                                </FormattedMessage>
                            </Col>
                            <Col className="col-2">
                                <Label><b><FormattedMessage id="EditVoucher.CardAmount" /></b></Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="EditVoucher.CardAmount" >
                                    {
                                        placeholder => <Input type="number" placeholder={placeholder} min="1" max="10000" step="0.01" value={this.state.voucherConfig.cardAmount} name="cardAmount" onChange={this.updateForm.bind(this, 'voucherConfig')} />
                                    }
                                </FormattedMessage>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <Label><b><FormattedMessage id="EditVoucher.SalesPeriod" /></b></Label>
                            </Col>
                            <Col className="col-4">
                                <DateRangePicker
                                    startDate={this.state.voucherConfig.saleValidFrom ? moment(this.state.voucherConfig.saleValidFrom, 'YYYY-MM-DDTHH:mm:ssZ') : null}
                                    startDateId="saleValidFrom"
                                    isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment().add(24, 'month')}
                                    endDate={this.state.voucherConfig.saleValidTo ? moment(this.state.voucherConfig.saleValidTo, 'YYYY-MM-DDTHH:mm:ssZ') : null}
                                    endDateId="saleValidTo"
                                    onDatesChange={({ startDate, endDate }) => this.handleDatesChange('voucherConfig', startDate, endDate)}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    small={true}
                                    required
                                    numberOfMonths={2}
                                    showDefaultInputIcon={true}
                                />
                            </Col>
                            <Col className="col-2">
                                <Label><b><FormattedMessage id="EditVoucher.ValidUntil" /></b></Label>
                            </Col>
                                <Col className="col-4">
                                <FormattedMessage id="EditVoucher.NumMonth" >
                                    {
                                        placeholder => <Input type="number" placeholder={placeholder} minLength="1" maxLength="48" value={this.state.voucherConfig.vouchersValidUntil} name="vouchersValidUntil" onChange={this.updateForm.bind(this, 'voucherConfig')} />
                                    }
                                </FormattedMessage>

                         
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <Label><b><FormattedMessage id="EditVoucher.TypePrefix" /></b></Label>
                            </Col>
                                <Col className="col-4">
                                <FormattedMessage id="EditVoucher.TypePrefix" >
                                    {
                                        placeholder => <Input type="text" placeholder={placeholder} required minLength="1" maxLength="10" value={this.state.voucherConfig.defaultPrefix} name="defaultPrefix" onChange={this.updateForm.bind(this, 'voucherConfig')} />
                                    }
                                </FormattedMessage>
                            </Col>
                            <Col className="col-2">
                                <Label><b><FormattedMessage id="EditVoucher.DisplayOrder" /></b></Label>
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="EditVoucher.DisplayOrder" >
                                    {
                                        placeholder => <Input type="number" min="1" max="100" placeholder={placeholder} required minLength="1" maxLength="50" value={this.state.voucherConfig.order} name="order" onChange={this.updateForm.bind(this, 'voucherConfig')} />
                                    }
                                </FormattedMessage>
                            </Col>
                            {/*        <Col className="col-2">
                                    <Label><b><FormattedMessage id="EditVoucher.Active" /></b></Label>
                                </Col>
                                <Col className="col-4">
                                    
                                </Col>
                        */}
                        </Row>
        
                    </form>

                    <Row className="mb-4">
                        <Col>
                            <CustomInput type="switch" label={<FormattedMessage id="EditVoucher.IsMoneyVoucher" />} checked={this.state.voucherConfig.isPaymentMode} name="isPaymentMode" id="isPaymentMode" onChange={this.handleSwitched.bind(this,'voucherConfig')} />
                        </Col>
                        <Col>
                            <CustomInput type="switch" label={<FormattedMessage id="EditVoucher.ParcialRedeem" />} checked={this.state.voucherConfig.allowPartialRedeem} name="allowPartialRedeem" id="allowPartialRedeem" onChange={this.handleSwitched.bind(this, 'voucherConfig')}  />
                        </Col>
                        <Col>
                            <CustomInput type="switch" label={<FormattedMessage id="EditVoucher.IncludesTax" />} checked={this.state.voucherConfig.salesHasTax} name="salesHasTax" id="salesHasTax" onChange={this.handleSwitched.bind(this, 'voucherConfig')} />
                        </Col>
                        <Col>
                            <CustomInput type="switch" label={<FormattedMessage id="EditVoucher.Active" />} checked={this.state.voucherConfig.active} name="active" id="active" onChange={this.handleSwitched.bind(this, 'voucherConfig')} />
                        </Col>
                    </Row>
                </BlankCard>

                <div className="mb-2" />

                {
                    !this.state.voucherConfig.isPaymentMode ?
                        <BlankCard title='EditVoucher.Rebates' block={this.state.block} >
                            <Row>
                                <Col>
                                    <h5><FormattedMessage id="EditVoucher.Rebates" /></h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-host btn-sm" onClick={this.toggleModal} disabled={this.state.voucherConfig.id === undefined ? true: false}><i class="fas fa-plus " /></Button>
                                </Col>
                            </Row>
                            <hr className="my-1 border-host" />
                            <Row className="mt-2">
                                {
                                    this.state.voucherRebateList && this.state.voucherRebateList.map((item, k) =>
                                        <Col className="col-3" key={k}>
                                            <Card>
                                                <CardBody className="">
                                                    <Row>
                                                        <Col className="col-1"></Col>
                                                        <Col className="text-center"><b>{item.code}</b></Col>
                                                        <Col className="col-1"><span style={{ cursor: 'pointer' }} onClick={this.removeRebate.bind(this, item.id)}><i className="fas fa-times color-host"></i></span></Col>
                                                    </Row>
                                                    <Row className="mb-4">
                                                        <Col>
                                                            <MultiLangText key={k} textList={item.multiLingual} renderInCard={true} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="border-bottom">
                                                        <Col className=""><b><FormattedMessage id="EditVoucher.Quantity" /> </b></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="">{item.quantity}</Col>
                                                    </Row>
                                                    <Row className="border-bottom">
                                                        <Col className=""><b><FormattedMessage id="EditVoucher.UsagePeriod" /></b></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="">{item.redeemValidFrom} <FormattedMessage id="EditVoucher.To" /> {item.redeemValidTo}</Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )
                                }
                            </Row>
                        </BlankCard>
                        :
                        ''
                }

              
                <div className="mb-2" />

                {
                    this.state.voucherConfig.id !== undefined ? 
                        <BlankCard title='Criar Voucher' block={this.state.block} >
                            <Row className="mt-2">
                                <Col>
                                    <h5><FormattedMessage id="EditVoucher.ValidHotels" /></h5>
                                </Col>
                                <Col className="text-right">
                                </Col>
                            </Row>
                            <hr className="my-1 border-host" />
                            {
                                this.state.hotelList && this.state.hotelList.hotels && this.state.hotelList.hotels.map((item, k) =>
                                    <Row key={k}>
                                        <Col>
                                            <CustomInput type="switch" label={item.name2} checked={this.state.voucherHotelList.find(x => x.guid === item.hotelId) === undefined ? false : true} name={'hotelid' + k} id={'hotelid' + k} onChange={this.manageHotel.bind(this, item.hotelId)} />
                                        </Col>
                                    </Row>
                                )
                            }
                        </BlankCard>
                        :
                        ''
                }
                
                <div className="mb-2" />
                {this.state.voucherConfig.id !== undefined ?
                    <BlankCard block={this.state.block}>
                        <Row className="mt-2">
                            <Col>
                                <h5><FormattedMessage id="EditVoucher.OnlineChannelConfiguration" /></h5>
                            </Col>
                            <Col className="text-right">

                            </Col>
                        </Row>

                        <hr className="my-1 border-host" />

                        <Row className="my-2">
                            <Col onClick={() => this.setState({ activeTab: 1 })} style={{ cursor: 'pointer' }} className={this.state.activeTab === 1 ? 'border-bottom-host-bold text-center ml-3' : ' text-center ml-3'}>
                                <FormattedMessage id="EditVoucher.Channels" />
                            </Col>
                            <Col onClick={() => this.setState({ activeTab: 2 })} style={{ cursor: 'pointer' }} className={this.state.activeTab === 2 ? 'border-bottom-host-bold text-center ' : ' text-center '}  >
                                <FormattedMessage id="EditVoucher.TermsAndConditions" />
                            </Col>
                            <Col onClick={() => this.setState({ activeTab: 3 })} style={{ cursor: 'pointer' }} className={this.state.activeTab === 3 ? 'border-bottom-host-bold text-center' : ' text-center'}>
                                <FormattedMessage id="EditVoucher.WhatsIncluded" />
                            </Col>
                            <Col onClick={() => this.setState({ activeTab: 4 })} style={{ cursor: 'pointer' }} className={this.state.activeTab === 4 ? 'border-bottom-host-bold text-center' : ' text-center'}>
                                <FormattedMessage id="EditVoucher.Images" />
                            </Col>
                            <Col onClick={() => this.setState({ activeTab: 5 })} style={{ cursor: 'pointer' }} className={this.state.activeTab === 5 ? 'border-bottom-host-bold text-center' : ' text-center'}>
                                <FormattedMessage id="EditVoucher.Promote" />
                            </Col>
                        </Row>

                        <Collapse isOpen={this.state.activeTab === 1}>
                            <Row className="mb-2">
                                <Col>
                                    {
                                        this.state.channelList && this.state.channelList.map((el, k) => {
                                            let chan = this.state.voucherChannelList && this.state.voucherChannelList.find(pos => pos.channelConfigId === el.id);
                                            return (
                                                <CustomInput key={k} checked={chan !== undefined ? true : false} type="switch" label={el.name} id={'channel' + k} onChange={this.manageChannel.bind(this, el.id, chan && chan.id, el.code)}></CustomInput>
                                            )
                                        })
                                    }
                                </Col>
                            </Row>

                        </Collapse>

                        <Collapse isOpen={this.state.activeTab === 2}>

                            <Row className="mb-2">
                                <Col><h6><FormattedMessage id="EditVoucher.Selected" /> </h6>
                                    <hr className="my-1" />
                                </Col>
                                <Col><h6><FormattedMessage id="EditVoucher.TermsAndConditions" /></h6>
                                    <hr className="my-1" />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-6 p-0">
                                    <div className="py-2 px-3" >
                                        <MultiLangText textList={getTecMultilingual(this.state.termsList, this.state.voucherConfig.tec)} renderInCard={true} />
                                    </div>
                                    <DragAndDrop handleDrop={this.handleDropTEC} divStyle={{ display: 'inline-block', position: 'absolute', top: '0', left: '6px', width: '98%', height: '35%' }}>
                                        <div style={{ height: 100, minWidth: 300,  whiteSpace: 'break-spaces' }}>
                                            {/*
                                                this.state.voucherConfig.tec && this.state.voucherConfig.tec.description && ReactHtmlParser(this.state.voucherConfig.tec && '<li>' + this.state.voucherConfig.tec.description.replace(/\n/g, "</li><li>") + '</li>')
                                            */}
                                            
                                        </div>
                                      
                                    </DragAndDrop>
                                </Col>
                                <Col className="col-6">
                                    {
                                        this.state.termsList && this.state.termsList.map((el, k) =>
                                            <Row className="mb-1">
                                                <Col>
                                                    <Card>
                                                        <CardBody style={{ whiteSpace: 'break-spaces' }} draggable="true" onDragStart={(event) => event.dataTransfer.setData("TEC", JSON.stringify(el))} >
                                                            {el.code}
                                                            <MultiLangText key={k} textList={el.multiLingual} />
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </Col>
                            </Row>
                        </Collapse>

                        <Collapse isOpen={this.state.activeTab === 3}>
                            <Row className="mb-2">
                                <Col className="col-5">
                                    <h6><i class="fas fa-check text-success" /> <FormattedMessage id="EditVoucher.Included" /> </h6>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-host btn-sm" onClick={this.saveTerms.bind(this, 'Included')}>
                                        <i class="fas fa-save" />
                                    </Button>
                                </Col>
                                <Col className="col-5">
                                    <h6> <i class="fas fa-times text-danger" /> <FormattedMessage id="EditVoucher.NotIncluded" /></h6>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-host btn-sm" onClick={this.saveTerms.bind(this, 'Excluded')}>
                                        <i class="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <MultiLangText textList={getTerm(this.state.voucherTermsList, "Included")} udpateTerms={this.updateIncludedTerm.bind(this)} />
                                </Col>
                                <Col>
                                    <MultiLangText textList={getTerm(this.state.voucherTermsList, "Excluded")} udpateTerms={this.updateExcludedTerm.bind(this)} />
                                </Col>
                            </Row>
                        </Collapse>

                        <Collapse isOpen={this.state.activeTab === 4}>

                            <Row>

                                {this.state.voucherImageList && this.state.voucherImageList.map((file) =>

                                    <Col className="col-3">
                                        <div className="text-right w-100"><i class="fas fa-times text-danger" style={{ cursor: 'pointer' }} onClick={this.deleteImage.bind(this, file.id)} /></div>
                                        <img src={file.url} alt="" style={{ maxWidth: '100%' }}></img>
                                    </Col>
                                )}
                                <Col className="col-3 text-muted align-middle text-center m-1" style={{ border: '2px dashed' }} >
                                    <DragAndDrop handleDrop={this.handleDrop}>
                                        <div style={{ height: 200, width: 250 }}>

                                        </div>
                                    </DragAndDrop>
                                </Col>

                            </Row>
                        </Collapse>

                        <Collapse isOpen={this.state.activeTab === 5}>
                            <Row className="mb-2">
                            <Col className="text-right">
                                <Button className="btn-host btn-sm" > <i class="fas fa-save " onClick={this.savePromote.bind(this)} /></Button>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <CustomInput type="switch" checked={this.state.voucherConfig.promote} label={<FormattedMessage id="EditVoucher.PromoteVoucher" />} name="promote" id="promote" onChange={this.handleSwitched.bind(this, 'voucherConfig')} />
                                </Col>
                                <Col>
                                    {this.state.voucherConfig.promote === true ? <CustomSelect required options={getPromoteTypes()} value={getPromoteTypes().find(el => el.value === this.state.voucherConfig.promoteType)} name={'promoteType'} onChange={this.updateComboForm.bind(this, 'voucherConfig')} placeholder={'Choose Promo Type'} /> : '' }
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <FormattedMessage id="EditVoucher.PromoteCode" />
                        </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="EditVoucher.PromoteCode" >
                                        {
                                            placeholder => < Input type="text" maxLength="50" name="promoCode" value={this.state.voucherConfig.promoCode} onChange={this.updateForm.bind(this, 'voucherConfig')} placeholder={placeholder} ></Input>
                                        }
                                    </FormattedMessage>
                                </Col>

                            </Row>
                        </Collapse>


                    </BlankCard>
                    : ''}
                    </>
        );
    }
}
