import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row, Button } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { getPaymentType } from "../../Base/Common/ReferenceDataFunctions";
import { StyledCard, PaymentPositionStatus, FormatAmountNumber } from "../../Base/Common/CommonUIComponents";
import { CustomTable } from '../../Base/Common/CustomTable';
import CustomSelect from '../../Base/Common/CustomSelect';

class PaymentPositions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            paymentPositionList: [],
            paymentType: null,
            hotelGroup: null,
            hotelGroupList: []
        }
    }

    componentDidMount() {
        this.getPaymentPosition();
        this.getHotelGroups();
    }

    getPaymentPosition = () => {
        this.setState({ block: true });
        var params = '';
        params += this.state.hotelGroup ? `&hotelGroup=${this.state.hotelGroup}` : '';
        var paymentType = this.state.paymentType ? this.state.paymentType : '';

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ paymentPositionList: data.response, block: false });
            }
        }, `/api/Voucher/v1/Config/clientPaymentPosition/admin?paymentType=${paymentType}` + params);
    }

    getHotelGroups = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const list = [];

                data.response && data.response.forEach(hg => {
                    list.push({ value: hg.hotelGroupId, label: hg.name });
                })

                this.setState({ hotelGroupList: list, block: false });
            }
        }, '/api/Voucher/Hotel/v1/HotelGroup');
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    render() {
        const columns = [
            {
                dataField: 'groupName',
                text: this.props.intl.formatMessage({ id: "PaymentPositions.HotelGroup" }),
                sort: true
            },
            {
                dataField: 'paymentType',
                text: this.props.intl.formatMessage({ id: "PaymentPositions.PaymentType" }),
                sort: true,
                formatter: (cell) => getPaymentType().find(pt => pt.value === cell).label
            },
            {
                dataField: 'payedVoucherNum',
                text: this.props.intl.formatMessage({ id: "PaymentPositions.PayedVoucherNum" }),
                sort: true
            },
            {
                dataField: 'payedVoucherAmount',
                text: this.props.intl.formatMessage({ id: "PaymentPositions.PayedVoucherAmount" }),
                sort: true,
                formatter: (cell) => <FormatAmountNumber value={cell} />
            },
            {
                dataField: 'pendingPaymentNum',
                text: this.props.intl.formatMessage({ id: "PaymentPositions.PendingPaymentNum" }),
                sort: true
            },
            {
                dataField: 'pendingPaymentAmount',
                text: this.props.intl.formatMessage({ id: "PaymentPositions.PendingPaymentAmount" }),
                sort: true,
                formatter: (cell) => <FormatAmountNumber value={cell} />
            },
            {
                dataField: 'canceledPaymentNum',
                text: this.props.intl.formatMessage({ id: "PaymentPositions.CanceledPaymentNum" }),
                sort: true
            },
            {
                dataField: 'canceledPaymentAmount',
                text: this.props.intl.formatMessage({ id: "PaymentPositions.CanceledPaymentAmount" }),
                sort: true,
                formatter: (cell) => <FormatAmountNumber value={cell} />
            }
        ];
        
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push('/PaymentDashboard/' + this.state.paymentPositionList[rowIndex].hotelGroupId)
            }
        };

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-file-invoice-dollar'} title={<FormattedMessage id="PaymentPositions.Title" />}>
                <Row className="mb-3">
                    <Col className="col-3">
                        <CustomSelect
                            options={getPaymentType()}
                            isClearable
                            placeholder={<FormattedMessage id="PaymentPositions.PaymentType" />}
                            onChange={this.handleSelect.bind(this, 'paymentType')}
                        />
                    </Col>
                    <Col className="col-3">
                        <CustomSelect
                            options={this.state.hotelGroupList}
                            isClearable
                            placeholder={<FormattedMessage id="PaymentPositions.HotelGroup" />}
                            onChange={this.handleSelect.bind(this, 'hotelGroup')}
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-sm btn-host ml-2" onClick={this.getPaymentPosition}><i className="fas fa-search"></i></Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="h-100">
                        <CustomTable
                            data={this.state.paymentPositionList ? this.state.paymentPositionList : []}
                            columns={columns}
                            shadow={false}
                            exportCSV={false}
                            showTotal={true}
                            search={false}
                            remote={false}
                            selectRow={selectRow}
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }

};
export default injectIntl(PaymentPositions);