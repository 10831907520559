import React, { Component } from 'react';
import { Badge, Row, Col, Button, UncontrolledTooltip, Label, FormGroup, Input, CustomInput } from 'reactstrap';
import { postAPI, getAPI } from '../Base/API';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import * as qs from 'query-string';
import { handleNotification } from "../Base/Common/Notification";
import { CustomTable } from '../Base/Common/CustomTable';
import  stripeLogo  from '../../img/Stripelogo.svg'
import stripebutton from '../../img/light-on-light@2x.png';
import alticeLogo from '../../img/logo_meo_wallet.png'
import redunicreLogo from '../../img/logo-reduniq.svg'
import { FormattedMessage, injectIntl } from 'react-intl';
import Authorization from '../Base/Authorization';

export class PaymentManagement extends Component {
    static displayName = PaymentManagement.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            paymentGatewaysCodes: [
                'Stripe',
                'AlticePay',
                'Reduniq'
            ],
            unicreValidPaymentModes: [
                'CreditCard',
                'MB',
                'MBWAY'
            ],
            unicre: {
                validPaymentModes: []
            }
        }
        this.handleUnicreData = this.handleUnicreData.bind(this);
        this.saveUnicreData = this.saveUnicreData.bind(this);
        this.updateUnicreValidPaymentModes = this.updateUnicreValidPaymentModes.bind(this);
        this.unicreForm = React.createRef();
    }

    componentDidMount() {
        const parsed = qs.parse(this.props.location.search);
        let code = parsed.code;
        let scope = parsed.scope;

        if (code != null && scope != null) {
            this.setState({ block: true });

            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="PaymentManagement.StripeConnected" />, <FormattedMessage id="PaymentManagement.Success" />);
                }
                this.getPaymentGateways();
            }, '/api/Voucher/Payment/v1/CompleteAccountCreation?code=' + code + '&scope=' + scope);
        } else {
            this.getPaymentGateways()
        }
    }

    getPaymentGateways() {
        const { paymentGatewaysCodes } = this.state;
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const unicrePaymentGateway = data.response.find(resp => resp.code === paymentGatewaysCodes[2]);
                if (unicrePaymentGateway) {
                    const { unicre } = this.state;
                    Object.assign(unicre, unicrePaymentGateway);
                    this.setState({ unicre });
                }
                this.setState({ block: false, paymentGateways: data.response });
            }
        }, '/api/Voucher/Payment/v1/PaymentGateways');
    }

    disconnectStripe() {
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                if (data.response.length > 0) {
                    const respMsg = data.response[0].active ? <FormattedMessage id="PaymentManagement.StripeConnected" /> : <FormattedMessage id="PaymentManagement.StripeDisconnected" />;
                    handleNotification(data, respMsg, <FormattedMessage id="PaymentManagement.Success" />);
                }
                this.setState({ paymentGateways: data.response });
            }
            this.setState({ block: false });
        }, '/api/Voucher/Payment/v1/RevokeAccountConnection');
    }

    setPaymentStripePaymentStatus(status, code) {
        this.setState({ block: true});
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="PaymentManagement.StatusUpdated" />, <FormattedMessage id="PaymentManagement.Success" />);
                    this.setState({ paymentGateways: data.response });
                }
            }
            this.setState({ error: errorMessage, block: false });
        },`/api/Voucher/Payment/v1/Status?status=${status}&code=${code}`);

    }

    handleUnicreData(evt) {
        if (evt.target) {
            const { unicre } = this.state;
            const name = evt.target.name;
            const value = evt.target.value;
            unicre[name] = value;
            this.setState({ unicre });
        }
    }

    saveUnicreData(unicre) {
        const creditcard = document.getElementById('creditcard');
        if (unicre.validPaymentModes.length === 0) {
            creditcard.setCustomValidity(this.props.intl.formatMessage({ id: "PaymentManagement.ValidPaymentModesIsEmpty" }));
        }
        else {
            creditcard.setCustomValidity("");
        }
        if (!this.unicreForm.current.reportValidity()) {
            return;
        }

        const req = {
            request: unicre
        }
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="PaymentManagement.UnicreSaveSuccessMessage" />, <FormattedMessage id="PaymentManagement.Success" />);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/Voucher/Payment/v1/Unicre', req);
    }

    updateUnicreValidPaymentModes(paymentMode, evt) {
        if (evt.target) {
            const { unicre } = this.state;
            if (evt.target.checked) {
                //push
                unicre.validPaymentModes.push(paymentMode);
            }
            else {
                //pop
                unicre.validPaymentModes.splice(unicre.validPaymentModes.indexOf(unicre.validPaymentModes.find(pm => pm === paymentMode)), 1);
            }
            this.setState({ unicre });
        }
    }

    render() {
        const { paymentGatewaysCodes, unicreValidPaymentModes, unicre } = this.state;
        function RenderPaymentStatus(props) {
            
            let p = props.paymentGateways && props.paymentGateways.find(el => el.code === props.channel);

            return (
                <>
                    {
                        p !== undefined && p !== null ?
                            <>
                                <i className=" color-host fas fa-check" />
                                <br />
                                {
                                    p.active === true ?
                                        <Badge className="btn-host ">
                                            <FormattedMessage id="PaymentManagement.Active" />
                                        </Badge>
                                        :
                                        <Badge color="secondary">
                                            <FormattedMessage id="PaymentManagement.Disabled" />
                                        </Badge>
                                }
                            </>
                            :
                            <>
                                <span />
                                <br />
                            </>
                    }
                </>
            )
        }
        
        function RenderStripeDetails(props) {

            let p = props.paymentGateways && props.paymentGateways.find(el => el.code === paymentGatewaysCodes[0]);

            return (
                <>
                    {
                        p === undefined || p === null || p.userId === null ? 
                            <>
                                <Row>
                                    <Col>
                                        <h5><FormattedMessage id="PaymentManagement.StripeConnection" /></h5>
                                    </Col>
                                </Row>
                                <hr className="my-1" />
                                <Row className="mb-2">
                                <Col>
                                    <a href={`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENTID}&scope=read_write&redirect_uri=${window.location.origin}/PaymentManagement`}><img height="30px" src={stripebutton}></img></a>
                                </Col>
                                </Row>
                            </>
                            : 
                            <>
                                <Row>
                                    <Col>
                                        <h5><FormattedMessage id="PaymentManagement.StripeSettings" /></h5>
                                    </Col>
                                </Row>
                                <hr className="my-1" />
                                <Row className="mb-2">
                                    <Col className="col-4">
                                        <FormattedMessage id="PaymentManagement.StripeUserId" />
                                    </Col>
                                    <Col className="col-8">
                                        {p.userId}
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-4">
                                        <FormattedMessage id="PaymentManagement.ConnectedOn" /> 
                                    </Col>
                                    <Col className="col-8">
                                        {p.createdAt}
                                    </Col>
                                </Row>
                                <Row className="mb-2 pt-2">
                                    <Col className="d-flex">
                                        <Authorization
                                            perform="paymentManagement:revoke"
                                            yes={() => (
                                                <div>
                                                    <Button className="btn btn-host btn-sm mr-2" onClick={props.disconnectStripe} id="revokeTooltip">
                                                        <i className="fas fa-trash-alt" />
                                                    </Button>
                                                    <UncontrolledTooltip placement="bottom" target="revokeTooltip">
                                                        <FormattedMessage id="PaymentManagement.RevokeConnection" />
                                                    </UncontrolledTooltip>
                                                </div>
                                            )}
                                            no={() => <div></div>}
                                        />
                                        <Authorization
                                            perform="paymentManagement:disable"
                                            yes={() => (
                                                <div>
                                                    <Button className="btn btn-host btn-sm" onClick={props.paymentStatus.bind(this, !p.active, paymentGatewaysCodes[0])} id="disableTooltip">
                                                        <i className="fas fa-power-off" />
                                                    </Button>
                                                    <UncontrolledTooltip placement="bottom" target="disableTooltip">
                                                        <FormattedMessage id="PaymentManagement.EnableStripe" />
                                                    </UncontrolledTooltip>
                                                </div>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </Col>
                                </Row>
                            </>
                    }
                </>
            )
        }


        return (
            <StyledCard icon={'fas fa-credit-card'} title={'PaymentManagement.PaymentGateways'} help={<FormattedMessage id="PaymentManagement.Help" />} id="paymentManagement" error={this.state.error} block={this.state.block}>
                <Row>
                    <Col className="col-6">
                        <hr className="m-0" />
                        <Row className={this.state.paymentMode === paymentGatewaysCodes[0] ? 'bg-light py-3' : 'bg-white py-3'}>
                            <Col>
                                <a onClick={() => this.setState({ paymentMode: paymentGatewaysCodes[0] })} style={{ cursor: 'pointer' }}><img height="40" src={stripeLogo}></img></a>
                            </Col>
                            <Col className="text-right">
                                <RenderPaymentStatus channel={paymentGatewaysCodes[0]} paymentGateways={this.state.paymentGateways} />
                            </Col>
                        </Row>
                        <hr className="m-0" />
                        {/*<Row className={this.state.paymentMode === 'Altice' ? 'bg-light py-3' : 'bg-white py-3'}>
                            <Col className="mx-1">
                                <a onClick={() => this.setState({ paymentMode: 'Altice' })} style={{ cursor: 'pointer' }}><img height="25" className="pr-1" src={alticeLogo}></img></a>
                            </Col>
                                <Col className="text-right">
                                <RenderPaymentStatus channel="Altive" paymentGateways={this.state.paymentGateways} />
                            </Col>
                        </Row>
                        <hr className="m-0" />
                        */}
                        {/*<Row className={this.state.paymentMode === paymentGatewaysCodes[2] ? 'bg-light py-3' : 'bg-white py-3'}>
                            <Col className="mx-1">
                                <a onClick={() => this.setState({ paymentMode: paymentGatewaysCodes[2] })} style={{ cursor: 'pointer' }}>
                                    <img height="45" className="pr-1" src={redunicreLogo} />
                                </a>
                            </Col>
                            <Col className="text-right">
                                <RenderPaymentStatus channel={paymentGatewaysCodes[2]} paymentGateways={this.state.paymentGateways} />
                            </Col>
                        </Row>*/}
                        <hr className="m-0" />
                    </Col>
                    <Col className="ml-2">
                        {
                            this.state.paymentMode === paymentGatewaysCodes[0] ?
                                <RenderStripeDetails paymentGateways={this.state.paymentGateways} disconnectStripe={this.disconnectStripe.bind(this)} paymentStatus={this.setPaymentStripePaymentStatus.bind(this)} />
                                :
                                ''
                        }
                        {
                            this.state.paymentMode === 'altice' ?
                                <>
                                </>
                                :
                                ''
                        }
                        {/*
                            this.state.paymentMode === paymentGatewaysCodes[2] ?
                                <form ref={this.unicreForm}>
                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <h5>
                                                        <FormattedMessage id="PaymentManagement.UnicreSettings" />
                                                    </h5>
                                                </Col>
                                            </Row>
                                            <hr className="my-1" />
                                            <FormGroup row>
                                                <Label sm={2}>
                                                    <FormattedMessage id="PaymentManagement.EntityId" />
                                                </Label>
                                                <Col sm={10}>
                                                    <Input type="text" maxLength="50" name="userId" id="userId" value={unicre.userId ? unicre.userId : ''} onChange={this.handleUnicreData} required />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label sm={2}>
                                                    <FormattedMessage id="PaymentManagement.AuthToken" />
                                                </Label>
                                                <Col sm={10}>
                                                    <Input type="text" maxLength="100" name="authToken" id="authToken" value={unicre.authToken ? unicre.authToken : ''} onChange={this.handleUnicreData} required />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label sm={2}>
                                                    <FormattedMessage id="PaymentManagement.SecretId" />
                                                </Label>
                                                <Col sm={10}>
                                                    <Input type="text" maxLength="100" name="secretId" id="secretId" value={unicre.secretId ? unicre.secretId : ''} onChange={this.handleUnicreData} />
                                                </Col>
                                            </FormGroup>
                                            <Row>
                                                <Col>
                                                    <h5>
                                                        <FormattedMessage id="PaymentManagement.UnicreValidPaymentModes" />
                                                    </h5>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-4">
                                                    <FormGroup row>
                                                        <Label sm={8}>
                                                            <FormattedMessage id="PaymentManagement.CreditCard" />
                                                        </Label>
                                                        <Col sm={4}>
                                                            <CustomInput type="switch" id="creditcard" checked={unicre.validPaymentModes.find(pm => pm === unicreValidPaymentModes[0])} onChange={this.updateUnicreValidPaymentModes.bind(this, unicreValidPaymentModes[0])} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-4">
                                                    <FormGroup row>
                                                        <Label sm={8}>
                                                            <FormattedMessage id="PaymentManagement.MbWay" />
                                                        </Label>
                                                        <Col sm={4}>
                                                            <CustomInput type="switch" id="mbway" checked={unicre.validPaymentModes.find(pm => pm === unicreValidPaymentModes[2])} onChange={this.updateUnicreValidPaymentModes.bind(this, unicreValidPaymentModes[2])} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                {
                                                    //<Col>
                                                    //    <FormGroup row>
                                                    //        <Label sm={8}>
                                                    //            <FormattedMessage id="PaymentManagement.Mb" />
                                                    //        </Label>
                                                    //        <Col sm={4}>
                                                    //            <CustomInput type="switch" id="mb" checked={unicre.validPaymentModes.find(pm => pm === unicreValidPaymentModes[1])} onChange={this.updateUnicreValidPaymentModes.bind(this, unicreValidPaymentModes[1])} />
                                                    //        </Col>
                                                    //    </FormGroup>
                                                    //</Col>
                                                }
                                            </Row>
                                            <Row className="mb-2">
                                                <Col className="d-flex">
                                                    <Authorization
                                                        perform="paymentManagement:save"
                                                        yes={() => (
                                                            <Button className="btn btn-host btn-sm mr-2" onClick={() => this.saveUnicreData(unicre)}>
                                                                <i className="fas fa-save" />
                                                            </Button>
                                                        )}
                                                        no={() => <div></div>}
                                                    />
                                                    <Authorization
                                                        perform="paymentManagement:disable"
                                                        yes={() => (
                                                            <Button className="btn btn-host btn-sm" onClick={this.setPaymentStripePaymentStatus.bind(this, !unicre.active, paymentGatewaysCodes[2])} disabled={unicre.userId === undefined || unicre.authToken === undefined || unicre.code === undefined}>
                                                                <i className="fas fa-power-off" />
                                                            </Button>
                                                        )}
                                                        no={() => <div></div>}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </form>
                                :
                                ''
                        */}
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(PaymentManagement);