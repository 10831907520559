import React, { Component } from 'react';
import { Row, Col, Badge, Button } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { getLicenseModules } from "../../Base/Common/ReferenceDataFunctions";
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import { CustomTable } from '../../Base/Common/CustomTable';
import { FormattedMessage, injectIntl } from "react-intl";
import { LicenseDetails } from "./LicenseDetails";

export class Licenses extends Component {
    static displayName = Licenses.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            modal: false,
            licenses: [],
            selectedLicense: null
        }
    }


    componentDidMount() {
        this.getLicenses();
    }

    getLicenses = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                 this.setState({ licenses: data.response, block: false });
            }
        }, `/api/Voucher/Hotel/v1/License`);
    }

    setComboStatus = (evt, inputValue) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value);
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    }

    handleSelect(combo) {
        this.setState({
            selectedRule: combo ? combo.value : null
        })
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedLicense: null,
        }));
    }

    updateTable = (license) => {
        this.setState({ block: true });
        const index = this.state.licenses && this.state.licenses.indexOf(this.state.licenses.find(l => l.id === license.id));
        const licenses = [...this.state.licenses];

        if (index !== -1) {
            licenses[index] = license;
        }
        else {
            licenses.push(license);
        }

        this.setState({ block: false, licenses });
    }

    render() {
        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "Licenses.Name" }),
                sort: true
            },
            {
                dataField: 'modules',
                text: this.props.intl.formatMessage({ id: "Licenses.Modules" }),
                sort: true,
                formatter: (cell, row) => cell ? cell.map(m => { const module = getLicenseModules().find(lm => lm.value === m); return module ? module.label : ''}) : ''
            },
            {
                dataField: 'transactions',
                text: this.props.intl.formatMessage({ id: "Licenses.Transactions" }),
                sort: true
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "Licenses.Status" }),
                sort: true,
                formatter: (cell, row) => cell ? <Badge color="primary"> <FormattedMessage id="generic.active" /> </Badge> : <Badge color="danger"> <FormattedMessage id="generic.inactive" /> </Badge> 
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState(prevState => ({
                    modal: !prevState.modal,
                    selectedLicense: row,
                }));
            }
        };

        const help = <div> </div>
        return (
            <StyledCard icon={'fas fa-file-alt'} title={'Licenses.Title'} help={help} error={this.state.error} block={this.state.block}>
                <Row className="mb-3">
                    <Col className="text-right">
                        <Button className="btn btn-host btn-sm float-right mx-1" onClick={this.toggleModal}><i className="fas fa-plus"></i></Button>
                    </Col>
                </Row>

                <CustomTable
                    data={this.state.licenses ? this.state.licenses : []}
                    columns={columns}
                    shadow={false}
                    showTotal={true}
                    remote={false}
                    selectRow={selectRow}
                />

                {this.state.modal ?
                    <LicenseDetails
                        modal={this.state.modal}
                        updateTable={this.updateTable}
                        toggle={this.toggleModal}
                        license={this.state.selectedLicense}
                    />
                : ''}
            </StyledCard>
        );
    }
}

export default injectIntl(Licenses);