import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Row, Col, FormGroup, Label, Input, Button, Form, CustomInput } from 'reactstrap';
import { handleNotification } from "../../Base/Common/Notification";
import { ErrorAlert } from "../../Base/ErrorAlert";
import { postAPI } from "../../Base/API";
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import Authorization from '../../Base/Authorization';

export class HotelConfigDetails extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            hotelConfig: this.props.selectedHotelConfig ? this.props.selectedHotelConfig : {},
        };
    }

    componentDidMount() {
        const { hotelConfig } = this.state;
        if (hotelConfig.hotelConfig && hotelConfig.hotelConfig.automaticVoucherIssue === null) {
            hotelConfig.hotelConfig.automaticVoucherIssue = false;
        }
        if (hotelConfig.hotelConfig && hotelConfig.hotelConfig.registerSalesInPms === null) {
            hotelConfig.hotelConfig.registerSalesInPms = false;
        }
        this.setState({ hotelConfig });
    }

    handleSwitch = (evt) => {
        const { name, checked } = evt.target;

        this.setState({
            hotelConfig: {
                ...this.state.hotelConfig,
                hotelConfig: {
                    ...this.state.hotelConfig.hotelConfig,
                    [name]: checked
                }
            }
        });
    };
    
    saveConfig = (e) => {
        e.preventDefault();

        this.setState({ block: true });
        var body = { ...this.state.hotelConfig.hotelConfig };
        body.hotelId = this.state.hotelConfig.hotelId;

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(({ block: false, error: errorMessage }));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification( data, <FormattedMessage id="HotelConfigDetails.ConfigSavedSuccessfully" />, <FormattedMessage id="generic.success" />  );

                    this.props.updateTable(data.response[0]);
                    this.setState(({ block: false }));
                    this.props.toggle();
                }
                this.setState(({ block: false }));
            }
            this.setState(({ block: false }));
        }, '/api/Voucher/Hotel/v1/HotelConfig', JSON.stringify({ request: body }));
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-md">
                <ModalHeader toggle={this.props.toggle}>
                    <FormattedMessage id="HotelConfigDetails.EditConfig" />
                </ModalHeader>

                <ModalBody className="p-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={this.saveConfig} innerRef={this.form}>
                            <Card className="shadow border-0 p-0">

                                <CardBody className="m-0">
                                    <Row className="bg-white mb-2">
                                        <Col className="text-right">
                                            <Authorization
                                                perform="hotelConfig:save"
                                                yes={() => (
                                                    <Button type="submit" className="btn btn-sm btn-host float-right">
                                                        <i className="fas fa-save" />
                                                    </Button>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label for="hotelId" sm={4}> <FormattedMessage id="HotelConfig.HotelId" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="text"
                                                        name="hotelId"
                                                        className="text-sm"
                                                        value={this.state.hotelConfig ? this.state.hotelConfig.hotelId : ''}
                                                        readOnly
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label for="name1" sm={4}> <FormattedMessage id="HotelConfig.HotelName" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="text"
                                                        name="name1"
                                                        className="text-sm"
                                                        value={this.state.hotelConfig ? this.state.hotelConfig.name2 : ''}
                                                        readOnly
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        {/*<Col>
                                            <FormGroup sm="true" row>
                                                <Label for="registerSalesInPms" sm={10}> <FormattedMessage id="HotelConfig.AutomaticVoucherIssue" /> </Label>
                                                <Col sm={2} className="d-flex align-items-center justify-content-end">
                                                    <CustomInput
                                                        type="switch"
                                                        name="automaticVoucherIssue"
                                                        id="automaticVoucherIssue"
                                                        placeholder={''}
                                                        checked={this.state.hotelConfig.hotelConfig && this.state.hotelConfig.hotelConfig.automaticVoucherIssue}
                                                        onChange={this.handleSwitch.bind(this)}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>*/}
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label for="registerSalesInPms" sm={10}> <FormattedMessage id="HotelConfig.RegisterSalesInPms" /> </Label>
                                                <Col sm={2} className="d-flex align-items-center justify-content-end">
                                                    <CustomInput
                                                        type="switch"
                                                        name="registerSalesInPms"
                                                        id="registerSalesInPms"
                                                        placeholder={''}
                                                        checked={this.state.hotelConfig.hotelConfig && this.state.hotelConfig.hotelConfig.registerSalesInPms}
                                                        onChange={this.handleSwitch.bind(this)}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}
