import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row, Button } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { getPaymentType } from "../../Base/Common/ReferenceDataFunctions";
import { StyledCard } from "../../Base/Common/CommonUIComponents";
import { HotelLicenseDetails } from './HotelLicensesDetails';
import { CustomTable } from '../../Base/Common/CustomTable';
import CustomSelect from '../../Base/Common/CustomSelect';

class HotelLicenses extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            hotelList: [],
            licenses: [],
            licenseId: null,
            isLocalPms: null,
            modal: false,
            selectedHotel: null
        }
    }

    componentDidMount() {
        this.getHotels();
        this.getLicenses();
    }

    handleFieldChange = (name, combo) => {
        const value = combo ? combo.value : null;
        this.setState(({ [name]: value }));
    }

    updateTable = (license, paymentType, hotelId) => {
        const idx = this.state.hotelList && this.state.hotelList.indexOf(this.state.hotelList.find(hl => hl.hotelId == hotelId));
        const { hotelList } = this.state;
        hotelList[idx].paymentType = paymentType;
        hotelList[idx].license = license;
        this.setState({ hotelList });
    }

    toggleModal = () => {
        this.setState(prevstate => ({
            modal: !prevstate.modal,
            selectedHotel: null
        }));
    }
    
    getLicenses = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                var list = [];

                data.response && data.response.map(license => {
                    list.push({ value: license.id, label: license.name })
                })
                this.setState({ licenses: list, block: false });
            }
        }, `/api/Voucher/Hotel/v1/License`);
    }

    getHotels = () => {
        this.setState({ block: true });
        var params = '';
        params += this.state.licenseId ? `?licenseId=${this.state.licenseId}` : '';

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ hotelList: data.response, block: false });
            }
        }, '/api/Voucher/Hotel/v1/AllHotel' + params);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    render() {
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row) => {
                this.setState({ modal: true, selectedHotel: row })
            }
        };
               
        const columns = [
            {
                dataField: 'hotelId',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.HotelId" }),
                sort: true
            },
            {
                dataField: 'hotelGroupName',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.HotelGroup" }),
                sort: true
            },
            {
                dataField: 'name2',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.HotelName" }),
                sort: true
            }, 
            {
                dataField: 'license.name',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.License" }),
                sort: true
            },
            {
                dataField: 'paymentType',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.PaymentType" }),
                sort: true,
                formatter: (cell, row) => getPaymentType().find(el => el.value === cell) ? getPaymentType().find(el => el.value === cell).label : '' 
            }
        ];

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-hotel'} title={"HotelLicenses.Title"}>
                <Row className="mb-3">
                    <Col className="col-3">
                        <CustomSelect
                            options={this.state.licenses}
                            isClearable
                            placeholder={<FormattedMessage id="AdminHotelList.License" />}
                            onChange={this.handleSelect.bind(this, 'licenseId')}
                        />

                    </Col>
                    <Col className="text-right">
                        <Button className="btn-sm btn-host ml-2" onClick={this.getHotels}><i className="fas fa-search"></i></Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="h-100">
                        <CustomTable
                            data={this.state.hotelList ? this.state.hotelList : []}
                            columns={columns}
                            shadow={false}
                            exportCSV={false}
                            showTotal={true}
                            selectRow={selectRow}
                            search={false}
                            remote={false}
                            keyField={'hotelId'}
                        />
                    </Col>
                </Row>

                {this.state.modal ?
                    <HotelLicenseDetails
                        modal={this.state.modal}
                        toggle={this.toggleModal}
                        selectedHotel={this.state.selectedHotel}
                        updateTable={this.updateTable}
                    />
                : ''}
            </StyledCard>
        );
    }

};
export default injectIntl(HotelLicenses);