import React from 'react';
import { withRouter } from 'react-router-dom';
import { authContext } from './AdalConfig';

class GoogleAnalytics extends React.Component {
    componentWillUpdate({ location, history }) {
        const gtag = window.gtag;
        if (location.pathname === this.props.location.pathname) {
            return;
        }

        if (history.action === 'PUSH' && typeof (gtag) === 'function' && window.location.hostname !== 'localhost' && !window.location.origin.includes('.dev')) {
            const userId = authContext.getCachedUser() && authContext.getCachedUser().profile && authContext.getCachedUser().profile.oid;

            gtag('config', 'G-Y3E6Y558Y9', { 'user_id': userId, 'userId': userId });

            gtag('send', 'page_view', {
                'page_title': document.title,
                'page_location': window.location.href,
                'page_path': location.pathname
            });
        }
    }

    render() {
        return null;
    }
}

export default withRouter(GoogleAnalytics);