import React, { Component } from 'react';
import { Button, Col, CustomInput, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { postAPI, getAPI } from '../../Base/API';
import { StyledCard, BlankCard, ActiveInactiveStatusBadge } from '../../Base/Common/CommonUIComponents';
import { CustomTable } from '../../Base/Common/CustomTable';
import { handleNotification } from "../../Base/Common/Notification";
import { FormattedMessage, injectIntl } from 'react-intl';
import { getCodeGenerationTypes, getHotelGroupModules } from '../../Base/Common/ReferenceDataFunctions';
import CustomSelect from '../../Base/Common/CustomSelect';

class AdminHotelGroupList extends Component {
    static displayName = AdminHotelGroupList.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.form = React.createRef();
        this.state = {
            block: true,
            hoteList: [],
            currentPage: 0,
            PageSize: 10,
            selectedHotel: {
                active: false,
                modules: []
            }
        }
    }

    componentDidMount() {
        this.getHotels();
    }

    save(e) {
        e.preventDefault();

        if (this.form.current.reportValidity()) {
            this.setState({ block: true });

            let req = {
                "request": {
                    "name": this.state.selectedHotel.name,
                    "hotelGroupId": this.state.selectedHotel.hotelGroupId,
                    "modules": this.state.selectedHotel.modules,
                    "active": this.state.selectedHotel.active,
                    "codeGenerationType": this.state.selectedHotel.codeGenerationType
                }
            }

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="AdminHotelGroupList.HotelGroupUpdated" />, <FormattedMessage id="AdminHotelGroupList.Success" />);
                    this.setState({ modal: false }, this.getHotels);
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, '/api/Voucher/Hotel/v1/HotelGroup', req);
        }
    }

    getHotels() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                data.response.forEach(resp => {
                    if (resp.modules == null) {
                        resp.modules = [];
                    }
                });
                this.setState({ hoteList: data.response, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, '/api/Voucher/Hotel/v1/HotelGroup');
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    updateForm(f, evt) {

        let name = evt.target.name;
        let value = evt.target.value;

        this.setState(prevState => ({
            ...prevState,
            [f]: {
                ...prevState[f],
                [name]: value

            },
        }));
    }

    handleModules = (combo, evt) => {
        if (evt) {
            const { selectedHotel } = this.state;
            selectedHotel.modules = combo !== null ? combo.map(mdl => mdl.value) : [];
            this.setState({ selectedHotel });
        }
    }

    handleActive = (evt) => {
        if (evt && evt.target) {
            const { selectedHotel } = this.state;
            const { checked } = evt.target;
            selectedHotel.active = checked;

            this.setState({ selectedHotel });
        }
    }

    handleCodeGenerationType = (combo, evt) => {
        if (evt) {
            const { selectedHotel } = this.state;
            selectedHotel.codeGenerationType = combo && combo.value;
            this.setState({ selectedHotel });
        }
    }
 
    render() {
        const columns = [
            {
                dataField: 'hotelGroupId',
                text: <FormattedMessage id="AdminHotelGroupList.HotelId" />,
                sort: true
            },
            {
                dataField: 'name',
                text: <FormattedMessage id="AdminHotelGroupList.GroupName" />,
                sort: true
            },
            {
                dataField: 'active',
                text: <FormattedMessage id="AdminHotelGroupList.Active" />,
                sort: true,
                formatter: cell => <ActiveInactiveStatusBadge status={cell} />
            },
            {
                dataField: 'modules',
                text: <FormattedMessage id="AdminHotelGroupList.Modules" />,
                formatter: (cell) => cell.map(mdl => {
                    const module = getHotelGroupModules(this.props.intl).find(lm => lm.value === mdl);
                    return module ? module.label : ''
                }).join(', ')
            },
            {
                dataField: 'codeGenerationType',
                text: <FormattedMessage id="AdminHotelGroupList.VoucherCode" />,
                formatter: (cell) => {
                    const codeGenerationType = getCodeGenerationTypes(this.props.intl).find(cgt => cgt.value === cell);
                    return codeGenerationType ? codeGenerationType.label : ''
                }
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ modal: true, selectedHotel: { ...row } })
            }
        };

        const help = <div> </div>

        return (
            <StyledCard icon={'fas fa-gift'} title={'AdminHotelGroupList.HotelGroup'} help={help} error={this.state.error} block={this.state.block}>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-xl" >
                    <ModalHeader toggle={this.toggleModal}>
                        <FormattedMessage id="AdminHotelGroupList.HotelGroup" />
                    </ModalHeader>
                    <ModalBody className="modal-xl p-0">
                        <BlankCard block={this.state.block}>
                            <form ref={this.form}>
                                <Row className="mb-2">
                                    <Col className="text-right">
                                        <Button className="btn-sm btn-host" onClick={this.save.bind(this)}> <i class="fas fa-save" /></Button>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-2"><FormattedMessage id="AdminHotelGroupList.Name" /></Col>
                                    <Col className="col-4">
                                        <FormattedMessage id="AdminHotelGroupList.HotelGroupName" >
                                        {
                                            placeholder => <Input minLength="3" maxLength="100" placeholder={placeholder} value={this.state.selectedHotel.name} onChange={this.updateForm.bind(this, 'selectedHotel')} name="name" required></Input>
                                        }
                                        </FormattedMessage>
                                    </Col>
                                    <Col className="col-2"><FormattedMessage id="AdminHotelGroupList.GroupId" /></Col>
                                    <Col className="col-4">
                                        <FormattedMessage id="AdminHotelGroupList.GroupId" >
                                            {
                                                placeholder => <Input pattern="^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$" name="hotelGroupId" value={this.state.selectedHotel.hotelGroupId} disabled={true} placeholder={placeholder} required></Input>
                                            }
                                        </FormattedMessage>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-2">
                                        <FormattedMessage id="AdminHotelGroupList.Modules" />
                                    </Col>
                                    <Col className="col-4">
                                        <CustomSelect
                                            options={getHotelGroupModules(this.props.intl)}
                                            onChange={this.handleModules}
                                            value={getHotelGroupModules(this.props.intl).filter(opt => this.state.selectedHotel.modules.some(m => opt.value === m))}
                                            placeholder={<FormattedMessage id="AdminHotelGroupList.Modules" />}
                                            isClearable
                                            isMulti
                                        />
                                    </Col>
                                    <Col className="col-2">
                                        <FormattedMessage id="AdminHotelGroupList.Active" />
                                    </Col>
                                    <Col className="col-4">
                                        <CustomInput type="switch" checked={this.state.selectedHotel.active} name="active" id="active" onChange={this.handleActive} />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-2">
                                        <FormattedMessage id="AdminHotelGroupList.VoucherCode" />
                                    </Col>
                                    <Col className="col-4">
                                        <CustomSelect
                                            options={getCodeGenerationTypes(this.props.intl)}
                                            onChange={this.handleCodeGenerationType}
                                            value={getCodeGenerationTypes(this.props.intl).filter(opt => this.state.selectedHotel.codeGenerationType === opt.value)}
                                            placeholder={<FormattedMessage id="AdminHotelGroupList.VoucherCode" />}
                                            required
                                        />
                                    </Col>
                                </Row>
                            </form>
                        </BlankCard>
                    </ModalBody>
                </Modal>

                <Row className="mb-2">
                    <Col className="text-right">
                        <Button className="btn-sm btn-host" onClick={this.toggleModal}><i className="fas fa-plus"></i></Button>
                    </Col>
                </Row>
                <CustomTable
                    keyField="hotelGroupId"
                    data={this.state.hoteList ? this.state.hoteList : []}
                    columns={columns}
                    shadow={false}
                    exportCSV={false}
                    showTotal={true}
                    remote={false}
                    selectRow={selectRow}
                />
            </StyledCard>


        );
    }
}
export default injectIntl(AdminHotelGroupList);