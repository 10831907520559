import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row } from 'reactstrap';
import { getAPI, postAPI } from "../../Base/API";
import { StyledCard } from "../../Base/Common/CommonUIComponents";
import { CustomTable } from '../../Base/Common/CustomTable';
import { FormattedMessage } from 'react-intl';


class ManageScheduleTask extends Component {

    state = {
        block: true,
        scheduleTaskList: [],
        selectedTask: null
    }

    componentDidMount() {
        this.getScheduleTasks();

    }

    getScheduleTasks() {
        this.setState({ block: false });


        var param = '';


        if (this.state.stState)
            param += '?state=' + this.state.stState


        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ scheduleTaskList: data, block: false });
            }
        }, '/api/VoucherScheduletask/ScheduleTasks/' + param);
    }

    changeJobState(action) {
        this.setState({ block: false });

        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ teste: data, block: false }, () => this.getScheduleTasks());
            }
        }, '/api/VoucherScheduletask/ScheduleTasks/' + action + '?jobName=' + this.state.selectedTask.id);
    }

    setFilterSelectedChannel = (name, combo) => {
        var label = combo ? combo.label : null
        var val = combo ? combo.value : null
        this.setState({
            channelCode: label,
            channelConfigId: val
        }
        );
    }

    render() {

        const helpContent =
            <div>
                TODO
                <hr />
            </div>


        const columns = [
            {
                dataField: 'id',
                text: <FormattedMessage id="ManageScheduleTask.Name" />,
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            }, {
                dataField: 'state',
                text: <FormattedMessage id="ManageScheduleTask.State" />,
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            }, {
                dataField: 'lastFireTime',
                text: <FormattedMessage id="ManageScheduleTask.LastFireTime" />,
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            }, {
                dataField: 'nextFireTime',
                text: <FormattedMessage id="ManageScheduleTask.NextFireTime" />,
                sort: true,
                headerStyle: () => {
                    return { width: "10%" };
                }
            },

            {
                dataField: 'priority',
                text: <FormattedMessage id="ManageScheduleTask.Priority" />,
                sort: true

            },
            {
                dataField: 'cronExpression',
                text: <FormattedMessage id="ManageScheduleTask.CronExpression" />,
                sort: true,

            },

            {
                dataField: 'processingInfo',
                text: <FormattedMessage id="ManageScheduleTask.ProcessingInfo" />,
                sort: true,
            }];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ selectedTask: row })
            }
        };


        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-list'} title={'ManageScheduleTask.title'} help={helpContent} error={this.state.error}>
                <Row>
                    <Col className="mb-1">
                        <Button className="btn btn-sm btn-host float-right mr-1" onClick={this.getScheduleTasks.bind(this)}><i className="fas fa-sync"></i>  </Button>
                        <Button className="btn btn-sm btn-host float-right mr-1" disabled={this.state.selectedTask === null ? true : false} ><i className="fas fa-clock"></i>  </Button>
                        <Button className="btn btn-sm btn-host float-right mr-1" disabled={this.state.selectedTask === null || this.state.selectedTask.state !== 'Paused' ? true : false} onClick={this.changeJobState.bind(this, 'ResumeJob')} ><i className="fas fa-play"></i>  </Button>
                        <Button className="btn btn-sm btn-host float-right mr-1" disabled={this.state.selectedTask === null || this.state.selectedTask.state === 'Paused' ? true : false} onClick={this.changeJobState.bind(this, 'PauseJob')}><i className="fas fa-pause"></i>  </Button>
                        <Button className="btn btn-sm btn-host float-right mr-1" disabled={this.state.selectedTask === null || this.state.selectedTask.state === 'Paused' ? true : false} onClick={this.changeJobState.bind(this, 'InterruptJob')}><i className="fas fa-stop"></i>  </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="h-100">
                        <Card className="border-0">

                            <CustomTable
                                data={this.state.scheduleTaskList ? this.state.scheduleTaskList : []}
                                columns={columns}
                                page={this.state.currentPage}
                                sizePerPage={this.state.resultsPerPage}
                                totalSize={this.state.systemEventsList && this.state.scheduleTaskList.length}
                                shadow={false}
                                search={false}
                                selectRow={selectRow}
                                remote={false}
                            />



                        </Card>

                    </Col>

                </Row>


            </StyledCard>


        );
    }

};
export default injectIntl(ManageScheduleTask)