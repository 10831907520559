import React, { Component } from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Form } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { postAPI } from '../Base/API';
import { handleNotification } from '../Base/Common/Notification';
import BlockUi from 'react-block-ui';

class OnlinePaymentModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: this.props.name,
            email: this.props.email
        };
    }
    

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState({ [name]: value });
    }

    saveData = (e) => {
        e.preventDefault();      
        this.setState({ block: true });
        const { name, email } = this.state;  

        const body = { name, email };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    this.props.updateOnlinePayment(name, email);
                    handleNotification(data, <FormattedMessage id="OnlineSalesDetail.DataSaved" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/Voucher/Voucher/v1/onlinePayment/${this.props.onlinePaymentId}`, body);
    }

    render() {
        const { block, name, email } = this.state;
        const { isOpen, toggle } = this.props;

        return (
            <Modal isOpen={isOpen} toggle={toggle} size="md">
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="OnlineSalesDetail.EditData" />
                </ModalHeader>
                <ModalBody>
                    <BlockUi tag="div" blocking={block}>
                        <Form onSubmit={this.saveData}>
                            <Row className="mb-3">
                                <Col className="text-right">
                                    <Button className="btn-host btn-sm" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="OnlineSalesDetail.Name" />
                                    </Label>
                                </Col>
                                <Col className="col-10">
                                    <Input
                                        type="text"
                                        required
                                        value={name || ''}
                                        name="name"
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-2">
                                    <Label>
                                        <FormattedMessage id="OnlineSalesDetail.Email" />
                                    </Label>
                                </Col>
                                <Col className="col-10">
                                    <Input
                                        type="text"
                                        required
                                        value={email || ''}
                                        name="email"
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}

export default OnlinePaymentModal;