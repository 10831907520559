import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Navbar, NavbarBrand, NavbarToggler, UncontrolledDropdown } from 'reactstrap';
import CustomSelect from './Base/Common/CustomSelect';
import { authContext } from '../Utils/AdalConfig';
import { getAPI, postAPI } from "./Base/API";
import './NavMenu.css';
import LanguageSwitch from './Profile/LanguageSwitch';
import Authorization, { CheckAuthorization } from './Base/Authorization';
import logo from '../img/HeyCard.png';
import 'moment/locale/es-us';
import 'moment/locale/pt';


export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            property: '',
            block: false,
            events: [],
            selectedProperty: authContext.getCachedUser().profile.city,
            notification: [{ text: 'Channel Booking with Mapping issues' }, { text: 'Syncronization errors in channel booking' }],
            allHotels: []
        };
    }

    componentDidMount() {
        if (CheckAuthorization("allProperties:changeProperty", null)) {
            this.getAllHotels();
        }       
    }

    getAllHotels = () => {
        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                var list = [];
                if (data.response && data.response.length > 0) {
                    data.response.forEach(el => {
                        var property = {
                            value: el.hotelId,
                            label: el.name2
                        }
                        list.push(property);
                    })
                }
                this.setState({ block: false, allHotels: list });
            }
        }, '/api/Voucher/Hotel/v1/AllHotel');
    }

    changeDefaultProperty(evt) {
        let hotelguid = evt && evt.value ? evt.value : evt.target && evt.target.id;

        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ selectedProperty: hotelguid, block: false });

                window.location.reload();
            }
        }, '/api/User/v1/User/' + hotelguid + '/setPreferedHotel');
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    logout() {
        authContext.logOut();

    }

    render() {
        return (
            <Container className="bg-light">
                <Navbar className="navbar-expand-sm navbar-toggleable-sm bg-white  box-shadow shadow sticky-top  max_navbar_height mb-2 font-weight-bold"    >
                    <NavbarBrand><img src={logo} height="55px" alt="" /></NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav ml-auto">
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className="text-secondary">

                                    {authContext.getCachedUser().userName}

                                </DropdownToggle>

                                <DropdownMenu right >
                                    <BlockUi tag="div" blocking={this.state.block}>
                                        <UncontrolledDropdown direction="left"  >
                                            <DropdownToggle tag="a" className="dropdown-item" caret>
                                                <FormattedMessage id="navbar.Property" />
                                            </DropdownToggle>
                                            
                                            <Authorization
                                                perform="allProperties:changeProperty"
                                                yes={() => (
                                                    <DropdownMenu style={{ width: '355px', transform: 'translate3d(-357px, -4px, 0px)', fontWeight: '400' }}>
                                                        <DropdownItem header>
                                                            <FormattedMessage id="navbar.ChangeProperty" />
                                                        </DropdownItem>
                                                        <div className="mx-3">
                                                            <CustomSelect
                                                                isSearchable
                                                                placeholder={""}
                                                                options={this.state.allHotels}
                                                                onChange={(combo) => this.changeDefaultProperty(combo)}
                                                            />
                                                        </div>
                                                    </DropdownMenu>
                                                )}
                                                no={() => 
                                                    <DropdownMenu>
                                                        <DropdownItem header><FormattedMessage id="navbar.ChangeProperty" /></DropdownItem>
                                                        {global.userAllowedProperties.map((item, key) =>
                                                            <DropdownItem key={key} active={item.property === global.defaultProperty ? true : false} id={item.property} onClick={this.changeDefaultProperty.bind(this)}>{item.propertyName}</DropdownItem>
                                                        )}
                                                    </DropdownMenu>
                                                }
                                            />
                                        </UncontrolledDropdown>
                                    </BlockUi>
                                    <LanguageSwitch />
                                    <DropdownItem tag={Link} to="/Profile" >
                                        <FormattedMessage id="navbar.Profile" />
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <div onClick={this.logout}><FormattedMessage id="navbar.Logout" /> </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                       
                            <div className="font_size_xxxs text-secondary position-absolute" style={{ bottom: '1px' }}>
                                {global.propertyName}
                            </div>
                        </ul>
                        <ul className="navbar-nav mx-auto">

                            <UncontrolledDropdown nav inNavbar className="customnav-item">
                                <DropdownToggle nav className="text-secondary " tag={Link} to="/SalesDashboard">
                                    <i className="fas fa-chart-line mr-1"></i> <FormattedMessage id="navbar.Dashboard" />
                                </DropdownToggle>

                            </UncontrolledDropdown>

                            <UncontrolledDropdown nav inNavbar className="customnav-item mr-2">
                                <DropdownToggle nav caret className="text-secondary " >
                                    <i className="fas fa-screwdriver mr-1"></i><FormattedMessage id="navbar.ConfigVoucher" />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag={Link} to="/VoucherManagment" >
                                        <FormattedMessage id="navbar.VoucherType" />
                                    </DropdownItem>
                                    <DropdownItem tag={Link} to="/TermsConditions" >
                                        <FormattedMessage id="navbar.TermsConditions" />
                                    </DropdownItem>

                                    <Authorization
                                        perform="manageUser:view"
                                        yes={() => (
                                            <DropdownItem tag={Link} to="/ManageUser" >
                                                <FormattedMessage id="navbar.ManageUser" />
                                            </DropdownItem>
                                        )}
                                        no={() => <div></div>}
                                    />

                                    <DropdownItem tag={Link} to="/VoucherTemplates" >
                                        <FormattedMessage id="navbar.VoucherTemplates" />
                                    </DropdownItem>
                                    {/*global.license && global.license.modules && global.license.modules.includes('PMSIntegration') ?
                                        <DropdownItem tag={Link} to="/VoucherIssueAutomation" >
                                            <FormattedMessage id="navbar.AutoVoucher" />
                                        </DropdownItem>
                                    : ''*/}
                                    {global.license && global.license.modules && global.license.modules.includes('PMSIntegration') ?
                                        <DropdownItem tag={Link} to="/ReferenceData" >
                                            <FormattedMessage id="navbar.ReferenceData" />
                                        </DropdownItem>
                                    : ''}
                                    <DropdownItem tag={Link} to="/Promotions" >
                                        <FormattedMessage id="navbar.Promotions" />
                                    </DropdownItem>
                                    <DropdownItem tag={Link} to="/LogsDashboard" >
                                        <FormattedMessage id="navbar.LogsDashboard" />
                                    </DropdownItem>

                                    <DropdownItem tag={Link} to="/VoucherTags" >
                                        <FormattedMessage id="navbar.VoucherTags" />
                                    </DropdownItem>
                                    
                                </DropdownMenu>

                            </UncontrolledDropdown>

                            <UncontrolledDropdown nav inNavbar className="customnav-item mr-2">
                                <DropdownToggle nav caret className="text-secondary " >
                                    <i className="fas fa-gift"></i> <FormattedMessage id="navbar.Voucher" />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag={Link} to="/OperationManagement" >
                                        <FormattedMessage id="navbar.AllVouchers" />
                                    </DropdownItem>
                                    <DropdownItem tag={Link} to="/Shopping" >
                                        <FormattedMessage id="navbar.Shopping" />
                                    </DropdownItem>
                                    <DropdownItem tag={Link} to="/OnlineSales" >
                                        <FormattedMessage id="navbar.OnlineSales" />
                                    </DropdownItem>
                                    {/*global.license && global.license.modules && global.license.modules.includes('PMSIntegration') ?
                                        <DropdownItem tag={Link} to="/ReservationVouchers" >
                                            <FormattedMessage id="navbar.ReservationVoucher" />
                                        </DropdownItem>
                                    : ''*/}
                                    <DropdownItem tag={Link} to="/UseVoucher" >
                                        <FormattedMessage id="navbar.UseVoucher" />
                                    </DropdownItem>
                                    <DropdownItem tag={Link} to="/ProfilesDashboard" >
                                        <FormattedMessage id="navbar.Profiles" />
                                    </DropdownItem>
                                </DropdownMenu>

                            </UncontrolledDropdown>
                            
                            <UncontrolledDropdown nav inNavbar className="customnav-item mr-2">
                                <DropdownToggle nav caret className="text-secondary " >
                                    <i className="fas fa-hotel mr-1"></i><FormattedMessage id="navbar.Hotel" />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag={Link} to="/HotelList" >
                                        <FormattedMessage id="navbar.HotelList" />
                                    </DropdownItem>

                                    <Authorization
                                        perform="HotelConfig:view"
                                        yes={() => (
                                            global.license && global.license.modules && global.license.modules.includes('PMSIntegration') ?
                                                <DropdownItem tag={Link} to="/HotelConfig" >
                                                    <FormattedMessage id="navbar.HotelConfig" />
                                                </DropdownItem>
                                                : ''
                                        )}
                                        no={() => <div></div>}
                                    />

                                    {global.license && global.license.modules && global.license.modules.includes('PMSIntegration') ?
                                        <DropdownItem tag={Link} to="/PMSConnectivity" >
                                            <FormattedMessage id="navbar.PMSConnectivity" />
                                        </DropdownItem>
                                        : ''}
                                    <Authorization
                                        perform="paymentDashboard:view"
                                        yes={() => (
                                            <DropdownItem tag={Link} to="/PaymentDashboard" >
                                                <FormattedMessage id="navbar.PaymentDashboard" />
                                            </DropdownItem>
                                        )}
                                        no={() => <div></div>}
                                    />
                                </DropdownMenu>
                            </UncontrolledDropdown>


                            <Authorization
                                perform="paymentManagement:view"
                                yes={() => (
                                    <UncontrolledDropdown nav inNavbar className="customnav-item">
                                        <DropdownToggle nav className="text-secondary " tag={Link} to="/PaymentManagement">
                                            <i className="fas fa-credit-card"></i> <FormattedMessage id="navbar.Payment" />
                                        </DropdownToggle>
                                    </UncontrolledDropdown>
                                )}
                                no={() => <div></div>}
                            />


                            <Authorization
                                perform="admin:view"
                                yes={() => (
                                    <UncontrolledDropdown nav inNavbar className="customnav-item">
                                        <DropdownToggle nav caret className="text-secondary ">
                                            <i className="fas fa-user-tie"></i> <FormattedMessage id="navbar.Admin" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem tag={Link} to="/AdminHotelGroupList" >
                                                <FormattedMessage id="navbar.ManageHotelGroup" />
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/AdminHotelList" >
                                                <FormattedMessage id="navbar.ManageHotel" />
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/PaymentKeyManagement" >
                                                <FormattedMessage id="navbar.HostPaymentGatewayManagement" />
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/ApiGatewayUserManagement" >
                                                <FormattedMessage id="navbar.ApiGatewayUserManagement" />
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/ManageScheduleTask" >
                                                <FormattedMessage id="navbar.ManageScheduleTask" />
                                            </DropdownItem>

                                            <DropdownItem tag={Link} to="/AdminUserManagement" >
                                                <FormattedMessage id="navbar.AdminUserManagement" />
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/Licenses" >
                                                <FormattedMessage id="navbar.Licenses" />
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/HotelLicenses" >
                                                <FormattedMessage id="navbar.HotelLicenses" />
                                            </DropdownItem>
                                            {/*<DropdownItem tag={Link} to="/PriceList" >
                                                <FormattedMessage id="navbar.PriceList" />
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/PaymentPositions" >
                                                <FormattedMessage id="navbar.PaymentPosition" />
                                            </DropdownItem>*/}
                                            <DropdownItem tag={Link} to="/Backends" >
                                                <FormattedMessage id="navbar.Backends" />
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/ResetCache" >
                                                <FormattedMessage id="navbar.ResetCache" />
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/PlatformDeepDive" >
                                                <FormattedMessage id="navbar.PlatformDeepDive" />
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )}
                                no={() => <div></div>}
                            />

                            
                        
                        </ul>

                    </Collapse>


                </Navbar>
            </Container>

        );
    }

}       