import React, { Component } from 'react';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import { FormattedMessage } from 'react-intl';
import { getAPI, putAPI } from '../Base/API';
import { handleNotification } from '../Base/Common/Notification';

export class EditClientModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.saveClient = this.saveClient.bind(this);
        this.associateProfileToVoucher = this.associateProfileToVoucher.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            block: false,
            profile: {}
        };
    }

    componentDidMount() {
        const { profileId } = this.props;
        if (profileId) {
            getAPI(result => {
                const { error, data } = result;
                var errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else if (data.response && data.response.length > 0) {
                        this.setState({ profile: data.response[0] });
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/Voucher/Profile/v1/Profile/${profileId}`);
        }
    }

    saveClient(evt) {
        evt.preventDefault();

        if (this.form.current.reportValidity()) {
            const { voucherId } = this.props;
            const { profile } = this.state;
            const request = {
                request: profile
            };
            this.setState({ block: true });
            putAPI(result => {
                const { error, data } = result;
                var errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="EditClientData.SaveProfileWithSuccess" />, <FormattedMessage id="generic.success" />);
                        if (profile.id !== data.response[0].id) {
                            this.associateProfileToVoucher(data.response[0].id, voucherId);
                        }
                        this.props.updateClientData(data.response[0]);
                        this.setState({ profile: data.response[0], error: errorMessage, block: false });
                    }
                    else {
                        this.setState({ error: errorMessage, block: false });
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/Voucher/Profile/v1/Profile`, request);
        }
    }

    associateProfileToVoucher(profileId, voucherId) {
        putAPI(result => {
            const { error, data } = result;
            var errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.errors && data.errors.length > 0) {
                handleNotification(data);
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/Voucher/Profile/v1/Profile/${profileId}/Voucher/${voucherId}`);
    }

    handleChange(evt) {
        if (evt && evt.target) {
            const { profile } = this.state;
            const { name, value } = evt.target;
            profile[name] = value;
            this.setState({ profile });
        }
    }

    render() {
        const { isOpen, toggle } = this.props;
        const { block, error, profile } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalBody className="modal-xl p-0">
                    <StyledCard title="EditClientData.Title" block={block} error={error} >
                        <form ref={this.form}>
                            <Row className="mb-3">
                                <Col className="text-right">
                                    <Button className="btn-host btn-sm mr-2" onClick={() => this.props.history.push('/Profiles/' + this.props.profileId) } id="profileDetails">
                                        <i className="far fa-address-card" />
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="profileDetails">
                                        <FormattedMessage id="EditClientModal.SeeProfileDetails" />
                                    </UncontrolledTooltip>

                                    <Button className="btn-host btn-sm" onClick={this.saveClient}>
                                        <i className="fa fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}>
                                            <FormattedMessage id="EditClientModal.Name" />
                                        </Label>
                                        <Col sm={8}>
                                            <Input type="text" id="firstName" name="firstName" value={profile.firstName} onChange={this.handleChange} required />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}>
                                            <FormattedMessage id="EditClientModal.LastName" />
                                        </Label>
                                        <Col sm={8}>
                                            <Input type="text" id="lastName" name="lastName" value={profile.lastName} onChange={this.handleChange} required />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}>
                                            <FormattedMessage id="EditClientModal.Email" />
                                        </Label>
                                        <Col sm={8}>
                                            <Input type="text" id="email" name="email" value={profile.email} onChange={this.handleChange} required />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </form>
                    </StyledCard>
                </ModalBody>
            </Modal>    
        );
    }
}