import React, { Component } from 'react';
import { Row, Col, Button, Input, Form } from 'reactstrap';
import { injectIntl, FormattedMessage } from "react-intl";
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Common/Notification';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { SelectHotel, StyledCard } from '../../Base/Common/CommonUIComponents';
import { CustomTable } from '../../Base/Common/CustomTable';


class ReservationVouchers extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            block: false,
            pageSize: 10,
            pageIndex: 0
        };
    }

    getVouchers = () => {
        this.setState({ block: true });

        var params = this.state.voucherNumber ? `&voucherNumber=${this.state.voucherNumber}` : '';
        if (this.state.resNumber) params += `&resNumber=${this.state.resNumber}`;
        if (this.state.startDate) params += `&fromDate=${moment(this.state.startDate).format("YYYY-MM-DD")}`;
        if (this.state.endDate) params += `&toDate=${moment(this.state.endDate).format("YYYY-MM-DD")}`;
        if (this.state.hotelId) params += `&hotelId=${this.state.hotelId}`;


        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ vouchers: data.response, totalItems: data.count, block: false });
            }
        }, `/api/Voucher/VoucherReservation/v1/ReservationVouchers?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}` + params);
    }

    doSearch = (e) => {
        e.preventDefault();

        this.setState({
            pageIndex: 0
        }, this.getVouchers)
    }

    setComboStatus = (evt, combo) => {
        this.setState({
            [evt]: combo ? combo.value : null
        })
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
        }, () => { this.getVouchers() });
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        })
    }
    
    render() {
        const columns = [
            {
                dataField: 'createdAt',
                text: this.props.intl.formatMessage({ id: "ReservationVoucher.CreatedAt" }),
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : ''
            },
            {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "ReservationVoucher.HotelName" }),
                sort: true
            },
            {
                dataField: 'resIdValue',
                text: this.props.intl.formatMessage({ id: "ReservationVoucher.ReservationId" }),
                sort: true
            },
            {
                dataField: 'resIdDate',
                text: this.props.intl.formatMessage({ id: "ReservationVoucher.ReservationDate" }),
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm") : ''
            },
            {
                dataField: 'voucher.voucherId',
                text: this.props.intl.formatMessage({ id: "ReservationVoucher.VoucherId" }),
                sort: true,
                formatter: (cell, row) => cell ? `${cell != '00000000-0000-0000-0000-000000000000' ? cell : ''}` : ''
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                if (row && row.voucher && row.voucher.id)
                    this.props.history.push('/VoucherOperationDetails/' + row.voucher.id)
            }
        };

        return (
            <StyledCard icon={'fas fa-suitcase'} title={'navbar.ReservationVoucher'} error={this.state.error} block={this.state.block}>
                <Form onSubmit={this.doSearch}>
                    <Row className="mb-4">
                        <Col className="col-2 pr-0">
                            <SelectHotel
                                onChangeFunc={this.setComboStatus.bind(this, 'hotelId')}
                                value={this.state.hotelId}
                            />
                        </Col>
                        <Col className="col-3 pr-0">
                            <Input
                                type="text"
                                name="voucherNumber"
                                value={this.state.voucherNumber}
                                onChange={(e) => this.handleChange(e)}
                                placeholder={this.props.intl.formatMessage({ id: "ReservationVoucher.VoucherId" })}
                            />
                        </Col>
                        <Col className="col-2 pr-0">
                            <Input
                                type="text"
                                name="resNumber"
                                value={this.state.resNumber}
                                onChange={(e) => this.handleChange(e)}
                                placeholder={this.props.intl.formatMessage({ id: "ReservationVoucher.ReservationId" })}
                            />
                        </Col>
                        <Col className="col-2">
                            <DateRangePicker
                                startDate={this.state.startDate ? moment(this.state.startDate) : null}
                                startDateId="fromDate"
                                isOutsideRange={day => day > moment()}
                                endDate={this.state.endDate ? moment(this.state.endDate) : null}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn-sm btn-host mb-1" type="submit">
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <CustomTable
                    data={this.state.vouchers || []}
                    columns={columns}
                    shadow={false}
                    showTotal={true}
                    remote={true}
                    selectRow={selectRow}
                    page={this.state.pageIndex + 1}
                    sizePerPage={this.state.pageSize}
                    totalSize={this.state.totalItems}
                    onTableChange={this.handleTableChange}
                />
            </StyledCard>
        );
    }
}

export default injectIntl(ReservationVouchers);