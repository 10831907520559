import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Button, Input } from 'reactstrap';
import { deleteAPI, getAPI, postAPI } from '../Base/API';
import { StyledCard, BlankCard, MultiLangText, getMultiLangEmpty } from '../Base/Common/CommonUIComponents';
import { CustomTable } from '../Base/Common/CustomTable';
import { handleNotification } from "../Base/Common/Notification";
import { FormattedMessage } from 'react-intl';
import Authorization from '../Base/Authorization';
import { processMultilingualTexts } from '../Base/Common/ReferenceDataFunctions';

export class TermsConditions extends Component {
    static displayName = TermsConditions.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.form = React.createRef();
        this.state = {
            block: true,
            termsList: [],
            selectedTerm: this.getNewSelectedTerm()
        }
    }

    getNewSelectedTerm() {
        const selectedTerm = {
            id: null,
            multiLingual: getMultiLangEmpty()
        }
        return selectedTerm;
    }

    componentDidMount() {
        this.getTerms();
    }

    getTerms() {
        this.setState({ blockCfg: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                data.response.forEach(processMultilingualTexts);
                this.setState({ termsList: data.response, block: false });
            }
        }, '/api/Voucher/v1/Config/getTermsAndConditions');
    }

    handleChange(evt) {
        let name = evt.target.name;
        let value = evt.target.value;

        this.setState(prevState => ({
            ...prevState,
            selectedTerm: {
                ...prevState.selectedTerm,
                [name]: value

            },
        }));
    }

    updateTerms(evt, lang) {
        const multiLingual = [...this.state.selectedTerm.multiLingual];
        const idx = multiLingual.indexOf(multiLingual.find(ml => ml.language === lang));
        if (idx >= 0) {
            multiLingual[idx].description = evt.target && evt.target.value;
            this.setState(prevState => ({
                ...prevState,
                selectedTerm: {
                    ...prevState.selectedTerm,
                    multiLingual: multiLingual
                }
            }));
        }
    }

    saveTerms(e) {
        e.preventDefault();

        if (this.form.current.reportValidity()) {
            this.setState({ block: true });
            const temp = this.state.selectedTerm;
            if (temp.id === undefined) {
                temp.id = null;
            }
            const payload = { request: temp };

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data && data.response) {
                    if (data.errors && data.errors.length > 0) {
                        data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                        this.setState(({ block: false, error: errorMessage }));
                        return;
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="TermsConditions.SaveSuccessMessage" />, <FormattedMessage id="TermsConditions.Success" />);
                        const termsList = [...this.state.termsList];
                        const idx = termsList.indexOf(termsList.find(t => t.id === data.response[0].id));
                        if (idx >= 0) {
                            termsList[idx] = data.response[0];
                        }
                        else {
                            data.response[0].multiLingual.forEach(el => {
                                el.language = el.languageCode
                            });

                            termsList.push(data.response[0]);
                        }
                        this.setState({ termsList: [] }, () => this.setState({ termsList }));
                        this.toggleModal();
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, '/api/Voucher/v1/Config/createOrUpdateTermsAndConditions', payload);
        }
    }

    removeTerms(e) {
        e.preventDefault();

        const { selectedTerm } = this.state;
        const termId = selectedTerm.id;

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState(({ block: false, error: errorMessage }));
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="TermsConditions.DeleteSuccessMessage" />, <FormattedMessage id="TermsConditions.Success" />);
                        const termsList = [...this.state.termsList];
                        const idx = termsList.indexOf(termsList.find(t => t.id === termId));
                        if (idx >= 0) {
                            termsList.splice(idx, 1);
                        }
                        this.setState({ block: false, error: errorMessage, termsList: [] }, () => this.setState({ termsList }));
                        this.toggleModal();
                    }
                }
                else {
                    this.setState(({ block: false, error: errorMessage }));
                }
            }
        }, `/api/Voucher/v1/Config/deleteTermsAndConditions/${termId}`);
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedTerm: this.getNewSelectedTerm()
        }));
    }

    render() {
        const columns = [
            {
                dataField: 'code',
                text: <FormattedMessage id="TermsConditions.Code" />,
                sort: true
            },
            {
                dataField: 'description',
                text: <FormattedMessage id="TermsConditions.Description" />,
                sort: true,
                formatter: (cell, row) => <div> {row.multiLingual && row.multiLingual.length > 0 ?  row.multiLingual[0].description : ''}</div>
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ selectedTerm: row, modal : true })
            }
        };

        return (
            <StyledCard icon={'fas fa-gift'} title="TermsConditions.Title" help={<FormattedMessage id="TermsConditions.Help" />} id="terms&conditions" error={this.state.error} block={this.state.block}>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-xl" >
                    <ModalHeader toggle={this.toggleModal}>
                        <FormattedMessage id="TermsConditions.Title" />
                    </ModalHeader>
                    <ModalBody className="modal-xl p-0">
                        <BlankCard block={this.state.block}>
                            <form ref={this.form}>
                                <Row className="mb-2">
                                    <Col className="text-right">
                                        <Authorization
                                            perform="termsConditions:save"
                                            yes={() => (
                                                <span>
                                                    {
                                                        this.state.selectedTerm.id !== null ?
                                                            <Button className="btn-host btn-sm mb-1 mr-1" onClick={this.removeTerms.bind(this)}>
                                                                <i className="fas fa-trash-alt" />
                                                            </Button>
                                                            :
                                                            <span />
                                                    }
                                                    <Button className="btn-host btn-sm mb-1" onClick={this.saveTerms.bind(this)}>
                                                        <i className="fas fa-save" />
                                                    </Button>
                                                </span>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-3"><FormattedMessage id="TermsConditions.Code" /></Col>
                                    <Col>
                                        <FormattedMessage id="TermsConditions.Code" >
                                            {
                                                placeholder => <Input type="text" max="30" placeholder={placeholder} name="code" required value={this.state.selectedTerm.code ? this.state.selectedTerm.code : ''} onChange={(e) => this.handleChange(e)}></Input>
                                            }
                                        </FormattedMessage>
                                    </Col>
                                </Row>
                                {/*
                                <Row className="mb-2">
                                    <Col className="col-3"><FormattedMessage id="TermsConditions.Terms&Conditions" /></Col>
                                    <Col>
                                        <FormattedMessage id="TermsConditions.Terms&Conditions" >
                                            {
                                                placeholder => < Input type="textarea" placeholder={placeholder} max="3500" rows="4" name="description" value={this.state.selectedTerm.description ? this.state.selectedTerm.description : ''} required onChange={(e) => this.handleChange(e)} />
                                            }
                                        </FormattedMessage>
                                    </Col>
                                </Row>
                                */}
                                <Row className="mb-2">
                                    <Col className="col-3"><FormattedMessage id="TermsConditions.Terms&Conditions" /></Col>
                                    <Col className="col-9">
                                        <MultiLangText textList={this.state.selectedTerm.multiLingual} udpateTerms={this.updateTerms.bind(this)} />
                                    </Col>
                                </Row>
                            </form>
                        </BlankCard>
                    </ModalBody>
                </Modal>

                <Row>
                    <Col className="text-right">
                        <Authorization
                            perform="termsConditions:add"
                            yes={() => (
                                <Button className="btn-host btn-sm mb-1" onClick={this.toggleModal}>
                                    <i className="fas fa-plus" />
                                </Button>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>
                <CustomTable
                    data={this.state.termsList}
                    columns={columns}
                    shadow={false}
                    exportCSV={false}
                    showTotal={true}
                    remote={true}
                    selectRow={selectRow}
                />
            </StyledCard>
        );
    }
}
