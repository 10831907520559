import moment from "moment";
import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { Line } from 'react-chartjs-2';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { getAPI } from "./Base/API";
import { SelectHotel, StyledCard } from './Base/Common/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';

export class Home extends Component {
  static displayName = Home.name;
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            dashboardinfo: [],
            startDate: moment().subtract(1, 'month'),
            endDate : moment()
        }
    }

    componentDidMount() {
        this.getDashboardInfo();

    }

    getDashboardInfo() {
        this.setState({ block: true });
        let param = '?fromDate=' + this.state.startDate.format('YYYY-MM-DD') + '&toDate=' + this.state.endDate.format('YYYY-MM-DD');
        

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                let arrDates = [];

                let arrCashQtdSold = [];
                let arrCashQtdRedeem = [];
                let arrCashQtdCanceled = [];
                let arrCashQtdRefund = [];
                let arrCashQtdPartialRefund = [];
                let arrCashQtdExpired = [];

                let arrCashAmountSold = [];
                let arrCashAmountRedeem = [];
                let arrCashAmountCanceled = [];
                let arrCashAmountExpired = [];
                let arrCashAmountRefund = [];
                let arrCashAmountPartialRefund = [];

                let arrServiceQtdSold = [];
                let arrServiceQtdRedeem = [];
                let arrServiceQtdCanceled = [];
                let arrServiceQtdExpired = [];
                let arrServiceQtdRefund = [];
                let arrServiceQtdPartialRefund= [];

                let arrServiceAmountSold = [];
                let arrServiceAmountRedeem = [];
                let arrServiceAmountCanceled = [];
                let arrServiceAmountExpired = [];
                let arrServiceAmountRefund = [];
                let arrServiceAmountPartialRefund = [];

                let CashQtdSold = 0;
                let CashQtdRedeem = 0;
                let CashQtdCanceled = 0;
                let CashQtdExpired = 0;
                let CashQtdRefund = 0;
                let CashQtdPartialRefund = 0;

                let CashAmountSold = 0;
                let CashAmountRedeem = 0;
                let CashAmountCanceled = 0;
                let CashAmountExpired = 0;
                let CashAmountRefund = 0;
                let CashAmountPartialRefund= 0;

                let ServiceQtdSold = 0;
                let ServiceQtdRedeem = 0;
                let ServiceQtdCanceled = 0;
                let ServiceQtdExpired = 0;
                let ServiceQtdRefund = 0;
                let ServiceQtdPartialRefund = 0;

                let ServiceAmountSold = 0;
                let ServiceAmountRedeem = 0;
                let ServiceAmountCanceled = 0;
                let ServiceAmountExpired = 0;
                let ServiceAmountRefund = 0;
                let ServiceAmountPartialRefund= 0;

                
                data.response.map((el, k) => { 
                    arrDates.push(el.date);
                    arrCashQtdSold.push(el.cashlessVouchers.vouchersQuantity.sold);
                    CashQtdSold += el.cashlessVouchers.vouchersQuantity.sold;

                    arrCashQtdRedeem.push(el.cashlessVouchers.vouchersQuantity.redeemed);
                    CashQtdRedeem += el.cashlessVouchers.vouchersQuantity.redeemed;

                    arrCashQtdExpired.push(el.cashlessVouchers.vouchersQuantity.expired);
                    CashQtdExpired += el.cashlessVouchers.vouchersQuantity.expired;

                    arrCashQtdCanceled.push(el.cashlessVouchers.vouchersQuantity.canceled);
                    CashQtdCanceled += el.cashlessVouchers.vouchersQuantity.canceled;

                    arrCashQtdRefund.push(el.cashlessVouchers.vouchersQuantity.refunded);
                    CashQtdRefund += el.cashlessVouchers.vouchersQuantity.refunded;

                    arrCashQtdPartialRefund.push(el.cashlessVouchers.vouchersQuantity.partialRefund);
                    CashQtdPartialRefund += el.cashlessVouchers.vouchersQuantity.partialRefund;



                    arrCashAmountSold.push(el.cashlessVouchers.vouchersAmount.sold);
                    CashAmountSold += el.cashlessVouchers.vouchersAmount.sold;

                    arrCashAmountRedeem.push(el.cashlessVouchers.vouchersAmount.redeemed);
                    CashAmountRedeem += el.cashlessVouchers.vouchersAmount.redeemed;

                    arrCashAmountExpired.push(el.cashlessVouchers.vouchersAmount.expired);
                    CashAmountExpired += el.cashlessVouchers.vouchersAmount.expired;

                    arrCashAmountCanceled.push(el.cashlessVouchers.vouchersAmount.canceled);
                    CashAmountCanceled += el.cashlessVouchers.vouchersAmount.canceled;

                    arrCashAmountRefund.push(el.cashlessVouchers.vouchersAmount.refunded);
                    CashAmountRefund += el.cashlessVouchers.vouchersAmount.refunded;

                    arrCashAmountPartialRefund.push(el.cashlessVouchers.vouchersAmount.partialRefund);
                    CashAmountPartialRefund += el.cashlessVouchers.vouchersAmount.partialRefund;




                    arrServiceQtdSold.push(el.serviceVouchers.vouchersQuantity.sold);
                    ServiceQtdSold += el.serviceVouchers.vouchersQuantity.sold;

                    arrServiceQtdRedeem.push(el.serviceVouchers.vouchersQuantity.redeemed);
                    ServiceQtdRedeem += el.serviceVouchers.vouchersQuantity.redeemed;

                    arrServiceQtdExpired.push(el.serviceVouchers.vouchersQuantity.expired);
                    ServiceQtdExpired += el.serviceVouchers.vouchersQuantity.expired;

                    arrServiceQtdCanceled.push(el.serviceVouchers.vouchersQuantity.canceled);
                    ServiceQtdCanceled += el.serviceVouchers.vouchersQuantity.canceled;

                    arrServiceQtdRefund.push(el.serviceVouchers.vouchersQuantity.refunded);
                    ServiceQtdRefund += el.serviceVouchers.vouchersQuantity.refunded;

                    arrServiceQtdPartialRefund.push(el.serviceVouchers.vouchersQuantity.partialRefund);
                    ServiceQtdPartialRefund += el.serviceVouchers.vouchersQuantity.partialRefund;




                    arrServiceAmountSold.push(el.serviceVouchers.vouchersAmount.sold);
                    ServiceAmountSold += el.serviceVouchers.vouchersAmount.sold;

                    arrServiceAmountRedeem.push(el.serviceVouchers.vouchersAmount.redeemed);
                    ServiceAmountRedeem += el.serviceVouchers.vouchersAmount.redeemed;

                    arrServiceAmountExpired.push(el.serviceVouchers.vouchersAmount.expired);
                    ServiceAmountExpired += el.serviceVouchers.vouchersAmount.expired;

                    arrServiceAmountCanceled.push(el.serviceVouchers.vouchersAmount.canceled);
                    ServiceAmountCanceled += el.serviceVouchers.vouchersAmount.canceled;

                    arrServiceAmountRefund.push(el.serviceVouchers.vouchersAmount.refunded);
                    ServiceAmountRefund += el.serviceVouchers.vouchersAmount.refunded;

                    arrServiceAmountPartialRefund.push(el.serviceVouchers.vouchersAmount.partialRefund);
                    ServiceAmountPartialRefund+= el.serviceVouchers.vouchersAmount.partialRefund;

                    return;
                })
                
                this.setState({
                    dashboardinfo: {
                        arrDates: arrDates,
                        arrCashQtdSold: arrCashQtdSold,
                        arrCashQtdRedeem: arrCashQtdRedeem,
                        arrCashQtdExpired: arrCashQtdExpired,
                        arrCashQtdCanceled: arrCashQtdCanceled,
                        arrCashQtdRefund: arrCashQtdRefund,
                        arrCashQtdPartialRefund: arrCashQtdPartialRefund,

                        arrCashAmountSold: arrCashAmountSold,
                        arrCashAmountRedeem: arrCashAmountRedeem,
                        arrCashAmountExpired: arrCashAmountExpired,
                        arrCashAmountCanceled: arrCashAmountCanceled,
                        arrCashAmountRefund: arrCashAmountRefund,
                        arrCashAmountPartialRefund: arrCashAmountPartialRefund,

                        arrServiceQtdSold: arrServiceQtdSold,
                        arrServiceQtdRedeem: arrServiceQtdRedeem,
                        arrServiceQtdExpired: arrServiceQtdExpired,
                        arrServiceQtdCanceled: arrServiceQtdCanceled,
                        arrServiceQtdRefund: arrServiceQtdRefund,
                        arrServiceQtdPartialRefund: arrServiceQtdPartialRefund,


                        arrServiceAmountSold: arrServiceAmountSold,
                        arrServiceAmountRedeem: arrServiceAmountRedeem,
                        arrServiceAmountExpired: arrServiceAmountExpired,
                        arrServiceAmountCanceled: arrServiceAmountCanceled,
                        arrServiceAmountRefund: arrServiceAmountRefund,
                        arrServiceAmountPartialRefund: arrServiceAmountPartialRefund,


                        CashQtdSold: CashQtdSold,
                        CashQtdRedeem: CashQtdRedeem,
                        CashQtdCanceled: CashQtdCanceled,
                        CashQtdExpired: CashQtdExpired,
                        CashQtdRefund: CashQtdRefund,
                        CashQtdPartialRefund: CashQtdPartialRefund,


                        CashAmountSold: CashAmountSold,
                        CashAmountRedeem: CashAmountRedeem,
                        CashAmountCanceled: CashAmountCanceled,
                        CashAmountExpired: CashAmountExpired,
                        CashAmountRefund: CashAmountRefund,
                        CashAmountPartialRefund: CashAmountPartialRefund,


                        ServiceQtdSold: ServiceQtdSold,
                        ServiceQtdRedeem: ServiceQtdRedeem,
                        ServiceQtdCanceled: ServiceQtdCanceled,
                        ServiceQtdExpired: ServiceQtdExpired,
                        ServiceQtdRefund: ServiceQtdRefund,
                        ServiceQtdPartialRefund: ServiceQtdPartialRefund,


                        ServiceAmountSold: ServiceAmountSold,
                        ServiceAmountRedeem: ServiceAmountRedeem,
                        ServiceAmountCanceled: ServiceAmountCanceled,
                        ServiceAmountExpired: ServiceAmountExpired,
                        ServiceAmountRefund: ServiceAmountRefund,
                        ServiceAmountPartialRefund: ServiceAmountPartialRefund,


                    }
                    , block: false
                });
            }
        }, '/api/Voucher/Dashboard/v1' + param );

    }

    setComboStatus = (evt, inputValue) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value);
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    }

    render() {

        let optionsCount = {
            legend: {
                position: "left"
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    gridLines: {
                        drawOnChartArea: false
                    }
                }],
                yAxes: [{
                    ticks: {
                        
                        
                        stepSize: 1
                    },
                    gridLines: {
                        drawOnChartArea: false
                    }
                }]
            }
        }

        let optionsAmount = {
            legend: {
                position: "left"
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    gridLines: {
                        drawOnChartArea: false
                    }
                }],
                yAxes: [{
                    ticks: {

                    },
                    gridLines: {
                        drawOnChartArea: false
                    }
                }]
            }
        }

        let objCashCount = {
            "drawOnChartArea": false,
            "datasets":
                [
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Sold" }),
                        "data": this.state.dashboardinfo.arrCashQtdSold ? this.state.dashboardinfo.arrCashQtdSold : [],
                        "fill": false,
                        "borderColor": "#64dd17",
                        "backgroundColor": "#64dd17aa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Redeem" }),
                         "data": this.state.dashboardinfo.arrCashQtdRedeem ? this.state.dashboardinfo.arrCashQtdRedeem : [],
                        "fill": false,
                        "borderColor": "#8bc34a",
                        "backgroundColor": "#8bc34aaa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    }
                    ,
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Expired" }),
                "data": this.state.dashboardinfo.arrCashQtdExpired ? this.state.dashboardinfo.arrCashQtdExpired : [],
                        "fill": false,
                        "borderColor": "#809c13",
                        "backgroundColor": "#809c13aa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Canceled" }),
                "data": this.state.dashboardinfo.arrCashQtdCanceled ? this.state.dashboardinfo.arrCashQtdCanceled : [],
                        "fill": false,
                        "borderColor": "#607c3c",
                        "backgroundColor": "#607c3caa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Refund" }),
                        "data": this.state.dashboardinfo.arrCashQtdRefund ? this.state.dashboardinfo.arrCashQtdRefund : [],
                        "fill": false,
                        "borderColor": "#d5d5d5",
                        "backgroundColor": "#d5d5d5aa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.PartialRefund" }),
                        "data": this.state.dashboardinfo.arrCashQtdPartialRefund ? this.state.dashboardinfo.arrCashQtdPartialRefund : [],
                        "fill": false,
                        "borderColor": "#4a4a4a",
                        "backgroundColor": "#4a4a4aaa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    }
                    
                ],
            "labels": this.state.dashboardinfo.arrDates ? this.state.dashboardinfo.arrDates : []
        };

        let objCashAmount = {
            "drawOnChartArea": false,
            "datasets":
                [
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Sold" }),
                        "data": this.state.dashboardinfo.arrCashAmountSold ? this.state.dashboardinfo.arrCashAmountSold : [],
                        "fill": false,
                        "borderColor": "#64dd17",
                        "backgroundColor": "#64dd17aa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Redeem" }),
                        "data": this.state.dashboardinfo.arrCashAmountRedeem ? this.state.dashboardinfo.arrCashAmountRedeem : [],
                        "fill": false,
                        "borderColor": "#8bc34a",
                        "backgroundColor": "#8bc34aaa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    }
                    ,
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Expired" }),
                        "data": this.state.dashboardinfo.arrCashAmountSold ? this.state.dashboardinfo.arrCashAmountSold : [],
                        "fill": false,
                        "borderColor": "#809c13",
                        "backgroundColor": "#809c13aa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Canceled" }),
                        "data": this.state.dashboardinfo.arrCashAmountCanceled ? this.state.dashboardinfo.arrCashAmountCanceled : [],
                        "fill": false,
                        "borderColor": "#607c3c",
                        "backgroundColor": "#607c3caa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Refund" }),
                        "data": this.state.dashboardinfo.arrCashAmountRefund ? this.state.dashboardinfo.arrCashAmountRefund : [],
                        "fill": false,
                        "borderColor": "#d5d5d5",
                        "backgroundColor": "#d5d5d5aa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.PartialRefund" }),
                        "data": this.state.dashboardinfo.arrCashAmountPartialRefund ? this.state.dashboardinfo.arrCashAmountPartialRefund : [],
                        "fill": false,
                        "borderColor": "#4a4a4a",
                        "backgroundColor": "#4a4a4aaa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    }

                ],
            "labels": this.state.dashboardinfo.arrDates ? this.state.dashboardinfo.arrDates : []
        };

        let objServiceCount = {
            "drawOnChartArea": false,
            "datasets":
                [
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Sold" }),
                        "data": this.state.dashboardinfo.arrServiceQtdSold ? this.state.dashboardinfo.arrServiceQtdSold : [],
                        "fill": false,
                        "borderColor": "#64dd17",
                        "backgroundColor": "#64dd17aa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Redeem" }),
                        "data": this.state.dashboardinfo.arrServiceQtdRedeem ? this.state.dashboardinfo.arrServiceQtdRedeem : [],
                        "fill": false,
                        "borderColor": "#8bc34a",
                        "backgroundColor": "#8bc34aaa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    }
                    ,
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Expired" }),
                        "data": this.state.dashboardinfo.arrServiceQtdExpired ? this.state.dashboardinfo.arrServiceQtdExpired : [],
                        "fill": false,
                        "borderColor": "#809c13",
                        "backgroundColor": "#809c13aa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Canceled" }),
                        "data": this.state.dashboardinfo.arrServiceQtdCanceled ? this.state.dashboardinfo.arrServiceQtdCanceled : [],
                        "fill": false,
                        "borderColor": "#607c3c",
                        "backgroundColor": "#607c3caa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Refund" }),
                        "data": this.state.dashboardinfo.arrServiceQtdRefund ? this.state.dashboardinfo.arrServiceQtdRefund: [],
                        "fill": false,
                        "borderColor": "#d5d5d5",
                        "backgroundColor": "#d5d5d5aa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.PartialRefund" }),
                        "data": this.state.dashboardinfo.arrServiceQtdPartialRefund ? this.state.dashboardinfo.arrServiceQtdPartialRefund: [],
                        "fill": false,
                        "borderColor": "#4a4a4a",
                        "backgroundColor": "#4a4a4aaa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    }

                ],
            "labels": this.state.dashboardinfo.arrDates ? this.state.dashboardinfo.arrDates : []
        };

        let objServiceAmount = {
            "drawOnChartArea": false,
            "datasets":
                [
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Sold" }),
                        "data": this.state.dashboardinfo.arrServiceAmountSold ? this.state.dashboardinfo.arrServiceAmountSold : [],
                        "fill": false,
                        "borderColor": "#64dd17",
                        "backgroundColor": "#64dd17aa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Redeem" }),
                        "data": this.state.dashboardinfo.arrServiceAmountRedeem ? this.state.dashboardinfo.arrServiceAmountRedeem : [],
                        "fill": false,
                        "borderColor": "#8bc34a",
                        "backgroundColor": "#8bc34aaa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    }
                    ,
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Expired" }),
                        "data": this.state.dashboardinfo.arrServiceAmountExpired ? this.state.dashboardinfo.arrServiceAmountExpired: [],
                        "fill": false,
                        "borderColor": "#809c13",
                        "backgroundColor": "#809c13aa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Canceled" }),
                        "data": this.state.dashboardinfo.arrServiceAmountCanceled ? this.state.dashboardinfo.arrServiceAmountCanceled : [],
                        "fill": false,
                        "borderColor": "#607c3c",
                        "backgroundColor": "#607c3caa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.Refund" }),
                        "data": this.state.dashboardinfo.arrServiceAmountRefund ? this.state.dashboardinfo.arrServiceAmountRefund: [],
                        "fill": false,
                        "borderColor": "#d5d5d5",
                        "backgroundColor": "#d5d5d5aa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": this.props.intl.formatMessage({ id: "Home.PartialRefund" }),
                        "data": this.state.dashboardinfo.arrServiceAmountPartialRefund ? this.state.dashboardinfo.arrServiceAmountPartialRefund: [],
                        "fill": false,
                        "borderColor": "#4a4a4a",
                        "backgroundColor": "#4a4a4aaa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    }

                ],
            "labels": this.state.dashboardinfo.arrDates ? this.state.dashboardinfo.arrDates : []
        };


        function Crt(props) {
            return <Card className="border-0">
                <CardBody className="p-1">
                    <Row>
                        <Col >
                            <div className="text-uppercase text-muted " ><b>{props.text}</b></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <h3><div className="text-value-xl " style={{ color: props.color }}> {props.value}</div></h3>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        }

        return (
            <BlockUi tag="div" blocking={this.state.block} error={this.state.error}>
                <Row className="mb-2">
                    <Col className="col-2">
                        <SelectHotel onChangeFunc={this.setComboStatus.bind(this, 'selectedHotel')} value={this.state.selectedHotel} />
                    </Col>
                    <Col className="col-3">
                        <DateRangePicker
                            startDate={this.state.startDate ? moment(this.state.startDate) : null}
                            startDateId="sellStartid"
                            isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment()}
                            endDate={this.state.endDate ? moment(this.state.endDate) : null}
                            endDateId="sellEndid"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            required
                            numberOfMonths={2}
                            showDefaultInputIcon={true}
                        />
                    </Col>
                    
                    <Col>
                        <Button className="btn-host btn-sm float-right" onClick={this.getDashboardInfo.bind(this)}> <i className="fas fa-search " /></Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <StyledCard title="# Cashless ">
                            
                            <Row >

                                <Col><Crt value={this.state.dashboardinfo.CashQtdSold} text="# Sold" color="#64dd17"></Crt></Col>

                                <Col><Crt value={this.state.dashboardinfo.CashQtdRedeem} text="# Redeem" color="#8bc34a"></Crt></Col>

                                <Col><Crt value={this.state.dashboardinfo.CashQtdExpired} text="# Expired" color="#809c13"></Crt></Col>

                                <Col><Crt value={this.state.dashboardinfo.CashQtdCanceled} text="# Canceled" color="#607c3c"></Crt></Col>
                            </Row>
                                <Row>
                                <Col className="col-12">
                                    <Row>
                                        <Col>
                                            <Line data={objCashCount} height={'80px'} options={optionsCount} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </StyledCard>
                    </Col>
                    <Col className="col-6">
                        <StyledCard title="&euro; Cashless ">
                            <Row className="mb-2">


                                <Col><Crt value={this.state.dashboardinfo.CashAmountSold} text="&euro; Sold" color="#64dd17"></Crt></Col>

                                <Col><Crt value={this.state.dashboardinfo.CashAmountRedeem} text="&euro; Redeem" color="#8bc34a"></Crt></Col>
                                 
                                <Col><Crt value={this.state.dashboardinfo.CashAmountExpired} text="&euro; Expired" color="#809c13"></Crt></Col>
                                  
                                <Col><Crt value={this.state.dashboardinfo.CashAmountCanceled} text="&euro; Canceled" color="#607c3c"></Crt></Col>
                            </Row>
                            <Row>

                                <Col className="col-12">
                                    <Row>
                                        <Col><Line data={objCashAmount} height={'80px'} options={optionsAmount} /></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </StyledCard>
                    </Col>
                </Row>
                <div className="mb-2" />
                <Row>
                    <Col className="col-6">
                        <StyledCard title="# Services">
                            <Row className="mb-2">

                                <Col><Crt value={this.state.dashboardinfo.ServiceQtdSold} text="# Sold" color="#64dd17"></Crt></Col>

                                <Col><Crt value={this.state.dashboardinfo.ServiceQtdRedeem} text="# Redeem" color="#8bc34a"></Crt></Col>

                                <Col><Crt value={this.state.dashboardinfo.ServiceQtdExpired} text="# Expired" color="#809c13"></Crt></Col>

                                <Col><Crt value={this.state.dashboardinfo.ServiceQtdCanceled} text="# Canceled" color="#607c3c"></Crt></Col>
                                    </Row>
                            <Row>
                                <Col className="col-12">
                                    <Row>
                                        <Col><Line data={objServiceCount} height={'80px'} options={optionsAmount} /></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </StyledCard>
                    </Col>
                    <Col className="col-6">
                        <StyledCard title="&euro; Services">
                            <Row className="mb-3">
                              

                                <Col><Crt value={this.state.dashboardinfo.ServiceAmountSold} text="&euro; Sold" color="#64dd17"></Crt></Col>

                                <Col><Crt value={this.state.dashboardinfo.ServiceAmountRedeem} text="&euro; Redeem" color="#8bc34a"></Crt></Col>

                                <Col><Crt value={this.state.dashboardinfo.ServiceAmountExpired} text="&euro; Expired" color="#809c13"></Crt></Col>

                                <Col><Crt value={this.state.dashboardinfo.ServiceAmountCanceled} text="&euro; Canceled" color="#607c3c"></Crt></Col>
                               
                            </Row>
                                <Row>
                                <Col className="col-12">
                                    <Row>
                                        <Col><Line data={objServiceAmount} height={'80px'} options={optionsAmount} /></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </StyledCard>
                    </Col>
                </Row>
               

            </BlockUi>
    );
  }
}

export default injectIntl(Home)