import React, { Component } from 'react';
import { CardBody, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import { FormattedMessage } from 'react-intl';
import Handlebars from 'handlebars';
import moment from 'moment';

export class TemplatePreviewModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            template: '',
        }
    }

    componentDidMount() {
        if (this.props.templateList && this.props.templateList.some(el => el.value === this.props.voucherConfig.notificationTemplateId) ) {            
            this.handlebars();
        }
    }

    handlebars = () => {
        var source = this.props.templateList.find(el => el.value === this.props.voucherConfig.notificationTemplateId)?.templateHtml;
        source = source.replace("{{#voucherType.VoucherTermsIncluded}}", "{{#each VoucherType.VoucherTermsIncluded}}");
        source = source.replace("{{/voucherType.VoucherTermsIncluded}}", "{{/each}}");
        source = source.replace("{{#voucherType.VoucherTermsExcluded}}", "{{#each VoucherType.VoucherTermsExcluded}}");
        source = source.replace("{{/voucherType.VoucherTermsExcluded}}", "{{/each}}");

        var template = Handlebars.compile(source);

        Handlebars.registerHelper('formatDate', function (date) {
            return moment(date).format("DD/MM/YYYY")
        })


        if (this.props.voucherConfig) {
            const voucher = this.props.voucherConfig;

            const logo = voucher.logoImage;
            const background = voucher.backgroundImage;
            const tec = voucher.tec && voucher.tec.multiLingual?.some(el => el.languageCode === 'PT') ? voucher.tec.multiLingual.find(el => el.languageCode === 'PT') : voucher.tec.multiLingual.find(el => el.description) ;
            const included = voucher.voucherTerms && voucher.voucherTerms.find(el => el.type === 'Included' && el.multiLingual.some(ml => ml.description));
            const excluded = voucher.voucherTerms && voucher.voucherTerms.find(el => el.type === 'Excluded' && el.multiLingual.some(ml => ml.description));

            const voucherText = voucher.multiLingual && voucher.multiLingual.some(el => el.languageCode === 'PT' && el.description) ? voucher.multiLingual.find(el => el.languageCode === 'PT') : voucher.multiLingual.find(el => el.description);


            var data = {
                HasLogo: logo ? true : false,
                HasBackground: background ? true : false,
                HasTermsIncluded: included ? true : false,
                HasTermsExcluded: excluded ? true : false,
                HasConditions: tec ? true : false,
                VoucherId: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
                ValidUntil: voucher.vouchersValidUntil ? moment().add(voucher.vouchersValidUntil, 'month') : moment(),
                VoucherTemplateConfig: {
                    LogoImage: logo,
                    BackgroundImage: background,
                    ShowPrice: voucher.showPrice
                },
                VoucherType: {
                    Title: voucherText ? voucherText.title : '',
                    Description: voucherText ? voucherText.description : '',
                    Tec: {
                        Description: tec ? tec.description : ''
                    },
                    VoucherTermsIncluded: [{
                        description: included ? included.multiLingual.some(el => el.languageCode === 'PT' && el.description !== null) ? included.multiLingual.find(el => el.languageCode === 'PT').description : included.multiLingual.find(el => el.description !== null).description : ''
                    }],
                    VoucherTermsExcluded: [{
                        description: excluded ? excluded.multiLingual.some(el => el.languageCode === 'PT' && el.description !== null) ? excluded.multiLingual.find(el => el.languageCode === 'PT').description : excluded.multiLingual.find(el => el.description !== null).description : ''
                    }]
                },
                VoucherValue: voucher.cardAmount,
                QrCode: ''
            };



            var result = template(data);

            this.setState({ template: result })
        }


    }

    render() {
        const { isOpen, toggle } = this.props;

        return (
            <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: '80%' }}>
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="CardConfigWizard.TemplatePreview" />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <CardBody>
                                {ReactHtmlParser(this.state.template)}
                            </CardBody>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>    
        );
    }
}