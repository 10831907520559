import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Row, Col, FormGroup, Label, Input, Button, Form } from 'reactstrap';
import { handleNotification } from "../../Base/Common/Notification";
import { ErrorAlert } from "../../Base/ErrorAlert";
import Authorization from '../../Base/Authorization';
import { postAPI } from "../../Base/API";
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';

export class PriceDetails extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            price: this.props.selectedPrice ? this.props.selectedPrice : { },
        };
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState(prevState => ({
            price: {
                ...prevState.price,
                [name]: value
            }
        }));
    }

    saveLicense = (e) => {
        e.preventDefault();

        this.setState({ block: true });
        var body = { ...this.state.price };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(({ block: false, error: errorMessage }));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="PriceDetails.PriceSaved" />, <FormattedMessage id="generic.success" />);

                    this.props.updateTable(data.response[0]);
                    this.setState(({ block: false }));
                    this.props.toggle();
                }
                this.setState(({ block: false }));
            }
            this.setState(({ block: false }));
        }, '/api/Voucher/v1/Config/cardPrice', JSON.stringify({ request: body }));
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-xl">
                <ModalHeader toggle={this.props.toggle}>
                    {this.props.selectedPrice ? <FormattedMessage id="PriceDetails.EditPrice" /> : <FormattedMessage id="PriceDetails.CreatePrice" />}
                </ModalHeader>

                <ModalBody className="p-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={this.saveLicense} innerRef={this.form}>
                            <Card className="shadow border-0 p-0">

                                <CardBody className="m-0">
                                    <Row className="bg-white mb-3">
                                        <Col className="text-right">
                                            <Authorization
                                                perform="priceList:delete"
                                                yes={() => (
                                                    <Button className="btn btn-sm btn-host mr-2" onClick={(e) => this.props.deletePriceList(e, this.state.price.id)}>
                                                        <i className="fas fa-trash-alt" />
                                                    </Button>
                                                )}
                                                no={() => <div></div>}
                                            />
                                            <Authorization
                                                perform="priceList:save"
                                                yes={() => (
                                                    <Button type="submit" className="btn btn-sm btn-host float-right">
                                                        <i className="fas fa-save" />
                                                    </Button>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={2}> <FormattedMessage id="PriceList.Name" /> </Label>
                                                <Col sm={10}>
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        className="text-sm"
                                                        value={this.state.price ? this.state.price.name : ''}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={4}> <FormattedMessage id="PriceList.NumberOfVouchers" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="number"
                                                        name="numberOfVouchers"
                                                        className="text-sm"
                                                        value={this.state.price ? this.state.price.numberOfVouchers : ''}
                                                        min="0"
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={4}> <FormattedMessage id="PriceList.Amount" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="number"
                                                        name="amount"
                                                        className="text-sm"
                                                        value={this.state.price ? this.state.price.amount : ''}
                                                        min="0"
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}
