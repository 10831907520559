import React, { Component } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import countryList from "react-select-country-list";
import moment from "moment";
import { getAPI, putAPI } from "../Base/API";
import { StyledCard } from "../Base/Common/CommonUIComponents";
import { handleNotification } from "../Base/Common/Notification";
import CustomSelect from "../Base/Common/CustomSelect";
import { getStatus, getPmsStatus, getHotelGroupModules, getRightVoucherId } from '../Base/Common/ReferenceDataFunctions';
import Authorization from '../Base/Authorization';

class ProfileDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            profile: {
                vouchers: [],
                pmsReservationDetails: []
            },
            countries: countryList().getData(),
            hasPNIntegration: false
        };
        this.form = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        const { match } = this.props;

        window.setTimeout(
            () => {
                //Display external ids data if hotel group has PNIntegration module
                this.setState({ hasPNIntegration: global.modules && global.modules.includes(getHotelGroupModules(this.props.intl)[0].value) });
            },
            global.modules ? 0 : 2500
        );

        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    if (!data.response[0].vouchers) {
                        data.response[0].vouchers = [];
                    }
                    if (!data.response[0].pmsReservationDetails) {
                        data.response[0].pmsReservationDetails = [];
                    }
                    this.setState({ profile: data.response[0] });
                }
            }
            this.setState({ error: errorMessage, block: false });
        },`/api/Voucher/Profile/v1/Profile/${match.params.id}`);
    }

    handleChange(evt) {
        if (evt.target) {
            const { profile } = this.state;
            const { name, value } = evt.target;
            profile[name] = value;

            this.setState({ profile });
        }
    }

    handleSave(e) {
        let { profile } = this.state;

        e.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        const request = {
            request: profile
        };
        this.setState({ block: true });
        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ProfileDetail.SaveSuccessMessage" />, <FormattedMessage id="generic.success" />);
                    Object.assign(profile, data.response[0]);
                    this.setState({ profile });
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/Voucher/Profile/v1/Profile`, request);
    }

    setCombo(name, combo) {
        const { profile } = this.state;
        profile[name] = combo && combo.value
        this.setState({ profile });
    }

    render() {
        const { block, error, profile, countries, hasPNIntegration } = this.state;

        return (
            <StyledCard icon={'fas fa-user'} title={'ProfilesDetail.Title'} error={error} block={block}>
                <form ref={this.form}>
                    <Row className="mb-1">
                        <Col className="text-right">
                            <Authorization
                                perform="profileDetail:save"
                                yes={() => (
                                    <Button className="btn btn-host btn-sm" onClick={this.handleSave}>
                                        <i className="fas fa-save" />
                                    </Button>
                                )}
                                no={() => <div></div>}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup sm="true" row>
                                <Label for="firstName" sm={4}>
                                    <FormattedMessage id="ProfileDetail.FirstName" />
                                </Label>
                                <Col sm={8}>
                                    <Input type="text" name="firstName" className="text-sm" maxLength="50" value={profile.firstName ? profile.firstName : ''} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup sm="true" row>
                                <Label for="lastName" sm={4}>
                                    <FormattedMessage id="ProfileDetail.LastName" />
                                </Label>
                                <Col sm={8}>
                                    <Input type="text" name="lastName" className="text-sm" maxLength="50" value={profile.lastName ? profile.lastName : ''} onChange={this.handleChange} required />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup sm="true" row>
                                <Label for="phone" sm={4}>
                                    <FormattedMessage id="ProfileDetail.Phone" />
                                </Label>
                                <Col sm={8}>
                                    <Input type="text" name="phone" className="text-sm" maxLength="25" value={profile.phone ? profile.phone : ''} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup sm="true" row>
                                <Label for="email" sm={4}>
                                    <FormattedMessage id="ProfileDetail.Email" />
                                </Label>
                                <Col sm={8}>
                                    <Input type="email" name="email" className="text-sm" maxLength="100" value={profile.email ? profile.email : ''} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup sm="true" row>
                                <Label for="address" sm={4}>
                                    <FormattedMessage id="ProfileDetail.Address" />
                                </Label>
                                <Col sm={8}>
                                    <Input type="text" name="address" className="text-sm" maxLength="100" value={profile.address ? profile.address : ''} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup sm="true" row>
                                <Label for="postCode" sm={4}>
                                    <FormattedMessage id="ProfileDetail.PostCode" />
                                </Label>
                                <Col sm={8}>
                                    <Input type="text" name="postCode" className="text-sm" maxLength="10" value={profile.postCode ? profile.postCode : ''} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup sm="true" row>
                                <Label for="city" sm={4}>
                                    <FormattedMessage id="ProfileDetail.City" />
                                </Label>
                                <Col sm={8}>
                                    <Input type="text" name="city" className="text-sm" maxLength="50" value={profile.city ? profile.city : ''} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup sm="true" row>
                                <Label for="countryCode" sm={4}>
                                    <FormattedMessage id="ProfileDetail.Country" />
                                </Label>
                                <Col sm={8}>
                                    <CustomSelect
                                        placeholder={<FormattedMessage id="ProfileDetail.Country" />}
                                        options={countries}
                                        value={countries.find(c => c.value === profile.countryCode)}
                                        onChange={this.setCombo.bind(this, 'countryCode')}
                                        isClearable
                                        isSearchable
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup sm="true" row>
                                <Label for="companyName" sm={4}>
                                    <FormattedMessage id="ProfileDetail.CompanyName" />
                                </Label>
                                <Col sm={8}>
                                    <Input type="text" name="companyName" className="text-sm" value={profile.companyName ? profile.companyName : ''} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup sm="true" row>
                                <Label for="fiscalNumber" sm={4}>
                                    <FormattedMessage id="ProfileDetail.FiscalNumber" />
                                </Label>
                                <Col sm={8}>
                                    <Input type="text" name="fiscalNumber" className="text-sm" value={profile.fiscalNumber ? profile.fiscalNumber : ''} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        hasPNIntegration ?
                            <Row>
                                <Col>
                                    <FormGroup sm="true" row>
                                        <Label for="externalId" sm={4}>
                                            <FormattedMessage id="ProfilesDashboard.ExternalId" />
                                        </Label>
                                        <Col sm={8}>
                                            <Input type="text" name="externalId" className="text-sm" value={profile.externalId} disabled />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup sm="true" row>
                                        <Label for="externalCrmId" sm={4}>
                                            <FormattedMessage id="ProfilesDashboard.ExternalCrmId" />
                                        </Label>
                                        <Col sm={8}>
                                            <Input type="text" name="externalCrmId" className="text-sm" value={profile.externalCrmId} disabled />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            :
                            <div />
                    }
                    <Row>
                        <Col>
                            <h5>
                                <FormattedMessage id="ProfileDetail.Vouchers" />
                            </h5>
                        </Col>
                    </Row>
                    <hr className="my-1" />
                    <Row className="mb-1">
                        <Col className="col-4">
                            <b>
                                <FormattedMessage id="ProfileDetail.VoucherId" />
                            </b>
                        </Col>
                        <Col className="col-2">
                            <b>
                                <FormattedMessage id="ProfileDetail.Value" />
                            </b>
                        </Col>
                        <Col className="col-3">
                            <b>
                                <FormattedMessage id="ProfileDetail.Valid" />
                            </b>
                        </Col>
                        <Col className="col-3">
                            <b>
                                <FormattedMessage id="ProfileDetail.Status" />
                            </b>
                        </Col>
                    </Row>
                    {
                        profile.vouchers.map((voucher, key) =>
                            <Row key={key} className="mb-1">
                                <Col className="col-4">
                                    <a style={{ cursor: 'pointer', color: "blue" }} onClick={() => this.props.history.push(`/VoucherOperationDetails/${voucher.id}`)}>
                                        {getRightVoucherId(voucher.voucherId, voucher.voucherIdFirst8)}
                                    </a>
                                </Col>
                                <Col className="col-2">
                                    {voucher.voucherValue}
                                </Col>
                                <Col className="col-3">
                                    {voucher.validUntil ? moment(voucher.validUntil).format("YYYY-MM-DD hh:mm:ss") : ''}
                                </Col>
                                <Col className="col-3">
                                    {getStatus().find(el => el.value === voucher.status) ? getStatus().find(el => el.value === voucher.status).label : voucher.status}
                                </Col>
                            </Row>    
                        )
                    }
                    <br/>
                    <Row>
                        <Col>
                            <h5>
                                <FormattedMessage id="ProfileDetail.Reservations" />
                            </h5>
                        </Col>
                    </Row>
                    <hr className="my-1" />
                    <Row className="mb-1">
                        <Col className="col-2">
                            <b>
                                <FormattedMessage id="ProfileDetail.ReservationId" />
                            </b>
                        </Col>
                        <Col className="col-2">
                            <b>
                                <FormattedMessage id="ProfileDetail.PmsReservationId" />
                            </b>
                        </Col>
                        <Col className="col-2">
                            <b>
                                <FormattedMessage id="ProfileDetail.Status" />
                            </b>
                        </Col>
                        <Col className="col-3">
                            <b>
                                <FormattedMessage id="ProfileDetail.CheckIn" />
                            </b>
                        </Col>
                        <Col className="col-3">
                            <b>
                                <FormattedMessage id="ProfileDetail.CheckOut" />
                            </b>
                        </Col>
                    </Row>
                    {
                        profile.pmsReservationDetails.map((reservation, key) =>
                            <Row key={key} className="mb-1">
                                <Col className="col-2">
                                    {reservation.reservationDetailId}
                                </Col>
                                <Col className="col-2">
                                    {reservation.pmsReservationId}
                                </Col>
                                <Col className="col-2">
                                    {getPmsStatus().find(el => el.value === reservation.reservationStatus) ? getPmsStatus().find(el => el.value === reservation.reservationStatus).label : reservation.reservationStatus}
                                </Col>
                                <Col className="col-3">
                                    {reservation.checkIn ? moment(reservation.checkIn).format("YYYY-MM-DD") : ''}
                                </Col>
                                <Col className="col-3">
                                    {reservation.checkOut ? moment(reservation.checkOut).format("YYYY-MM-DD") : ''}
                                </Col>
                            </Row>    
                        )
                    }
                </form>
            </StyledCard>       
        );
    }
}

export default injectIntl(ProfileDetail);