import moment from "moment";
import React, { Component } from 'react'
import { Row, Col, Badge, Card, CardBody, Input, Button, Form, Label } from 'reactstrap';
import QrReader from 'react-qr-reader';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { getAPI, postAPI } from '../Base/API';
import { ErrorAlert } from "../Base/ErrorAlert";
import { CommonHelper, StyledCard } from '../Base/Common/CommonUIComponents';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { handleNotification } from "../Base/Common/Notification";
import BlockUi from 'react-block-ui';
import UseVoucherList from "./UseVoucherList";
import Authorization from '../Base/Authorization';
import { getCodeGenerationTypes } from "../Base/Common/ReferenceDataFunctions";

class UseVoucher extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vid: '',
            block: false,
            voucher: null,
            voucherValue: 0,
            notes: null,
            quantityList: [],
            voucherId: null,
            refund: 0,
            redeems: 0,
            showUseVoucherList: false,
            vouchers: [],
            isHttps: window.location.protocol === 'https:'
        };
        this.form = React.createRef();
        this.registRedeem = this.registRedeem.bind(this);
        this.changeField = this.changeField.bind(this);
        this.getVoucher = this.getVoucher.bind(this);
    }
    

    handleScan = data => {
        if (data ) {
            if (data !== this.state.vid) {
                this.setState({ vid: data,block: true });
           
                this.getVoucher(data);
            }
        }
    }

    handleBarCodeScan = (_, data) => {
        if (data) {
            const voucherId = data.text.replaceAll('+', '').toLowerCase();
            if (voucherId !== this.state.vid) {
                this.setState({ vid: voucherId });

                this.getVoucher(voucherId);
            }
        }
    }

    getVoucher(data, evt) {
        if (evt) {
            evt.preventDefault();
        }
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, voucher: null, block: false });

                return;
            }
            if (data && data.response[0] === null) {
                errorMessage.push({ message: <FormattedMessage id="UseVoucher.VoucherIsNotValid" />, stack: '', messageType: 'danger' });
                this.setState({ error: errorMessage, voucher: null, block: false, vouchers: [] });
                return;
            }
            else {
                if (data && data.response && data.response.length > 0) {
                    if (data.response.length === 1) {
                        const voucherDetails = this.getVoucherDetails(data.response[0]);
                        this.setState({ voucher: data.response[0], block: false, quantityList: voucherDetails.quantityList, error: data.errors, voucherValue: voucherDetails.voucherValue, refund: voucherDetails.refund, redeems: voucherDetails.redeems, vouchers: [] });
                    }
                    else {
                        this.setState({ vouchers: data.response, block: false, error: data.errors, showUseVoucherList: true });
                    }
                }
                else {
                    handleNotification({ errors: [{ message: <FormattedMessage id="UseVoucher.VoucherNotFound" />, code: ''}] });
                    this.setState({ voucher: null, block: false, quantityList: [], vouchers: [] });
                }
            }

        }, '/api/Voucher/Voucher/v1/voucher/externalId/' + data);
    }

    changeField(evt) {
        const value = evt.target ? evt.target.value : null;
        const name = evt.target && evt.target.name;

        this.setState({ [name]: value });
    }

    registRedeem(rebateId, redeemAll) {
        const { voucher, voucherValue, notes, quantityList } = this.state;
        let req = {
            request: []
        };

        if (redeemAll) {
            this.state.voucher.voucherType && this.state.voucher.voucherType.voucherRebates.forEach(el => {
                req.request.push({
                    "hotelId": voucher.hotelId,
                    "usageHotel": voucher.hotelId,
                    "voucherId": voucher.id,
                    "targetReference": null,
                    "businessDate": moment().format('YYYY-MM-DD'),
                    "quantity": (el.id != null && quantityList.some(ql => ql.id === el.id)) ? quantityList.find(ql => ql.id === el.id).value : 1,
                    "amount": voucherValue,
                    "notes": notes,
                    "rebateId": el.id
                })
            });
        }
        else {
            req.request.push({
                "hotelId": voucher.hotelId,
                "usageHotel": voucher.hotelId,
                "voucherId": voucher.id,
                "targetReference": null,
                "businessDate": moment().format('YYYY-MM-DD'),
                "quantity": (rebateId != null && quantityList.find(el => el.id === rebateId)) ? quantityList.find(el => el.id === rebateId).value : 1,
                "amount": voucherValue,
                "notes": notes,
                "rebateId": rebateId
            })
        }

        if (this.form.current.reportValidity()) {
            this.setState({ block: true });
            postAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="EditVoucher.RebateAdded" />, <FormattedMessage id="AdminUserManagement.Success" />);
                        this.getVoucher(voucher.voucherId);
                    }
                }
            }, '/api/Voucher/Redeem/Redeem/v1', req);
        }
    }
    
    handleError = err => {
        console.error(err);
    }
    
    changeQty(id, evt) {
        let k = evt.target.value;
         this.setState(prevState => ({ quantityList: [prevState.quantityList.filter(x => x.id !== id), { "id": id, "value": k }] }))
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState({
            [name]: value
        });
    }

    selectVoucher = (newVoucher) => {
        const voucher = Object.assign({}, newVoucher);
        const voucherDetails = this.getVoucherDetails(newVoucher);
        this.setState({
            voucher: voucher,
            showUseVoucherList: false,
            vid: voucher.voucherId,
            quantityList: voucherDetails.quantityList,
            voucherValue: voucherDetails.voucherValue,
            refund: voucherDetails.refund,
            redeems: voucherDetails.redeems
        });
    }

    getVoucherDetails(voucher) {
        const voucherDetails = {
            quantityList: [],
            voucherValue: 0,
            refund: 0,
            redeems: 0
        };

        voucherDetails.quantityList = voucher.voucherType.voucherRebates.map((el, k) => {
            const item = {
                id: el.id,
                value: !voucher.voucherType.allowPartialRedeem ? el.quantity - (voucher.redeems != undefined && voucher.redeems.filter(x => x.rebateId === el.id).reduce((acc, cur) => acc + cur.quantity, 0)) : ''
            };
            return item;
        });

        voucherDetails.refund = voucher.refunds && voucher.refunds.reduce((acc, cur) => acc + cur.amount, 0).toFixed(2);
        voucherDetails.redeems = voucher.redeems && voucher.redeems.reduce((acc, cur) => acc + cur.amount, 0).toFixed(2);
        if (voucher.voucherType.isPaymentMode && !voucher.voucherType.allowPartialRedeem) {
            voucherDetails.voucherValue = voucher.voucherValue - voucherDetails.refund - voucherDetails.redeems;
        }
        return voucherDetails;
    }

    render() {
        const { refund, redeems, showUseVoucherList, vouchers, isHttps } = this.state;

        const isBarcode = global.codeGenerationType === getCodeGenerationTypes(this.props.intl)[0].value;

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                {
                    showUseVoucherList ?
                        <UseVoucherList isOpen={showUseVoucherList} selectVoucher={this.selectVoucher} vouchers={vouchers} />
                        :
                        <div />
                }
                <Row>                
                    <Col>
                        <Form onSubmit={(e) => this.getVoucher(this.state.vid, e)}>
                            <Row className="mt-3">
                                <Col className="col-3">
                                    <Label className="ml-2">
                                        <FormattedMessage id="UseVoucher.EnterVoucherID" />
                                    </Label>
                                </Col>
                                <Col className="col-7">
                                    <Input type="text"
                                        //required
                                        name="vid"
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.vid ? this.state.vid : ''}
                                    />
                                </Col>
                                <Col className="text-right col-2 pr-0">
                                    <Button className="btn-host btn-sm" type="submit">
                                        <i className="fas fa-search" />
                                    </Button>

                                    <CommonHelper help={<FormattedHTMLMessage id="UseVoucher.Help"/>} id="useVoucher"/>
                                </Col>
                            </Row>
                        </Form>
                        {this.state.voucher ?
                            <div className="mt-2">
                                <form ref={this.form}>
                                    <StyledCard title={this.state.voucher.voucherType ? this.state.voucher.voucherType.code : ''} error={this.state.error} block={this.state.block}>
                                        <Row>
                                            <Col>{this.state.voucher.name}</Col>
                                            <Col>{this.state.voucher.email}</Col>
                                            <Col className="text-right"><Badge className="bg-host p-2">{this.state.voucher.status}</Badge></Col>
                                        </Row>

                                    </StyledCard>
                                    <div className="mb-2" />
                                    <StyledCard title={'VoucherOperationDetails.UsageSummary'} block={this.state.block}>

                                        {this.state.voucher.voucherType && this.state.voucher.voucherType.isPaymentMode === true ?

                                            <>
                                                <Row className="mt-2">
                                                    <Col>
                                                        <FormattedMessage id="VoucherOperationDetails.CardAmount" /> {this.state.voucher.voucherValue}
                                                    </Col>
                                                    <Col>
                                                        <FormattedMessage id="VoucherOperationDetails.Refunded" />  {refund ? refund : 0}</Col>
                                                    <Col>
                                                        <FormattedMessage id="VoucherOperationDetails.Used" /> {redeems ? redeems : 0}
                                                    </Col>
                                                    <Col>
                                                        <b><FormattedMessage id="VoucherOperationDetails.Left" /> {(this.state.voucher.voucherValue - refund - redeems).toFixed(2)}</b>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col>
                                                        <Input
                                                            type="number"
                                                            name="voucherValue"
                                                            min="0.01"
                                                            step="0.01"
                                                            max={this.state.voucher.voucherValue - refund - redeems}
                                                            placeholder="Amount"
                                                            onChange={this.changeField}
                                                            value={this.state.voucherValue}
                                                            disabled={!this.state.voucher.voucherType.allowPartialRedeem}
                                                        />
                                                    </Col>
                                                    <Col className="text-right">
                                                        <Authorization
                                                            perform="useVoucher:save"
                                                            yes={() => (
                                                                <Button className="btn-host btn-sm" onClick={() => this.registRedeem(null, false)}
                                                                    disabled={this.state.voucherValue === 0 || moment(this.state.voucher.validUntil).isSameOrBefore(moment().format('YYYY-MM-DD')) || (this.state.voucher.status !== 'partialRedeemed' && this.state.voucher.status !== 'sold')}>
                                                                    <i className="fas fa-save" />
                                                                </Button>
                                                            )}
                                                            no={() => <div></div>}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col>
                                                        <Input
                                                            type="textarea"
                                                            name="notes"
                                                            placeholder="Notes"
                                                            onChange={this.changeField}
                                                            value={this.state.notes ? this.state.notes : ''}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                            : <Row>
                                                <Col>

                                                    {this.state.voucher.voucherType && this.state.voucher.voucherType.allowPartialRedeem === false ?
                                                        <Col className="text-right">
                                                            <Button className="btn-host btn-sm" onClick={() => this.registRedeem(null, true)}
                                                                disabled={moment(this.state.voucher.validUntil).isSameOrBefore(moment().format('YYYY-MM-DD')) || this.state.voucher.status !== 'sold'}>
                                                                <i className="fas fa-save" />
                                                            </Button>
                                                        </Col>
                                                    : ''}
                                                    <Row>
                                                    {
                                                        this.state.voucher.voucherType && this.state.voucher.voucherType.voucherRebates.map((el, k) => {
                                                            const left = el.quantity - (this.state.voucher.redeems != undefined && this.state.voucher.redeems.filter(x => x.rebateId === el.id).reduce((acc, cur) => acc + cur.quantity, 0));
                                                            return (
                                                                <Col key={k} className="col-3">
                                                                    <Card>
                                                                        <CardBody>
                                                                            <div><b>{el.description}</b></div>
                                                                            <Row className="mb-2">
                                                                                <Col><div><FormattedMessage id="VoucherOperationDetails.Total" /> </div> {el.quantity}</Col>
                                                                                <Col><div><FormattedMessage id="VoucherOperationDetails.Used" /></div>  {this.state.voucher.redeems != undefined && this.state.voucher.redeems.filter(x => x.rebateId === el.id).reduce((acc, cur) => acc + cur.quantity, 0)}</Col>
                                                                                <Col><div><FormattedMessage id="VoucherOperationDetails.Left" /></div>  <b>{left}</b></Col>
                                                                            </Row>
                                                                            <Row >
                                                                                <Col>
                                                                                    <Input
                                                                                        type="number"
                                                                                        name="quantity"
                                                                                        min="1"
                                                                                        max={el.quantity - (this.state.voucher.redeems != undefined && this.state.voucher.redeems.filter(x => x.rebateId === el.id).reduce((acc, cur) => acc + cur.quantity, 0))}
                                                                                        placeholder="quantity"
                                                                                        value={this.state.quantityList.find(item => item.id === el.id) !== undefined ? this.state.quantityList.find(item => item.id === el.id).value : ''}
                                                                                        onChange={this.changeQty.bind(this, el.id)}
                                                                                        disabled={!this.state.voucher.voucherType.allowPartialRedeem}
                                                                                    />
                                                                                </Col>
                                                                                {this.state.voucher.voucherType.allowPartialRedeem ?
                                                                                    <Col className="text-right">
                                                                                        <Button className="btn-host btn-sm" onClick={() => this.registRedeem(el.id, false)}
                                                                                            disabled={left === 0 || moment(this.state.voucher.validUntil).isSameOrBefore(moment().format('YYYY-MM-DD')) || (this.state.voucher.status !== 'partialRedeemed' && this.state.voucher.status !== 'sold')}>
                                                                                            <i className="fas fa-save" />
                                                                                        </Button>
                                                                                    </Col>
                                                                                : ''}
                                                                            </Row>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            );
                                                        })
                                                    }
                                                    </Row>
                                                </Col>
                                            </Row>}
                                    </StyledCard>
                                    <div className="mb-2" />
                                </form>
                            </div>
                            :
                            <div>
                                <ErrorAlert error={this.state.error} />
                                <Row className={this.state.error? "mt-3" : "mt-5"}>
                                    <Col className="text-center">
                                        {
                                            isBarcode ?
                                                <div>
                                                    <div><h4> Read Bar Code</h4></div>
                                                    <i className="fas fa-4x fa-barcode"></i>
                                                </div>
                                                :
                                                <div>
                                                    <div><h4> Read QR Code</h4></div>
                                                    <i className="fas fa-4x fa-qrcode"></i>
                                                </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        }
                    </Col>
                    <Col className="col-3">
                        {
                            isBarcode ?
                                (
                                    isHttps ?
                                        <BarcodeScannerComponent
                                            width={355}
                                            height={355}
                                            torch={false}
                                            onUpdate={this.handleBarCodeScan}
                                        />
                                        :
                                        <span />
                                )
                                :
                                <QrReader
                                    delay={300}
                                    onError={this.handleError}
                                    onScan={this.handleScan}
                                    style={{ width: '100%' }}
                                />
                        }
                    </Col>
                </Row>
            </BlockUi>
        )
    }
}

export default injectIntl(UseVoucher);