import React, { Component } from 'react';
import { Row, Col, Badge, Form, Card, Input, Button, UncontrolledTooltip } from 'reactstrap';
import { getAPI, putAPI } from '../../Base/API';
import { ActiveInactiveStatusCombo, StyledCard } from '../../Base/Common/CommonUIComponents';
import { FormattedMessage, injectIntl } from "react-intl";
import Authorization from '../../Base/Authorization';
import sendGrid from '../../../img/SendGrid.ico';
import { handleNotification } from '../../Base/Common/Notification';
import ConnectionModal from './ConnectionModal';


class HotelGroupConnections extends Component {
    static displayName = HotelGroupConnections.name;
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            bloack: false,
            modal: false,
            connections: [
                {
                    type: 'SendGrid',
                    logo: sendGrid,
                    configuration: { name: null, active: null, type: 'SendGrid', isConfigured: false },
                    title: <FormattedMessage id="HotelConfig.SendGridConnection" />
                }
            ]
        }
    }


    componentDidMount() {
        this.getHotelGroupApiConnections();
    }

    getHotelGroupApiConnections() {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.response && data.response.length > 0) {
                    const { connections } = this.state;

                    connections.forEach(connection => {
                        const config = data.response?.find(c => c.type === connection.type);

                        if (config) {
                            connection.configuration = config;
                        }
                    })

                    this.setState({ connections, block: false });
                }
                else this.setState({ block: false });
            }
            else this.setState({ block: false });
        }, `/api/Voucher/Hotel/v1/hotelgroup/apiConnections`);
    }

    saveConnection = (event, type) => {
        event.preventDefault();
        this.setState({ block: true });

        const connections = [...this.state.connections];
        const index = connections.findIndex(conn => conn.type === type);
        const config = connections[index].configuration;


        putAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="HotelConfig.ConnectionSaved" />, <FormattedMessage id="generic.success" />);

                    Object.assign(config, data.response[0]);
                    config.isConfigured = true;
                    this.setState({ error: errorMessage, connections: connections, block: false })
                }
            }
            else this.setState({ error: errorMessage, block: false });
        }, '/api/Voucher/Hotel/v1/hotelGroup/apiConnection', JSON.stringify({ request: config }));
    }

    toggleModal = (configuration) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedConfig: configuration
        }))
    }

    handleChange = (e, type) => {
        const { name, value } = e.target;
        const { connections } = this.state;
        const index = connections.findIndex(conn => conn.type === type);

        if (index !== -1) {
            connections[index].configuration[name] = value;
        }

        this.setState({ connections })
    }

    handleConnectionStatus = (type, fieldName, combo) => {
        const { connections } = this.state;
        const index = connections.findIndex(conn => conn.type === type);

        if (index !== -1) {
            connections[index].configuration.active = combo ? combo.value : null;
        }

        this.setState({ connections })
    };


    render() {
        const { connections } = this.state;


        return (
            <StyledCard icon={'fas fa-link'} title={'HotelConfig.Connections'} error={this.state.error} block={this.state.block}>

                {connections.map((connection, key) => global.modules && global.modules.some(m => m === connection.type) ?
                    <Form onSubmit={(e) => this.saveConnection(e, connection.type)} key={key}>
                        <Row className="align-items-center">
                            <Col>
                                <h5 className="m-0">
                                    <img alt="" src={connection.logo} height="20px" className="mr-2" />
                                    {connection.title}
                                </h5>
                            </Col>

                            <Col className="col-1"><FormattedMessage id="HotelConfig.Name" /></Col>
                            <Col className="col-3 pl-0">
                                <Input
                                    maxLength="200"
                                    name="name"
                                    value={connection.configuration && connection.configuration.name || ''}
                                    onChange={(e) => this.handleChange(e, connection.type)}
                                    required
                                />
                            </Col>
                            <Col className="col-1"><FormattedMessage id="generic.status" /></Col>
                            <Col className="col-2 pl-0">
                                <ActiveInactiveStatusCombo
                                    name='active'
                                    onChangeFunc={this.handleConnectionStatus.bind(this, connection.type)}
                                    placeholder={''}
                                    value={connection.configuration && connection.configuration.active}
                                    required
                                />
                            </Col>

                            <Col className="d-flex justify-content-end" sm={2}>
                                {connection.configuration && (connection.configuration.isConfigured === undefined || connection.configuration.isConfigured !== false) ?
                                    <Authorization
                                        perform="hotelConfig:connection:newKey"
                                        yes={() => (
                                            <>
                                                <Button className="btn btn-sm btn-host mr-2" onClick={() => this.toggleModal(connection.configuration)} id={`setNewKey${key}`}>
                                                    <i className="fas fa-key" />
                                                </Button>
                                                <UncontrolledTooltip placement="bottom" target={`setNewKey${key}`}>
                                                    <FormattedMessage id="HotelConfig.SetNewKey" />
                                                </UncontrolledTooltip>
                                            </>
                                        )}
                                        no={() => <div></div>}
                                    />
                                    : ''}


                                <Authorization
                                    perform="hotelConfig:connection:save"
                                    yes={() => (
                                        <Button className="btn btn-sm  btn-host " type="submit">
                                            <i className="fas fa-save "></i>
                                        </Button>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Col>
                        </Row>
                    </Form>
                    : '')}


                {this.state.modal ?
                    <ConnectionModal
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        selectedConfig={this.state.selectedConfig}
                    />
                : ''}
            </StyledCard>
        );
    }
}

export default injectIntl(HotelGroupConnections);