import React, { Component } from 'react';
import { FormGroup, Label, Input, Col, Card, CardBody, Table, Row, CardHeader, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getAPI, postAPI, deleteAPI } from "../Base/API";
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../Base/ErrorAlert";
import { CustomTable } from '../Base/Common/CustomTable'
import { handleNotification } from "../Base/Common/Notification"
import { ActiveInactiveStatusBadge, CommonHelper } from "../Base/Common/CommonUIComponents"
import Authorization from '../Base/Authorization';


class ManageUser extends Component {

    constructor(props) {
        super(props);


        this.form = React.createRef();
        this.toggleModal = this.toggleModal.bind(this);

        this.state = {
            hotelId: this.props.hotelId,
            block: true,
            blockUserDetails: false,
            blockProperty: false,
            modal: false,
            ComplexData: null,
            user: {},
            selectedUser: {},
            userDetails: {}
        };

    }

    componentDidMount() {

        this.searchUser();
        this.setState({ blockProperty: true });
        getAPI(result => {
            const { data, error, isLoading } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {

                this.setState({ user: data, property: data.defaultProperty, blockProperty: false });
            }
        }, '/api/User/v1/User');
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    searchUser() {
        this.setState({ block: true })
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ listUsers: data.users, property: data.defaultProperty, userDetails: {}, block: false });
            }
        }, '/api/User/v1/User/getUsers');
    }

    getAccessGroups() {
        return [
            { value: 'da9f33db-f99c-4f3f-8817-ccc263972d5a', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.da9f33db-f99c-4f3f-8817-ccc263972d5a' }) },
            { value: '7279a2b8-235c-4e18-9906-7d1baebdbf24', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.7279a2b8-235c-4e18-9906-7d1baebdbf24' }) },
            { value: 'ec96829b-df17-4e86-b85a-460995dc7e50', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.ec96829b-df17-4e86-b85a-460995dc7e50' }) },
            { value: '0901c0c4-f875-40ea-89bc-603394609a66', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.0901c0c4-f875-40ea-89bc-603394609a66' }) }            
        ]
    }

    getUserDetails() {
        this.setState({ blockUserDetails: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ userDetails: data.user, blockUserDetails: false });
            }
        }, '/api/User/v1/User/getUser/' + this.state.selectedUser.adId);
    }

    reinviteUser() {

        let body = {
            "adId": this.state.selectedUser.adId,
            "name": this.state.selectedUser.name,
            "surname": this.state.selectedUser.surname,
            "mobile": this.state.selectedUser.mobile,
            "email": this.state.selectedUser.email
        }

        this.setState({ blockUserDetails: true });
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="AdminUserManagement.NotificationSent" />, <FormattedMessage id="AdminUserManagement.Success" />);
                }

                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/reInviteUser?company=CARD', body);

    }

    disableUser() {

        this.setState({ blockUserDetails: true });
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="AdminUserManagement.UserDisabled" />, <FormattedMessage id="AdminUserManagement.Success" />);
                }

                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/disableUser/' + this.state.selectedUser.adId);

    }

    enableUser() {

        this.setState({ blockUserDetails: true });
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="AdminUserManagement.UserEnabled" />, <FormattedMessage id="AdminUserManagement.Success" />);
                }

                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/enableUser/' + this.state.selectedUser.adId);

    }

    deleteUser() {

        this.setState({ blockUserDetails: true });
        deleteAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="AdminUserManagement.UserDeleted" />, <FormattedMessage id="AdminUserManagement.Success" />);
                }

                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/deleteUser/' + this.state.selectedUser.adId);

    }

    createUser(event) {

        event.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails;

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="AdminUserManagement.UserCreated" />, <FormattedMessage id="AdminUserManagement.Success" />);
                }

                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/addUser?company=CARD', req);
    }

    saveUser(event) {

        event.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails;

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="AdminUserManagement.UserUpdated" />, <FormattedMessage id="AdminUserManagement.Success" />);
                }

                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/updateUser', req);
    }

    updateProperties() {

        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails.allowedProperties
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="AdminUserManagement.PropertiesUpdated" />, <FormattedMessage id="AdminUserManagement.Success" />);
                }

                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/' + this.state.selectedUser.adId + '/updateAllowedProperties', req);

    }

    updateGroups() {
        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails.groups;

        postAPI(result => {
            const { data, error, isLoading } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="AdminUserManagement.GroupUpdated" />, <FormattedMessage id="AdminUserManagement.Success" />);
                }

                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/' + this.state.selectedUser.adId + '/updateGroups', req);
    }

    manageList(el, type, event) {

        if (this.state.userDetails[type] === undefined)
            return;

        let newgroup = this.state.userDetails[type].indexOf(el) >= 0 ? this.state.userDetails[type].filter(ar => ar !== el) : [...this.state.userDetails[type], el]

        this.setState(prevState => ({
            ...prevState,
            userDetails: {
                ...prevState.userDetails,
                [type]: newgroup
            }
        }));

    }

    changeUserState(event) {

        let name = event.target.name;
        let value = event.target.value;

        this.setState(prevState => ({
            ...prevState,
            userDetails: {
                ...prevState.userDetails,
                [name]: value
            }
        }));
    }

    render() {
        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "ManageUser.name" }),
                sort: true
            },
            {
                dataField: 'surname',
                text: this.props.intl.formatMessage({ id: "ManageUser.surname" }),
                sort: true
            },
            {
                dataField: 'email',
                text: this.props.intl.formatMessage({ id: "ManageUser.email" }),
                sort: true
            },
            {
                dataField: 'mobile',
                text: this.props.intl.formatMessage({ id: "ManageUser.mobile" }),
                sort: true
            },/*
            {
                dataField: 'userPrinciplaName',
                text: this.props.intl.formatMessage({ id: "ManageUser.userPrinciplaName" }),
                sort: true
            }*/
            {
                dataField: 'accountEnabled',
                text: this.props.intl.formatMessage({ id: "ManageUser.accountStatus" }),
                sort: true,
                formatter: (cell, row) => <ActiveInactiveStatusBadge status={cell} />
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ selectedUser: row, blockUserDetails: true }, () => this.getUserDetails())
            }
        };

        {/*const accessGroups = [
            {
                value: 'Administrator',
                label: <FormattedMessage id="ManagerUser.Administrator" />
            },
            {
                value: 'Price Management',
                label: <FormattedMessage id="ManagerUser.PriceManagement" />
            },
            {
                value: 'Marketing Management',
                label: <FormattedMessage id="ManagerUser.MarketingManagement" />
            },
            {
                value: 'View Only',
                label: <FormattedMessage id="ManagerUser.ViewOnly" />
            },

        ];

        function getAccessGroup(item) {
            const accessGroup = accessGroups.find(accessGroup => accessGroup.value === item.label);
            return accessGroup !== undefined ? accessGroup.label : '';
        }*/}

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />

                <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >
                    <ModalHeader toggle={this.toggleModal}  > <FormattedMessage id="ConfigChannelAutoActions.Channel" />
                        Rule For channel code {this.state.modalData ? this.state.modalData.channelConfigId : ''}
                    </ModalHeader>
                    <ModalBody className="modal-lg px-0 pb-0">
                    </ModalBody>
                </Modal>

                <form ref={this.form}>
                    <div className="container-fluid content-row">
                        <Row>
                            <Col className="Col-6 pl-0">
                                <BlockUi tag="div" blocking={this.state.block}>
                                    <Card className="mb-2 border-0 shadow">
                                        <CardHeader className="border-bottom bg-white">
                                            <h6>
                                                <span className="fas fa-users mr-1/" />
                                                <FormattedMessage id="ManageUser.users" />
                                                <Button className="btn btn-host btn-sm float-right mx-1" onClick={this.searchUser.bind(this)}>
                                                    <i className="fas fa-sync-alt" />
                                                </Button>
                                            </h6>
                                        </CardHeader>
                                        <CardBody>
                                            <CustomTable
                                                data={this.state.listUsers ? this.state.listUsers : []}
                                                columns={columns}
                                                page={this.state.currentPage}
                                                totalSize={this.state.listUsers && this.state.listUsers.length}
                                                onTableChange={this.handleTableChange}
                                                shadow={false}
                                                exportCSV={false}
                                                selectRow={selectRow}
                                                search={true}
                                                remote={false}
                                            />
                                        </CardBody>
                                    </Card>
                                </BlockUi>
                                <BlockUi tag="div" blocking={this.state.blockUserDetails}>
                                    <Card className="mb-2 border-0 shadow small">
                                        <CardHeader className="border-bottom bg-white">
                                            <h6>
                                                <CommonHelper help={<FormattedMessage id="ManageUser.Help" />} id={'manageruserhelp'} />
                                                <span className="fas fa-id-card mr-1" />
                                                <FormattedMessage id="ManageUser.selectedUser" />
                                                {
                                                    this.state.userDetails.id === undefined ?
                                                        <Authorization
                                                            perform="manageUser:add"
                                                            yes={() => (
                                                                <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === null} onClick={this.createUser.bind(this)}><i className="fas fa-plus"></i></Button>
                                                            )}
                                                            no={() => <div></div>}
                                                        />
                                                        :
                                                        <div>
                                                            <Authorization
                                                                perform="manageUser:selectedUser:delete"
                                                                yes={() => (
                                                                    <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === undefined} onClick={this.deleteUser.bind(this)}>
                                                                        <i className="fas fa-user-times" />
                                                                    </Button>
                                                                )}
                                                                no={() => <div></div>}
                                                            />
                                                            {
                                                                this.state.userDetails.accountEnabled ?
                                                                    <Authorization
                                                                        perform="manageUser:selectedUser:disable"
                                                                        yes={() => (
                                                                            <Button className="btn btn-host btn-sm float-right mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.disableUser.bind(this)} >
                                                                                <i className="fas fa-user-slash"></i>
                                                                            </Button>
                                                                        )}
                                                                        no={() => <div></div>}
                                                                    />
                                                                    :
                                                                    <Authorization
                                                                        perform="manageUser:selectedUser:enable"
                                                                        yes={() => (
                                                                            <Button className="btn btn-host btn-sm float-right mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.enableUser.bind(this)}>
                                                                                <i className="fas fa-user-check" />
                                                                            </Button>
                                                                        )}
                                                                        no={() => <div></div>}
                                                                    />
                                                            }
                                                            <Authorization
                                                                perform="manageUser:selectedUser:save"
                                                                yes={() => (
                                                                    <Button className="btn btn-host btn-sm float-right mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.saveUser.bind(this)}  >
                                                                        <i className="fas fa-save" />
                                                                    </Button>
                                                                )}
                                                                no={() => <div></div>}
                                                            />
                                                        </div>
                                                }
                                            </h6>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm small">
                                                        <Label for="User" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.user" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" readOnly name="userPrinciplaName" value={this.state.userDetails.userPrinciplaName ? this.state.userDetails.userPrinciplaName : ''} id="userPrinciplaName" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm">
                                                        <Label for="Email" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.email" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="email" required email bsSize="sm" name="email" value={this.state.userDetails.email ? this.state.userDetails.email : ''} onChange={this.changeUserState.bind(this)} id="email" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm">
                                                        <Label for="FirstName" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.name" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" required name="name" value={this.state.userDetails.name ? this.state.userDetails.name : ''} onChange={this.changeUserState.bind(this)} id="name" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm">
                                                        <Label for="FamilyName" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.surname" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" required name="surname" value={this.state.userDetails.surname ? this.state.userDetails.surname : ''} onChange={this.changeUserState.bind(this)} id="surname" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup row size="sm">
                                                        <Label for="mobile" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.mobile" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" required name="mobile" value={this.state.userDetails.mobile ? this.state.userDetails.mobile : ''} onChange={this.changeUserState.bind(this)} id="mobile" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>

                                                <Col>
                                                    <FormGroup row size="sm">
                                                        <Label for="stat" className="font-weight-bold" sm={3}> Inv. Status </Label>
                                                        <Col sm={this.state.userDetails.status && this.state.userDetails.status === 'PendingAcceptance' ? 7 : 9}>

                                                            <Input type="text" bsSize="sm" name="stat" defaultValue={this.state.userDetails.status ? this.state.userDetails.status : ''} disabled id="mobile" />
                                                        </Col>
                                                        {this.state.userDetails.status && this.state.userDetails.status === 'PendingAcceptance' ?
                                                            <Col>
                                                                <Button className="btn btn-sm btn-host float-right"><i className="far fa-share-square" onClick={this.reinviteUser.bind(this)}></i></Button>
                                                            </Col>
                                                            : ''}
                                                    </FormGroup>

                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </BlockUi>
                            </Col>
                            <Col className="pr-0">
                                <Row>
                                    <Col className="pr-1">
                                        <BlockUi tag="div" blocking={this.state.blockUserDetails || this.state.blockProperty}>
                                            <Card className="mb-1 h-100  border-0 shadow">
                                                <CardHeader className="border-bottom bg-white">
                                                    <h6>
                                                        <span className="fas fa-hotel mr-1"></span> <FormattedMessage id="Profile.Property" />
                                                        <Authorization
                                                            perform="manageUser:property:save"
                                                            yes={() => (
                                                                <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === undefined} onClick={this.updateProperties.bind(this)}>
                                                                    <i className="fas fa-save" />
                                                                </Button>
                                                            )}
                                                            no={() => <div></div>}
                                                        />
                                                    </h6>
                                                </CardHeader>
                                                <CardBody>
                                                    <Table className={"table-sm table-borderless" + (this.state.userDetails.id === undefined ? '  text-secondary' : '')}>
                                                        <tbody>
                                                            {
                                                                this.state.user.userAllowedProperties && this.state.user.userAllowedProperties.map((item, key) =>
                                                                    <tr key={key} className={this.state.userDetails && this.state.userDetails.allowedProperties && this.state.userDetails.allowedProperties.filter(el => el === item.property).length > 0 ? 'bg-secondary text-white' : ''} >
                                                                        <th scope="row"> {key}</th>
                                                                        <td onClick={this.manageList.bind(this, item.property, 'allowedProperties')}>{item.propertyName}</td>
                                                                    </tr>
                                                                )}
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </BlockUi>
                                    </Col>
                                    <Col className="pl-1">
                                        <BlockUi tag="div" blocking={this.state.blockUserDetails}>
                                            <Card className="mb-1 h-100  bg-white border-bottom shadow">
                                                <CardHeader className="border-bottom bg-white">
                                                    <h6>
                                                        <span className="fas fa-users mr-1" />
                                                        <FormattedMessage id="Profile.AccessGroups" />

                                                        <Authorization
                                                            perform="manageUser:accessGroup:save"
                                                            yes={() => (
                                                                <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === undefined} onClick={this.updateGroups.bind(this)} >
                                                                    <i className="fas fa-save" />
                                                                </Button>
                                                            )}
                                                            no={() => <div></div>}
                                                        />
                                                    </h6>
                                                </CardHeader>
                                                <CardBody>
                                                    <Table className={"table-sm table-borderless" + (this.state.userDetails.id === undefined ? '  text-secondary' : '')}>
                                                        <tbody>
                                                            {
                                                                this.getAccessGroups().map((item, key) =>
                                                                    <tr key={key}>
                                                                        <th scope="row"> {key}</th>
                                                                        <td className={this.state.userDetails && this.state.userDetails.groups && this.state.userDetails.groups.filter(el => el === item.value).length > 0 ? 'bg-secondary text-white' : ''} onClick={this.manageList.bind(this, item.value, 'groups')} >
                                                                            {item.label}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </BlockUi>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </form>
            </BlockUi>
        );
    }
};

export default injectIntl(ManageUser)