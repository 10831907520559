import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Button, Input, Form, CustomInput, Label, FormGroup } from 'reactstrap';
import { postAPI, deleteAPI } from '../../Base/API';
import { BlankCard } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from "../../Base/Common/Notification";
import { FormattedMessage, injectIntl } from 'react-intl';

class TagModal extends Component {
    static displayName = TagModal.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = {
            block: false,
            tag: this.props.selectedTag || {},
        }
    }

    saveTag = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = this.state.tag;

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="VoucherTags.TagSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0], false);
                }

                this.setState({ block: false });
            }
            else this.setState({ block: false });
        }, '/api/Voucher/Voucher/v1/voucherTag', JSON.stringify({ request: body }));
    }

    removeTag = (e) => {
        e && e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="VoucherTags.TagDeleted" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(this.state.tag, true);
                }

                this.setState({ block: false });
            }
            else this.setState({ block: false });
        }, '/api/Voucher/Voucher/v1/voucherTag/' + this.state.tag.id);
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState(prevState => ({
            tag: {
                ...prevState.tag,
                [name]: value
            }
        }));
    }

    handleSwitch = (evt) => {
        const { id, checked } = evt.target;

        this.setState(prevState => ({
            tag: {
                ...prevState.tag,
                [id]: checked
            }
        }));
    }

    render() {
        const { tag } = this.state;

        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} fade={false} className="modal-xl" >
                <ModalHeader toggle={this.props.toggleModal}>
                    {tag.id ? <FormattedMessage id="VoucherTags.EditTag" /> : <FormattedMessage id="VoucherTags.CreateTag" />}
                </ModalHeader>
                <ModalBody className="modal-xl p-0">
                    <BlankCard block={this.state.block} error={this.state.error}>
                        <Form onSubmit={this.saveTag}>
                            <Row className="mb-2">
                                <Col className="text-right">
                                    {tag.id ?
                                        <Button className="btn-sm btn-host" onClick={this.removeTag}>
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    : ''}
                                    <Button className="btn-sm btn-host ml-2" type="submit"> <i className="fas fa-save" /></Button>
                                </Col>
                            </Row>
                            <FormGroup row className="align-items-center">
                                <Label sm={2}> <FormattedMessage id="VoucherTags.Status" /></Label>
                                <Col className="col-10">
                                    <CustomInput
                                        name="active"
                                        type="switch"
                                        id="active"
                                        checked={tag.active}
                                        onChange={(evt) => this.handleSwitch(evt)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="VoucherTags.Code" /></Label>
                                <Col className="col-10">
                                    <Input
                                        name="code"
                                        maxLength="50"
                                        placeholder={this.props.intl.formatMessage({ id: "VoucherTags.Code" })}
                                        value={tag.code}
                                        onChange={(e) => this.handleChange(e, 'code')}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="VoucherTags.Description" /></Label>
                                <Col className="col-10">
                                    <Input
                                        name="description"
                                        maxLength="500"
                                        placeholder={this.props.intl.formatMessage({ id: "VoucherTags.Description" })}
                                        value={tag.description}
                                        onChange={(e) => this.handleChange(e, 'description')}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(TagModal);