import React, { Component } from 'react';
import { Label, Row, Col, Button, ModalBody, Modal, ModalFooter, Form } from 'reactstrap';
import { postAPI, getAPI } from '../Base/API';
import { PaymentPositionStatus, BlankCard, paymentStatus } from '../Base/Common/CommonUIComponents';
import { handleNotification } from "../Base/Common/Notification";
import { CustomTable } from '../Base/Common/CustomTable';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../Base/Common/CustomSelect';
import Authorization, { CheckAuthorization } from '../Base/Authorization';
import { ConfirmationModal } from './ConfirmationModal';
import { PaymentModal } from './PaymentModal';
import { Line } from 'react-chartjs-2';
import moment from 'moment'

export class PaymentDashboard extends Component {
    static displayName = PaymentDashboard.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            payments: [],
            modal: false,
            clientCashDeposit: null,
            pageSize: 5,
            pageIndex: 0,
            totalItems: 0,
            selectedPayment: null,
            confirmationModal: false,
            isPayed: false
        }       
    }

    componentDidMount() {
        this.getClientCashDeposit(this.props.match.params.id);
    }

    getClientCashDeposit = (hotelGroupId) => {
        this.setState({ block: true });
        var params = '';
        params += this.state.status ? `&status=${this.state.status}` : '';
        params += hotelGroupId ? `&hotelGroup=${hotelGroupId}` : '';

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(({ block: false, error: errorMessage }));
                return;
            }
            if (data) {
                this.setState(({ error: [], payments: data.response, totalItems: data.count }));
            }
            this.setState(({ block: false }));
        }, `/api/Voucher/v1/Config/clientCashDeposit?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}` + params);
    }

    doSearch = () => {
        this.setState({
            pageIndex: 0
        }, () => this.getClientCashDeposit())
    }

    toggleModal = (modal, payment, isPayed) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedPayment: payment,
            isPayed
        }));
    }

    updateTable = (payment) => {
        const { payments } = this.state;
        this.setState({ block: true, payments: [] });
        const index = payments && payments.indexOf(payments.find(p => p.id === payment.id));

        if (index !== -1) {
            payments[index] = payment;
        }
        else {
            payments.push(payment);
        }

        this.setState({ block: false, payments });
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null,
            amount: combo ? combo.amount : null,
            numberOfVouchers: combo ? combo.numberOfVouchers : null
        })
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, () => { this.getClientCashDeposit() });
    }

    render() {
        const channelColumns = [
            {
                dataField: 'hotelName',
                text: 'Hotel'
            },
            {
                dataField: 'requestDate',
                text: this.props.intl.formatMessage({ id: 'PaymentDashboard.RequestDate' }),
                formatter: (cell) => moment(cell).format('YYYY-MM-DD')
            },
            {
                dataField: 'value',
                text: this.props.intl.formatMessage({ id: 'PaymentDashboard.Value' })
            },
            {
                dataField: 'numberOfVouchers',
                text: this.props.intl.formatMessage({ id: 'PaymentDashboard.NumberOfVouchers' })
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: 'PaymentDashboard.Status' }),
                formatter: (cell) => <PaymentPositionStatus status={cell} />
            },
            {
                dataField: 'payday',
                text: this.props.intl.formatMessage({ id: "PaymentDashboard.Payday" }),
                formatter: (cell) => cell ? moment(cell).format('YYYY-MM-DD') : ''                
            },
            {
                dataField: 'id',
                isDummyField: true,
                text: 'Cancelar Pagamaneto',
                formatter: (cell, row) => row.status === 'Requested' && row.paymentType === 'Prepay' ?
                    <div className="text-center" ><Button className="btn-sm " onClick={() => this.toggleModal('confirmationModal', row, false)}> Cancelar </Button> </div>
                    : '',
                headerStyle: { width: "12%" }
            },
            {
                dataField: '',
                isDummyField: true,
                text: 'Marcar como Pago',
                formatter: (cell, row) => row.status === 'Requested' && row.paymentType === 'Prepay' ?
                    <div className="text-center" ><Button className="btn-sm btn-host" onClick={() => this.toggleModal('confirmationModal', row, true)}> Pago </Button> </div>
                    : '',
                headerStyle: { width: "12%" },
                hidden: CheckAuthorization("payment:payed") ? false : true 
            }
        ]

        let optionsCount = {
            legend: {
                position: "top"
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        displayFormats: {
                            quarter: 'MMM YYYY'
                        }
                    },
                    gridLines: {
                        drawOnChartArea: false
                    }
                }],
                yAxes: [{
                    ticks: {

                        stepSize: 1
                    },
                    gridLines: {
                        drawOnChartArea: false
                    }
                }]
            }
        }

        let objCashCount = {
            "drawOnChartArea": false,
            "datasets":
                [
                    {
                        "label": "Voucher gerados",
                        "data": [50,150,190,270,350],
                        "fill": true,
                        "borderColor": "#64dd17",
                        "backgroundColor": "#64dd17aa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    },
                    {
                        "label": "Voucher Disponiveis",
                        "data": [150, 300, 300, 300, 450],
                        "fill": false,
                        "borderColor": "#8bc34a",
                        "backgroundColor": "#8bc34aaa",
                        "borderWidth": "1",
                        "lineTension": "0.2"
                    }

                ],
            "labels": [new Date(), new Date().setMonth(2), new Date().setMonth(3), new Date().setMonth(4), new Date().setMonth(5)]
        };

        const help = <div><FormattedMessage id="PaymentDashboard.Help" /></div>

        return (
            <div >
                {/*
                <Row className="mb-2"> 
                    <Col className="col-4">
                        <BlankCard>
                            <div className="font-weight-bold">Total Comprados</div>
                            <div className="text-right font-weight-bolder text-secondary" style={{ fontSize: '150%' }}>450</div>
                        </BlankCard>
                    </Col>
                    <Col className="col-4">
                        <BlankCard>
                            <div className="font-weight-bold">Total Emitidos</div>
                            <div className="text-right font-weight-bolder text-secondary" style={{ fontSize: '150%' }}>350</div>
                        </BlankCard>
                    </Col>
                    <Col className="col-4">
                        <BlankCard>
                            <div className="font-weight-bold">Total Disponiveis</div>
                            <div className="text-right font-weight-bolder text-secondary" style={{ fontSize: '150%' }}>100</div>
                        </BlankCard>
                    </Col>
                </Row>
                */}
                <Row className="mb-2">
                    <Col>
                        <BlankCard >
                            <Row className="mb-2">
                                <Col>Pré pago / Pós Pago</Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host btn" onClick={() => this.toggleModal('modal')}>Carregar</Button>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-3">
                                    <CustomSelect
                                        isClearable
                                        placeholder={<FormattedMessage id="PaymentDashboard.Status" />}
                                        options={paymentStatus}
                                        onChange={this.handleSelect.bind(this, 'status')}
                                    />
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host btn" onClick={this.doSearch}>
                                        <i className="fas fa-search" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>                        
                                    <CustomTable
                                        data={this.state.payments ? this.state.payments : []}
                                        columns={channelColumns}
                                        shadow={false}
                                        showTotal={true}
                                        remote={true}
                                        page={this.state.pageIndex + 1}
                                        sizePerPage={this.state.pageSize}
                                        totalSize={this.state.totalItems}
                                        onTableChange={this.handleTableChange}
                                    />                        
                                </Col>
                            </Row>
                        </BlankCard>
                    </Col>
                </Row>
                {/*
                <BlankCard className="mb-2">
                    <Row>
                        <Col>
                            <Line data={objCashCount} height={'60px'} options={optionsCount} />
                        </Col>
                    </Row>
                </BlankCard>
                */}

                {this.state.modal ? 
                    <PaymentModal
                        modal={this.state.modal}
                        toggle={() => this.toggleModal('modal')}
                        updateTable={this.updateTable}
                    />
                : ''}

                {this.state.confirmationModal ?
                    <ConfirmationModal
                        modal={this.state.confirmationModal}
                        toggleModal={this.toggleModal}
                        updateTable={this.updateTable}
                        selectedPayment={this.state.selectedPayment}
                        isPayed={this.state.isPayed}
                    />
                : ''}
            </div>
        );
    }
}
export default injectIntl(PaymentDashboard);