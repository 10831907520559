import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export class CustomExportCSV extends Component {

    handleClick() {
        const { handleOnClick } = this.props;
        handleOnClick(() => {
            this.props.onExport();
        });
    }

    render() {
        const { children, tooltipMessage } = this.props;

        return (
            <div>
                <button id="exportCsvButton" className="btn btn-host btn-sm float-right" onClick={this.handleClick.bind(this)}>
                    {children}
                </button>
                <UncontrolledTooltip placement="left" target="exportCsvButton">
                    {tooltipMessage}
                </UncontrolledTooltip>
            </div>
        );
    }
}