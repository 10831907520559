import React, { Component } from 'react';
import { Row, Col, Button, Badge, Input } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import { CustomTable } from '../../Base/Common/CustomTable';
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../../Base/Common/CustomSelect';

export class ApiGatewayUserManagement extends Component {
    static displayName = ApiGatewayUserManagement.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            blockHotels: true,
            keyList: [],
            hotels: [],
            note: null,
            email: null,
            firstname: null
        };
        this.getUSers = this.getUSers.bind(this);
        this.getHotels = this.getHotels.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getUSers();
        this.getHotels();
    }

    getUSers() {
        const { note, email, firstname } = this.state;

        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ userList: data.value, block: false });
            }
        }, `/api/User/APIGateway/v1/Users?note=${note !== null ? note : ''}&email=${email !== null ? email : ''}&firstname=${firstname !== null ? firstname : ''}`);
    }

    getHotels() {
        this.setState({ blockHotels: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockHotels: false });

                return;
            }
            if (data) {
                if (data.response && data.response.length > 0) {
                    const hotels = data.response.map(h => {
                        const hotel = {
                            value: `${h.hotelId}`,
                            label: `${h.name2}`
                        };
                        return hotel;
                    });
                    this.setState({ hotels: hotels });
                }
            }
            this.setState({ blockHotels: false });
        }, `/api/Voucher/Hotel/v1/AllHotel`);
    }

    handleChange(evt) {
        if (evt.target) {
            const { name, value } = evt.target;
            this.setState({ [name]: value });
        }
    }

    setCombo(name, combo) {
        this.setState({ [name]: combo && combo.value });
    }

    render() {
        const { error, block, blockHotels, userList, hotels, note, email, firstname } = this.state;

        const columns = [
            {
                dataField: 'firstName',
                text: <FormattedMessage id="ApiGatewayUserManagement.FirstName" />,
                sort: true,
                formatter: (cell, row) => <div>{row.properties.firstName}</div>
            },
            {
                dataField: 'lastName',
                text: <FormattedMessage id="ApiGatewayUserManagement.LastName" />,
                sort: true,
                formatter: (cell, row) => <div>{row.properties.lastName}</div>
            },
            {
                dataField: 'email',
                text: <FormattedMessage id="ApiGatewayUserManagement.Email" />,
                sort: true,
                formatter: (cell, row) => row.properties.email
            },
            {
                dataField: 'registrationDate',
                text: <FormattedMessage id="ApiGatewayUserManagement.RegistrationDate" />,
                sort: true,
                formatter: (cell, row) => row.properties.registrationDate
            },
            {
                dataField: 'note',
                text: <FormattedMessage id="ApiGatewayUserManagement.Note" />,
                sort: true,
                formatter: (cell, row) => row.properties.note
            },
            {
                dataField: 'state',
                text: <FormattedMessage id="ApiGatewayUserManagement.State" />,
                sort: true,
                formatter: (cell, row) => <Badge className={row.properties.state === "active" ? 'btn-host p-1' :'p-1'}>{row.properties.state}</Badge>
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push('/ApiGatewayUserDetails/' + row.name)
            }
        };

        const help = <div> </div>

        return (
            <StyledCard icon={'fas fa-user-cog'} title={'ApiGatewayUserManagement.ApiGatewayUsers'} help={help} error={error} block={block || blockHotels}>
                <Row className="mt-4">
                    <Col>
                        <h4>
                            <FormattedMessage id="ApiGatewayUserDetails.Subscriptions" />
                        </h4>
                    </Col>
                </Row>
                <Row className="mt-4 mb-1">
                    <Col>
                        <CustomSelect options={hotels} value={hotels.find(opt => opt.value === note)} onChange={this.setCombo.bind(this, 'note')} isClearable isSearchable placeholder={<FormattedMessage id="ApiGatewayUserManagement.Note" />} />
                    </Col>
                    <Col>
                        <FormattedMessage id="ApiGatewayUserManagement.Email">{
                            placeholder => <Input type="text" id="email" name="email" value={email ? email : ''} placeholder={placeholder} onChange={this.handleChange} />
                        }
                        </FormattedMessage>
                    </Col>
                    <Col>
                        <FormattedMessage id="ApiGatewayUserManagement.FirstName">{
                            placeholder => <Input type="text" id="firstname" name="firstname" value={firstname ? firstname : ''} placeholder={placeholder} onChange={this.handleChange} />
                        }
                        </FormattedMessage>
                    </Col>
                    <Col className="text-right">
                        <Button onClick={this.getUSers} className="btn-sm btn-host mr-1">
                            <i class="fas fa-search" />
                        </Button>
                        <Button onClick={() => this.props.history.push('/ApiGatewayUserDetails/')} className="btn-sm btn-host">
                            <i class="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <CustomTable
                    data={userList ? userList : []}
                    columns={columns}
                    shadow={false}
                    exportCSV={false}
                    showTotal={true}
                    remote={false}
                    selectRow={selectRow}
                />
            </StyledCard>
        );
    }
}
