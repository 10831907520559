import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { getAPI, deleteAPI } from '../../Base/API';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from "../../Base/Common/Notification";
import { CustomTable } from '../../Base/Common/CustomTable';
import { FormattedMessage, injectIntl } from "react-intl";
import Authorization from '../../Base/Authorization';
import { PriceDetails } from "./PriceDetails"

class PriceList extends Component {
    static displayName = PriceList.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            modal: false,
            priceList: [],
            selectedPrice: null
        }
    }
    
    componentDidMount() {
        this.getPriceList();
    }

    getPriceList = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ priceList: data.response, block: false });
            }
        }, `/api/Voucher/v1/Config/cardPrice`);
    }

    setComboStatus = (evt, inputValue) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value);
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    }

    handleSelect(combo) {
        this.setState({
            selectedRule: combo ? combo.value : null
        })
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedPrice: null,
        }));
    }

    updateTable = (data) => {
        this.setState({ block: true });
        const index = this.state.priceList && this.state.priceList.indexOf(this.state.priceList.find(l => l.id === data.id));
        const priceList = [...this.state.priceList];

        if (index !== -1) {
            priceList[index] = data;
        }
        else {
            priceList.push(data);
        }

        this.setState({ block: false, priceList });
    }

    deletePriceList = (evt, id) => {
        evt.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="PriceList.PriceDeleted" />, <FormattedMessage id="generic.success" />);
                    var list = this.state.priceList.filter(p => p.id !== id);
                    this.setState({ priceList: list });
                    this.toggleModal();
                }
                this.setState({ block: false });
            }
        }, `/api/Voucher/v1/Config/cardPrice/${id}`);
    }

    render() {
        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "PriceList.Name" }),
                sort: true
            },
            {
                dataField: 'numberOfVouchers',
                text: this.props.intl.formatMessage({ id: "PriceList.NumberOfVouchers" }),
                sort: true
            },
            {
                dataField: 'amount',
                text: this.props.intl.formatMessage({ id: "PriceList.Amount" }),
                sort: true
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState(prevState => ({
                    modal: !prevState.modal,
                    selectedPrice: row,
                }));
            }
        };

        const help = <div> </div>
        return (
            <StyledCard icon={'fas fa-file-alt'} title={'PriceList.Title'} help={help} error={this.state.error} block={this.state.block}>
                <Row className="mb-3">
                    <Col className="text-right">
                        <Authorization
                            perform="priceList:add"
                            yes={() => (
                                <Button className="btn btn-host btn-sm float-right mx-1" onClick={this.toggleModal}>
                                    <i className="fas fa-plus"></i>
                                </Button>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>

                <CustomTable
                    data={this.state.priceList ? this.state.priceList : []}
                    columns={columns}
                    shadow={false}
                    showTotal={true}
                    remote={false}
                    selectRow={selectRow}
                />

                {this.state.modal ?
                    <PriceDetails
                        modal={this.state.modal}
                        toggle={this.toggleModal}
                        selectedPrice={this.state.selectedPrice}
                        updateTable={this.updateTable}
                        deletePriceList={this.deletePriceList}
                    />
                : ''}
            </StyledCard>
        );
    }
}

export default injectIntl(PriceList);