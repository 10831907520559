import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, CardBody } from 'reactstrap';
import { getAPI } from '../Base/API';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import { CustomTable } from '../Base/Common/CustomTable';
import ReactHtmlParser from 'react-html-parser';
import { FormattedMessage, injectIntl } from "react-intl";

export class VoucherTemplates extends Component {
    static displayName = VoucherTemplates.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            block: true,
            modal: false,
            selectedLine: undefined,
            templateList: [],
            currentPage: 0,
            PageSize: 10,
        }
    }


    componentDidMount() {
        this.getTemplates();

    }

    getTemplates() {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ templateList: data.response, block: false });
            }
            else {
                this.setState({ templateList: [], block: false });
            }
        }, '/api/Voucher/v1/Config/notificationTemplates');
    }

    getTemplateSample = (templateId) => {        
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ selectedLine: data.response[0], modal: true, error: data.erros });
                }
            }
            this.setState({ block: false });
        }, `/api/Voucher/v1/Notification/voucherNotification/sample/${templateId}`);
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "VoucherTemplates.name" }),
                sort: true
            },
            {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "VoucherTemplates.type" }),
                sort: true
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "VoucherTemplates.status" }),
                sort: true
            },
            {
                dataField: 'languageCode',
                text: this.props.intl.formatMessage({ id: "VoucherTemplates.lang" }),
                sort: true
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.getTemplateSample(row.id);
            }
        };


        return (
            <StyledCard icon={'far fa-file'} title={'VoucherTemplates.title'} help={<FormattedMessage id="VoucherTemplates.Help" />} id="voucherTemplates" error={this.state.error} block={this.state.block}>
                {this.state.modal ?
                    <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-xl" >
                        <ModalHeader toggle={this.toggleModal}>
                            <FormattedMessage id="VoucherTemplates.templatedisplay" />
                        </ModalHeader>
                        <ModalBody className="modal-xl p-0">
                            <CardBody>


                                {ReactHtmlParser(this.state.selectedLine && this.state.selectedLine.template)}


                            </CardBody>
                        </ModalBody>
                    </Modal>
                    : ''}

                <CustomTable
                    keyField='id'
                    data={this.state.templateList ? this.state.templateList : []}
                    columns={columns}
                    shadow={false}
                    showTotal={true}
                    remote={false}
                    selectRow={selectRow}
                />
            </StyledCard>


        );
    }
}

export default injectIntl(VoucherTemplates);