import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { ActiveInactiveStatusBadge, StyledCard } from '../../Base/Common/CommonUIComponents';
import { CustomTable } from '../../Base/Common/CustomTable';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Common/Notification';
import TagModal from './TagModal';

class VoucherTags extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            voucherTags: [],
            modal: false
        }
    }

    componentDidMount() {
        this.getVoucherTags();
    }

    getVoucherTags = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ voucherTags: data.response, block: false });
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Voucher/Voucher/v1/voucherTag` )
    }

    toggleModal = (tag) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedTag: tag
        }));
    }

    updateTable = (tag, isToRemove) => {
        this.setState({ block: true });

        const { voucherTags } = this.state;

        const index = voucherTags && voucherTags.findIndex(t => t.id === tag.id);

        if (isToRemove && index !== -1) {
            voucherTags.splice(index, 1)
        }
        else if (index !== -1) {
            voucherTags[index] = tag;
        }
        else {
            voucherTags.push(tag);
        }

        this.setState({ block: false, voucherTags, modal: false });
    }

    render() {
        const { voucherTags, modal } = this.state;

        const columns = [
            {
                dataField: 'code',
                text: this.props.intl.formatMessage({ id: "VoucherTags.Code" }),
            },
            {
                dataField: 'description',
                text: this.props.intl.formatMessage({ id: "VoucherTags.Description" })
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "VoucherTags.Status" }),
                formatter: (cell) => <ActiveInactiveStatusBadge status={cell} />,
                headerStyle: { width: "10%" }
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.toggleModal(row);
            }
        };

        return (
            <StyledCard icon="fas fa-tag" block={this.state.block} error={this.state.error} title={`navbar.VoucherTags`}>
                {modal ?
                    <TagModal
                        modal={modal}
                        toggleModal={this.toggleModal}
                        selectedTag={this.state.selectedTag}
                        updateTable={this.updateTable}
                    />
                    : ''}

                <Row className="mb-2">
                    <Col className="text-right">
                        <Button className="btn-sm btn-host" onClick={this.toggleModal}>
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>

                <CustomTable
                    data={voucherTags || []}
                    columns={columns}
                    shadow={false}
                    exportCSV={false}
                    showTotal={true}
                    remote={false}
                    selectRow={selectRow}
                />
            </StyledCard>
        );
    }
}

export default injectIntl(VoucherTags)