import React, { Component } from 'react';
import { Button, Col, Input, Row, Modal, Form, ModalBody } from 'reactstrap';
import { getAPI } from '../Base/API';
import { StyledCard, SelectHotel } from '../Base/Common/CommonUIComponents';
import CustomSelect from '../Base/Common/CustomSelect';
import { CustomTable } from '../Base/Common/CustomTable';
import { getStatus, getChannels, getRightVoucherId } from '../Base/Common/ReferenceDataFunctions';
import AddVoucher from './AddVoucher';
import { FormattedMessage, injectIntl } from 'react-intl';
import Authorization from '../Base/Authorization';
import { handleNotification } from '../Base/Common/Notification';
import moment from 'moment';

export class OperationManagement extends Component {
    static displayName = OperationManagement.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.getVouchers = this.getVouchers.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.voucherIdForm = React.createRef();
        this.state = {
            block: true,
            blockCfg: true,
            voucherList: [],
            voucherConfigList: [],
            PageIndex: 1,
            PageSize: 10,
            filterVoucherId: ''
        }
    }


    componentDidMount() {
        this.updateStateFilters();
        this.getConfig();

    }

    getConfig() {
        this.setState({ blockCfg: true });
       
        
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockCfg: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    let arr = [];
                    data.response.map((el) => { arr.push({ value: el.id, label: el.code }) })

                    this.setState({ voucherConfigList: arr });
                }
            }
            this.setState({ error: errorMessage, blockCfg: false });
        }, '/api/Voucher/V1/Config' );
    }

    getVouchers = (e, updateUrlParams, totalItemsToExport, cb) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        var params = this.updateAndGetUrlParams(updateUrlParams, totalItemsToExport)
                
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data) {
                    if (totalItemsToExport) {
                        this.setState({ voucherList: data.response }, () => { cb(); this.getVouchers(null, false) });
                    }
                    else {
                        this.setState({ error: errorMessage, block: false, voucherList: data.response, totalItems: data.count });
                    }
                }else {
                    this.setState({ voucherList: [], block: false });
                }
            }
            else this.setState({ error: errorMessage, block: false });
        }, '/api/Voucher/Voucher/v1/listvouchers' + params);
    }


    updateStateFilters = () => {
        let params = new URLSearchParams(window.location.search);
        
        if (!params.has('PageIndex')) params.append('PageIndex', 1);
        if (!params.has('PageSize')) params.append('PageSize', 10);

        this.props.history.push({ search: `?${params}` });


        let PageIndex = params.get('PageIndex' || 1);
        let PageSize = params.get('PageSize');
        let voucherId = params.get('voucherId');
        let status = params.get('status');
        let channel = params.get('channel');
        let hotelId = params.get('hotelId');
        let type = params.get('type');

        this.setState({ PageIndex, PageSize, voucherId, status, channel, hotelId, type },
            () => this.getVouchers(null, false));
    }

    updateAndGetUrlParams = (updateUrlParams, totalItemsToExport) => {
        const { PageIndex, PageSize, voucherId, status, channel, hotelId, type } = this.state;
        
        if (updateUrlParams) {
            let url = `?PageIndex=${PageIndex}&PageSize=${PageSize}`;

            if (voucherId) url += `&voucherId=${voucherId}`;
            if (status) url += `&status=${status}`;
            if (channel) url += `&channel=${channel}`;
            if (type) url += `&type=${type}`;
            if (hotelId) url += `&hotelId=${hotelId}`;

            this.props.history.push({ search: url });
        }

        var params = `?PageSize=${totalItemsToExport || PageSize}&PageIndex=${totalItemsToExport ? 0 : PageIndex - 1}`;

        if (voucherId) params += `&voucherId=${voucherId}`;
        if (status) params += `&status=${status}`;
        if (channel) params += `&channel=${channel}`;
        if (type && type !== 'null') params += `&type=${type}`;
        if (hotelId) params += `&hotelId=${hotelId}`;

        return params;
    }


    updateFilter(evt) {
        if (evt.target) {
            const name = evt.target.name;
            const value = evt.target.value;
            this.setState({ [name]: value });
        }
    }

    updateFilterCombo(val, evt) {
        if (evt) {
            let name = evt.name;
            let value = val ? val.value : val;

            this.setState({ [name]: value });
        }
    }

    setComboStatus = (evt, inputValue) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value);
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            PageIndex: (page),
            PageSize: sizePerPage
        }, () => { this.getVouchers(null, true) });
    }
    
    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    saveVoucher() { }

    handleCustomExportClick = (cb) => {
        this.setState({ block: true }, _ => this.getVouchers(null, false, 1000, cb));
    }

    doSearch = (e, clearFilters) => {
        if(e) e.preventDefault();

        if (clearFilters) {
            this.setState({ PageIndex: 1, voucherId: null, type: null, hotelId: null, status: null, channel: null },
                () => this.getVouchers(null, true))
        }
        else {
            this.setState({ PageIndex: 1 }, () => this.getVouchers(null, true))
        }
    }

    render() {
        const columns = [
            {
                dataField: 'voucherId',
                text: this.props.intl.formatMessage({ id: "OperationManagement.CardId" }),
                sort: true,
                formatter: (cell, row) => getRightVoucherId(cell, row.voucherIdFirst8)
            },
            {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "VoucherOperationDetails.SoldAt" }),
                sort: true
            },
            {
                dataField: 'saleOrigin',
                text: this.props.intl.formatMessage({ id: "OperationManagement.Channel" }),
                sort: true
            },
            {
                dataField: 'ordinal',
                text: this.props.intl.formatMessage({ id: "OperationManagement.Number" }),
                sort: true
            },
            {
                dataField: 'voucherTypeCode',
                text: this.props.intl.formatMessage({ id: "OperationManagement.CardType" }),
                sort: true
            },
            {
                dataField: 'createdAt',
                text: this.props.intl.formatMessage({ id: "OperationManagement.IssueDate" }),
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : null
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "OperationManagement.Status" }),
                sort: true,
                formatter: (cell) => {
                    if (cell) {
                        const status = getStatus().find(s => s.value === cell);
                        return status ? status.label : ''
                    }
                }
            },
            {
                dataField: 'validUntil',
                text: this.props.intl.formatMessage({ id: "OperationManagement.ValidUntil" }),
                sort: true
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push('/VoucherOperationDetails/'+row.id)
            }
        };


        return (
            <StyledCard icon={'fas fa-gift'} title={'OperationManagement.AllCards'} help={<FormattedMessage id="OperationManagement.Help" />} id="operationManagement" error={this.state.error} block={this.state.block}>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-xl" >
                    <ModalBody className="modal-xl p-0">
                        <AddVoucher selectedVoucher={{}} getVouchers={() => { this.toggleModal(); this.doSearch(null, true)}} operations={true}/>
                    </ModalBody>
                </Modal>

                <Form onSubmit={this.doSearch}>
                    <Row className="mb-2">
                        <Col className="col-2">
                            <form ref={this.voucherIdForm}>
                                <FormattedMessage id="OperationManagement.CardId" >
                                    {
                                        placeholder => <Input
                                            type="text"
                                            placeholder={placeholder}
                                            id="filterVoucherId"
                                            name="voucherId"
                                            value={this.state.voucherId || ''}
                                            onChange={this.updateFilter.bind(this)}
                                            minLength={8}
                                            maxLength={36}
                                        />
                                    }
                                </FormattedMessage>
                            </form>
                        </Col>
                        <Col className="col-2">
                            <CustomSelect options={this.state.voucherConfigList} isClearable value={this.state.voucherConfigList.find(el => el.value.toString() === this.state.type?.toString())} name="type" onChange={this.updateFilterCombo.bind(this)} placeholder={<FormattedMessage id="OperationManagement.CardType" />} />
                        </Col>
                        <Col className="col-2">
                            <SelectHotel onChangeFunc={this.setComboStatus.bind(this, 'hotelId')} value={this.state.hotelId} />
                        </Col>
                        <Col className="col-2">
                            <CustomSelect options={getStatus()} isClearable value={getStatus().find(el => el.value === this.state.status)} name="status" onChange={this.updateFilterCombo.bind(this)} placeholder={<FormattedMessage id="OperationManagement.Status" />} />
                        </Col>
                        <Col className="col-2">
                            <CustomSelect options={getChannels()} isClearable value={getChannels().find(el => el.value === this.state.channel)} name="channel" onChange={this.updateFilterCombo.bind(this)} placeholder={<FormattedMessage id="OperationManagement.Channel" />} />
                        </Col>
                        <Col className="text-right">
                            <Authorization
                                perform="operationManagement:add"
                                yes={() => (
                                    <Button className="btn-host btn-sm mr-2" onClick={this.toggleModal} > <i className="fas fa-plus " /></Button>
                                )}
                                no={() => <div></div>}
                            />
                            <Button className="btn-host btn-sm" type="submit">
                                <i className="fas fa-search " />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <CustomTable
                    data={this.state.voucherList ? this.state.voucherList : []}
                    columns={columns}
                    shadow={false}
                    exportCSV={true}
                    customExportCsv={true}
                    totalSize={this.state.totalItems}
                    onTableChange={this.handleTableChange}
                    search={false}
                    showTotal={true}
                    remote={true}
                    selectRow={selectRow}
                    tooltipMessage={<FormattedMessage id="OperationManagement.ExportCsv" />}
                    handleOnClick={this.handleCustomExportClick.bind(this)}
                    hideSizePerPage={false}
                    page={parseInt(this.state.PageIndex || 1)}
                    sizePerPage={this.state.PageSize}
                />
            </StyledCard>
        );
    }
}

export default injectIntl(OperationManagement);