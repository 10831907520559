import React, { Component } from 'react';
import { Label, Form, Row, Col, Button, Input, CustomInput, FormGroup } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from "../../Base/Common/Notification";
import { getAPI, putAPI } from '../../Base/API';

class BackendDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            backend: {
                properties: {
                    tls: {}
                }
            }
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.getBackend();
        }
    }

    getBackend = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ backend: data, block: false });
            }
        }, `/api/Voucher/ApiGateway/v1/backend/${this.props.match.params.id}`)
    }

    saveBackend = (e) => {
        e.preventDefault();

        this.setState({ block: true });
        var body = this.state.backend && this.state.backend.properties ? { ...this.state.backend.properties } : {};

        putAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(({ block: false, error: errorMessage }));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="BackendDetails.BackendSaved" />, <FormattedMessage id="generic.success" />);

                    if (!this.props.match.params.id && data.id) {
                        this.props.history.push({ pathname: "/BackendDetails/" + data.id.split("/").pop() });
                    }
                }

                this.setState(({ block: false, error: [] }));
            }
        }, `/api/Voucher/ApiGateway/v1/backend/${this.state.backend.name}`, body);
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            backend: {
                ...this.state.backend,
                properties: {
                    ...this.state.backend.properties,
                    [name]: value
                }
            }
        })
    }

    handleSwitch = (evt) => {
        const { id, checked } = evt.target;

        let tlsCopy = this.state.backend.properties.tls ? this.state.backend.properties.tls : {};
        tlsCopy[id] = checked;

        this.setState({
            backend: {
                ...this.state.backend,
                properties: {
                    ...this.state.backend.properties,
                    tls: tlsCopy
                }
            }
        })
    }

    render() {
        const help = <div>TODO</div>

        return (
            <StyledCard icon="fas fa-chalkboard" block={this.state.block} error={this.state.error} help={help} title={`BackendDetails.BackendDetails`}>
                <Form onSubmit={(e) => this.saveBackend(e)}>
                    <Row className="mb-3">
                        <Col>
                            <Button type="submit" className="btn btn-sm btn-host float-right">
                                <i className="fas fa-save" />
                            </Button>
                        </Col>
                    </Row>
                    <FormGroup sm="true" row>
                        <Col sm={2}>
                            <Label><FormattedMessage id="Backends.Name" /> </Label>
                        </Col>
                        <Col sm={4}>
                            <Input
                                type="text"
                                name="name"
                                className="text-sm"
                                value={this.state.backend ? this.state.backend.name : ''}
                                onChange={(e) => this.setState({ backend: { ...this.state.backend, name: e.target.value } })}
                                required
                                readOnly={this.props.match.params.id ? true : false}
                            />
                        </Col>
                    </FormGroup>

                    {this.state.backend && this.state.backend.properties ?
                        <>
                            <FormGroup sm="true" row>
                                <Col sm={2}>
                                    <Label> <FormattedMessage id="Backends.Title" /> </Label>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="text"
                                        name="title"
                                        className="text-sm"
                                        value={this.state.backend.properties.title ? this.state.backend.properties.title : ''}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </Col>
                                <Col sm={2}>
                                    <Label><FormattedMessage id="Backends.Description" /> </Label>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="text"
                                        name="description"
                                        className="text-sm"
                                        value={this.state.backend.properties.description ? this.state.backend.properties.description : ''}
                                        onChange={(e) => this.handleChange(e)}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup sm="true" row>
                                <Col sm={2}>
                                    <Label> <FormattedMessage id="BackendDetails.Protocol" /> </Label>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="text"
                                        name="protocol"
                                        className="text-sm"
                                        value={this.state.backend.properties && this.state.backend.properties.protocol ? this.state.backend.properties.protocol : ''}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </Col>
                                <Col sm={2}>
                                    <Label><FormattedMessage id="Backends.Url" /> </Label>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="text"
                                        name="url"
                                        className="text-sm"
                                        value={this.state.backend.properties && this.state.backend.properties.url ? this.state.backend.properties.url : ''}
                                        onChange={(e) => this.handleChange(e)}
                                        required
                                    />
                                </Col>
                            </FormGroup>

                            <Row className="mt-4 mb-2">
                                <Col>
                                    <h5>TLS</h5>
                                </Col>
                            </Row>

                            <FormGroup sm="true" row>
                                <Col>
                                    <CustomInput
                                        name="active" type="switch" id="validateCertificateChain"
                                        checked={this.state.backend.properties && this.state.backend.properties.tls && this.state.backend.properties.tls.validateCertificateChain ? true : false}
                                        onChange={(evt) => this.handleSwitch(evt)}
                                    >
                                        <FormattedMessage id="BackendDetails.ValidateCertificateChain" />
                                    </CustomInput>
                                </Col>
                                <Col>
                                    <CustomInput
                                        name="active" type="switch" id="validateCertificateName"
                                        checked={this.state.backend.properties && this.state.backend.properties.tls && this.state.backend.properties.tls.validateCertificateName ? true : false}
                                        onChange={(evt) => this.handleSwitch(evt)}
                                    >
                                        <FormattedMessage id="BackendDetails.ValidateCertificateName" />
                                    </CustomInput>
                                </Col>
                            </FormGroup>

                        </>
                    : ''}
                </Form>
            </StyledCard>
        );
    }
}
export default injectIntl(BackendDetails)