import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Col, Form, Input, Row, Modal, ModalBody, Label } from "reactstrap";
import { handleNotification } from "../../Base/Common/Notification";
import { BlankCard } from "../../Base/Common/CommonUIComponents";
import { putAPI } from "../../Base/API";

class ConnectionModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.form = React.createRef();
        this.state = {
            block: false,
            selectedConfig: this.props.selectedConfig || {}
        };
    }

    saveNewKey = (event, connecion) => {
        event.preventDefault();
        this.setState({ block: true });

        const { apiKey, key } = this.state;

        putAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="HotelConfig.ConnectionKeySaved" />, <FormattedMessage id="generic.success" />);
                }

                this.props.toggleModal();
            }
            this.setState({ block: false });
        }, `/api/Voucher/Hotel/v1/hotelGroup/apiConnection/${connecion.id}/key?key=${key}${!apiKey ? '' : `&apiKey=${apiKey}`}`);
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        })
    }

    render() {
        const { selectedConfig } = this.state;

        return (
            <Modal isOpen={this.props.modal} fade={false} className="modal-lg" >
                <ModalBody className="p-0">
                    <BlankCard block={this.state.block} error={this.state.error}>
                        <Form onSubmit={(e) => this.saveNewKey(e, selectedConfig)} className="mb-2">
                            <Row className="mb-3">
                                <Col>
                                    <h5>
                                        <FormattedMessage id="HotelConfig.SetNewKey" />
                                    </h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>

                            <Row>
                                <Label className="col-2"><FormattedMessage id="HotelConfig.NewKey" /></Label>
                                <Col className="col-10">
                                    <Input
                                        name={"key"}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(ConnectionModal)