import rules from "./Common/RbacRules";
import { authContext } from '../../Utils/AdalConfig';

export function CheckAuthorization(action, data) { return check(action, data) }

const check = (action, data) => {

    if (authContext.getCachedUser().profile == undefined || authContext.getCachedUser().profile.groups === undefined) return false;
    let max = authContext.getCachedUser().profile.groups.length;
    for (var fieldIndex = 0; fieldIndex <= max; fieldIndex++) {

        const permissions = rules[authContext.getCachedUser().profile.groups[fieldIndex]];

        if (permissions) {
            const staticPermissions = permissions.static;

            if (staticPermissions && staticPermissions.includes(action)) {
                // static rule not provided for action
                return true;
            }

            if (staticPermissions && staticPermissions.includes(action)) {
                // static rule not provided for action
                return true;
            }
        }

        if (permissions) {
            const dynamicPermissions = permissions.dynamic;

            if (dynamicPermissions) {
                const permissionCondition = dynamicPermissions[action];
                if (!permissionCondition) {
                    // dynamic rule not provided for action
                    return false;
                }

                return permissionCondition(data);
            }
        }
    }
     
    //if (!permissions) {
        // role is not present in the rules
    //    return false;
    //}

    
    return false;
};

const Authorization = props =>
    check( props.perform, props.data)
        ? props.yes()
        : props.no();

Authorization.defaultProps = {
    yes: () => null,
    no: () => null
};

export default Authorization;