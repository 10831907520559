import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, Row, Badge } from 'reactstrap';
import { postAPI } from '../../Base/API';
import { DateRangePicker } from 'react-dates';
import { ErrorAlert } from "../../Base/ErrorAlert";
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { CustomTable } from '../../Base/Common/CustomTable';


class Events extends Component {

    constructor(props) {
        super(props);

        this.state = {
            eventOptions: [
                { value: "error_ui", label: this.props.intl.formatMessage({ id: "PlatformDeepDive.error_ui" }), isVisible: true },
                { value: "error_api", label: this.props.intl.formatMessage({ id: "PlatformDeepDive.error_api" }), isVisible: true },
                { value: "help", label: this.props.intl.formatMessage({ id: "PlatformDeepDive.help" }), isVisible: true },
                { value: "help_click", label: this.props.intl.formatMessage({ id: "PlatformDeepDive.help_click" }), isVisible: true },
                { value: "api_exceeded_time", label: this.props.intl.formatMessage({ id: "PlatformDeepDive.api_exceeded_time" }), isVisible: true }
            ],
        }
    }

    componentDidMount() {
        this.getEventsOverTime();
    }

    componentDidUpdate(prevProps){
        if ( prevProps.productType !== this.props.productType ) {
            this.getEventsOverTime();
        }
    }

    getEventsOverTime = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        const body = {
            usageType: this.props.usageType,
            productType: this.props.productType,
            startDate: moment(this.props.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.props.endDate).format("YYYY-MM-DD"),
            dimensions: ["eventName", "pagePath", "customEvent:description", "customEvent:help_id", "customEvent:stack", "customEvent:userId", "dateHourMinute"],
            metrics: ["eventCount"],
            aggregation: [],
            dimensionFilter: {
                filter: {
                    fieldName: "eventName",
                    inListFilter: {
                        values: this.state.eventOptions.filter(el => el.isVisible).map(el => el.value)
                    }
                }
            },
            orderBys: [
                {
                    "dimension": {
                        "dimensionName": "dateHourMinute",
                        "orderType": "NUMERIC"
                    },
                    "desc": true
                }
            ]
        }

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const json = JSON.parse(data);

                this.createChartData(json);
            }
            else this.setState({ block: false });
        }, `/api/hotel/v1/googleanalytics`, body);
    }

    createChartData = (json) => {

        this.setState({
            events: json.rows,
            block: false
        });
    }

    handleBadge = (event) => {
        const eventOptions = [...this.state.eventOptions];
        const index = eventOptions.findIndex(el => el.value === event);

        if (index !== -1) {
            eventOptions[index].isVisible = !eventOptions[index].isVisible

            this.setState({ eventOptions });
        }
    }


    render() {
        const { events, eventOptions } = this.state;

        const columns = [
            {
                dataField: 'dimensionValues[0].value',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.Event" }),
                sort: true
            },
            {
                dataField: 'dimensionValues[6].value',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.Date" }),
                sort: true,
                formatter: (cell) => cell ? `${cell.substring(0, 4)}-${cell.substring(4, 6)}-${cell.substring(6, 8)} ${cell.substring(8, 10)}:${cell.substring(10)}` : ''
            },
            {
                dataField: 'dimensionValues[1].value',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.PagePath" }),
                sort: true
            },
            {
                dataField: 'dimensionValues[2].value',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.Description" }),
                sort: true
            },
            {
                dataField: 'dimensionValues[3].value',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.HelpId" }),
                sort: true
            },
            {
                dataField: 'dimensionValues[4].value',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.Stack" }),
                sort: true
            },
            {
                dataField: 'dimensionValues[5].value',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.UserId" }),
                sort: true
            },
            {
                dataField: 'metricValues[0].value',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.Count" }),
                sort: true
            },
        ];

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <Row className="align-items-center mb-4 pt-1">
                    <Col className="d-flex align-items-center">
                        <div style={{ width: 'fit-content' }}>
                            <DateRangePicker
                                startDate={this.props.startDate}
                                startDateId="your_unique_start_date_id_announcement"
                                isOutsideRange={(day) => this.props.isOutsideDate(day)}
                                endDate={this.props.endDate}
                                endDateId="your_unique_end_date_id_announcement"
                                onDatesChange={({ startDate, endDate }) => this.props.handleDates(startDate, endDate)}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                small={true}
                                numberOfMonths={2}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            />
                        </div>

                        <div className="ml-4">
                            <i className="fas fa-filter fa-sm mr-2 text-muted" />
                            {eventOptions.map((event, key) =>
                                <Badge className="mr-3 p-2 pointer" key={key} color={event.isVisible ? 'primary' : 'secondary'} onClick={() => this.handleBadge(event.value)}>
                                    {event.label}
                                </Badge>
                            )}
                        </div>
                    </Col>

                    <Col className="text-right col-1">
                        <Button className="float-right  btn-sm btn-host btn" onClick={this.getEventsOverTime}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>

                <Card className="shadow border-0 p-2">
                    <CustomTable
                        data={events || []}
                        columns={columns}
                        showTotal={true}
                        remote={false}
                        search={true}
                        hideSizePerPage={false}
                    />
                </Card>
            </BlockUi>
        );
    }

};
export default injectIntl(Events);