import React, { Component } from 'react';
import { Button, Col, CustomInput, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { EmptyCard } from '../Base/Common/CommonUIComponents';
import CustomSelect from '../Base/Common/CustomSelect';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { postAPI } from '../Base/API';
import { handleNotification } from '../Base/Common/Notification';
import Authorization from '../Base/Authorization';

export class PromotionModal extends Component {

    constructor(props) {
        super(props);
        this.savePromotion = this.savePromotion.bind(this);
        this.setCombo = this.setCombo.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setStatus = this.setStatus.bind(this);
        this.form = React.createRef();
        this.state = {
            block: false,
            promotion: {
                voucherTypeIds: [],
                channelIds: [],
                active: false
            }
        };
    }

    componentDidMount() {
        if (this.props.promotion !== null) {
            let { promotion, channels } = this.state;
            Object.assign(promotion, this.props.promotion);
            const voucherType = this.props.voucherTypes.find(v => v.value === promotion.voucherTypeId);
            if (voucherType) {
                channels = voucherType.channels;
            }
            this.setState({ promotion, channels });
        }
    }

    savePromotion(evt) {
        evt.preventDefault();

        if (!this.form.current.reportValidity()) {
            return;
        }

        const { promotion } = this.state;

        const request = {
            request: promotion
        }
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="Promotions.SuccessSavePromotion" />, <FormattedMessage id="generic.success" />);
                    Object.assign(promotion, data.response[0]);
                    this.props.updateTable(promotion);
                    this.setState({ error: errorMessage, block: false, promotion });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Voucher/v1/Promotion/promotion`, request);
    }

    setCombo(combo, evt) {
        if (evt) {
            const { promotion } = this.state;
            const { name } = evt;
            promotion[name] = combo.map(c => c.value);
            this.setState({ promotion });
        }
    }

    handleChange(evt) {
        if (evt && evt.target) {
            const { promotion } = this.state;
            const { name, value } = evt.target;
            promotion[name] = value;
            this.setState({ promotion });
        }
    }

    setStatus(evt) {
        if (evt.target) {
            const { promotion } = this.state;
            const { checked } = evt.target;
            promotion.active = checked;
            this.setState({ promotion });
        }
    }

    render() {
        const { isOpen, toggle, title, voucherTypes, channels } = this.props;
        const { block, promotion } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>
                    {title}
                </ModalHeader>
                <ModalBody className="p-0">
                    <EmptyCard block={block}>
                        <form ref={this.form}>
                            <Authorization
                                perform="promotions:save"
                                yes={() => (
                                    <Row className="mb-2">
                                        <Col className="text-right">
                                            <Button className="btn btn-host btn-sm float-right" onClick={this.savePromotion}>
                                                <i className="fas fa-save" />
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                                no={() => <div />}
                            />
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={3}>
                                            <FormattedMessage id="Promotions.Name" />
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                type="text"
                                                id="name"
                                                name="name"
                                                value={promotion.name}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}>
                                            <FormattedMessage id="Promotions.Discount" />
                                        </Label>
                                        <Col sm={8}>
                                            <Input
                                                type="number"
                                                id="discount"
                                                name="discount"
                                                value={promotion.discount}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={3}>
                                            <FormattedMessage id="Promotions.Validity" />
                                        </Label>
                                        <Col sm={9}>
                                            <DateRangePicker
                                                startDate={promotion.startDate ? moment(promotion.startDate) : null}
                                                startDateId="startDateId"
                                                isOutsideRange={day => day >= moment().add(12, 'month') || day < moment()}
                                                endDate={promotion.endDate ? moment(promotion.endDate) : null}
                                                endDateId="endDateId"
                                                onDatesChange={({ startDate, endDate }) => {
                                                    const { promotion } = this.state;
                                                    promotion.startDate = startDate;
                                                    promotion.endDate = endDate;
                                                    this.setState({ promotion });
                                                }}
                                                focusedInput={this.state.focusedInput}
                                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                small={true}
                                                required
                                                numberOfMonths={2}
                                                showDefaultInputIcon={true}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}>
                                            <FormattedMessage id="Promotions.Status" />
                                        </Label>
                                        <Col sm={8}>
                                            <CustomInput
                                                type="switch"
                                                id="active"
                                                name="active"
                                                checked={promotion.active}
                                                onChange={this.setStatus}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={3}>
                                            <FormattedMessage id="Promotions.VoucherType" />
                                        </Label>
                                        <Col sm={9}>
                                            <CustomSelect
                                                name="voucherTypeIds"
                                                options={voucherTypes}
                                                value={voucherTypes.filter(v => promotion.voucherTypeIds.find(vId => v.value === vId))}
                                                onChange={this.setCombo}
                                                isClearable
                                                required
                                                isMulti
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}>
                                            <FormattedMessage id="Promotions.ChannelInstance" />
                                        </Label>
                                        <Col sm={8}>
                                            <CustomSelect
                                                name="channelIds"
                                                options={channels}
                                                value={channels.filter(c => promotion.channelIds.find(cId => c.value === cId))}
                                                onChange={this.setCombo}
                                                isClearable
                                                required
                                                isMulti
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </form>
                    </EmptyCard>
                </ModalBody>
            </Modal>    
        );
    }
}