import React, { Component } from 'react';
import { Row, Col, Input, Button, Badge, Form } from 'reactstrap';
import { getAPI } from '../Base/API';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import { CustomTable } from '../Base/Common/CustomTable';
import moment from "moment";
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../Base/Common/CustomSelect';

export class SalesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            endDate: moment(),
            startDate: moment().add(-6, 'months'),
            onlinePaymentsList: [],
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0,
            issuedInvoice: null
        }
    }

    componentDidMount() {
        this.getOnlinePayments();
    }

    getOnlinePayments = (event) => {
        if (event) event.preventDefault();

        this.setState({ block: true });

        const { channel } = this.props;
        let qstr = '&starDate=' + this.state.startDate.format('YYYY-MM-DD') + '&endDate=' + this.state.endDate.format('YYYY-MM-DD');
        if (this.state.name) {
            qstr += '&name=' + this.state.name;
        }
        if (this.state.email) {
            qstr += '&email=' + this.state.email;
        }
        if (this.state.paymentStatus) {
            qstr += '&paymentStatus=' + this.state.paymentStatus;
        }
        if (this.state.issuedInvoice !== null) {
            qstr += '&issuedInvoice=' + this.state.issuedInvoice;
        }

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ onlinePaymentsList: data.response, totalItems: data.count, block: false });
            }
        }, `/api/Voucher/Voucher/v1/onlineSale?channel=${channel}&pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}${qstr}`);
    }

    setComboStatus = (name, combo) => {
        this.setState({ [name]: combo ? combo.value : null });
    }

    handleTableChange = (_, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, () => { this.getOnlinePayments() });
    }

    render() {
        const { icon, title } = this.props;

        const columns = [
            {
                dataField: 'paymentStatus',
                text: this.props.intl.formatMessage({ id: "OnlineSales.PaymentStatus" }),
                sort: true,
                formatter: (cell, row) => <Badge className=" btn-host p-2" >{cell} </Badge>
            },
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "OnlineSales.Name" }),
                sort: true
            },
            {
                dataField: 'email',
                text: this.props.intl.formatMessage({ id: "OnlineSales.Email" }),
                sort: true
            },
            {
                dataField: 'createdAt',
                text: this.props.intl.formatMessage({ id: "OnlineSales.CreationDate" }),
                sort: true
            },
            {
                dataField: 'Amount',
                text: this.props.intl.formatMessage({ id: "OnlineSales.PayedAmount" }),
                sort: true,
                formatter: (cell, row) => <div >{row.items && row.items.reduce((acc, val) => acc + val.payedAmount, 0)} </div>
            },
            {
                dataField: 'Amount',
                text: this.props.intl.formatMessage({ id: "OnlineSales.Quantity" }),
                sort: true,
                formatter: (cell, row) => <div >{row.items && row.items.reduce((a, b) => a + b.quantity, 0)} </div>
            },
            {
                dataField: 'channelConfigName',
                text: this.props.intl.formatMessage({ id: "OnlineSales.Channel" }),
                sort: true
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push('/OnlineSalesDetail/' + row.id)
            }
        };

        const paymentStatusOptions = [
            {
                value: 'succeeded',
                label: <FormattedMessage id="OnlineSales.Succeeded" />
            },
            {
                value: 'requires_payment_method',
                label: <FormattedMessage id="OnlineSales.RequiresPayment" />
            }
        ];

        const issuedInvoiceOptions = [
            {
                value: true,
                label: <FormattedMessage id="OnlineSales.Yes" />
            },
            {
                value: false,
                label: <FormattedMessage id="OnlineSales.No" />
            }
        ];


        return (
            <StyledCard icon={icon} title={title} help={this.props.help} id={this.props.id} error={this.state.error} block={this.state.block}>
                <Form onSubmit={this.getOnlinePayments}>
                    <Row>
                        <Col className="col-2 px-1">
                            <DateRangePicker
                                startDate={this.state.startDate ? moment(this.state.startDate) : null}
                                startDateId="sellStartid"
                                isOutsideRange={day => day > moment()}
                                endDate={this.state.endDate ? moment(this.state.endDate) : null}
                                endDateId="sellEndid"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                small={true}
                                required
                                numberOfMonths={2}
                                showDefaultInputIcon={true}
                            />
                        </Col>
                        <Col>
                            <FormattedMessage id="OnlineSales.Name">
                                {
                                    placeholder => <Input placeholder={placeholder} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} ></Input>
                                }
                            </FormattedMessage>
                        </Col>
                        <Col>
                            <FormattedMessage id="OnlineSales.Email">
                                {
                                    placeholder => <Input placeholder={placeholder} value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} ></Input>
                                }
                            </FormattedMessage>
                        </Col>
                        <Col>
                            <CustomSelect
                                options={paymentStatusOptions}
                                placeholder={<FormattedMessage id="OnlineSales.PaymentStatus" />}
                                value={paymentStatusOptions.find(opt => opt.value === this.state.paymentStatus)}
                                onChange={this.setComboStatus.bind(this, 'paymentStatus')}
                                isClearable
                                isSearchable
                            />
                        </Col>
                        <Col>
                            <CustomSelect
                                options={issuedInvoiceOptions}
                                placeholder={<FormattedMessage id="OnlineSales.IssuedInvoice" />}
                                value={paymentStatusOptions.find(opt => opt.value === this.state.issuedInvoice)}
                                onChange={this.setComboStatus.bind(this, 'issuedInvoice')}
                                isClearable
                                isSearchable
                            />
                        </Col>
                        <Col className="col-1 text-right">
                            <Button className="btn btn-sm btn-host" type="submit">
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col>
                        <CustomTable
                            keyField='id'
                            data={this.state.onlinePaymentsList ? this.state.onlinePaymentsList : []}
                            columns={columns}
                            shadow={false}
                            exportCSV={true}
                            showTotal={true}
                            remote={true}
                            selectRow={selectRow}
                            page={this.state.pageIndex + 1}
                            sizePerPage={this.state.pageSize}
                            totalSize={this.state.totalItems}
                            onTableChange={this.handleTableChange}
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(SalesTable);