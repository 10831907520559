import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardHeader, CardTitle, Badge, Row, Col, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { prettifyXml} from './CommonUIComponents'

class Timeline extends Component {
    //const Timeline = ({ children, className, layout, selectedEvent }) => (

    state = {
        error: null,
        selectedEvent: this.props.selectedEvent,
        block: this.props.block,
        requestContent: '',
        requestType: '',
        modal: false,
        errorDetailList: ['Booking.com.MISSING_RATEAMOUNTMESSAGES_ELEMENT', 'Airbnb.7','Airbnb.400']
    }

    toggle(type,evt) {
        var content = '';
        var contentType = '';
        if (type && this.props.selectedEvent !== undefined) {
            contentType = type;
            try {
                content = JSON.stringify(JSON.parse(this.props.selectedEvent[type]), null, 2)
            } catch (e) {
                content = this.props.selectedEvent[type];
            }
        }

        this.setState(prevState => ({
            requestType: contentType,
            requestContent: content,
            modal: !prevState.modal
        }));
    }

    render() {
        const {
            children,
            selectedEvent,
            className,
            layout,
            block,
            selectedChannel
        } = this.props;
        return (
            <Row>
                <Modal isOpen={this.state.modal} toggle={this.toggle.bind(this, '')} className="modal-xl">
                    <ModalHeader toggle={this.toggle.bind(this, '')}>
                        {this.state.requestType ? this.state.requestType : ''} Content
                    </ModalHeader>
                    <ModalBody >
                        <div className="overflow-auto" style={{ 'height': '70vh'}}>
                            <pre>
                                <code>
                                    {
                                        this.state.requestContent && this.state.requestContent.length > 0 && this.state.requestContent.charAt(0) === '<' ?
                                            prettifyXml(this.state.requestContent)
                                            :
                                            this.state.requestContent
                                    }
                                </code>
                            </pre>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle.bind(this, '')}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
                <Col className="col-lg-5 col-sm-12 left border-right ">
                    <div
                        className={classNames(className, 'timeline ', {
                            'timeline--two-columns': layout === '2-columns',
                            'timeline--one-column bg-white': layout === '1-column',
                        })}
                    >
                        {children}
                    </div>
                </Col>
                <Col className="col-lg-7 col-sm-12 left">
                    {selectedEvent && selectedEvent.id ?
                        <BlockUi tag="div" blocking={block}>
                            <Card className="small border-0">
                                <CardHeader className="bg-white">
                                    <div className="inline">
                                        <i className="far fa-envelope mr-2"></i> Raw Data
                                        <a id="togglerRawData" className="float-right">
                                            <span className="fas fa-angle-down" />
                                        </a>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    {selectedEvent.ids && selectedEvent.ids.length > 0 && selectedEvent.eventType === "Payment" ?
                                        <Row className="mb-3">
                                            <Col>
                                                <b><FormattedMessage id="Timeline.OnlineSaleId" /> </b>
                                                {selectedEvent.ids.map((id, key) =>
                                                    <Link to={`/OnlineSalesDetail/${id}`} key={key}>
                                                        <span> {id} </span>
                                                    </Link>
                                                )}
                                            </Col>
                                        </Row>
                                    : ''}
                                    <Row>
                                        <Col className="col-6">
                                            <Button className="btn-sm btn-host shadow mr-2" disabled={!(selectedEvent && selectedEvent.messageRequest)} onClick={this.toggle.bind(this, 'messageRequest')}>
                                                <i className="fas fa-file-import" />
                                            </Button>
                                            Request
                                        </Col>
                                        <Col className="col-6">
                                            <Button className="btn-sm  btn-host shadow mr-2 " disabled={!(selectedEvent && selectedEvent.messageResponse)} onClick={this.toggle.bind(this, 'messageResponse')}>
                                                <i className="fas fa-file-export" />
                                            </Button>
                                            Response
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </BlockUi>
                        : 
                        <Card className=" text-secondary align-middle text-center h-100 pt-5 shadow border-0">
                            <i className="fas fa-info-circle fa-8x"><br /></i> <h5><FormattedMessage id="Timeline.SelectEventToSeeDetails" /></h5>
                        </Card>
                    }
                </Col>
            </Row>
        );
    }
}

Timeline.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
    animate: PropTypes.bool,
    selectedEvent: PropTypes.object, 
    selectedChannel: PropTypes.string,
    layout: PropTypes.oneOf(['1-column', '2-columns']),
};

Timeline.defaultProps = {
    animate: true,
    className: '',
    layout: '2-columns',
};

export default Timeline;