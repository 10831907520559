import React, { Component } from 'react';
import {  Card, CardBody, UncontrolledCollapse } from 'reactstrap';



export class ErrorAlert extends Component {
   
    render() {
        return (
            <div>
                {this.props.error && this.props.error.map((item, key) =>
                    
                item.stack ? 
                        <div className={'alert alert-' + item.messageType} style={{ display: item ? 'block' : 'none' }} key={key} >
                    <span className='glyphicon glyphicon-triangle-bottom ml-2' id={'togglerError'+key} /> {item ? item.message : ''}
                        
                        <UncontrolledCollapse toggler={'#togglerError' + key}> 
                    <Card>
                        <CardBody>
                                    {item ? item.stack : ''}
                        </CardBody>
                    </Card>
                            </UncontrolledCollapse>
                            
                    </div>
                        : 
                        <div key={key} className={'alert alert-' + item.messageType} style={{ display: item ? 'block' : 'none' }} >
                        <span  /> {item ? item.message : ''}
                    </div>
                
                )}
            </div>
           )
    }
}