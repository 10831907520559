import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import { getAPI } from '../Base/API';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import { CustomTable } from '../Base/Common/CustomTable';
import { FormattedMessage } from 'react-intl';

export class PaymentKeyManagement extends Component {
    static displayName = PaymentKeyManagement.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            keyList: [],
           
        }
    }


    componentDidMount() {
        this.getHotels();

    }

    getHotels() {
        this.setState({ block: true });


        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ keyList: data.response, block: false });
            }
        }, '/api/Voucher/Payment/v1/HostPaymentInformation');
    }




    render() {

        const columns = [
            {
                dataField: 'code',
                text: <FormattedMessage id="PaymentKeyManagement.Code" />,
                sort: true
            },
            {
                dataField: 'sharedKey',
                text: <FormattedMessage id="PaymentKeyManagement.SharedKey" />,
                sort: true
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
             //   this.props.history.push('/Hotel/' + row.hotelId)
            }
        };

        const help = <div> </div>
        return (


            <StyledCard icon={'fas fa-key'} title={'PaymentKeyManagement.PaymentGatewayKeyManagment'} help={help} error={this.state.error} block={this.state.block}>
               
               
                <CustomTable
                    data={this.state.keyList ? this.state.keyList : []}
                    columns={columns}
                    shadow={false}
                    exportCSV={false}
                    showTotal={true}
                    remote={true}
                />
            </StyledCard>


        );
    }
}
