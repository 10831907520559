import React, { Component } from 'react';
import { Row, Col, Input, Button, Collapse, FormGroup, UncontrolledTooltip, CardBody, Card } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getAPI } from "./Base/API";
import ReactHtmlParser from 'react-html-parser';
import { BlankCard } from "./Base/Common/CommonUIComponents";
import { createBrowserHistory } from "history";
import { Link } from 'react-router-dom';
import Authorization from './Base/Authorization';

export class SearchButton extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.state = {
            isOpen: false,
            searchquery: null,
            result: null,
            totalPage: 3,
            page: 0,
            maxPage: 0,
            defaultProperty: '',
        }
    }

    componentDidMount() {
        this.getUser();
    }

    toggle() {
        this.setState(prevstate => ({
            isOpen: !prevstate.isOpen,
            result: null,
            page: 0
        }));
    }

    doSearch() {
        const { searchquery, totalPage } = this.state;
        if (searchquery !== null && searchquery.length > 0) {
            this.setState({ block: true });
            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({
                        message: error.message,
                        stack: error.stack,
                        messageType: "danger"
                    });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    const maxPage = data.results ? (data.results.length % totalPage === 0 ? data.results.length / totalPage : parseInt(data.results.length / totalPage)) : 0;
                    this.setState({ result: data, page: 0, maxPage: maxPage, block: false });
                }
            }, `/wiki/rest/api/search?cql=text~"${searchquery}"+and+type=page+and+space=HEYC`);
        }
    }

    nextPage() {
        const page = this.state.page + 1;
        this.setState({ page });
    }

    prevPage() {
        const page = this.state.page - 1;
        this.setState({ page });
    }

    getUser = () => {
        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({
                    defaultProperty: data.defaultProperty ? data.defaultProperty : '',
                    block: false
                })
            }
        }, '/api/User/v1/User');
    }

    render() {
        const begin = this.state.page * this.state.totalPage;
        const end = begin + this.state.totalPage;

        const supportLink = process.env.REACT_APP_SUPPORT_URL + `?brand=HeyTravel&v1=HEYCARD&hotel=${this.state.defaultProperty}`;
        
        return (
            <div className="col-6">
                <Row style={{ position: 'fixed', bottom: '0', right: '0', zIndex: '1000' }}>
                    <Col>
                        <span id="SearchButton" className="btn-sm btn-host float-right mb-1 mr-1" onClick={this.toggle}>
                            <i className="fas  fa-question-circle" />
                        </span>
                    </Col>
                </Row>

                <Collapse isOpen={this.state.isOpen}>
                    <Card className="shadow" style={{ position: 'fixed', bottom: '0', width: '50%', right: '0', zIndex: '999' }}>
                        <CardBody className="pt-1">
                            <BlankCard error={this.state.error} block={this.state.block} className="border-0 h-100">
                                <Row>
                                    <Col>
                                        <FormGroup sm="true" row>
                                            <Col sm={10}>
                                                <FormattedMessage id="SearchButton.Search">
                                                    {
                                                        placeholder => <Input id="SearchText" type="text" placeholder={placeholder} onChange={(evt) => this.setState({ searchquery: evt.target.value })} />
                                                    }
                                                </FormattedMessage>
                                            </Col>
                                            <Col sm={2} className="text-right px-1" >
                                                <Authorization
                                                    perform="support:view"
                                                    yes={() => (
                                                        <>
                                                            <Button className="btn-sm btn-host mr-2" id="Tooltipcreateticket" on="true" tag="a" href={supportLink} target="_blank">
                                                                <i className="fas fa-headset" />
                                                            </Button>
                                                            <UncontrolledTooltip placement="top" target="Tooltipcreateticket" >
                                                                <FormattedMessage id="SearchButton.createTicket" />
                                                            </UncontrolledTooltip>
                                                        </>

                                                    )}
                                                    no={() => <div></div>}
                                                />

                                                <Button className="btn-sm btn-host " onClick={this.doSearch}>
                                                    <i className="fas fa-search" />
                                                </Button>

                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {
                                    this.state.result && this.state.result.results && this.state.result.results.slice(begin, end).map((result, key) =>
                                        <CardBody className="py-1" key={key}>
                                            <Row className="mb-1">
                                                <Col><i className="far fa-file-alt mr-2 " /><b><a href={this.state.result._links.base + result.url} target="_blank"> {ReactHtmlParser(result.title.replace(/\@\@\@hl\@\@\@/gi, "<u>").replace(/\@\@\@endhl\@\@\@/gi, "</u>"))}</a></b></Col>
                                            </Row>
                                            <Row>
                                                <Col>{ReactHtmlParser(result.excerpt.replace(/\@\@\@hl\@\@\@/gi, "<b>").replace(/\@\@\@endhl\@\@\@/gi, "</b>"))}</Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-secondary">{result.friendlyLastModified}</Col>
                                            </Row>
                                        </CardBody>
                                    )
                                }
                                {this.state.result && this.state.result.results.length === 0 ? 'No Results' : ''}

                                {
                                    this.state.result && this.state.result.results && this.state.result.results.length > 0 ?
                                        <Row>
                                            <Col>
                                                <Button className="btn-sm btn-host float-left" onClick={this.prevPage} disabled={this.state.page === 0}>
                                                    <i className="fas fa-angle-left" />
                                                </Button>
                                                <Button className="btn-sm btn-host float-right mr-1" onClick={this.nextPage} disabled={this.state.page === this.state.maxPage}>
                                                    <i className="fas fa-angle-right" />
                                                </Button>
                                            </Col>
                                        </Row>
                                        :
                                        <div />
                                }

                            </BlankCard>


                        </CardBody>
                    </Card>
                </Collapse>
            </div>
        );
    }
}
