import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Input, Row, Col, Button, Badge, CardBody, Form } from 'reactstrap';
import { putAPI, getAPI, postAPI } from '../../Base/API';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import CustomSelect from '../../Base/Common/CustomSelect';
import { handleNotification } from "../../Base/Common/Notification";
import { FormattedMessage } from 'react-intl';

export class ApiGatewayUserDetails extends Component {
    static displayName = ApiGatewayUserDetails.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleModalSub = this.toggleModalSub.bind(this);
        
        this.form = React.createRef();
        this.formSub = React.createRef();
        
        this.state = {
            block: true,
            subscriptions: [],
            productList:[],
            user: {},
            originalUser: {},
            keys: {},
            modal: false,
            modalSubs:false
        }
    }


    componentDidMount() {
        if (this.props.match.params.id) {
            this.getSubscriptions(this.props.match.params.id);
            this.getUsers(this.props.match.params.id)
        }
        this.getProducts();
    }

    getSubscriptions(id) {
        this.setState({ block: true });


        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.value) {
                    this.setState({ subscriptions: data.value, block: false });
                }
                else {
                    handleNotification(data, '', <FormattedMessage id="ApiGatewayUserDetails.Success" />);
                }


                
            }
        }, '/api/User/APIGateway/v1/Subscriptions/'+id);
    }

    getUsers(id) {
        this.setState({ block: true });


        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.properties) {
                    this.setState({ originalUser: data, user: data.properties, block: false });
                }
                else {

                    handleNotification(data, '', <FormattedMessage id="ApiGatewayUserDetails.Success" />);
                }

                
            }
        }, '/api/User/APIGateway/v1/User/'+id);
    }

    getKeys(id) {
        this.setState({ block: true });


        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ keys: data,modal:true, block: false });
            }
        }, '/api/User/APIGateway/v1/Subscriptions/keys/' + id);
    }

    getProducts() {
        this.setState({ block: true });


        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ productList: data.value, block: false });
            }
        }, '/api/User/APIGateway/v1/Products');
        
    }

    getProductList() {
        let arr = [];
        this.state.productList.map((el, k) =>
            arr.push({ label: el.properties.displayName, value: el.id }))
        return arr;
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    toggleModalSub() {
        this.setState(prevState => ({
            modalSubs: !prevState.modalSubs
        }));
    }
    saveUser(e) {
        e.preventDefault();

        if (this.form.current.reportValidity()) {
            this.setState({ block: true });
            let req = {
                "properties": {
                    "firstName": this.state.user.firstName,
                    "lastName": this.state.user.lastName,
                    "email": this.state.user.email,
                    "note": this.state.user.note,
                    "confirmation": "signup"
                }
            }

            putAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="ApiGatewayUserDetails.UserCreated/Updated" />, <FormattedMessage id="ApiGatewayUserDetails.Success" />);
                    this.setState({ block: false });
                    this.props.history.push('/ApiGatewayUserDetails/' + data.name)
                }
            }, '/api/User/APIGateway/v1/User/' + (this.state.originalUser.name ? this.state.originalUser.name : this.state.user.email), req);


        }
    }

    saveSubscription(e) {


        e.preventDefault();

        if (this.formSub.current.reportValidity()) {
            this.setState({ block: true });
            let req = {
                "properties": {
                    "ownerId": this.state.originalUser.id,
                    "scope": this.state.subs.scope,
                    "displayName": this.state.subs.subscriptionName
                }
            }
            putAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="ApiGatewayUserDetails.SubscriptionAdded" />, <FormattedMessage id="ApiGatewayUserDetails.Success" />);
                    this.getSubscriptions(this.props.match.params.id);
                }
            }, '/api/User/APIGateway/v1/Subscription/' + this.state.subs.subscriptionName, req);
            
        }


    }

    updateComboForm(f, val, evt) {
        if (evt != undefined) {
            let name = evt.name;
            let value = val.value;

            this.setState(prevState => ({
                ...prevState,
                [f]: {
                    ...prevState[f],
                    [name]: value

                },
            }));
        }
    }
    updateForm(f, evt) {

        let name = evt.target.name;
        let value = evt.target.value;

        this.setState(prevState => ({
            ...prevState,
            [f]: {
                ...prevState[f],
                [name]: value

            },
        }));
    }

    render() {

        const help = <div> </div>
        return (
            <div>
            <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-xl" >
                <ModalHeader toggle={this.toggleModal}>
                        <FormattedMessage id="ApiGatewayUserDetails.SubscriptionKeys" />
                    </ModalHeader>
                    <ModalBody className="modal-xl p-0">
                        <CardBody>
                            
                            <Row className="mb-2">
                                <Col>
                                    <b><FormattedMessage id="ApiGatewayUserDetails.PrimaryKey" /></b>
                                </Col>
                        <Col>{this.state.keys.primaryKey}</Col>
                    </Row>
                            <Row className="mb-2">
                                <Col>
                                    <b><FormattedMessage id="ApiGatewayUserDetails.SecondaryKey" /></b>
                                </Col>
                            <Col>{this.state.keys.secondaryKey}</Col>
                                </Row>
                        </CardBody>
                </ModalBody>
            </Modal>

                <Modal isOpen={this.state.modalSubs} toggle={this.toggleModalSub} fade={false} className="modal-xl" >
                    <ModalHeader toggle={this.toggleModalSub}>
                        <FormattedMessage id="ApiGatewayUserDetails.AddSubscriptionToUser" />
                    </ModalHeader>
                    <ModalBody className="modal-xl p-0">
                        <CardBody>
                            <form ref={this.formSub}>
                            <Row className="mb-2">
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" onClick={this.saveSubscription.bind(this)}><i class="fas fa-save"></i></Button>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <FormattedMessage id="ApiGatewayUserDetails.Name" />
                                </Col>
                                <Col>
                                    <FormattedMessage id="ApiGatewayUserDetails.Name" >
                                        {
                                            placeholder => <Input required placeholder={placeholder} name="subscriptionName" minLength="2" maxLength="40" onChange={this.updateForm.bind(this, 'subs')} ></Input>
                                        }
                                    </FormattedMessage>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                    <Col className="col-2"><FormattedMessage id="ApiGatewayUserDetails.Product" /></Col>
                                <Col>
                                    <CustomSelect required options={this.getProductList()} isClearable value={this.getProductList().find(el => el.value === this.state.reason)} onChange={this.updateComboForm.bind(this, 'subs')} name="scope" placeholder={<FormattedMessage id="ApiGatewayUserDetails.Type" />} />
                                </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </ModalBody>
                </Modal>

            <StyledCard icon={'fas fa-user-cog'} title={'ApiGatewayUserDetails.ApiGatewayUserDetails'} help={help} error={this.state.error} block={this.state.block}>
                    <form ref={this.form}>
                        <Row className="mb-2">
                            <Col><FormattedMessage id="ApiGatewayUserDetails.APIGatewayUser" /></Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" onClick={this.saveUser.bind(this)}><i class="fas fa-save"></i></Button>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="col-2"><FormattedMessage id="ApiGatewayUserDetails.FirstName" /></Col>
                        <Col className="col-4">
                            <FormattedMessage id="ApiGatewayUserDetails.FirstName" >
                                {
                                    placeholder => <Input type="text" required value={this.state.user.firstName} name="firstName" onChange={this.updateForm.bind(this, 'user')} placeholder={placeholder} />
                                }
                            </FormattedMessage>
                        </Col>
                    <Col className="col-2"><FormattedMessage id="ApiGatewayUserDetails.LastName" /></Col>
                        <Col className="col-4">
                            <FormattedMessage id="ApiGatewayUserDetails.LastName" >
                                {
                                    placeholder => <Input type="text" required value={this.state.user.lastName} name="lastName" onChange={this.updateForm.bind(this, 'user')} placeholder={placeholder} />
                                }
                            </FormattedMessage>
                        </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="col-2"><FormattedMessage id="ApiGatewayUserDetails.Email" /></Col>
                        <Col className="col-4">
                            <FormattedMessage id="ApiGatewayUserDetails.Email" >
                                {
                                    placeholder => <Input type="text" required value={this.state.user.email} name="email" onChange={this.updateForm.bind(this, 'user')} placeholder={placeholder} />
                                }
                            </FormattedMessage>
                        </Col>
                    <Col className="col-2"><FormattedMessage id="ApiGatewayUserDetails.CreationDate" /></Col>
                    <Col className="col-4">{this.state.user.registrationDate} </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="col-2"><FormattedMessage id="ApiGatewayUserDetails.HotelId" /></Col>
                        <Col className="col-4">
                            <FormattedMessage id="ApiGatewayUserDetails.HotelId" >
                                {
                                    placeholder => <Input type="text" required value={this.state.user.note} name="note" onChange={this.updateForm.bind(this, 'user')} placeholder={placeholder} />
                                }
                            </FormattedMessage>
                        </Col>
                    <Col className="col-2"><FormattedMessage id="ApiGatewayUserDetails.Status" /></Col>
                    <Col className="col-4"><Badge className={this.state.user.state === "active" ? 'btn-host p-1' : 'p-1'}>{this.state.user.state}</Badge></Col>
                </Row>

                <Row className="mt-4">
                    <Col><h4 ><FormattedMessage id="ApiGatewayUserDetails.Subscriptions" /></h4></Col>
                    <Col className="text-right">
                        <Button disabled={this.state.originalUser.name === undefined} onClick={this.toggleModalSub} className="btn-sm btn-host"><i class="fas fa-plus"></i></Button>
                    </Col>
                        </Row>
                    </form>
                <hr className="my-1" />
                <Row className="mb-1">
                    <Col>
                        <FormattedMessage id="ApiGatewayUserDetails.Name" />
                    </Col>
                    <Col> 
                        <FormattedMessage id="ApiGatewayUserDetails.Scope" />
                    </Col>
                    <Col>                            
                        <FormattedMessage id="ApiGatewayUserDetails.CreationDate" />
                    </Col>
                    <Col>                            
                        <FormattedMessage id="ApiGatewayUserDetails.StartDate" />
                    </Col>
                    <Col>                            
                        <FormattedMessage id="ApiGatewayUserDetails.ExpirationDate" />
                    </Col>
                    <Col>                            
                        <FormattedMessage id="ApiGatewayUserDetails.EndDate" />
                    </Col>
                    <Col>                            
                         <FormattedMessage id="ApiGatewayUserDetails.Status" />
                    </Col>
                    <Col>
                        <FormattedMessage id="ApiGatewayUserDetails.Action" />
                    </Col>
                </Row> 
                {this.state.subscriptions.map((el, k) =>
                    <div key={k}>
                        <Row className="mb-1">
                            <Col>
                                {el.name}
                            </Col>
                            <Col>
                                {el.properties.scope.substring(el.properties.scope.lastIndexOf("/")+1, el.properties.scope.length)  }
                            </Col>
                            <Col>
                                {el.properties.createdDate}
                            </Col>
                            <Col>
                                {el.properties.startDate}
                            </Col>
                            <Col>
                                {el.properties.expirationDate}
                            </Col>
                            <Col>
                                {el.properties.endDate}
                            </Col>
                            <Col>
                                <Badge className={el.properties.state === "active" ? 'btn-host p-1' : 'p-1'}>{el.properties.state}</Badge> 
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" onClick={this.getKeys.bind(this, el.name)}><i className="fas fa-key"></i></Button>
                            </Col>
                        </Row>              

                    </div>
                    
                )}
                
            </StyledCard>

            </div>
        );
    }
}
